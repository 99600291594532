import React, { useRef, useState } from 'react'
import { Button } from '../../../../components/Tailwind'
import { CheckIcon, XIcon } from '@heroicons/react/outline'
import { SiMicrosoftexcel } from 'react-icons/si';
import { FaRegKeyboard } from 'react-icons/fa'
import { useHistory } from 'react-router-dom';
import AddWorkersPageOne from './addWorkersStepOne.jsx'
import AddWorkersPageTwo from './addWorkersStepTwo.jsx'
import { ChevronLeftIcon } from '@heroicons/react/solid';

function AddWorkersPage() {
    const cancelButtonRef = useRef(null);
    const history = useHistory()
    const [pageStep, setPageStep] = useState(1)

    console.log(pageStep)
    return (
        <div className="fixed top-0 left-0 inline-block w-full h-screen text-left align-top transition-all transform bg-white shadow-xl" >
            <div className="flex flex-col">
                <div className="flex-initial">
                    <div className="px-4 pt-4 pb-4 bg-white">
                        <div className="md:flex md:items-center md:justify-between">
                            <div className="flex-1 min-w-0">
                                <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate"></h2>
                                <button
                                    type="button"
                                    ref={cancelButtonRef}
                                    onClick={() => setPageStep(1)}
                                    className={`${pageStep === 1 && 'hidden'} inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                >
                                    <ChevronLeftIcon className="w-5 h-5 mr-2 -ml-1" aria-hidden="true" />
                                    Back
                                    
                                </button>
                            </div>
                            <div className="flex mt-4 md:mt-0 md:ml-4">
                                <button
                                    type="button"
                                    ref={cancelButtonRef}
                                    onClick={() => history.push('./workers')}
                                    className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Cancel
                                    <XIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex-1">
                    <div className={pageStep === 1 ? '' : 'hidden'}>
                        <AddWorkersPageOne 
                            setPageStep={setPageStep}
                        />
                    </div>
                    <div className={pageStep === 1 ? 'hidden' : ''}>
                        <AddWorkersPageTwo 
                            setPageStep={setPageStep}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddWorkersPage