import React, { Fragment } from 'react'
import { withTranslation } from 'react-i18next'
import { classNames } from '../../../../util/helpers.js'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Listbox, Transition } from '@headlessui/react'
import AvatarImage from '../../../../components/AvatarImage/index.jsx'

class AddJobForm extends React.Component {
  state = {
    focused: false,
  }
  render() {
    const { t } = this.props // translations
    const selectedUser = this.props.people.find(i => i.id === this.props.FORM_assignedFacilitatorUserId)
    const selectedEmployer = this.props.employers.find(i => i.id === this.props.FORM_employerId)

    return (
      <form className='space-y-8 divide-y divide-gray-200' style={{ minHeight: '200px' }} onSubmit={this.props.onSubmit}>
        <div className='space-y-8 divide-y divide-gray-200'>
          <div>
            <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
              <div className='sm:col-span-6'>
                <Listbox value={selectedEmployer} onChange={selectedEmployer => this.props.handleChange('FORM_employerId', selectedEmployer.id)}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className='block text-sm font-medium text-gray-700'>Employer</Listbox.Label>
                      <div className='mt-1 relative'>
                        <Listbox.Button className='relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-300 focus:border-primary-300 sm:text-sm'>
                          {selectedEmployer ? (
                            <>
                              <span className='flex items-center'>
                                <span className='ml-3 block truncate'>{selectedEmployer?.fields?.name}</span>
                              </span>
                              <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className='flex items-center'>
                                <span className='inline-block h-6 w-6 rounded-full overflow-hidden bg-gray-100'>
                                  <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                                    <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                                  </svg>
                                </span>

                                <span className='ml-3 block truncate'>Not Assigned</span>
                              </span>
                              <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                              </span>
                            </>
                          )}
                        </Listbox.Button>

                        <Transition show={open} as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
                          <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                            {this.props?.employers.map(employer => (
                              <Listbox.Option key={employer.id} className={({ active }) => classNames(active ? 'text-white bg-primary-300' : 'text-gray-900', 'select-none relative py-2 pl-3 pr-9 cursor-pointer')} value={employer}>
                                {({ selected, active }) => (
                                  <>
                                    <div className='flex items-center'>
                                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>{employer?.fields?.name}</span>
                                    </div>

                                    {selected ? (
                                      <span className={classNames(active ? 'text-white' : 'text-primary-300', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              <div className='sm:col-span-6'>
                <Listbox value={selectedUser} onChange={selectedUser => this.props.handleChange('FORM_assignedFacilitatorUserId', selectedUser.id)}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className='block text-sm font-medium text-gray-700'>Assign to</Listbox.Label>
                      <div className='mt-1 relative'>
                        <Listbox.Button className='relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-primary-300 focus:border-primary-300 sm:text-sm'>
                          {selectedUser ? (
                            <>
                              <span className='flex items-center'>
                                {selectedUser?.fields?.photo ? <img src={selectedUser?.fields?.photo?.url} alt='' className='flex-shrink-0 h-6 w-6 rounded-full' /> : <AvatarImage size={6} />}
                                <span className='ml-3 block truncate'>{selectedUser?.fields?.displayName}</span>
                              </span>
                              <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                              </span>
                            </>
                          ) : (
                            <>
                              <span className='flex items-center'>
                                <span className='inline-block h-6 w-6 rounded-full overflow-hidden bg-gray-100'>
                                  <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                                    <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                                  </svg>
                                </span>

                                <span className='ml-3 block truncate'>Not Assigned</span>
                              </span>
                              <span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
                                <SelectorIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                              </span>
                            </>
                          )}
                        </Listbox.Button>

                        <Transition show={open} as={Fragment} leave='transition ease-in duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
                          <Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
                            {this.props?.people.map(person => (
                              <Listbox.Option key={person.id} className={({ active }) => classNames(active ? 'text-white bg-primary-300' : 'text-gray-900', 'select-none relative py-2 pl-3 pr-9 cursor-pointer')} value={person}>
                                {({ selected, active }) => (
                                  <>
                                    <div className='flex items-center'>
                                      {person?.fields?.photo ? <img src={person?.fields?.photo?.url} alt='' className='flex-shrink-0 h-6 w-6 rounded-full' /> : <AvatarImage size={6} />}
                                      <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}>{person?.fields?.displayName}</span>
                                    </div>

                                    {selected ? (
                                      <span className={classNames(active ? 'text-white' : 'text-primary-300', 'absolute inset-y-0 right-0 flex items-center pr-4')}>
                                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
              <div className='sm:col-span-6'>
                <label htmlFor='FORM_season' className='block text-sm font-medium text-gray-700'>
                  Season
                </label>
                <select 
                  id='FORM_season' 
                  name='FORM_season' 
                  className='mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-300 focus:outline-none focus:ring-primary-300 sm:text-sm' 
                  value={this.props.FORM_season}
                  onChange={e => this.props.handleChange('FORM_season', e.target.value)}
                  >
                    <option value="2021" >2021</option>
                    <option value="2022" >2022</option>
                    <option value="2023" >2023</option>
                    <option value="2024" >2024</option>
                    <option value="2025" >2025</option>
                </select>
              </div>
              <div className='sm:col-span-6'>
                <label htmlFor='FORM_petitionId' className='block text-sm font-medium text-gray-700'>
                  Petition Id
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='FORM_petitionId'
                    id='FORM_petitionId'
                    className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                    value={this.props.FORM_petitionId || ''}
                    onChange={event => this.props.handleChange(event.target.name, event.target.value)}
                  />
                  <p className='mt-2 text-sm text-gray-500'>This is the identifier for the contract workers sign for this job. </p>
                </div>
              </div>
              <div className='sm:col-span-6'>
                <label htmlFor='FORM_externalContractTemplateReferenceId' className='block text-sm font-medium text-gray-700'>
                  Template ID
                </label>
                <div className='mt-1'>
                  <input
                    type='text'
                    name='FORM_externalContractTemplateReferenceId'
                    id='FORM_externalContractTemplateReferenceId'
                    className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                    value={this.props.FORM_externalContractTemplateReferenceId || ''}
                    onChange={event => this.props.handleChange(event.target.name, event.target.value)}
                  />
                  <p className='mt-2 text-sm text-gray-500'>This identifier must be provided to you by a Ruta staff member.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className={'hidden'} type='submit' />
      </form>
    )
  }
}

export default withTranslation()(AddJobForm)
