import React, { Component } from 'react'
import { Link, Redirect } from "react-router-dom"
import { StateContext } from '../../../../../util/state.jsx'
import { Dropdown, Button, ConfirmModal } from '../../../../../components/Tailwind'
import { Menu } from '@headlessui/react'
import { classNames } from '../../../../../util/helpers.js'
import { deleteInvitation, sendInvitationSms } from '../../../../../models/invitation.jsx'

import {
    ChevronLeftIcon,
    ChevronRightIcon,
    MailIcon,
    XCircleIcon,
} from '@heroicons/react/outline'

import WorkerSection from './workerSection'
import InvitationSection from './invitationSection'
import TaskTracker from './taskTracker'
import moment from 'moment'
import { sendReminderSms } from '../../../../../models/user.jsx'

export default class InvitationDetailPage extends React.Component {
    static contextType = StateContext

    state = {
        deleteModalIsOpen: false,
        waitingOnAPIResult: false,
        sentInvitationsTo: [], // short term hack to avoid a user sending a million message invites
    }

    setDeleteModalOpen = (open) => {
        this.setState({ deleteModalIsOpen: open })
    }

    handleSendInvitationSms = async (invitationId, method) => {
        const [{ firebaseUser }, dispatch] = this.context;
        const { employerId } = this.props.match.params

        this.setState({ waitingOnAPIResult: true })

        try {
            let result = await sendInvitationSms(firebaseUser, invitationId, method)
            this.props.listInvitationsForOrganizationAndEmployer(employerId, null)
            let newSentInvitationsTo = this.state.sentInvitationsTo
            newSentInvitationsTo.push(invitationId)
            this.setState({ waitingOnAPIResult: false })
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Success!', content: `Sent reminder text!`, success: true },
                showNotification: true
            })
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Failed to send invitation.', content: `${error.message}`, success: false },
                showNotification: true
            })
            return
        }
    }

    handleDeleteInvitation = async (invitationId) => {
        const [{ firebaseUser }, dispatch] = this.context;
        const { employerId } = this.props.match.params

        this.setState({ waitingOnAPIResult: true })

        try {
            let result = await deleteInvitation(firebaseUser, invitationId)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Success!', content: `Deleted invitation.`, success: true },
                showNotification: true
            })
            this.props.listInvitationsForOrganizationAndEmployer(employerId, null)
            this.setState({ waitingOnAPIResult: false, deleteModalIsOpen: false })
            this.props.history.push(`/facilitator/employers/${employerId}/summary/invited`)
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Failed to delete invitation.', content: `${error.message}`, success: false },
                showNotification: true
            })
            return
        }
    }

    render() {
        const [{ employerWorkersAndMeta, employersAndPagination, employerInvitationsAndPagination }, dispatch] = this.context;
        const [{ isLoadingEmployers, isLoadingOrganization, isLoadingEmployerWorkersAndMeta, isLoadingInvitations }] = this.context;
        const { invitationOnly } = this.props
        const { employerId, workerId, invitationId } = this.props.match.params

        // Wait until all the loading is done
        if (isLoadingEmployers || isLoadingOrganization || isLoadingEmployerWorkersAndMeta || isLoadingInvitations) {
            return (
                <p>Loading...</p>
            )
        }

        const employer = employersAndPagination.result.find(e => e.fields?.id === employerId)
        const invitedWorkers = employerInvitationsAndPagination[employerId]?.result
        const invitedWorker = invitedWorkers.find(w => w.id === invitationId)

        if (!invitedWorker) {
            return (<Redirect to={'../summary/invited'} />)
        }

        const steps = [
            { name: 'Create Account', description: 'Sign up for Ruta.', href: '#', status: 'current' },
            { name: 'Complete Profile', description: 'Confirm all information is correct.', href: '#', status: 'upcoming' },
            { name: 'Add Selfie', description: 'Add a current self picture.', href: '#', status: 'upcoming' },
            { name: 'Proof of Vaccination', description: 'Upload official proof of Covid19 vaccine.', href: '#', status: 'upcoming' },
            { name: 'Passport', description: 'Upload photo of passport.', href: '#', status: 'upcoming' },
            { name: 'Add expenses', description: 'Add travel expenses.', href: '#', status: 'upcoming' },
        ]

        return (
            <>
                <div className="px-4 pt-4 pb-4 shadow bg-slate-50">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                        <div className="flex items-center justify-between">
                            <div className="flex-1 min-w-0">
                                <nav className="sm:flex" aria-label="Breadcrumb">
                                    <ol role="list" className="flex items-center space-x-4">
                                        <li>
                                            <div className="flex">
                                                <Link to='/facilitator/employers' className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    <h2 className="font-bold leading-7 text-gray-900 text-l sm:truncate">Employers</h2>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                                                <Link to="../summary" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    <h2 className="font-bold leading-7 text-l sm:truncate">{employer.fields?.name}</h2>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                                                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    {invitedWorker.fields?.displayName}
                                                </a>
                                            </div>
                                        </li>

                                    </ol>
                                </nav>

                            </div>
                            <div className="flex mt-4 md:mt-0 md:ml-4">
                                <div className="flex items-center justify-center mr-4">
                                    {/* Put stuff on the right if needed */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">

                    <main className="py-10">
                        {/* Page header */}
                        <div className="max-w-3xl px-4 mx-auto sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
                            <div className="flex items-center space-x-5">
                                <div className="flex-shrink-0">
                                    <div className="relative">
                                        <span className="inline-block w-16 h-16 overflow-hidden bg-gray-100 rounded-full">
                                            <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                            </svg>
                                        </span>

                                    </div>
                                </div>
                                <div>
                                    <h1 className="mb-2 text-2xl font-bold text-gray-900">{invitedWorker.fields?.displayName}</h1>
                                    <dd className="flex items-center mt-3 text-sm font-bold text-red-500 capitalize sm:mr-6 sm:mt-0">
                                        {/* <CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" /> Account  */}
                                        <XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" /> Has not signed up for Ruta
                                    </dd>
                                </div>
                            </div>
                            <div className="flex flex-col-reverse mt-6 space-y-4 space-y-reverse justify-stretch sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                                <Button color='red' onClick={() => this.setDeleteModalOpen(true)}>Delete</Button>
                                <ConfirmModal
                                    title='Confirm Delete'
                                    content='Are you sure you delete this invitation?'
                                    buttonLabel='Delete'
                                    open={this.state.deleteModalIsOpen}
                                    setOpen={this.setDeleteModalOpen}
                                    actionOnClick={() => this.handleDeleteInvitation(invitationId)}
                                />

                                <Dropdown label='Send Reminder' >
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                onClick={() => this.handleSendInvitationSms(invitationId, 'email')}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                )}
                                            >
                                                Send Invitation Email
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                onClick={() => this.handleSendInvitationSms(invitationId, 'sms')}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm'
                                                )}
                                            >
                                                Send Invitation Text
                                            </a>
                                        )}
                                    </Menu.Item>
                                </Dropdown>
                            </div>
                        </div>

                        <div className="grid max-w-3xl grid-cols-1 gap-6 mx-auto mt-8 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                                {/* Worker Info */}
                                <InvitationSection
                                    invitation={invitedWorker}
                                    listInvitationsForOrganizationAndEmployer={this.props.listInvitationsForOrganizationAndEmployer}
                                />

                                {/* Invitation Info */}
                                <section aria-labelledby="applicant-information-title">
                                    <div className="bg-white shadow sm:rounded-lg">
                                        <div className="flex flex-wrap items-center justify-between px-4 py-5 sm:px-6 sm:flex-nowrap">
                                            <div className="mt-2">
                                                <h2 className="text-lg font-medium leading-6 text-gray-900">Invitation Details</h2>
                                            </div>
                                        </div>

                                        <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
                                            <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                                                <div className="sm:col-span-1">
                                                    <dt className="text-sm font-medium text-gray-500">Invitation Link</dt>
                                                    <dd className="mt-1 text-sm text-gray-900">{`${process.env.REACT_APP_WEB_HOST}/login?i=${invitedWorker?.fields?.code}`}</dd>
                                                </div>
                                            </dl>
                                            <div className="relative mt-2">
                                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                                    <div className="w-full border-t border-gray-300" />
                                                </div>
                                                <div className="relative flex justify-center">
                                                    <span className="px-2 text-sm text-gray-500 bg-white">Logs</span>
                                                </div>
                                            </div>
                                            <div>
                                                <ul role="list" className="divide-y divide-gray-200">
                                                    {invitedWorker?.fields?.logs.map((log) => (
                                                        <li key={log.id} className="py-4">
                                                            <div className="flex space-x-3">
                                                                <div className="flex-1 space-y-1">
                                                                    <div className="flex items-center justify-between">
                                                                        <h3 className="text-sm font-bold">{log?.type}</h3>
                                                                        <p className="text-sm text-gray-500">{moment(log?.createdAt).format('LLL')}</p>
                                                                    </div>
                                                                    <p className="text-sm text-gray-500">
                                                                        {log?.details}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </section>


                                {/* Logs*/}

                            </div>

                            {/* Tasks */}
                            <TaskTracker steps={steps} />
                        </div>
                    </main>



                </div>

            </>

        )
    }
}
