import React from 'react'
import { Redirect } from "react-router-dom"
import { Link } from "react-router-dom"
import { StateContext } from '../../../../../util/state.jsx'
import {
    ChevronRightIcon,
    CheckCircleIcon,
    XCircleIcon,
    InformationCircleIcon
} from '@heroicons/react/outline'
import { Button } from '../../../../../components/Tailwind'

import WorkerSection from './workerSection'
import TaskTracker from './taskTracker'
import TripSection from './tripSection'
import AssignFacilitatorModal from '../../../modals/AssignFacilitatorModal/index.jsx'
import ApproveForTravelModal from '../../../modals/ApproveForTravelModal/index.jsx'
import WorkerLogs from './workerLogs.jsx'
import AvatarWithViewModal from '../../../components/AvatarWithViewModal/index.jsx'
import { Dropdown, ConfirmModal } from '../../../../../components/Tailwind'
import { Menu } from '@headlessui/react'
import { classNames } from '../../../../../util/helpers.js'
import { downloadReportForWorker, unlinkWorkerFromEmployer } from '../../../../../models/organization.jsx'
import ChangeEmployerModal from '../../../modals/ChangeEmployerModal/index.jsx'

export default class WorkerDetailPage extends React.Component {
    static contextType = StateContext

    state = {
        assignFacilitatorModalIsOpen: false,
        changeEmployerModalIsOpen: false,
        approveForTravelModalIsOpen: false,
        viewPhotoModalIsOpen: false,
        unlinkWorkerModalIsOpen: false
    }

    setUnlinkWorkerModalIsOpen = (open) => this.setState({ unlinkWorkerModalIsOpen: open })

    componentDidMount = () => {
        const [{ employerWorkersAndMeta, employerInvitationsAndPagination, employersAndPagination }, dispatch] = this.context;
        const { employerId } = this.props.match.params

        const employer = employersAndPagination.result.find(e => e.fields?.id === employerId)
        if (typeof (employer) === 'undefined') {
            // If the user navigates to this page and we don't have the employer, go fetch it. 
            this.props.listEmployers({
                employerId: employerId
            }, true)
        }

        let employerData = employerWorkersAndMeta[employerId]
        if (!employerData) {
            // If we don't have any workers for this employer, go get it. Provide filters as null to get the defaults
            this.props.listWorkersForOrganizationAndEmployer(employerId, null)
        }

    }

    handleUnlinkWorker = async () => {
        const [{ firebaseUser, employerWorkersAndMeta, organization }, dispatch] = this.context;
        const { workerId, employerId } = this.props.match.params
        const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

        this.setState({ waitingOnAPIResult: true })

        try {
            let document = await unlinkWorkerFromEmployer(firebaseUser, organization.id, workerId)
            this.setState({ waitingOnAPIResult: false })
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Success!', content: `Removed worker.`, success: true },
                showNotification: true
            })
            this.setUnlinkWorkerModalIsOpen(false)
            this.props.history.push('../summary')
            this.props.listWorkersForOrganizationAndEmployer(employerId, filtersAndPagination)
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Failed to remove document.', content: `${error.message}`, success: false },
                showNotification: true
            })
            return
        }
    }

    toggleAssignFacilitatorModal = (open) => this.setState({ assignFacilitatorModalIsOpen: open })
    toggleChangeEmployerModal = (open) => this.setState({ changeEmployerModalIsOpen: open })
    toggleApproveForTravelModal = (open) => this.setState({ approveForTravelModalIsOpen: open })
    toggleViewPhotoModal = (open) => this.setState({ viewPhotoModalIsOpen: open })

    render() {
        const [{ employerWorkersAndMeta, employersAndPagination, employerInvitationsAndPagination }, dispatch] = this.context;
        const [{ isLoadingEmployers, isLoadingOrganization, isLoadingEmployerWorkersAndMeta, isLoadingInvitations }] = this.context;
        const { invitationOnly } = this.props
        const { employerId, workerId, invitationId } = this.props.match.params
        // Wait until all the loading is done
        if (isLoadingEmployers || isLoadingOrganization || isLoadingEmployerWorkersAndMeta) {
            return (
                <p>Loading...</p>
            )
        }

        const employer = employersAndPagination.result.find(e => e.fields?.id === employerId)


        const invitedWorkers = employerInvitationsAndPagination[employerId]?.result
        const workers = employerWorkersAndMeta[employerId]?.result
        const worker = invitationOnly ? invitedWorkers.find(w => w.id === invitationId) : workers.find(w => w.id === workerId)

        if (!worker) {
            return <Redirect to={'../summary'} />
        }

        const hiddenIfReadOnly = () => this.props.readonly ? 'hidden' : ''

        return (
            <div className="grid w-full grid-flow-row auto-rows-max">
                <div className={`${this.props.readonly ? 'hidden' : ''} px-4 pt-4 pb-4 shadow bg-slate-50`}>
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                        <div className="flex items-center justify-between">
                            <div className="flex-1 min-w-0">
                                <nav className="sm:flex" aria-label="Breadcrumb">
                                    <ol role="list" className="flex items-center space-x-4">
                                        <li>
                                            <div className="flex">
                                                <Link to='/facilitator/employers' className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    <h2 className="font-bold leading-7 text-gray-900 text-l sm:truncate">Employers</h2>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                                                <Link to="../summary" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    <h2 className="font-bold leading-7 text-l sm:truncate">{employer?.fields?.name}</h2>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                                                <a href="#" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    {worker?.fields?.displayName}
                                                </a>
                                            </div>
                                        </li>

                                    </ol>
                                </nav>

                            </div>
                            <div className="flex mt-4 md:mt-0 md:ml-4">
                                <div className="flex items-center justify-center mr-4">
                                    {/* Put stuff on the right if needed */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                    <main className="py-10">
                        {/* Page header */}
                        <div className="max-w-3xl px-4 mx-auto sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl">
                            <div className="flex items-center space-x-5">
                                <div className="flex-shrink-0">
                                    <div className="relative">
                                        <AvatarWithViewModal
                                            worker={worker}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <h1 className="mb-2 text-2xl font-bold text-gray-900">{worker.fields?.displayName}</h1>
                                    {worker?.fields?.approvedForTravel === 'YES' ?
                                        <dd className="flex items-center mt-3 text-sm font-bold text-green-500 capitalize sm:mr-6 sm:mt-0">
                                            <><CheckCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-400" /> Approved for travel</>
                                        </dd>
                                        :
                                        <dd className="flex items-center mt-3 text-sm font-bold text-red-500 capitalize sm:mr-6 sm:mt-0">
                                            <><XCircleIcon className="flex-shrink-0 mr-1.5 h-5 w-5 text-red-400" /> Not approved for travel</>
                                        </dd>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col-reverse mt-6 space-y-4 space-y-reverse justify-stretch sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
                                <Dropdown label='Manage' className={`${hiddenIfReadOnly()} z-50`} >
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                onClick={() => this.setUnlinkWorkerModalIsOpen(true)}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-red-900' : 'text-red-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                            >
                                                Remove
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                onClick={() => this.toggleAssignFacilitatorModal(true)}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                            >
                                                Assign Facilitator
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                onClick={() => this.toggleChangeEmployerModal(true)}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                            >
                                                Change Employer
                                            </a>
                                        )}
                                    </Menu.Item>
                                </Dropdown>
                                <ConfirmModal
                                    title='Remove Worker'
                                    content='Are you sure you remove this worker? All their details will be deleted and they will not be able to login to Ruta. '
                                    buttonLabel='Remove'
                                    open={this.state.unlinkWorkerModalIsOpen}
                                    setOpen={this.setUnlinkWorkerModalIsOpen}
                                    actionOnClick={this.handleUnlinkWorker}
                                />
                                <ChangeEmployerModal
                                    initialEmployerId={worker?.fields?.employer?.id || undefined}
                                    open={this.state.changeEmployerModalIsOpen}
                                    setOpen={this.toggleChangeEmployerModal}
                                    listWorkersForOrganizationAndEmployer={this.props.listWorkersForOrganizationAndEmployer}
                                />
                                <AssignFacilitatorModal
                                    assignedFacilitatorUserId={worker?.fields?.assignedFacilitatorUserId || undefined}
                                    open={this.state.assignFacilitatorModalIsOpen}
                                    setOpen={this.toggleAssignFacilitatorModal}
                                    listWorkersForOrganizationAndEmployer={this.props.listWorkersForOrganizationAndEmployer}
                                />
                                {worker?.fields?.approvedForTravel === 'NO' &&
                                    <Button
                                        onClick={() => this.toggleApproveForTravelModal(true)}
                                        className={`${hiddenIfReadOnly()}`}
                                    >
                                        Approve for travel
                                    </Button>
                                }
                                <ApproveForTravelModal
                                    open={this.state.approveForTravelModalIsOpen}
                                    setOpen={this.toggleApproveForTravelModal}
                                    listWorkersForOrganizationAndEmployer={this.props.listWorkersForOrganizationAndEmployer}
                                    worker={worker}
                                />
                            </div>
                        </div>

                        <div className="grid max-w-3xl grid-cols-1 gap-6 mx-auto mt-8 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
                            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
                                {(!worker?.fields?.assignedFacilitator && !this.props.readonly) &&
                                    <div className={` p-4 border-l-4 border-yellow-400 bg-yellow-50`}>
                                        <div className="flex">
                                            <div className="flex-shrink-0">
                                                <InformationCircleIcon className="w-5 h-5 text-yellow-400" aria-hidden="true" />
                                            </div>
                                            <div className="ml-3">
                                                <h3 className="text-sm font-medium text-yellow-700">Assignment Required</h3>
                                                <div className="mt-2 text-sm text-yellow-700">
                                                    <p>This worker is not assigned to a facilitator. </p>
                                                </div>
                                                <div className="mt-4">
                                                    <div className="-mx-2 -my-1.5 flex">
                                                        <button
                                                            type="button"
                                                            onClick={() => this.toggleAssignFacilitatorModal(true)}
                                                            className="bg-yellow-50 px-2 py-1.5 rounded-md text-sm font-medium text-yellow-800 hover:bg-yellow-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-yellow-50 focus:ring-yellow-600"
                                                        >
                                                            Assign Facilitator
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {/* Worker Info */}
                                <WorkerSection
                                    readonly={this.props.readonly}
                                    worker={worker}
                                    listWorkersForOrganizationAndEmployer={this.props.listWorkersForOrganizationAndEmployer}
                                />


                                {/* Trip Info */}
                                {worker?.fields?.trips.length > 0 &&
                                    <TripSection
                                        readonly={this.props.readonly}
                                        worker={worker}
                                        listWorkersForOrganizationAndEmployer={this.props.listWorkersForOrganizationAndEmployer}
                                    />
                                }


                                {/* Comments*/}

                            </div>
                            <section aria-labelledby="timeline-title" className="space-y-5 lg:col-start-3 lg:col-span-1">
                                {/* Tasks */}
                                <TaskTracker worker={worker} />

                                {/* Audit Log */}
                                <WorkerLogs worker={worker} />
                            </section>

                        </div>
                    </main>



                </div>
            </div>
        )
    }
}
