import { Fragment, useContext, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { HiArchiveBox, HiChevronDown, HiDocumentDuplicate, HiHeart, HiPencilSquare, HiTrash, HiUserCircle } from 'react-icons/hi2'
import { classNames } from '../../../../util/helpers'
import AssignFacilitatorToJobModal from './JobDetail/assignFacilitatorToJobModal'
import AddJobModal from '../../modals/AddJobModal'
import { ConfirmModal } from '../../../../components/Tailwind'
import { deleteJob } from '../../../../models/organization'
import { StateContext } from '../../../../util/state'

const JobDropdownMenu = props => {
  const [{ organization }, dispatch] = useContext(StateContext)
  const { job, listJobs } = props
  const [assignFacilitatorModalIsOpen, setAssignFacilitatorModalIsOpen] = useState(false)
  const [addJobModalIsOpen, setAddJobModalIsOpen] = useState(false)
  const [confirmDeleteJobModalIsOpen, setConfirmDeleteJobModalIsOpen] = useState(false)
  const [isLoadingDeleteJob, setIsLoadingDeleteJob] = useState(false)

  const handleDeleteJob = async () => {
    try {
      setIsLoadingDeleteJob(true)
      await deleteJob(organization.id, job.id)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Successfully delete job ${job?.fields?.employer?.fields.name}.`, waitingOnAPIResult: false, success: true },
        showNotification: true,
      })
      setIsLoadingDeleteJob(false)
      setConfirmDeleteJobModalIsOpen(false)
      listJobs(null)
    } catch (error) {
      console.log(error)
      setIsLoadingDeleteJob(false)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to delete job.`, content: error.message, waitingOnAPIResult: false },
        showNotification: true,
      })
    }
  }

  return (
    <Menu as='div' className='relative inline-block text-left'>
      <div>
        <Menu.Button className='inline-flex w-full justify-center items-center leading-4 rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-primary-300 focus:ring-offset-2 focus:ring-offset-gray-100'>
          Actions
          <HiChevronDown className='-mr-1 ml-2 h-3 w-3' aria-hidden='true' />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items className='absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
          <div className='py-1'>
            <Menu.Item>
              {({ active }) => (
                <a onClick={() => setAddJobModalIsOpen(true)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                  <HiPencilSquare className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' aria-hidden='true' />
                  Edit
                </a>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <a onClick={() => setAssignFacilitatorModalIsOpen(true)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                  <HiUserCircle className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' aria-hidden='true' />
                  Assign Facilitator
                </a>
              )}
            </Menu.Item>
            {/* <Menu.Item>
              {({ active }) => (
                <a
                  
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <HiDocumentDuplicate
                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                  Duplicate
                </a>
              )}
            </Menu.Item> */}
          </div>
          {/* <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <HiArchiveBox className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Archive
                </a>
              )}
            </Menu.Item>
          </div>
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <a
                  
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'group flex items-center px-4 py-2 text-sm'
                  )}
                >
                  <HiHeart className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                  Add to favorites
                </a>
              )}
            </Menu.Item>
          </div>
           */}
          <div className='py-1'>
            <Menu.Item>
              {({ active }) => (
                <a onClick={() => setConfirmDeleteJobModalIsOpen(true)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'group flex items-center px-4 py-2 text-sm')}>
                  <HiTrash className='mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500' aria-hidden='true' />
                  Delete
                </a>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
      <AssignFacilitatorToJobModal open={assignFacilitatorModalIsOpen} setOpen={setAssignFacilitatorModalIsOpen} job={props.job} listJobs={listJobs} />
      <ConfirmModal
        title='Delete Job'
        content='Are you sure you want to delete this job?'
        buttonLabel='Delete'
        success={false}
        open={confirmDeleteJobModalIsOpen}
        setOpen={setConfirmDeleteJobModalIsOpen}
        actionOnClick={handleDeleteJob}
        loading={isLoadingDeleteJob}
      />
      <AddJobModal open={addJobModalIsOpen} setOpen={setAddJobModalIsOpen} listJobs={props.listJobs} job={props.job} />
    </Menu>
  )
}

export default JobDropdownMenu
