import React, { Component } from 'react';
import { Redirect, Route, Link, withRouter } from "react-router-dom"
import { StateContext } from '../../../../../util/state.jsx'
import SearchInput from '../../../../../components/SearchInput'
import PropTypes from 'prop-types';
import { CheckIcon, XIcon } from '@heroicons/react/outline';
import { Badge } from '../../../../../components/Tailwind/index.jsx';

class WorkerLedger extends Component {
    static contextType = StateContext
    
    handlePaginationChange = (delta) => {
        const [{employerWorkersAndMeta}, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta
        filtersAndPagination['currentPage'] = filtersAndPagination['currentPage'] + delta
        this.props.listWorkersForOrganizationAndEmployer(employerId, filtersAndPagination)
      }  
    
    render() {
        const [{ employerWorkersAndMeta }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const workers = employerWorkersAndMeta[employerId]?.result
        let filtersAndPagination = employerWorkersAndMeta[employerId]?.meta
        let firstRecordCount = (filtersAndPagination?.currentPage - 1) * filtersAndPagination?.pageSize + 1
        let lastRecordCount = filtersAndPagination?.count < (filtersAndPagination?.currentPage * filtersAndPagination?.pageSize) ? filtersAndPagination?.count : filtersAndPagination?.currentPage * filtersAndPagination?.pageSize
        let totalCount = filtersAndPagination?.count

        return (
            <>
                <div className="mt-4 md:flex md:items-center md:justify-between">
                    <div className="flex-1 min-w-0">
                        <h1 className="text-2xl font-bold text-gray-900">All Active Workers</h1>
                        <p className="text-gray-500 text-s">Workers who have an account with Ruta to track their information, documents, and expenses.  </p>
                    </div>
                    <div className="flex mt-4 md:mt-0 md:ml-4">

                        {/* <div className="relative flex items-center min-w-0 mr-4">
                            <input
                                type="text"
                                placeholder="Search workers..."
                                id="search"
                                className="block w-full pr-12 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                            />
                            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                <kbd className="inline-flex items-center px-2 font-sans text-sm font-medium text-gray-400 border border-gray-200 rounded">
                                    ⌘K
                                </kbd>
                            </div>
                        </div> */}
                        {/* <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            Invite New Worker
                        </button> */}

                    </div>
                </div>
                <div className="flex flex-col py-4">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Facilitator
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Worker
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Contact
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Progress
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Travel approval
                                        </th>
                                        <th scope="col" className="relative px-6 py-3">
                                            <span className="sr-only">Edit</span>
                                        </th>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {workers.map((worker) => (
                                            <tr key={worker?.id} className="cursor-pointer hover:bg-gray-50" onClick={() => this.props.history.push(`/facilitator/employers/${employerId}/workers/${worker?.id}`)}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="flex-shrink-0 w-10 h-10">
                                                            {worker?.fields?.assignedFacilitator?.fields?.photo ? (
                                                                <img className="object-cover w-10 h-10 rounded-full" src={worker?.fields?.assignedFacilitator?.fields?.photo.url} alt="" />
                                                            ) : (
                                                                <span className="inline-block w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                                                                    <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                    </svg>
                                                                </span>
                                                            )}
                                                        </div>
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">{worker?.fields?.assignedFacilitator?.fields ? worker?.fields?.assignedFacilitator?.fields?.firstName + " " + worker?.fields?.assignedFacilitator?.fields?.lastName : <Badge color='gray' label='Not assigned' /> }</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        {/* <div className="flex-shrink-0 w-10 h-10">
                                                            {worker?.fields?.photo ? (
                                                                <img className="object-cover w-10 h-10 rounded-full" src={worker?.fields?.photo.url} alt="" />
                                                            ) : (
                                                                <span className="inline-block w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                                                                    <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                    </svg>
                                                                </span>
                                                            )}
                                                        </div> */}
                                                        <div className="ml-4">
                                                            <div className="text-sm font-medium text-gray-900">{worker?.fields?.displayName}</div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">{worker?.fields?.displayPhoneNumber}</div>
                                                    <div className="text-sm text-gray-500">{worker?.fields?.email}</div>
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                    <div>
                                                        <h4 className="sr-only">Status</h4>
                                                        <div className="" aria-hidden="true">
                                                            <div className="overflow-hidden bg-gray-200 rounded-lg">
                                                                <div className="h-2 bg-green-600 rounded-full" style={{ width: `${worker?.fields?.taskTracker?.percentComplete*100.00}%` }} />
                                                            </div>
                                                            <div className="hidden grid-cols-4 mt-1 text-sm font-medium text-center text-gray-600 sm:grid">
                                                                <div className="col-span-4 text-gray-800">{`${worker?.fields?.taskTracker?.percentComplete*5} of 5`}</div>
                                                                {/* <div className="text-center text-gray-800">Migrating database</div>
                                                                <div className="text-center">Compiling assets</div>
                                                                <div className="text-right">Deployed</div> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">
                                                        <div className="flex">
                                                        {worker?.fields?.approvedForTravel === 'YES' ?
                                                            <>
                                                                <CheckIcon className="w-5 h-5 mr-2 text-green-400" aria-hidden="true" />
                                                                <Badge color='green' label='APPROVED' />
                                                            </>
                                                            :
                                                            <>
                                                                <XIcon className="w-5 h-5 mr-2 text-red-400" aria-hidden="true" />
                                                                <Badge color='red' label='NOT APPROVED' />
                                                            </>
                                                        }
                                                    </div>
                                                </td>
                                                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                        Edit
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <nav
                                className="flex items-center justify-between py-3 bg-white border-t border-gray-200"
                                aria-label="Pagination"
                            >
                                <div className="hidden sm:block">
                                    <a
                                        onClick={ () => this.handlePaginationChange(-1)}
                                        className={`${firstRecordCount === 1 ? 'hidden' : ''} relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50`}
                                    >
                                        Previous
                                    </a>
                                    <a
                                        onClick={ () => this.handlePaginationChange(1)}
                                        className={`${lastRecordCount >= totalCount ? 'hidden' : ''} relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50`}
                                    >
                                        Next
                                    </a>
                                </div>
                                <div className="flex justify-between flex-1 sm:justify-end">
                                    <p className="text-sm text-gray-700">
                                        Showing <span className="font-medium">{firstRecordCount}</span> to <span className="font-medium">{lastRecordCount}</span> of{' '}
                                        <span className="font-medium">{totalCount}</span> results
                                    </p>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

WorkerLedger.propTypes = {

};

export default withRouter(WorkerLedger);