import React from 'react'
import { HiCalendar, HiChevronRight } from 'react-icons/hi2'
import { useContext } from 'react'
import { StateContext } from '../../../util/state'
import { country_codes_to_names, state_codes_to_names } from '../../../util/constants'
import { useTranslation } from 'react-i18next';

const Step0JobPicker = props => {
  const [{user}, dispatch] = useContext(StateContext)
  const { t, i18n } = useTranslation();

  const handlePickJob = (job) => {
    console.log(job)
    if (job?.workerDidConfirm && job?.externalContractStatus === 'completed') {
      props.changeStep(`8/${job?.job?.id}`)
    } else {
      props.changeStep(`1/${job?.job?.id}`)
    }
  }

  return (
    <div className="mb-6">
      <p className="text-center mb-6">{t('What company are you working for?')}</p>
      <div className="overflow-hidden bg-white shadow sm:rounded-md mb-6">
      <ul role="list" className="divide-y divide-gray-200">
        {user?.fields?.jobs.map((job) => (
          <li key={job?.job?.id}>
            <a onClick={() => handlePickJob(job)} className="block hover:bg-gray-50 cursor-pointer">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate w-full" >
                    <div className="flex justify-between text-sm">
                      <p className="items-start truncate font-medium text-blue-600">{job?.job?.employer?.name}</p>
                      <div className="ml-1 items-end flex font-normal text-gray-500">
                        <p>
                          <time dateTime={job?.job?.season}>{job?.job?.season} </time>
                        </p>
                        <HiCalendar className="ml-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                    </div>
                      {/* <p className="ml-1 flex-shrink-0 font-normal text-gray-500">in {job?.job?.employer?.stateProvinceRegion} {job?.job?.employer?.country}</p> */}
                    </div>
                    <div className="mt-2 flex">
                      {/* <div className="flex items-center text-sm text-gray-500">
                        <HiCalendar className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                        <p>
                          <time dateTime={job?.job?.season}>{job?.job?.season} </time>
                        </p>
                      </div> */}

                      <div className="flex items-center text-sm text-gray-500">
                        <p>
                          {state_codes_to_names[job?.job?.employer?.stateProvinceRegion]}, {country_codes_to_names[job?.job?.employer?.country]}
                        </p>
                      </div>

                    </div>
                  </div>
                  
                </div>
                <div className="ml-5 flex-shrink-0">
                  <HiChevronRight className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
    </div>
    </div>
  )
}

export default Step0JobPicker
