import React from 'react'
import ImageCropper from '../../../components/ImageCropper'
import { useTranslation } from 'react-i18next';

const Step5SetPhoto = props => {
  const { t, i18n } = useTranslation();
  return (
    <div className='mb-6 pb-6'>
        <ImageCropper 
          initialDocumentUrl={null}
          formInputName='FORM_photo'
          handleChange={props.handleChange}
          formLabel={t('Choose an image')}
          initialCrop={{
              unit: '%', // default, can be 'px' or '%'
              x: 5,
              y: 5,
              width: 90,
              aspect: 1
          }}
        />
    </div>
  )
}

export default Step5SetPhoto
