import { Fragment, useLayoutEffect, useRef, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronLeftIcon, DotsVerticalIcon, SearchIcon } from '@heroicons/react/outline'
import AvatarImage from '../../../../../components/AvatarImage'
import { HiBadgeCheck, HiCheckCircle, HiDocumentDownload, HiExternalLink, HiFilter, HiOutlineDocumentDownload, HiOutlineExclamation, HiOutlineMail, HiXCircle } from 'react-icons/hi'
import { classNames, validateEmail } from '../../../../../util/helpers'
import BulkSendInvitationModal from '../../../modals/BulkSendInvitationModal'
import { Button, ConfirmModal } from '../../../../../components/Tailwind'
import { downloadContractForWorkerOnJob, downloadJobReport, generateContractForWorkerOnJob, generateSignUrlForWorkerOnJob, removeWorkerFromJob, sendJobReportEmailToMember, updateWorkerStatusForJob } from '../../../../../models/organization'
import { useContext } from 'react'
import { StateContext } from '../../../../../util/state'
import { HiArrowPath, HiExclamationTriangle, HiOutlineArrowDownTray, HiOutlineArrowPath, HiOutlineCheck, HiOutlineLink, HiOutlineSparkles, HiSparkles } from 'react-icons/hi2'
import UserProfileModal from '../../../modals/userProfileModal'
import WorkerSlideover from './workerSlideover'
import moment from 'moment'
import WorkerOnJobModal from '../../../modals/WorkerOnJobModal'
import BulkSendContractReminderModal from '../../../modals/BulkSendContractReminderModal'
import { AiOutlineFileZip } from 'react-icons/ai'

const WorkerTable = props => {
  const [{ organization, analytics }, dispatch] = useContext(StateContext)
  const [searchText, setSearchText] = useState('')
  const [currentTab, setCurrentTab] = useState('all')
  const checkbox = useRef()
  const { job } = props
  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedWorkers, setSelectedWorkers] = useState([])

  const [bulkSendInvitationsModalIsOpen, setBulkSendInvitationsModalIsOpen] = useState(false)
  const [bulkSendContractReminderModalIsOpen, setBulkSendContractReminderModalIsOpen] = useState(false)
  const [bulkApproveModalIsOpen, setBulkApproveModalIsOpen] = useState(false)

  const [isLoadingBulkApprove, setIsLoadingBulkApprove] = useState(false)
  const [removeWorkerConfirmModal, setremoveWorkerConfirmModal] = useState({ open: false, worker: undefined })
  const [isLoadingRemoveWorker, setisLoadingRemoveWorker] = useState(false)

  const [regenerateContractModal, setregenerateContractModal] = useState({ open: false, worker: undefined })
  const [isLoadingGenerateContract, setisLoadingGenerateContract] = useState(false)

  const [viewWorkerModalIsOpen, setviewWorkerModalIsOpen] = useState({ open: false, worker: undefined })
  const [isLoadingUpdateWorker, setisLoadingUpdateWorker] = useState(false)

  const [viewContractModalIsOpen, setviewContractModalIsOpen] = useState({ open: false, contractUrl: undefined })
  const [isLoadingViewContract, setisLoadingViewContract] = useState(false)

  const [workerOnJobModalIsOpen, setworkerOnJobModalIsOpen] = useState({ open: false, workerOnJob: undefined })
  const [workerSlideoverIsOpen, setworkerSlideoverIsOpen] = useState({ open: false, workerId: undefined })

  const [sendContractReminderEmailModalIsOpen, setsendContractReminderEmailModalIsOpen] = useState({ open: false, worker: undefined })
  const [isLoadingContractReminderEmail, setisLoadingContractReminderEmail] = useState(false)

  const [isLoadingJobReport, setisLoadingJobReport] = useState(false)

  const [isLoadingSendEmployerEmail, setisLoadingSendEmployerEmail] = useState(false)

  const changeSlideoverWorker = changeBy => {
    const currentWorkerId = workerSlideoverIsOpen?.workerId
    const currentIndex = job?.fields?.workers.findIndex(w => w.id === currentWorkerId)
    if (currentIndex + 1 + changeBy > job?.fields?.workers.length || currentIndex + 1 + changeBy < 0) return // first or last
    const nextWorker = job?.fields?.workers[currentIndex + changeBy]
    setworkerSlideoverIsOpen({ open: true, workerId: nextWorker.id })
  }

  const tabs = [
    { id: 'all', name: 'All', href: '#', current: currentTab === 'all' },
    { id: 'not_registered', name: 'Not Registered', href: '#', current: currentTab === 'not_registered' },
    { id: 'awaiting_signature', name: 'Awaiting Signature', href: '#', current: currentTab === 'awaiting_signature' },
    // { id: 'needs_worker_confirmation', name: 'Not Verified', href: '#', current: currentTab === 'needs_worker_confirmation' },
    { id: 'needs_facilitator_approval', name: 'Needs Final Review', href: '#', current: currentTab === 'needs_facilitator_approval' },
    { id: 'ready_for_visa', name: 'Ready for Visa', href: '#', current: currentTab === 'ready_for_visa' },
  ]

  const tableRows = []
  let currentTabWorkers = []
  let workersByActiveTab = undefined

  if (!props.isLoadingJob) {
    // Precompute all the sub lists in memory
    let sortedWorkers = job?.fields?.workers.sort((a, b) => a?.fields?.displayName?.localeCompare(b?.fields?.displayName))

    if (searchText) {
      sortedWorkers = sortedWorkers.filter(w => {
        return w.fields?.displayName ? w.fields?.displayName.toLowerCase().includes(searchText.toLowerCase()) : w.fields?.email?.toLowerCase().includes(searchText.toLowerCase())
      })
    }

    workersByActiveTab = {
      all: (currentTabWorkers = sortedWorkers),
      not_registered: (currentTabWorkers = sortedWorkers.filter(w => w?.fields?.stytchUserId === null)),
      awaiting_signature: (currentTabWorkers = sortedWorkers.filter(w => w?.jobDetails?.externalContractStatus !== 'completed')),
      // needs_worker_confirmation: (currentTabWorkers = sortedWorkers.filter(w => !w?.jobDetails?.workerDidVerifyIdentity || !w?.jobDetails?.workerDidConfirm)),
      needs_facilitator_approval: (currentTabWorkers = sortedWorkers.filter(w => !w?.jobDetails?.facilitatorDidApprove)),
      ready_for_visa: (currentTabWorkers = sortedWorkers.filter(w => w?.jobDetails?.facilitatorDidApprove)),
    }
    currentTabWorkers = workersByActiveTab[currentTab]

    // Build the table based on the current tab
    currentTabWorkers?.forEach((worker, i) => {
      tableRows.push(
        <tr key={worker.id} className={selectedWorkers.includes(worker) ? 'bg-gray-50 hover:bg-gray-50' : 'hover:bg-gray-50'} onClick={() => setworkerSlideoverIsOpen({ open: true, workerId: worker?.id })}>
          <td className='relative w-12 px-6 sm:w-16 sm:px-8' onClick={e => e.stopPropagation()}>
            {selectedWorkers.includes(worker) && <div className='absolute inset-y-0 left-0 w-0.5 bg-primary-400' />}
            <input
              type='checkbox'
              className='absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-primary-400 focus:ring-indigo-500 sm:left-6'
              value={worker.id}
              checked={selectedWorkers.includes(worker)}
              onChange={e => setSelectedWorkers(e.target.checked ? [...selectedWorkers, worker] : selectedWorkers.filter(p => p !== worker))}
            />
          </td>
          <td className={classNames('cursor-pointer whitespace-nowrap py-3 pr-3 text-sm font-medium', selectedWorkers.includes(worker) ? 'text-primary-400' : 'text-gray-900')}>
            <div className='flex items-center'>
              <div className='flex-shrink-0 w-10 h-10'>{worker?.fields?.photo ? <img className='object-cover w-10 h-10 rounded-full' src={worker?.fields?.photo.url} alt='' /> : <AvatarImage size={10} />}</div>
              <div className='ml-4'>
                <div className='flex font-medium text-gray-900'>{worker?.fields?.displayName}</div>
                <div className='text-gray-500'>
                  {worker?.fields?.email && worker?.fields?.preferredContactMethod === 'EMAIL'
                    ? `${worker?.fields?.email}`
                    : worker?.fields?.displayPhoneNumber && worker?.fields?.preferredContactMethod === 'PHONE'
                    ? `${worker?.fields?.displayPhoneNumber}`
                    : worker?.fields?.displayPhoneNumber && worker?.fields?.preferredContactMethod === 'WHATSAPP'
                    ? `${worker?.fields?.displayPhoneNumber}`
                    : 'No preferred contact method'}
                </div>
              </div>
            </div>
          </td>
          <td className='flex justify-between px-3 py-4 space-x-8 text-sm whitespace-nowrap items-top'>
            <div className='relative break-all'>
              {worker?.jobDetails?.externalContractTemplateOverrideReferenceId && <p className='absolute text-gray-500 text-xs font-black w-24 text-right -top-1.5 right-6'>W</p>}
              <p className='justify-center'>
                {worker?.jobDetails?.externalContractReferenceId ? (
                  <svg className='w-3 h-3 mx-auto mb-1 text-green-400' fill='currentColor' viewBox='0 0 8 8'>
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                ) : (
                  <svg className='w-3 h-3 mx-auto mb-1 text-red-400' fill='currentColor' viewBox='0 0 8 8'>
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                )}
              </p>
              <p className='w-24 text-xs text-center'>Contract Ready</p>
            </div>

            <div className='break-all'>
              <p className='justify-center'>
                {worker?.fields?.stytchUserId ? (
                  <svg className='w-3 h-3 mx-auto mb-1 text-green-400' fill='currentColor' viewBox='0 0 8 8'>
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                ) : (
                  <svg className='w-3 h-3 mx-auto mb-1 text-red-400' fill='currentColor' viewBox='0 0 8 8'>
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                )}
              </p>
              <p className='w-24 text-xs text-center'>Registered</p>
            </div>

            <div className='break-all'>
              <p className='justify-center'>
                {worker?.jobDetails?.externalContractStatus === 'completed' ? (
                  <svg className='w-3 h-3 mx-auto mb-1 text-green-400' fill='currentColor' viewBox='0 0 8 8'>
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                ) : (
                  <svg className='w-3 h-3 mx-auto mb-1 text-red-400' fill='currentColor' viewBox='0 0 8 8'>
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                )}
              </p>
              <p className='w-24 text-xs text-center'>Contract Signed</p>
            </div>

            <div className='break-all'>
              <p className='justify-center'>
                {worker?.jobDetails?.facilitatorDidApprove ? (
                  <svg className='w-3 h-3 mx-auto mb-1 text-green-400' fill='currentColor' viewBox='0 0 8 8'>
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                ) : (
                  <svg className='w-3 h-3 mx-auto mb-1 text-red-400' fill='currentColor' viewBox='0 0 8 8'>
                    <circle cx={4} cy={4} r={3} />
                  </svg>
                )}
              </p>
              <p className='w-24 text-xs text-center'>Visa Ready</p>
            </div>
          </td>

          <td className='px-3 py-3 text-sm text-gray-500 whitespace-nowrap' onClick={e => e.stopPropagation()}>
            {/* First, create a contract */}
            {!worker?.jobDetails?.externalContractReferenceId && (
              <Button color='light' onClick={() => setregenerateContractModal({ open: true, worker: worker })}>
                Create Contract
              </Button>
            )}

            {/* Then, Invite them to Ruta */}
            {worker?.jobDetails?.externalContractReferenceId && !worker?.fields?.stytchUserId && (
              <Button
                color='light'
                onClick={() => {
                  setSelectedWorkers([worker])
                  setBulkSendInvitationsModalIsOpen(true)
                }}
              >
                Invite to Ruta
              </Button>
            )}

            {/* Then, send a contract reminder email */}
            {worker?.jobDetails?.externalContractReferenceId && worker?.fields?.stytchUserId && worker?.jobDetails?.externalContractStatus !== 'completed' && (
              <Button
                color='light'
                onClick={() => {
                  setSelectedWorkers([worker])
                  setBulkSendContractReminderModalIsOpen(true)
                }}
              >
                Send Contract Reminder
              </Button>
            )}

            {/* Then, approve for visa */}

            {worker?.jobDetails?.externalContractReferenceId && worker?.fields?.stytchUserId && worker?.jobDetails?.externalContractStatus === 'completed' && !worker?.fields?.facilitatorDidApprove && (
              <Button
                color='light'
                onClick={() => {
                  setSelectedWorkers([worker])
                  setBulkApproveModalIsOpen(true)
                }}
              >
                Mark as Ready
              </Button>
            )}

            {/* Then, download the contract */}

            {worker?.jobDetails?.externalContractReferenceId && worker?.fields?.stytchUserId && worker?.jobDetails?.externalContractStatus === 'completed' && worker?.fields?.facilitatorDidApprove && (
              <Button color='light' onClick={() => handleDownloadContract(worker)}>
                Download <HiExternalLink className='w-5 h-5 ml-1' />
              </Button>
            )}
          </td>

          <td className='relative py-3 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6' onClick={e => e.stopPropagation()}>
            <Menu as='div' className='flex-shrink-0 pr-2'>
              <Menu.Button className='inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2'>
                <span className='sr-only'>Open options</span>
                <DotsVerticalIcon className='w-5 h-5' aria-hidden='true' />
              </Menu.Button>
              <Transition
                as={Fragment}
                enter='transition ease-out duration-100'
                enterFrom='transform opacity-0 scale-95'
                enterTo='transform opacity-100 scale-100'
                leave='transition ease-in duration-75'
                leaveFrom='transform opacity-100 scale-100'
                leaveTo='transform opacity-0 scale-95'
              >
                <Menu.Items className='absolute z-10 w-48 mx-3 mt-1 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg cursor-pointer right-10 top-3 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                  <div className='py-1'>
                    <Menu.Item>
                      {({ active }) => (
                        <a onClick={() => setviewWorkerModalIsOpen({ open: true, worker: worker })} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                          View / Change
                        </a>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <a onClick={() => setworkerOnJobModalIsOpen({ open: true, workerOnJob: worker?.jobDetails })} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                          Update Job Details
                        </a>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            setSelectedWorkers([worker])
                            setBulkSendInvitationsModalIsOpen(true)
                          }}
                          className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                        >
                          Invite to Ruta
                        </a>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <a
                          onClick={() => {
                            setSelectedWorkers([worker])
                            setBulkSendContractReminderModalIsOpen(true)
                          }}
                          className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                        >
                          Send Contract Reminder
                        </a>
                      )}
                    </Menu.Item>

                    <Menu.Item>
                      {({ active }) => (
                        <a onClick={() => setregenerateContractModal({ open: true, worker: worker })} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                          Create Contract
                        </a>
                      )}
                    </Menu.Item>

                    {worker?.jobDetails?.externalContractReferenceId && worker?.jobDetails?.externalContractStatus !== 'completed' && (
                      <Menu.Item>
                        {({ active }) => (
                          <a onClick={() => handleViewContract(worker.id)} className={classNames(active ? 'bg-gray-100 text-red-900' : 'text-red-700', 'flex px-4 py-2 text-sm justify-end')}>
                            <HiOutlineExclamation className='w-5 h-5 mr-2 text-red-600' />
                            View Contract
                          </a>
                        )}
                      </Menu.Item>
                    )}

                    {worker?.jobDetails?.externalContractStatus === 'completed' && (
                      <Menu.Item>
                        {({ active }) => (
                          <a onClick={() => handleDownloadContract(worker)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                            Download Contract
                          </a>
                        )}
                      </Menu.Item>
                    )}
                    {!worker?.jobDetails?.facilitatorDidApprove && (
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            onClick={() => {
                              setSelectedWorkers([worker])
                              setBulkApproveModalIsOpen(true)
                            }}
                            className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}
                          >
                            Mark as Ready
                          </a>
                        )}
                      </Menu.Item>
                    )}
                  </div>
                  <div className='py-1'>
                    <Menu.Item>
                      {({ active }) => (
                        <a onClick={() => setremoveWorkerConfirmModal({ open: true, worker: worker })} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                          Remove from Job
                        </a>
                      )}
                    </Menu.Item>
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </td>
        </tr>
      )
    })
  } else {
    for (let index = 0; index < 5; index++) {
      tableRows.push(
        <tr key={index}>
          <td colSpan='8'>
            <div className='flex p-4 space-x-4 animate-pulse'>
              <div className='w-10 h-10 rounded-full bg-slate-200'></div>
              <div className='flex-1 py-1 space-y-4'>
                <div className='h-2 rounded bg-slate-200'></div>
                <div className='space-y-3'>
                  <div className='grid grid-cols-3 gap-4'>
                    <div className='h-2 col-span-2 rounded bg-slate-200'></div>
                    <div className='h-2 col-span-1 rounded bg-slate-200'></div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )
    }
  }

  const handleSearchChange = e => {
    const searchText = e.target.value
    setSearchText(searchText)
  }

  useLayoutEffect(() => {
    const isIndeterminate = selectedWorkers.length > 0 && selectedWorkers.length < currentTabWorkers.length
    setChecked(selectedWorkers.length === currentTabWorkers.length && currentTabWorkers.length !== 0)
    setIndeterminate(isIndeterminate)
    checkbox.current.indeterminate = isIndeterminate
  }, [selectedWorkers])

  function toggleAll() {
    setSelectedWorkers(checked || indeterminate ? [] : currentTabWorkers)
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  const handBulkApproveWorkers = async () => {
    try {
      setIsLoadingBulkApprove(true)
      for (let i = 0; i < selectedWorkers.length; i++) {
        const worker = selectedWorkers[i]
        await updateWorkerStatusForJob(organization.id, job.id, worker.id, { facilitatorDidApprove: true })
      }
      setIsLoadingBulkApprove(false)
      await props.retrieveJobDetails()
      setBulkApproveModalIsOpen(false)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Successfully approved ${selectedWorkers.length} workers.`, waitingOnAPIResult: false, success: true },
        showNotification: true,
      })
    } catch (error) {
      console.log(error)
      setIsLoadingBulkApprove(false)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to approve all workers. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  const handleDownloadJobReport = async () => {
    try {
      setisLoadingJobReport(true)
      const response = await downloadJobReport(organization.id, job.id)
      await props.retrieveJobDetails()
      if (!response?.downloadUrl) {
        return
      }
      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = response?.downloadUrl
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Done!', content: `You successfully downloaded the report. `, success: true },
        showNotification: true,
      })
      if (analytics) analytics.track('Downloaded Job Report')
      setisLoadingJobReport(false)
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Failed to download the report.', content: `${error.message}`, success: false },
        showNotification: true,
      })
      setisLoadingJobReport(false)
      return
    }
  }

  const handleSendJobReportEmailToMember = async () => {
    try {
      setisLoadingSendEmployerEmail(true)
      const member = job?.fields?.employer?.fields?.members?.[0]
      await sendJobReportEmailToMember(organization?.id, job?.id, job?.fields?.employer?.id, member?.id)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Success!`, content: `Email sent to ${member?.fields?.email}. `, success: true },
        showNotification: true,
      })
      setisLoadingSendEmployerEmail(false)
    } catch (error) {
      console.log(error)
      setisLoadingSendEmployerEmail(false)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Failed to send email.', content: `${error.message}`, success: false },
        showNotification: true,
      })
      return
    }
  }

  const copyJobReportLinkToClipboard = async () => {
    await navigator.clipboard.writeText(job?.fields?.reportUrl)
    dispatch({
      type: 'showNotification',
      notificationDetails: { title: `Copied to clipboard!`, waitingOnAPIResult: false, success: true },
      showNotification: true,
    })
  }

  const handleRemoveWorkerFromJob = async () => {
    const workerToDelete = removeWorkerConfirmModal?.worker
    try {
      setisLoadingRemoveWorker(true)
      await removeWorkerFromJob(organization?.id, job?.id, workerToDelete?.id)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Successfully removed ${workerToDelete?.fields?.displayName}.`, waitingOnAPIResult: false, success: true },
        showNotification: true,
      })
      setisLoadingRemoveWorker(false)
      setremoveWorkerConfirmModal({ open: false, worker: undefined })
    } catch (error) {
      console.log(error)
      setisLoadingRemoveWorker(false)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to remove ${workerToDelete?.fields?.displayName}. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  const handleRegenerateContract = async () => {
    try {
      // Make sure email is a valid email
      if (!validateEmail(regenerateContractModal?.worker?.fields?.email)) {
        dispatch({
          type: 'showNotification',
          notificationDetails: { title: `${regenerateContractModal?.worker?.fields?.firstName} does not have an email`, content: 'An email is required to create a contract. Please add an email for this worker.', waitingOnAPIResult: false },
          showNotification: true,
        })
        return
      }

      setisLoadingGenerateContract(true)
      await generateContractForWorkerOnJob(organization?.id, job?.id, regenerateContractModal?.worker?.id)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Successfully generated contract for ${regenerateContractModal?.worker?.fields?.displayName}.`, waitingOnAPIResult: false, success: true },
        showNotification: true,
      })
      await props.retrieveJobDetails()
      setisLoadingGenerateContract(false)
      setregenerateContractModal({ open: false, worker: undefined })
    } catch (error) {
      console.log(error)
      setisLoadingGenerateContract(false)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to create contract for ${regenerateContractModal?.worker?.fields?.displayName}. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  const handleDownloadContract = async worker => {
    try {
      // setisLoadingViewContract(true)
      // setviewContractModalIsOpen({ open: true, contractUrl: null })

      const response = await downloadContractForWorkerOnJob(organization?.id, job?.id, worker?.id)
      console.log(response)
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `${job?.fields?.employer?.fields?.name}_${worker?.fields?.displayName}_${moment().format('yyyy-MM-DD')}.zip`) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)

      // setviewContractModalIsOpen({ open: true, contractUrl: result?.data?.url })
      // setisLoadingViewContract(false)
      // window.open(result?.data?.url, '_blank')
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to view contract. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  const handleViewContract = async workerId => {
    try {
      setisLoadingViewContract(true)
      setviewContractModalIsOpen({ open: true, contractUrl: null })
      const result = await generateSignUrlForWorkerOnJob(organization?.id, job?.id, workerId)
      setviewContractModalIsOpen({ open: true, contractUrl: result?.data?.url })
      setisLoadingViewContract(false)
      // window.open(result?.data?.url, '_blank')
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to view contract. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  return (
    <>
      <div className='md:flex md:items-center md:justify-between'>
        {/* <div className='flex-1 min-w-0'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>Workers</h3>
        </div> */}
        <div className='flex'>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative rounded-md shadow-sm'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <SearchIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
            </div>
            <input
              type='search'
              name='search'
              id='search'
              onChange={handleSearchChange}
              value={searchText}
              className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
              placeholder='Search Workers'
            />
          </div>
        </div>
        <div className='flex items-center space-x-2'>
          <h2 className='text-sm font-bold uppercase'>Job Report: </h2>
          <Button disabled={job?.fields?.reportUrl === null} color='white' onClick={handleSendJobReportEmailToMember} loading={isLoadingSendEmployerEmail}>
            <HiOutlineMail className='w-5 h-5 mr-2' />
            Send Email
          </Button>
          <Button disabled={job?.fields?.reportUrl === null} color='white' onClick={copyJobReportLinkToClipboard}>
            <HiOutlineLink className='w-5 h-5 mr-2' />
            Copy Link
          </Button>
          <Button disabled={job?.fields?.reportUrl === null} color='white' onClick={() => window.open(job?.fields?.reportUrl, '_blank', 'noreferrer')}>
            <HiOutlineArrowDownTray className='w-5 h-5 mr-2' />
            Download
          </Button>
          <Button loading={isLoadingJobReport} color='white' onClick={() => handleDownloadJobReport()}>
            <HiOutlineSparkles className='w-5 h-5 mr-2' />
            Create
          </Button>
        </div>
      </div>

      {/* TABS */}
      <div className='mt-2'>
        <div className='sm:hidden'>
          <label htmlFor='tabs' className='sr-only'>
            Select a tab
          </label>
          {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
          <select
            id='tabs'
            name='tabs'
            className='block w-full py-2 pl-3 pr-10 text-base border-gray-300 rounded-md focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'
            defaultValue={tabs.find(tab => tab.current).name}
          >
            {tabs.map(tab => (
              <option key={tab.name}>{tab.name}</option>
            ))}
          </select>
        </div>
        <div className='hidden pt-2 bg-white rounded-t-lg shadow sm:block'>
          <div className='border-b border-gray-200'>
            <nav className='flex px-4 -mb-px space-x-8' aria-label='Tabs'>
              {tabs.map(tab => (
                <a
                  key={tab.name}
                  onClick={() => setCurrentTab(tab.id)}
                  className={classNames(
                    tab.current ? 'border-primary-500 text-primary-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                    'whitespace-nowrap flex py-3 px-1 border-b-2 font-medium text-sm cursor-pointer'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                  {workersByActiveTab ? (
                    <span className={classNames(tab.current ? 'bg-primary-100 text-primary-600' : 'bg-gray-100 text-gray-900', 'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block')}>
                      {workersByActiveTab[tab.id].length}
                    </span>
                  ) : null}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <div className='flex flex-col'>
        <div>
          <div className='inline-block min-w-full align-middle'>
            <div className='relative rounded-sm shadow ring-1 ring-black ring-opacity-5'>
              {selectedWorkers.length > 0 && (
                <div className='absolute top-0 flex items-center h-12 space-x-3 left-12 bg-white-50 sm:left-16'>
                  <Button className='text-xs h-7' color='primary' onClick={() => setBulkSendInvitationsModalIsOpen(true)}>
                    Send Registration Reminder
                  </Button>
                  <Button className='text-xs h-7' color='primary' onClick={() => setBulkSendContractReminderModalIsOpen(true)}>
                    Send Contract Reminder
                  </Button>
                  <Button className='text-xs h-7' color='primary' onClick={() => setBulkApproveModalIsOpen(true)}>
                    Ready for Visa
                  </Button>
                </div>
              )}
              <table className='min-w-full divide-y divide-gray-300 table-fixed'>
                <thead className='bg-white'>
                  <tr>
                    <th scope='col' className='relative w-12 px-6 sm:w-16 sm:px-8'>
                      <input type='checkbox' className='absolute w-4 h-4 -mt-2 border-gray-300 rounded left-4 top-1/2 text-primary-400 focus:ring-primary-500 sm:left-6' ref={checkbox} checked={checked} onChange={toggleAll} />
                    </th>
                    <th scope='col' className='w-36 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                      <div>Worker</div>
                    </th>
                    {/* <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Contract
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Ruta Account
                    </th>
                    <th scope='col' className='px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Visa Status
                    </th> */}
                    <th scope='col' className='w-44 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                      Status
                    </th>
                    <th scope='col' className='w-24 px-3 py-3.5 text-sm font-semibold text-gray-900 text-left'>
                      Action Required
                    </th>
                    <th scope='col' className='w-12 relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6'>
                      <span className='sr-only'>Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className='bg-white divide-y divide-gray-200'>{tableRows}</tbody>
              </table>
            </div>
          </div>
          <BulkSendInvitationModal open={bulkSendInvitationsModalIsOpen} setOpen={setBulkSendInvitationsModalIsOpen} selectedWorkers={selectedWorkers} job={job} />
          <BulkSendContractReminderModal open={bulkSendContractReminderModalIsOpen} setOpen={setBulkSendContractReminderModalIsOpen} selectedWorkers={selectedWorkers} job={job} />
          <ConfirmModal
            title='Mark as Ready'
            content='Mark a worker as ready if you have reviewed their information, and their profile is ready to submit for a visa application. '
            buttonLabel='Ready for Visa'
            open={bulkApproveModalIsOpen}
            setOpen={setBulkApproveModalIsOpen}
            actionOnClick={handBulkApproveWorkers}
            loading={isLoadingBulkApprove}
          />
          <ConfirmModal
            title='Remove Worker'
            content='Remove a worker if they are no longer working this job. '
            buttonLabel='Remove'
            open={removeWorkerConfirmModal?.open}
            setOpen={open => setremoveWorkerConfirmModal({ open: open, worker: undefined })}
            actionOnClick={handleRemoveWorkerFromJob}
            loading={props.isLoadingJob || isLoadingRemoveWorker}
          />
          <ConfirmModal
            title='Create Contract'
            content='Create a new worker contract if you need to make changes to the contract.'
            buttonLabel='Create'
            success={true}
            open={regenerateContractModal?.open}
            setOpen={open => setregenerateContractModal({ open: open, worker: undefined })}
            actionOnClick={handleRegenerateContract}
            loading={props.isLoadingJob || isLoadingGenerateContract}
          />
          <ConfirmModal
            title='View Contract'
            content='Do not sign this contract -- it is meant to be signed by the worker.'
            buttonLabel='View'
            open={viewContractModalIsOpen?.open}
            setOpen={open => setviewContractModalIsOpen({ open: open, contractUrl: undefined })}
            actionOnClick={() => window.open(viewContractModalIsOpen?.contractUrl, '_blank')}
            loading={isLoadingViewContract}
          />
          <UserProfileModal open={viewWorkerModalIsOpen?.open} setOpen={open => setviewWorkerModalIsOpen({ open: open, worker: undefined })} profile={viewWorkerModalIsOpen?.worker} pullLatestAfterUpdate={props.retrieveJobDetails} />
          <WorkerSlideover
            open={workerSlideoverIsOpen?.open}
            setOpen={setworkerSlideoverIsOpen}
            worker={job?.fields?.workers.find(w => w.id === workerSlideoverIsOpen?.workerId)}
            pullLatestAfterUpdate={props.retrieveJobDetails}
            changeSlideoverWorker={changeSlideoverWorker}
          />
          <WorkerOnJobModal
            open={workerOnJobModalIsOpen?.open}
            setOpen={open => setworkerOnJobModalIsOpen({ open: open, workerOnJob: undefined })}
            workerOnJob={workerOnJobModalIsOpen?.workerOnJob}
            pullLatestAfterUpdate={props.retrieveJobDetails}
          />
        </div>
      </div>
    </>
  )
}

export default WorkerTable
