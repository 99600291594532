import React, { useContext, useEffect, useRef } from 'react'
import { StateContext } from './state.jsx'
import { retrieveUser } from '../models/user'
import axios from 'axios'
import { parseJwt } from './helpers.js'
import * as FullStory from '@fullstory/browser'
import { AnalyticsBrowser } from '@june-so/analytics-next';

function AuthWrapper() {
  const [{ sessionToken, sessionJwt, user, analytics }, dispatch] = useContext(StateContext)

  // On component mount, create a June AnalyticsBrowser instance.
  useEffect(() => {
    const loadAnalytics = async () => {
      let [response] = await AnalyticsBrowser.load({
        writeKey: process.env.REACT_APP_JUNE_API_KEY,
      });
      dispatch({
        type: 'setAnalytics',
        analytics: response,
      })
    };
    if (!process.env.REACT_APP_WEB_HOST.includes('localhost')) {
      loadAnalytics();
    }
  }, []);

  const calledOnce = useRef(false) // helper to only call this function once on load
  // https://www.robinwieruch.de/react-useeffect-only-once/
  useEffect(() => {
    if (calledOnce.current) return

    // Send a pageview event once Analytics is ready.
    if (analytics && user) {
      console.log('Initializing June')
      analytics.identify(user.id, {
        name: user.fields?.displayName,
        email: user?.fields?.email,
        phoneNumber: user.fields?.phoneNumber,
        group: user?.fields?.group,
        role: user?.fields?.role,
        organizationId: user?.fields?.organizationId,
        employerId: user?.fields?.employerId,
        facilitatorId: user?.fields?.facilitatorId,
        stytchUserId: user?.fields?.stytchUserId,
        preferredContactMethod: user?.fields?.preferredContactMethod,
      })
      analytics.track('Loaded App')
      calledOnce.current = true 
    }
  }, [analytics, user]);

  // This runs on app load. session tokens will be undefined if they aren't stored in the local storage.
  // If this happens, set user to null from undefined and the user will be required to login
  // If both tokens are defined but user is not, then go get the user and dispatch it. User will be routed to proper page
  if (typeof sessionToken === 'undefined' || typeof sessionJwt === 'undefined') {
    // Make the user login by setting to null
    dispatch({
      type: 'setUserAndToken',
      user: null,
      sessionToken: null,
      sessionJwt: null,
    })
  } else if (sessionToken && sessionJwt && !user) {
    // Grab the user based on the jwt
    console.log('Logged in, getting user... ')
    console.log(sessionToken)
    // Set token as default header for api reqeusts
    axios.defaults.headers.common = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${sessionToken}`,
    }

    // Sub is the user ID from stytch.
    const { sub } = parseJwt(sessionJwt)

    retrieveUser(null, sub)
      .then(retrievedUser => {
        console.log('Got user', retrievedUser)
        if (retrievedUser.id) {
          // so as to not identify user as null and merge all anon data
          window.heap.identify(retrievedUser.id)
          window.heap.addUserProperties({
            Name: retrievedUser?.fields?.displayName,
            displayName: retrievedUser?.fields?.displayName,
            email: retrievedUser?.fields?.email,
            phoneNumber: retrievedUser?.fields?.phoneNumber,
            group: retrievedUser?.fields?.group,
            role: retrievedUser?.fields?.role,
            organizationId: retrievedUser?.fields?.organizationId,
            employerId: retrievedUser?.fields?.employerId,
            facilitatorId: retrievedUser?.fields?.facilitatorId,
            approvedForTravel: retrievedUser?.fields?.approvedForTravel,
            stytchUserId: retrievedUser?.fields?.stytchUserId,
            preferredContactMethod: retrievedUser?.fields?.preferredContactMethod,
          })
          FullStory.identify(retrievedUser.id, {
            phoneNumber: retrievedUser.fields?.phoneNumber,
            displayName: retrievedUser.fields?.displayName,
            email: retrievedUser?.fields?.email,
            group: retrievedUser?.fields?.group,
            role: retrievedUser?.fields?.role,
            organizationId: retrievedUser?.fields?.organizationId,
            employerId: retrievedUser?.fields?.employerId,
            facilitatorId: retrievedUser?.fields?.facilitatorId,
            approvedForTravel: retrievedUser?.fields?.approvedForTravel,
            stytchUserId: retrievedUser?.fields?.stytchUserId,
            preferredContactMethod: retrievedUser?.fields?.preferredContactMethod,
          })
        }
        dispatch({
          type: 'setUser',
          user: retrievedUser,
        })
      })
      .catch(error => {
        console.log(error)
        if (error?.code === 401) {
          dispatch({
            type: 'showNotification',
            notificationDetails: { title: `Expired session.`, content: 'Please log in again.', waitingOnAPIResult: false },
            showNotification: true,
          })
        } else {
          dispatch({
            type: 'showNotification',
            notificationDetails: { title: `Could not log in...`, content: `${error.message}`, waitingOnAPIResult: false },
            showNotification: true,
          })
        }
        // user is not authenticated, session has expired or been revoked.
        dispatch({
          type: 'setUserAndToken',
          user: null,
          sessionToken: null,
          sessionJwt: null,
        })
      })
  }
  return <></>
}

export default AuthWrapper
