import React, { Component, Fragment } from 'react'
import { StateContext } from '../../../../../util/state.jsx'
import { withRouter } from 'react-router';
import {
    CheckCircleIcon,
    XCircleIcon,
    ChevronDownIcon,
    PencilAltIcon,
    EyeIcon,
    DocumentTextIcon,
    ExclamationCircleIcon,
} from '@heroicons/react/outline'
import mapboxgl from 'mapbox-gl'; // or "const mapboxgl = require('mapbox-gl');"
import moment from 'moment';
import { Dropdown, ConfirmModal, Modal, Button } from '../../../../../components/Tailwind'
import { Transition, Menu } from '@headlessui/react';
import TripModal from '../../../modals/tripModal.jsx'
import DocumentModal from '../../../modals/documentModal.jsx'
import { classNames } from '../../../../../util/helpers.js'
import { deleteDocument } from '../../../../../models/document';
import ExpenseSection from './TripSections/expenseSection.jsx'
import { downloadReportForWorker } from '../../../../../models/organization.jsx';

mapboxgl.accessToken = 'pk.eyJ1IjoiZGVzZHVnZ2FucnV0YSIsImEiOiJja3Y1eTJsNGEwcGVmMnZvOHg1d2Z2ZnE2In0.TaXw7GU00giW4N0gMSgiVw';

class TripSection extends Component {
    constructor(props) {
        super(props);
        this.mapContainer = React.createRef();
        this.dropzoneRef = React.createRef()
    }
    static contextType = StateContext
    state = {
        latestTrip: this.props.worker?.fields?.trips.sort((a, b) => moment(a) < moment(b))[0],

        lng: -70.9,
        lat: 42.35,
        zoom: 9,
        editTripModalIsOpen: false,
        editDocumentModalIsOpen: false,
        selectedDocument: null,
        selectedDocumentType: null,
        deleteDocumentModalIsOpen: false,
        viewDocumentModalIsOpen: false,

        editExpenseModalIsOpen: false,
        deleteExpenseModalIsOpen: false,
        viewExpenseModalIsOpen: false,
        selectedExpense: null,

        waitingOnAPIResult: false
    }
    toggleTripModal = (open) => this.setState({ editTripModalIsOpen: open })
    toggleDocumentModal = (open, document, documentType) => this.setState({ editDocumentModalIsOpen: open, selectedDocument: document, selectedDocumentType: documentType })
    toggleDeleteDocumentModal = (open, document) => this.setState({ deleteDocumentModalIsOpen: open, selectedDocument: document })
    toggleViewDocumentModal = (open, document) => this.setState({ viewDocumentModalIsOpen: open, selectedDocument: document })

    toggleExpenseModal = (open, expense) => this.setState({ editExpenseModalIsOpen: open, selectedExpense: expense })
    toggleDeleteExpenseModal = (open, expense) => this.setState({ deleteExpenseModalIsOpen: open, selectedExpense: expense })
    toggleViewExpenseModal = (open, expense) => this.setState({ viewExpenseModalIsOpen: open, selectedExpense: expense })

    componentDidMount = () => {
        
      }
    
      componentDidUpdate = (prevProps, prevState, snapshot) => {
        if (this.props.match.params?.workerId !== prevProps.match.params?.workerId) {
            this.setState({latestTrip: this.props.worker?.fields?.trips.sort((a, b) => moment(a) < moment(b))[0]})
        }
      }

    handleDownloadReport = async () => {
        const [{ firebaseUser, employerWorkersAndMeta, organization }, dispatch] = this.context;
        const { workerId } = this.props.match.params
        const { latestTrip } = this.state

        this.setState({ waitingOnAPIResult: true })

        try {
            await downloadReportForWorker(firebaseUser, organization.id, workerId, latestTrip.id)
            this.setState({ waitingOnAPIResult: false })
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Done!', content: `You successfully downloaded the report. `, success: true },
                showNotification: true
            })
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Failed to download the report.', content: `${error.message}`, success: false },
                showNotification: true
            })
            return
        }

    }

    handleDeleteDocument = async (tripId, documentId) => {
        const [{ firebaseUser, employerWorkersAndMeta }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

        this.setState({ waitingOnAPIResult: true })

        try {
            let document = await deleteDocument(firebaseUser, tripId, documentId)
            this.setState({ waitingOnAPIResult: false })
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Success!', content: `Removed document.`, success: true },
                showNotification: true
            })
            this.props.pullLatestAfterUpdate()
            this.toggleDeleteDocumentModal(false)
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: 'Failed to remove document.', content: `${error.message}`, success: false },
                showNotification: true
            })
            return
        }
    }

    render() {
        const { worker } = this.props
        // select the latest trip
        const latestTrip = this.state.selectedTripId 
            ? worker?.fields?.trips.filter(t =>  t.id === this.state.selectedTripId)
            : worker?.fields?.trips.sort((a, b) => moment(a) < moment(b))[0]

        console.log(latestTrip)
        const documentsMap = {
            'PASSPORT': {
                'title': 'Current Passport'
            },
            'VISA': {
                'title': 'Current Work Visa'
            },
            'BORDER_CROSSING': {
                'title': 'I-94 US Border Document'
            },
            'COVID_TEST': {
                'title': 'Recent Covid Test'
            },
            'COVID_VACCINE_PROOF': {
                'title': 'Proof of Covid 19 Vaccine'
            }
        }
        
        latestTrip?.fields?.documents.forEach(doc => {
            const type = doc?.fields?.type
            if (Object.keys(documentsMap).includes(type)) {
                documentsMap[type].document = doc
            }
        })
        const expenses = latestTrip?.fields?.expenses

        const perDiemRate = 13.17
        const totalUSD = expenses.reduce((partial_sum, a) => partial_sum + ((a?.fields?.currency === 'USD' && a?.fields?.payer?.id === worker.id) ? Number(a?.fields?.total) : 0), 0);
        const totalMXN = expenses.reduce((partial_sum, a) => partial_sum + ((a?.fields?.currency === 'MXN' && a?.fields?.payer?.id === worker.id) ? Number(a?.fields?.total) : 0), 0);
        const totalUSDFac = expenses.reduce((partial_sum, a) => partial_sum + ((a?.fields?.currency === 'USD' && a?.fields?.payer?.id !== worker.id) ? Number(a?.fields?.total) : 0), 0);
        const totalMXNFac = expenses.reduce((partial_sum, a) => partial_sum + ((a?.fields?.currency === 'MXN' && a?.fields?.payer?.id !== worker.id) ? Number(a?.fields?.total) : 0), 0);
        const tripLength = (latestTrip?.fields?.arrivalDate && latestTrip?.fields?.departureDate) ? moment(latestTrip?.fields?.arrivalDate).diff(moment(latestTrip?.fields?.departureDate), 'days') : 'Enter travel dates'
        const tripPerDiemTotal = tripLength * perDiemRate
        const stats = [
            // { name: 'Travel Days', stat: `${tripLength} days`, detail: null, change: '12%', changeType: 'increase' },
            { name: 'Total Per Diem', stat: `$${tripPerDiemTotal}`, detail: `${tripLength} days @ USD $13.17` },
            { name: 'Worker Receipts', stat: `$${totalUSD} / $${totalMXN}`, detail: 'USD / MXN' },
            { name: 'Facilitator Receipts', stat: `$${totalUSDFac} / $${totalMXNFac}`, detail: 'USD / MXN' },
        ]

        return (
            <section aria-labelledby="applicant-information-title">
                <div className="">
                    <div className="flex flex-wrap items-center justify-between px-4 py-4 sm:px-6 sm:flex-nowrap">
                        <div className="mt-2">
                            <h2 className="text-lg font-medium leading-6 text-gray-900">Trip to {latestTrip?.fields?.displayDestination}</h2>
                        </div>
                        <div className="flex-shrink-0 ml-4">
                            {worker?.fields?.trips.length > 1 &&
                                <Dropdown label='Pick Trip' className="mr-4">
                                    {worker?.fields?.trips.map(trip =>
                                        <Menu.Item>
                                            {({ active }) => (
                                                <a
                                                    onClick={() => this.setState({ selectedTripId: trip.id })}
                                                    className={classNames(
                                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                        'block px-4 py-2 text-sm'
                                                    )}
                                                >
                                                    {trip?.fields?.displayDestination} / {moment(trip?.fields?.departureDate).format('YYYY')}
                                                </a>
                                            )}
                                        </Menu.Item>
                                    )}
                                </Dropdown>
                            }

                            <Button
                                onClick={this.handleDownloadReport}
                                className={`${this.props.readonly ? 'hidden' : ''} mr-4`}
                            >
                                Download Report
                            </Button>
                            <button
                                type="button"
                                onClick={() => this.toggleTripModal(true)}
                                className={`${this.props.readonly ? 'hidden' : ''} inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500`}
                            >
                                Update Trip
                            </button>
                        </div>
                    </div>
                    <TripModal
                        open={this.state.editTripModalIsOpen}
                        setOpen={this.toggleTripModal}
                        trip={latestTrip}
                        pullLatestAfterUpdate={this.props.pullLatestAfterUpdate}
                    />


                    <div className="border-t border-gray-200">
                        <dl className="grid grid-cols-1 overflow-hidden bg-white divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
                            {stats.map((item) => (
                                <div key={item.name} className="px-4 py-5 sm:p-6">
                                    <dt className="text-base font-normal text-gray-900">{item.name}</dt>
                                    <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
                                        <div className="flex items-baseline text-2xl font-semibold text-green-600">
                                            {item.stat}
                                        </div>
                                    </dd>
                                    <dt className="text-sm font-medium text-gray-500">{item.detail}</dt>
                                </div>
                            ))}
                        </dl>
                    </div>


                    {/* <div ref={this.mapContainer} className="border-t border-gray-200 map-container" style={{ height: '300px' }} /> */}

                    <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">From</dt>
                                <dd className="mt-1 text-sm text-gray-900">{latestTrip?.fields?.displayOrigin}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">To</dt>
                                <dd className="mt-1 text-sm text-gray-900">{latestTrip?.fields?.displayDestination}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Departure Date</dt>
                                <dd className="mt-1 text-sm text-gray-900">{moment(latestTrip?.fields?.departureDate).format('YYYY-MM-DD')}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Arrival Date</dt>
                                <dd className="mt-1 text-sm text-gray-900">{moment(latestTrip?.fields?.arrivalDate).format('YYYY-MM-DD')}</dd>
                            </div>


                            <div className={`sm:col-span-2`}>

                                <dt className="text-sm font-medium text-gray-500">Documents</dt>

                                <dd className="mt-1 text-sm text-gray-900">
                                    <ul role="list" className="border border-gray-200 divide-y divide-gray-200 rounded-md">
                                        {Object.keys(documentsMap).map((kind) => {
                                            return (
                                                <li key={documentsMap[kind].title} className="flex items-center justify-between py-3 pl-3 pr-4 text-sm" >
                                                    <div className="flex items-center flex-1 w-0">
                                                        {documentsMap[kind]?.document ? <CheckCircleIcon className="flex-shrink-0 w-5 h-5 text-green-400" aria-hidden="true" /> : <XCircleIcon className="flex-shrink-0 w-5 h-5 text-red-400" aria-hidden="true" />}
                                                        <span className="flex-1 w-0 ml-2 truncate"><b>{documentsMap[kind].title} </b></span>
                                                    </div>
                                                    <div className="flex-shrink-0 ml-4">
                                                        {documentsMap[kind]?.document?.fields?.document?.url &&
                                                            <button
                                                                onClick={() => this.toggleViewDocumentModal(true, documentsMap[kind]?.document)}
                                                                className="inline-flex items-center px-2 py-1 ml-4 mr-4 text-sm font-medium leading-5 text-gray-700 bg-white border border-gray-300 rounded-lg shadow-sm hover:bg-gray-50"
                                                            >
                                                                View
                                                                <EyeIcon className="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                            </button>
                                                        }
                                                        {(!documentsMap[kind]?.document && !this.props.readonly) &&
                                                            <button
                                                                onClick={() => this.toggleDocumentModal(true, documentsMap[kind]?.document, kind)}
                                                                className="inline-flex items-center px-2 py-1 ml-4 text-sm font-medium leading-5 text-red-700 bg-white border border-red-300 rounded-lg shadow-sm hover:bg-red-50">
                                                                Upload
                                                                <ExclamationCircleIcon className="w-5 h-5 ml-2 text-red-400 group-hover:text-red-500" aria-hidden="true" />
                                                            </button>
                                                        }
                                                        {documentsMap[kind]?.document &&
                                                            <Menu as="div" className={`${this.props.readonly ? 'hidden' : ''} relative inline-block text-left`}>
                                                                <div>
                                                                    <Menu.Button className="inline-flex justify-center w-full px-2 py-1 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500">
                                                                        Manage
                                                                        <ChevronDownIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                                                                    </Menu.Button>
                                                                </div>

                                                                <Transition
                                                                    as={Fragment}
                                                                    enter="transition ease-out duration-100"
                                                                    enterFrom="transform opacity-0 scale-95"
                                                                    enterTo="transform opacity-100 scale-100"
                                                                    leave="transition ease-in duration-75"
                                                                    leaveFrom="transform opacity-100 scale-100"
                                                                    leaveTo="transform opacity-0 scale-95"
                                                                >
                                                                    <Menu.Items className="absolute right-0 z-50 w-40 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                                        <div className="py-1">
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <a
                                                                                        onClick={() => this.toggleDocumentModal(true, documentsMap[kind]?.document, kind)}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                                                            'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                                                                        )}
                                                                                    >

                                                                                        <PencilAltIcon className="w-5 h-5 mr-3 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                                                                                        Edit
                                                                                    </a>
                                                                                )}
                                                                            </Menu.Item>
                                                                            <Menu.Item>
                                                                                {({ active }) => (
                                                                                    <a
                                                                                        onClick={() => this.toggleDeleteDocumentModal(true, documentsMap[kind]?.document)}
                                                                                        className={classNames(
                                                                                            active ? 'bg-gray-100 text-red-900' : 'text-red-700',
                                                                                            'group flex items-center px-4 py-2 text-sm cursor-pointer'
                                                                                        )}
                                                                                    >
                                                                                        <XCircleIcon className="w-5 h-5 mr-3 text-red-400 group-hover:text-red-500" aria-hidden="true" />
                                                                                        Remove
                                                                                    </a>
                                                                                )}
                                                                            </Menu.Item>
                                                                        </div>
                                                                    </Menu.Items>
                                                                </Transition>
                                                            </Menu>

                                                        }

                                                    </div>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </dd>
                                <DocumentModal
                                    open={this.state.editDocumentModalIsOpen}
                                    setOpen={this.toggleDocumentModal}
                                    document={this.state.selectedDocument}
                                    documentType={this.state.selectedDocumentType}
                                    trip={latestTrip}
                                    pullLatestAfterUpdate={this.props.pullLatestAfterUpdate}
                                />
                                <ConfirmModal
                                    title='Remove Document'
                                    content='Are you sure you remove this document?'
                                    buttonLabel='Remove'
                                    open={this.state.deleteDocumentModalIsOpen}
                                    setOpen={this.toggleDeleteDocumentModal}
                                    actionOnClick={() => this.handleDeleteDocument(latestTrip.id, this.state.selectedDocument.id)}
                                />
                                <Modal
                                    title='View Document'
                                    buttonLabel='Done'
                                    open={this.state.viewDocumentModalIsOpen}
                                    setOpen={this.toggleViewDocumentModal}
                                    actionOnClick={() => this.toggleViewDocumentModal(false, null)}
                                >
                                    {this.state.selectedDocument?.fields?.document?.url.includes('.pdf') ?
                                        <object data={this.state.selectedDocument?.fields?.document?.url} type="application/pdf" className='h-96' width="100%" height="100%">
                                            <p><a href={this.state.selectedDocument?.fields?.document?.url}>Click here to view</a></p>
                                        </object>
                                        :
                                        <img className="rounded-md h-50 w-50" src={this.state.selectedDocument?.fields?.document?.url} alt="" />
                                    }
                                </Modal>
                            </div>
                            
                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Expenses</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                <ExpenseSection
                        readonly={this.props.readonly}
                        worker={worker}
                        trip={latestTrip}
                        expenses={expenses}
                        pullLatestAfterUpdate={this.props.pullLatestAfterUpdate}
                    />

                                </dd>
                            </div>
                        </dl>
                    </div>



                </div>
            </section>

        )
    }
}

export default withRouter(TripSection)