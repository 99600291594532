import React from 'react'
import PropTypes from 'prop-types'

function Badge(props) {
    const { label, color, className } = props;
    return (
        <span className={`${className} inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-${color}-100 text-${color}-800`}>
            {label}
        </span>
    )
}

Badge.defaultProps = {
    label: "Badge Text",
    color: 'gray'  // options gray, red, yellow, green, blue, indigo, purple, pink
}

export default Badge

