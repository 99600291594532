import React, { Component } from 'react';
import { Link, withRouter } from "react-router-dom"
import { StateContext } from '../../../util/state.jsx'
import WorkerLedger from '../../FacilitatorV2/pages/Employers/EmployerDetail/WorkerLedger'
import { ChevronRightIcon } from '@heroicons/react/outline'
import EmployerPortalWorkerLedger from './employerPortalWorkerLedger.jsx';

class EmployerPortalWorkerDashboard extends Component {
    static contextType = StateContext

    state = {
    }

    componentDidMount = () => {
        const [{ employerWorkersAndMeta, employerInvitationsAndPagination, employersAndPagination }, dispatch] = this.context;
        const { employerId } = this.props.match.params

        let employerData = employerWorkersAndMeta[employerId]
        if (!employerData) {
            // If we don't have any workers for this employer, go get it. Provide filters as null to get the defaults
            this.props.listWorkersForOrganizationAndEmployer(employerId, null)
        }
    }

    render() {
        const { employerId } = this.props?.match.params
        const [{ isLoadingEmployers, employersAndPagination, isLoadingOrganization, organization, isLoadingEmployerWorkersAndMeta, employerWorkersAndMeta, isLoadingInvitations, employerInvitationsAndPagination }, dispatch] = this.context;

        // Wait until all the loading is done
        if (isLoadingOrganization || isLoadingEmployerWorkersAndMeta ) {
            return (
                <p>Loading...</p>
            )
        }
        const employer = organization
        return (
            <>
                {/* <Route path={`/facilitator/employers/:employerId/workers/:workerId`} component={WorkerDetailPage} /> */}
                <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                    <header className="pt-4">
                        <div className="md:flex md:justify-between">
                            <div className="flex-1 min-w-0">
                                <h1 className="text-3xl font-bold leading-tight text-gray-900">Workers</h1>
                            </div>
                            <div className="flex mt-4 space-x-4 md:mt-0 md:ml-4">
                            </div>
                        </div>
                    </header>

                    <EmployerPortalWorkerLedger
                        listWorkersForOrganizationAndEmployer={this.props.listWorkersForOrganizationAndEmployer}
                    />

                </div>


            </>
        );
    }
}


export default withRouter(EmployerPortalWorkerDashboard);