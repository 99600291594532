import React from 'react'
import {AiOutlineLoading3Quarters} from 'react-icons/ai'
import { classNames } from '../../../util/helpers';

const sizeMap = {
    default: 'text-sm py-2 px-4',
    lg: 'text-base py-2 px-4',
    xl: 'text-base py-3 px-5',
}

const colorMap = {
    light: 'bg-light hover:bg-light-100 focus:ring-light-200 border border-stone-300',
    primary: 'bg-primary-200 hover:bg-primary-300 focus:ring-primary-300 border border-transparent',
    accent: 'bg-accent hover:bg-accent-700 focus:ring-accent-800 border border-transparent',
    secondary: 'bg-secondary-700 hover:bg-secondary-900 focus:ring-secondary-900 border border-transparent',
    white: 'border border-gray-300 bg-white text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100'
}
const fontColorMap = {
    light: 'text-black',
    primary: 'text-black',
    accent: 'text-white',
    secondary: 'text-white',
    white: 'text-black'
}

function Button(props) {
    const { color, children, onClick, disabled, className, loading, size, fluid, innerRef} = props;

    return (
        <button
            type="button"
            ref={innerRef}
            onClick={onClick}
            disabled={disabled || loading}
            className={classNames(
                className,
                'content-center inline-flex items-center rounded-md shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2',
                'disabled:opacity-50 disabled:cursor-not-allowed',
                fluid ? 'w-full flex justify-center' : '',
                sizeMap[size],
                colorMap[color],
                fontColorMap[color]
            )}
        >
            { loading &&   <AiOutlineLoading3Quarters className="inline animate-spin h-5 w-5 mr-3" ></AiOutlineLoading3Quarters> }
            {children}
        </button>
    )
}

Button.defaultProps = {
    color: 'primary', // options gray, red, yellow, green, blue, indigo, purple, pink,
    size: 'default',
    fluid: false,
}

export default Button
