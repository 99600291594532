import React, { Fragment, useContext, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { StateContext } from '../../../util/state'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'
import { generateSignUrlForWorkerOnJob } from '../../../models/organization'
import { useHistory, useParams } from 'react-router-dom'
import { Button, Modal } from '../../../components/Tailwind'
import { useRef } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { retrieveUser } from '../../../models/user'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

const Step6SignContract = props => {
  const [{ user }, dispatch] = useContext(StateContext)
  const history = useHistory()
  const { t, i18n } = useTranslation()
  const [isLoadingUrl, setIsLoadingUrl] = useState(false)
  const [signUrl, setsignUrl] = useState(undefined)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { jobId } = useParams()
  const [isLoadingiFrame, setIsLoadingiFrame] = useState(false)
  // const { setsignUrl, setIsModalOpen } = props
  // console.log(signUrl)
  // console.log(props)
  const cancelButtonRef = useRef(null)

  useEffect(() => {
    window.addEventListener('message', async ({ origin, data }) => {
      if (origin !== 'https://app.useanvil.com') return
      console.log(data)
      if (data && typeof data === 'object') {
        // You will receive the event object when the signing process is complete, or
        // the user sees an error. This object will contain several properties:
        // `action`, `signerStatus`, `signerEid`, `nextSignerEid`, `documentGroupStatus`,
        // `documentGroupEid`, `etchPacketEid`, `weldDataEid`.
        //
        // e.g.
        //
        // {
        //  action: 'signerComplete',
        //  signerStatus: 'completed',
        //  signerEid: 'Jc1ZJisPnpF5lHyfyqBW',
        //  nextSignerEid: 'WBqyfyHl5FpnPsiJZ1cJ',
        //  documentGroupStatus: 'partial',
        //  documentGroupEid: 'nEKq2eGim0ijSqKd98nG',
        //  etchPacketEid: 'XzfmVPfGUEyBc1XyylFo',
        // }
        //
        // When there is an error, you will also receive `errorType`, `error`, and
        // `message` properties.
        //
        // You do not need to trigger a redirect, the iframe will make the redirect.
        if (data.action === 'signerComplete') {
          // A signer has finished signing
          console.log(data)
          setIsModalOpen(false)
          const retrievedUser = await retrieveUser(null, user.id)
          dispatch({ type: 'setUser', user: retrievedUser })
          props.changeStep(`7/${jobId}`)
        } else if (data.action === 'signerError') {
          // A signer has experienced an error
          dispatch({
            type: 'showNotification',
            notificationDetails: { title: `Error signing contract.`, content: JSON.stringify(data), waitingOnAPIResult: false, success: false },
            showNotification: true,
          })
        }
      }
    })
  }, [])

  const handleOpenSignModal = async () => {
    setIsLoadingUrl(true)
    setIsLoadingiFrame(true)
    const result = await generateSignUrlForWorkerOnJob(user?.fields?.facilitatorId, jobId, user?.id)
    if (!result.success) {
      console.log(result)
      if (result.data === 'Packet already completed') {
        dispatch({
          type: 'showNotification',
          notificationDetails: { title: `Contract previously signed.`, waitingOnAPIResult: false, success: true },
          showNotification: true,
        })
        props.changeStep(`7/${jobId}`)
      } else {
        dispatch({
          type: 'showNotification',
          notificationDetails: { title: `Error signing contract ${result.data}.`, waitingOnAPIResult: false, success: false },
          showNotification: true,
        })
      }
      setIsLoadingUrl(false)
    } else {
      setIsLoadingUrl(false)
      setsignUrl(result.data.url)
      setIsModalOpen(true)
    }
  }

  return (
    <>
      <Button fluid loading={isLoadingUrl} onClick={handleOpenSignModal}>
        {t('Sign Contract')}
      </Button>
      <Transition.Root show={isModalOpen} as={Fragment}>
        <Dialog as='div' className='fixed inset-0 z-10 overflow-y-auto w-full' initialFocus={cancelButtonRef} onClose={setIsModalOpen}>
          <div className='flex items-center justify-center min-h-screen text-center'>
            <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
              <Dialog.Overlay className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-75' />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className='hidden sm:inline-block sm:align-middle sm:h-screen' aria-hidden='true'>
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className={`inline-block text-left align-bottom transition-all transform shadow-xl w-full max-w-lg p-1 rounded-lg bg-[#FBFBFC]`}>
                <div className=''>
                  <div className='sm:flex sm:items-start'>
                    <div className='w-full bg-light h-[600px]'>
                      {isLoadingiFrame && (
                        <div className='w-full h-full flex justify-center items-center'>
                          <div className='py-12'>
                            <AiOutlineLoading3Quarters className='mx-auto animate-spin h-5 w-5'></AiOutlineLoading3Quarters>
                            <p className='text-gray-600 mt-2 text-sm'>Loading Contract</p>
                          </div>
                        </div>
                      )}
                      {signUrl && <iframe onLoad={() => setIsLoadingiFrame(false)} src={signUrl} className='w-full h-[600px] border-b z-50' />}
                    </div>
                  </div>
                </div>
                <div className='px-4 py-3 bg-gray-50'>
                  <Button fluid className='' color='light' innerRef={cancelButtonRef} onClick={() => setIsModalOpen(false)}>
                    {t('Cancel')}
                  </Button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  )
}

export default Step6SignContract
