/* eslint import/no-webpack-loader-syntax: off */
import React, { useEffect, useRef } from 'react'
import mapboxgl from '!mapbox-gl';
import { useState } from 'react'
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ID

const DashboardMap = props => {
  const mapContainer = useRef(null)
  const [lng, setLng] = useState(-95.5876)
  const [lat, setLat] = useState(33.3345)
  const [zoom, setZoom] = useState(2.8)

  const {jobsWithCoordinates} = props

  useEffect(() => {
    // https://medium.com/@kyrcha/mapbox-with-react-hooks-365cc569ea0a
    const map = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/desdugganruta/clbld9bc3000215o9txbpp9re',
      center: [lng, lat],
      zoom: zoom,
    })
    jobsWithCoordinates?.forEach(job => {
      let coords = job?.coordinates
      if (coords) {
        let marker = new mapboxgl.Marker()
        .setLngLat([coords?.longitude, coords?.latitude])
        .addTo(map);
      }

    })
  })

  return (
    <>
      <h3 className='text-lg font-medium leading-6 text-gray-900'>Map of Employers</h3>
      <div className='px-4 py-5 mt-5 overflow-hidden bg-white rounded-lg shadow sm:p-6'>
        <div ref={mapContainer} className='map-container min-h-[500px]' />
      </div>
    </>
  )
}

export default DashboardMap
