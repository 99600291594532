import React from 'react';
import { StateContext } from '../../../../util/state.jsx';
import { HotTable, HotColumn } from '@handsontable/react';
import { registerAllModules } from 'handsontable/registry';
import { visaOptions, countryOptions, stateOptions } from '../../../../util/constants.js';
import { SiMicrosoftexcel } from 'react-icons/si';
import { CheckIcon } from '@heroicons/react/outline'
import readXlsxFile from 'read-excel-file'
import Fuse from 'fuse.js'
import { withRouter } from 'react-router';
import { createInvitation } from '../../../../models/invitation.jsx';
import moment from 'moment';

// Prepare HandsOnTable
registerAllModules();

const visaTypeLookUp = {
    'H2-A': 'H-2A',
    'H2A': 'H-2A',
    'H2B': 'H-2B',
    'H2-B': 'H-2A',
}

const formatPhoneNumber = (phoneNumberString, code) => {
    var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    var matchTest = cleaned.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/)
    if (match) {
        console.log(matchTest)
        //   var intlCode = (match[1] ? `+${code} ` : '');
        return [code, ' (', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
}

class AddWorkersPageTwo extends React.Component {
    static contextType = StateContext;
    static defaultProps = {}
    constructor(props) {
        super(props);
        this.invitationsTableRef = React.createRef();
        this.cancelButtonRef = React.createRef();
    }

    state = {
        waitingOnAPIResult: false,
        sendInviteCheckbox: false,

        data: [
            // {
            //     // id: "6eaf74c0-3aca-11ec-b38c-892577ac5b14",
            //     firstName: "Adrián",
            //     lastName: "Velasco Montoro",
            //     phoneNumber: "525555550072",
            //     email: "Adrian@hotmail.com",
            //     visaType: "H-2A",
            //     //     "code": "LIDVE",
            //     //     "recipientUserGroup": "EMPLOYEE",
            //     //     "recipientUserRole": "EMPLOYEE_EMPLOYEE",
            //     address1: "Fracc. Monasterios, Calle La Merced #70",
            //     //     address2: "",
            //     city: "Tabasco",
            //     postalCode: "99630",
            //     stateProvinceRegion: "Zacatecas",
            //     country: "Mexico",
            //     departureDate: "2021-05-11",
            //     arrivalDate: "2021-05-13",
            //     originCity: "Tabasco",
            //     originStateProvinceRegion: "Zacatecas",
            //     originCountry: "Mexico",
            //     destinationCity: "Quincy",
            //     destinationStateProvinceRegion: "Washington",
            //     destinationCountry: "United States",
            //     gender: "M",
            //     dateOfBirth: "1998-03-07",
            //     passportNumber: "G30000072",
            //     passportIssueDate: "2016-11-18",
            //     passportExpirationDate: "2022-11-18",
            //     //     "passportCountryOfResidence": "Mexico",
            //     //     "passportCountryOfBirth": "Mexico",
            //     //     "passportCountryOfCitizenship": "Mexico",
            //     //     "createdAt": "2021-11-01T04:16:05.644Z",
            //     //     "updatedAt": "2021-11-01T04:16:05.644Z",
            //     //     "deletedAt": null,
            //     //     "senderId": "7f5ce010-315f-11ec-bab5-017c28742335",
            //     //     "recipientOrganizationId": null,
            //     //     "recipientEmployerId": "77141a30-3a7a-11ec-b38c-892577ac5b14",
            //     //     "recipientFacilitatorId": "6c80fe90-315f-11ec-bab5-017c28742335",
            //     //     "sender": {
            //     //         "firstName": "Anatoli",
            //     //         "lastName": "Muñoz Herrero"
            //     //     },
            //     //     "recipientEmployer": {
            //     //         "id": "77141a30-3a7a-11ec-b38c-892577ac5b14",
            //     //         "fields": {
            //     //             "id": "77141a30-3a7a-11ec-b38c-892577ac5b14",
            //     //             "name": "Alliance Data Systems",
            //     //             "type": "EMPLOYER",
            //     //             "createdAt": "2021-10-31T18:43:39.987Z",
            //     //             "updatedAt": "2021-10-31T18:43:39.987Z",
            //     //             "deletedAt": null
            //     //         },
            //     //         "createdAt": "2021-10-31T18:43:39.987Z",
            //     //         "updatedAt": "2021-10-31T18:43:39.987Z"
            //     //     },
            //     //     "linkedUser": null,
            //     //     "logs": [],
            //     //     "displayName": "Adrián Velasco Montoro",
            //     //     "displayAddress": "Fracc. Monasterios, Calle La Merced #70  99630 Tabasco, Zacatecas Mexico",
            //     //     "displayPhoneNumber": "+52 55 5555 0072"
            //     // }
            // }
        ],
    }

    handleChange = async (event) => this.setState({ [event.target.name]: event.target.value })
    handleFileUploadChange = async (event) => this.setState({ FORM_photo: event.target.files[0] })

    handleExcelFileImport = async (event) => {
        const [{ employersAndPagination }, dispatch] = this.context;
        let rows = await readXlsxFile(event.target.files[0])

        const { employerId } = this.props.match.params
        const employer = employersAndPagination.result.find(e => e.fields?.id === employerId)

        // first build a map of fields to col numbers
        const fieldToColumns = {}
        const headers = rows[0]
        headers.forEach((column, i) => {
            if (column.startsWith('Given Name')) fieldToColumns.firstName = i
            if (column.startsWith('Surname')) fieldToColumns.lastName = i
            if (column.startsWith('Passport Number')) fieldToColumns.passportNumber = i
            if (column.startsWith('DOB Foreign')) fieldToColumns.dateOfBirth = i
            if (column.startsWith('Gender')) fieldToColumns.gender = i
            if (column.includes('Renovación')) fieldToColumns.visaType = i
            if (column.startsWith('Cell Phone 1')) fieldToColumns.phoneNumber = i
            if (column.includes('Address on Passport')) fieldToColumns.address1 = i
            if (column.startsWith('City')) fieldToColumns.city = i
            if (column.includes('Postal Code')) fieldToColumns.postalCode = i
            if (column.includes('State')) fieldToColumns.stateProvinceRegion = i
            // if (column.includes('country')) fieldToColumns.country = i
            if (column.includes('Passport Issue Date')) fieldToColumns.passportIssueDate = i
            if (column.includes('Passport Exp Date') && !column.includes('Passport Exp Date US')) fieldToColumns.passportExpirationDate = i
            if (column.includes('E-MAIL')) fieldToColumns.email = i
            // if (column.includes('originCity')) fieldToColumns.originCity = i
            // if (column.includes('originStateProvinceRegion')) fieldToColumns.originStateProvinceRegion = i
            // if (column.includes('originCountry')) fieldToColumns.originCountry = i
            // if (column.includes('destinationCity')) fieldToColumns.destinationCity = i
            // if (column.includes('destinationStateProvinceRegion')) fieldToColumns.destinationStateProvinceRegion = i
            // if (column.includes('destinationCountry')) fieldToColumns.destinationCountry = i
        })

        let newData = []
        rows.forEach((row, i) => {
            if (i === 0) return
            if (!row[1]) return

            let visaType = visaTypeLookUp[row[fieldToColumns?.visaType]]
            // TODO
            // - try to do phone number formatting
            // Then run validate on the spreadsheet to highlight any errors

            const stateSearchIndex = new Fuse(stateOptions['All'], { keys: ['text'], includeScore: true })
            let fuzzyMatchedStateProvinceRegion = row[fieldToColumns?.stateProvinceRegion] ? stateSearchIndex.search(row[fieldToColumns?.stateProvinceRegion])[0]?.item?.text : null

            // Upper case names
            let firstName = row[fieldToColumns?.firstName].toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());
            let lastName = row[fieldToColumns?.lastName].toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase());

            let phoneNumber = `+${row[fieldToColumns?.phoneNumber].replace(/\D/g, '')}`

            console.log(moment(moment.utc(row[fieldToColumns?.dateOfBirth])).format('YYYY-MM-DD'))
            console.log(moment(moment.utc(null)).format('YYYY-MM-DD'))

            newData.push({
                firstName: firstName,
                lastName: lastName,
                passportNumber: row[fieldToColumns?.passportNumber],
                dateOfBirth: moment(moment.utc(row[fieldToColumns?.dateOfBirth])).format('YYYY-MM-DD'),
                gender: row[fieldToColumns?.gender],
                visaType: visaType,
                phoneNumber: phoneNumber,

                address1: row[fieldToColumns?.address1],
                // address2: row[],
                city: row[fieldToColumns?.city],
                postalCode: row[fieldToColumns?.postalCode],
                stateProvinceRegion: fuzzyMatchedStateProvinceRegion,
                country: 'Mexico', // Hardcoded

                passportIssueDate: moment(moment.utc(row[fieldToColumns?.passportIssueDate])).format('YYYY-MM-DD'),
                passportExpirationDate: moment(moment.utc(row[fieldToColumns?.passportExpirationDate])).format('YYYY-MM-DD'),

                email: row[fieldToColumns?.email],

                originCity: row[fieldToColumns?.city],
                originStateProvinceRegion: fuzzyMatchedStateProvinceRegion,
                originCountry: 'Mexico',
                destinationCity: employer?.fields?.city || '',
                destinationStateProvinceRegion: employer?.fields?.stateProvinceRegion || '',
                destinationCountry: employer?.fields?.country || '',
            })
        })
        this.setState({ data: newData })
        setTimeout(() => {
            this.invitationsTableRef.current.hotInstance.validateCells()
        }, 100);
    }
    componentDidMount = () => {
        this.setState({
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open == true && prevProps.open == false) {
            this.setState({

            })
        }
    }

    handleSave = async () => {
        const [{ user, firebaseUser, selectedTrip }, dispatch] = this.context;

        const FORM_recipientUserGroup = "EMPLOYEE"
        const { employerId } = this.props.match.params
        const FORM_recipientUserRole = "TEST"

        console.log(this.invitationsTableRef.current.hotInstance.isEmptyRow(1))
        const invitationsToCreate = this.state.data.filter((row, i) => {
            return !this.invitationsTableRef.current.hotInstance.isEmptyRow(i)
        })

        this.setState({ waitingOnAPIResult: true })
        try {

            for (const invitationRow of invitationsToCreate) {
                let invitationData = {
                    departureDate: (invitationRow.departureDate) ? moment(invitationRow.departureDate).valueOf() : null,
                    arrivalDate: (invitationRow.arrivalDate) ? moment(invitationRow.arrivalDate).valueOf() : null,

                    firstName: invitationRow.firstName, // required
                    lastName: invitationRow.lastName, // required
                    passportNumber: invitationRow.passportNumber,
                    dateOfBirth: (invitationRow.dateOfBirth) ? moment(invitationRow.dateOfBirth).valueOf() : null,
                    gender: invitationRow.gender,
                    visaType: invitationRow.visaType || null,
                    phoneNumber: "+" + invitationRow.phoneNumber.replace(/\D/g, ''), // required


                    address1: invitationRow.address1,
                    // address2: invitationRow.address2,
                    city: invitationRow.city,
                    postalCode: invitationRow.postalCode,
                    stateProvinceRegion: invitationRow.stateProvinceRegion,
                    country: invitationRow.country,

                    passportIssueDate: (invitationRow.passportIssueDate) ? moment(invitationRow.passportIssueDate).valueOf() : null,
                    passportExpirationDate: (invitationRow.passportExpirationDate) ? moment(invitationRow.passportExpirationDate).valueOf() : null,

                    email: invitationRow.email || null,

                    originCity: invitationRow.city,
                    originStateProvinceRegion: invitationRow.stateProvinceRegion,
                    originCountry: invitationRow.country,

                    destinationCity: invitationRow.destinationCity,
                    destinationStateProvinceRegion: invitationRow.destinationStateProvinceRegion,
                    destinationCountry: invitationRow.destinationCountry,

                    recipientUserGroup: FORM_recipientUserGroup, // required
                    senderId: user.id, // required
                }
                if (FORM_recipientUserGroup === 'EMPLOYEE') {
                    invitationData.recipientFacilitatorId = user.fields?.organizationId
                    invitationData.recipientEmployerId = employerId
                    invitationData.recipientUserRole = 'EMPLOYEE_EMPLOYEE' // required
                } else if (FORM_recipientUserGroup === 'FACILITATOR') {
                    invitationData.recipientOrganizationId = user.fields?.organizationId
                    invitationData.recipientUserRole = FORM_recipientUserRole // required
                }
                let invitation = await createInvitation(firebaseUser, invitationData, this.state.sendInviteCheckbox)
            }
            this.closeAndResetModal()
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `Failed to save an invitation. ${error.message} ${error.details}`, waitingOnAPIResult: false },
                showNotification: true
            })
            this.setState({ waitingOnAPIResult: false })
            return
        }

    }

    // handlePatchUser = async (e) => {
    //     e.preventDefault();
    //     const [{ firebaseUser, user }, dispatch] = this.context;
    //     this.setState({ waitingOnAPIResult: true })

    //     let fileURL = undefined
    //     if (this.state.FORM_photo) {
    //         try {
    //             fileURL = await uploadFile(user.id, this.state.FORM_photo)
    //             console.log(fileURL)
    //         } catch (error) {
    //             console.log(error)
    //         }
    //     }

    //     try {
    //         let patchedUserResult = await patchUser(firebaseUser, this.props.profile.id, {
    //             "firstName": this.state.FORM_firstName,
    //             "lastName": this.state.FORM_lastName,
    //             "email": this.state.FORM_email,
    //             "visaType": this.state.FORM_visaType,
    //             "photo": fileURL ? fileURL : null,
    //         })
    //         this.closeAndResetModal()
    //     } catch (error) {
    //         console.log(error)
    //         dispatch({
    //             type: 'showNotification',
    //             notificationDetails: { title: `Failed to update profile. ${error.message} ${error.details}`, waitingOnAPIResult: false },
    //             showNotification: true
    //         })
    //         return
    //     }
    // }

    closeAndResetModal = async () => {
        const [{ employerInvitationsAndPagination }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerInvitationsAndPagination[employerId]?.meta

        this.setState({
            waitingOnAPIResult: false,
            data: []
        })
        dispatch({
            type: 'showNotification',
            notificationDetails: { title: 'Success!', content: `Updated worker profile.`, success: true },
            showNotification: true
        })
        this.props.listInvitationsForOrganizationAndEmployer(employerId, filtersAndPagination)
        this.props.history.push('./summary')
    }

    render() {
        const visaOptionsDropdown = visaOptions.map(o => o.key)
        return (
            <div className="w-full h-screen overflow-scroll text-left align-top transition-all transform bg-white shadow-xl" initialFocus={this.cancelButtonRef}>
                <header>
                    <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                        <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
                            Import Preview
                        </p>
                    </div>
                </header>

                <main className="grid grid-cols-2 gap-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <div className="">
                        {/* Replace with your content */}
                        <div className="px-4 py-8 sm:px-0">
                            <p className="mb-6 text-xl text-gray-600">
                                Below is a preview of workers you are about to import. If you need to make changes, upload a new Excel file with changes in it. 
                            </p>

                            <div className="mt-6">
                                <button
                                    type="button"
                                    onClick={() => this.props.setPageStep(2)}
                                    className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Import Workers
                                    <CheckIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                                </button>
                                <button
                                    type="button"
                                    onClick={() => this.props.setPageStep(2)}
                                    className="inline-flex items-center px-3 py-2 mx-4 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    Replace Excel 
                                    <SiMicrosoftexcel className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                                </button>
                            </div>
                        </div>
                        {/* /End replace */}
                    </div>
                    <div>
                        {/* <img className="object-contain w-full h-72" src={WorkerIllustration1} alt="" /> */}
                    </div>

                </main>
                <div className="flex flex-col ">
                    <div className="flex-1">
                        <div className=""  >
                            <HotTable
                                ref={this.invitationsTableRef}
                                licenseKey='non-commercial-and-evaluation'
                                data={this.state.data}
                                rowHeaders={true}
                                width='100vw'
                                stretchH='all'
                                colWidths='100'
                                height='auto'
                                minSpareRows={100}
                            >
                                {/* <HotColumn title="Departure Date" data='departureDate' type='date' dateFormat='YYYY-MM-DD' correctFormat={true} /> */}
                                {/* <HotColumn title="Arrival Date" data='arrivalDate' type='date' dateFormat='YYYY-MM-DD' correctFormat={true} /> */}

                                <HotColumn title="Surname (Last)" data='lastName' />
                                <HotColumn title="Given Name (First)" data='firstName' />

                                {/* <HotColumn title="Passport Number" data='passportNumber' /> */}
                                {/* <HotColumn title="Passport Date of Birth" data='dateOfBirth' type='date' dateFormat='YYYY-MM-DD' correctFormat={true} /> */}
                                {/* <HotColumn title="Gender" data='gender' type={"autocomplete"} allowInvalid={true} strict={true} source={['M', 'F']} /> */}
                                {/* <HotColumn title="Visa Type" data='visaType' type={"autocomplete"} source={visaOptionsDropdown} /> */}
                                <HotColumn title="Cell Phone" data='phoneNumber' />
                                <HotColumn title="WhatsApp" data='phoneNumber' />

                                {/* <HotColumn title="Passport Address" data='address1' /> */}
                                {/* <HotColumn title="Passport Postal Code" data='postalCode' type='numeric' /> */}
                                {/* <HotColumn title="Passport City" data='city' /> */}
                                {/* <HotColumn title="Passport State/Province" data='stateProvinceRegion' type={"autocomplete"} allowInvalid={true} strict={true} source={stateOptions['All'].map(o => o.key)} /> */}
                                {/* <HotColumn title="Passport Country" data='country' type={"autocomplete"} allowInvalid={true} strict={true} source={countryOptions.map(o => o.key)} /> */}

                                {/* <HotColumn title="Passport Issue Date" data='passportIssueDate' type='date' dateFormat='YYYY-MM-DD' correctFormat={true} /> */}
                                {/* <HotColumn title="Passport Expiration Date" data='passportExpirationDate' type='date' dateFormat='YYYY-MM-DD' correctFormat={true} /> */}


                                <HotColumn title="Email" data='email' />

                                {/* <HotColumn title="Destination City" data='destinationCity' /> */}
                                {/* <HotColumn title="Destination State" data='destinationStateProvinceRegion' type={"autocomplete"} allowInvalid={true} strict={true} source={stateOptions['All'].map(o => o.key)} /> */}
                                {/* <HotColumn title="Destination Country" data='destinationCountry' type={"autocomplete"} allowInvalid={true} strict={true} source={countryOptions.map(o => o.key)} /> */}


                            </HotTable>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(AddWorkersPageTwo)