import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Badge, Button } from '../../../components/Tailwind'
import Container from '../../../components/Tailwind/Container'
import { classNames } from '../../../util/helpers'
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, SearchIcon } from '@heroicons/react/outline'
import { FilterIcon } from '@heroicons/react/solid'
import { StateContext } from '../../../util/state'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
// import JobDropdownMenu from './jobDropdownMenu'
import { HiCheck, HiCheckCircle, HiOutlineCheckCircle, HiOutlineMinusCircle, HiOutlineUserCircle } from 'react-icons/hi2'
import moment from 'moment'
import { listJobsForEmployer, listJobsForPartner } from '../../../models/employer.jsx'

const EmployerPortalJobsPage = props => {
  const [{ user, isLoadingJobs, jobsAndMeta }, dispatch] = useContext(StateContext)
  const history = useHistory()
  const location = useLocation()
  const [addJobModalIsOpen, setAddJobModalIsOpen] = useState(false)
  const [addEmployerModalIsOpen, setAddEmployerModalIsOpen] = useState(false)
  const [jobs, setJobs] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  // Filters
  const [searchText, setSearchText] = useState('')

  const fetchJobs = async () => {
    setIsLoading(true)
    try {
      let results = []
      if (user?.fields?.group === 'EMPLOYER') {
        results = await listJobsForEmployer(user.fields?.organizationId)
      } else if (user?.fields?.group === 'PARTNER') {
        results = await listJobsForPartner(user.fields?.organizationId)
      }
      setJobs(results)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to get jobs.`, waitingOnAPIResult: false, success: false },
        showNotification: true,
      })
    }
  }

  useEffect(() => {
    if (user) fetchJobs()
  }, [user])

  const handleSearchChange = e => {
    const searchText = e.target.value
    setSearchText(searchText)
  }

  const filteredJobs = searchText.length > 0 ? jobs.filter(job => job?.fields?.employer?.fields?.name?.toLowerCase().includes(searchText?.toLowerCase())) : jobs

  return (
    <div className='h-full bg-light'>
      <Container className='bg-white shadow'>
        <div className='w-full md:flex md:items-center md:justify-between'>
          <div className='flex-1 min-w-0 py-1.5'>
            <h2 className='text-xl font-medium text-gray-900'>Jobs</h2>
          </div>
          <div className='flex mt-4 space-x-4 md:mt-0 md:ml-4'></div>
        </div>
      </Container>
      <Container className='max-w-7xl'>
        <div className='grid grid-cols-12 space-x-6'>
          <div className='col-span-12'>
            <div className='md:flex md:items-center md:justify-between'>
              <div className='flex-1 min-w-0'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>All</h3>
              </div>
              <div className='flex mt-4 md:mt-0 md:ml-4'>
                {/* <Button>
              Add Jobs
            </Button> */}
              </div>
            </div>

            <form className='flex mt-3 space-x-4' action='#'>
              <div className='flex'>
                <label htmlFor='search' className='sr-only'>
                  Search
                </label>
                <div className='relative rounded-md shadow-sm'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <SearchIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                  </div>
                  <input
                    type='search'
                    name='search'
                    id='search'
                    onChange={handleSearchChange}
                    value={searchText}
                    className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                    placeholder='Search'
                  />
                </div>
              </div>
              <div className='flex items-center pl-4 space-x-4'></div>
            </form>

            <div className='flex flex-col mt-3'>
              <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div className='rounded-sm shadow ring-1 ring-black ring-opacity-5'>
                    <table className='min-w-full divide-y divide-gray-300' >
                      <thead className='bg-gray-50'>
                        <tr>
                          <th scope='col' className='whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 w-24'>
                            Number
                          </th>
                          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 w-32'>
                            Started On
                          </th>

                          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'>
                            Employer
                          </th>
                          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 w-44'>
                            Petition #
                          </th>

                          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900 w-24'>
                            Status
                          </th>
                          <th scope='col' className='relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6 w-24'>
                            <span className='sr-only'>Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white divide-y divide-gray-200'>
                        {isLoading && (
                          <tr>
                            <td colSpan='8'>
                              <div className='flex p-4 space-x-4 animate-pulse'>
                                <div className='w-10 h-10 rounded-full bg-slate-200'></div>
                                <div className='flex-1 py-1 space-y-4'>
                                  <div className='h-2 rounded bg-slate-200'></div>
                                  <div className='space-y-3'>
                                    <div className='grid grid-cols-3 gap-4'>
                                      <div className='h-2 col-span-2 rounded bg-slate-200'></div>
                                      <div className='h-2 col-span-1 rounded bg-slate-200'></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )}
                        {!isLoading &&
                          filteredJobs?.map((job, i) => {
                            let workersSigned = job?.fields?.workers.filter(w => w?.jobDetails?.externalContractStatus === 'completed').length
                            let progress = workersSigned > 0 ? (workersSigned / job?.fields?.workers.length) * 100 : 0
                            return (
                              <tr key={job.id} className='cursor-pointer hover:bg-gray-50' onClick={() => history.push(`./${job.fields?.employer?.id}/jobs/${job.id}`)}>
                                <td className='py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6'>#{job?.fields?.number}</td>
                                <td className='px-2 py-4 text-sm text-gray-500 whitespace-nowrap'>
                                  <p>{moment(job?.createdAt).format('LL')}</p>
                                  <p className='text-xs text-gray-400'>{job?.fields?.season} season</p>
                                </td>

                                <td className='px-2 py-4 text-sm font-medium text-gray-900 whitespace-nowrap'>
                                  <p>{job?.fields?.employer?.fields?.name}</p>
                                </td>

                                <td className='px-2 py-4 text-sm font-medium text-gray-900 whitespace-nowrap'>
                                  <p className=''>{job?.fields?.referenceId}</p>
                                </td>


                                <td className='px-2 py-4 text-sm text-gray-900 whitespace-nowrap'>
                                  <div className='flex items-center justify-between my-1'>
                                    <p className='text-xs text-stone-500'>
                                      <b className='mr-1'>
                                        {workersSigned}/{job?.fields?.workers.length}
                                      </b>
                                      signed
                                    </p>
                                  </div>
                                  <div className='w-24 h-2 bg-gray-200 rounded-full'>
                                    <div className={`h-full text-center w-full text-xs text-secondary bg-primary rounded-full`} style={{ width: `${progress}%` }}></div>
                                  </div>
                                </td>
                                {/* <td className='px-2 py-4 text-sm text-gray-500 whitespace-nowrap'>{job?.fields?.season}</td> */}
                                <td className='relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6' >
                                  View
                                </td>
                              </tr>
                            )
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default EmployerPortalJobsPage
