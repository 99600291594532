import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

function Message(props) {
  const { title, details, color, className, icon, children, link } = props
  const history = useHistory

  const bgColors = {
    blue: 'bg-blue-50',
    red: 'bg-red-50'
  }

  const textColors = {
    blue: 'text-blue-800',
    red: 'text-red-700'
  }
  
  return (
    <div className={`${className} rounded-md ${bgColors[color]} p-4`}>
      <div className='flex'>
        {icon && <div className='flex-shrink-0'> {icon} </div>}
        <div className='ml-3 flex-1 md:flex md:justify-between'>
          {title && <h3 className='text-sm font-medium text-green-800'>{title}</h3>}
          {details && <p className={`text-sm ${textColors[color]}`}>{details}</p>}
          {children}
          {link && (
            <p className='mt-3 text-sm md:mt-0 md:ml-6'>
              <a onClick={() => history.push(link)} className={`whitespace-nowrap font-medium text-gray-700 hover:text-gray-600`}>
                Details
                <span aria-hidden='true'> &rarr;</span>
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

Message.defaultProps = {
  title: null,
  details: null,
  color: 'blue', // options gray, red, yellow, green, blue, indigo, purple, pink
}

export default Message
