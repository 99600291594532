import React from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai';
import { SpinnerDiamond } from 'spinners-react';

const FullPageLoader = props => {
    return (
        <div id="loading-screen" className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
          <div className="w-full text-center justify-center pt-6 flex-wrap">
            <AiOutlineLoading3Quarters className='inline animate-spin h-5 w-5'></AiOutlineLoading3Quarters><p className="text-gray-600 mt-2 text-sm">{props?.text}</p>
          </div>
        </div>
    )
}

export default FullPageLoader