import React, { useContext, useEffect } from 'react';
import { StateContext, StateProvider } from './util/state.jsx';
import AuthWrapper from './util/authWrapper.jsx'

import { I18nextProvider } from 'react-i18next'
import i18n from './util/i18n.js'
import * as FullStory from '@fullstory/browser';

import { BrowserRouter as Router, Route, Redirect, useHistory, useLocation, Switch } from 'react-router-dom'
import FacilitatorPortalV2 from './pages/FacilitatorV2'
import PrivacyPolicy from './pages/Misc/PrivacyPolicy'
import TermsAndConditions from './pages/Misc/TermsAndConditions'

import LoginPage from './pages/LoginPage/index.jsx';
import EmployerPortal from './pages/Employer/index.jsx';
import AccountPortal from './pages/AccountV2/index.jsx';
import NotFoundPage from './pages/Misc/NotFoundPage/index.jsx';

const fullStoryConfig = {
  orgId: process.env.REACT_APP_FULL_STORY_ORG_ID,
  debug: true,
  devMode: process.env.REACT_APP_WEB_HOST !== 'https://useruta.com'
}
FullStory.init(fullStoryConfig)

const initialState = {
  user: undefined,
  sessionToken: localStorage.getItem('rutaSessionToken') ?? undefined,
  sessionJwt: localStorage.getItem('rutaSessionJwt') ?? undefined, 

  analytics: undefined,

  firebaseUser: undefined,
  firebaseUserIdToken: undefined,
  selectedTrip: undefined,
  apiErrorMessage: undefined,
  isLoadingTrips: false,
  tripsForEmployees: {},

  isLoadingOrganization: true,

  isLoadingEmployerWorkersAndMeta: true,
  employerWorkersAndMeta: {},

  isLoadingWorkers: true,
  workersAndMeta: undefined,

  isLoadingJobs: true,
  jobsAndMeta: undefined,

  isLoadingSelectedWorker: true,
  selectedWorker: undefined,

  isLoadingEmployers: true,
  employers: undefined,

  isLoadingEmployers: true,
  employersAndPagination: { result: [], meta: {} },

  isLoadingInvitations: true,
  employerInvitationsAndPagination: {},

  showNotification: false,
  notificationDetails: { title: '', content: '' }
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setUserAndToken':
      return {
        ...state,
        sessionToken: action.sessionToken,
        sessionJwt: action.sessionJwt,
        user: action.user
      };
    case 'setTokens':
      return {
        ...state,
        sessionToken: action.sessionToken,
        sessionJwt: action.sessionJwt,
      };
    case 'setUser':
      return {
        ...state,
        user: action.user
      };
    case 'setFirebaseUserAndToken':
      return {
        ...state,
        firebaseUser: action.firebaseUser,
        firebaseUserIdToken: action.firebaseUserIdToken
      };

    case 'setAnalytics':
      return {
        ...state,
        analytics: action.analytics,
      };

    case 'setSelectedTrip':
      return {
        ...state,
        selectedTrip: action.trip
      };

    // Used for facilitator portal
    case 'setIsLoadingTrips':
      return {
        ...state,
        isLoadingTrips: action.isLoadingTrips
      };
    case 'setTripsForEmployee':
      return {
        ...state,
        isLoadingTrips: action.isLoadingTrips,
        tripsForEmployees: {
          ...state.tripsForEmployees,
          [action.employeeId]: action.trips
        },
        selectedTrip: action.selectedTrip ? action.selectedTrip : state.selectedTrip
      };

    case 'setIsLoadingEmployerWorkersAndMeta':
      return {
        ...state,
        isLoadingEmployerWorkersAndMeta: action.isLoadingEmployerWorkersAndMeta
      };
    case 'setEmployerWorkersAndMeta':
      return {
        ...state,
        isLoadingEmployerWorkersAndMeta: action.isLoadingEmployerWorkersAndMeta,
        employerWorkersAndMeta: {
          ...state.employerWorkersAndMeta,
          [action.employerId]: action.workersAndMeta
        },
      };

    case 'setIsLoadingWorkers':
      return {
        ...state,
        isLoadingWorkers: action.isLoadingWorkers
      };
    case 'setWorkersAndMeta':
      return {
        ...state,
        isLoadingWorkers: action.isLoadingWorkers,
        workersAndMeta: action.workersAndMeta
      };
    case 'updateWorker':
      return {
        ...state,
        workersAndMeta: {
          result: state?.workersAndMeta?.result?.map(w =>
            w.id === action.worker.id
              ? action.worker
              : w
          ),
          meta: state?.meta
        }
      };
    case 'removeWorker':
      return {
        ...state,
        workersAndMeta: {
          result: state?.workersAndMeta?.result.filter(w => w.id !== action.workerId),
          meta: state?.meta
        }
      };
      
    case 'setIsLoadingJobs':
      return {
        ...state,
        isLoadingJobs: action.isLoadingJobs
      };
    case 'setJobsAndMeta':
      return {
        ...state,
        isLoadingJobs: action.isLoadingJobs,
        jobsAndMeta: action.jobsAndMeta
      };


    case 'setIsLoadingSelectedWorker':
      return {
        ...state,
        isLoadingSelectedWorker: action.isLoadingSelectedWorker
      };
    case 'setSelectedWorker':
      return {
        ...state,
        isLoadingSelectedWorker: action.isLoadingSelectedWorker,
        selectedWorker: action.selectedWorker
      };

    case 'setIsLoadingInvitations':
      return {
        ...state,
        isLoadingInvitations: action.isLoadingInvitations
      };
    case 'setEmployerInvitationsAndPagination':
      return {
        ...state,
        isLoadingInvitations: action.isLoadingInvitations,
        employerInvitationsAndPagination: {
          ...state.employerInvitationsAndPagination,
          [action.employerId]: action.invitationsAndPagination
        },
      };
    case 'setApiErrorMessage':
      return {
        ...state,
        apiErrorMessage: action.apiErrorMessage
      }

    case 'isLoadingEmployers':
      return {
        ...state,
        isLoadingEmployers: action.isLoadingEmployers
      };
    case 'setEmployersAndPagination':
      return {
        ...state,
        isLoadingEmployers: action.isLoadingEmployers,
        employersAndPagination: action.employersAndPagination,
      };

    case 'isLoadingOrganization':
      return {
        ...state,
        isLoadingOrganization: action.isLoadingOrganization
      };
    case 'setOrganization':
      return {
        ...state,
        isLoadingOrganization: action.isLoadingOrganization,
        organization: action.organization,
      };

    case 'showNotification':
      return {
        ...state,
        notificationDetails: action.notificationDetails,
        showNotification: action.showNotification
      }

    case 'resetState':
      return {
        ...initialState,
      }
    default:
      return state;
  }
};

function Home() {
  return (<Redirect to={'/login'} />)
}

// For all authenticated users
const PrivateRoute = ({ component: Component, ...rest }) => {
  const [{ sessionToken, user }, dispatch] = useContext(StateContext);
  const history = useHistory();
  const { search } = useLocation();
  return (
    <Route {...rest} render={(props) => (
      (sessionToken && user)
        ? <Component {...props} {...rest} />
        : <Redirect to={`/login?redirect=${history.location.pathname}${search}`} />
    )} />
  );
}

function App() {
  return (
    <StateProvider initialState={initialState} reducer={reducer}>
      <I18nextProvider i18n={i18n}>
        <AuthWrapper />
        <Router>
          <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/signin" component={() => <Redirect to="/login" />} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/terms" component={TermsAndConditions} />
          {/* <PrivateRoute path='/onboarding' component={OnboardingPage} /> */}
          <PrivateRoute path='/account' component={AccountPortal} />
          <PrivateRoute path='/facilitator' component={FacilitatorPortalV2} />
          <Route path='/employer' component={EmployerPortal} />
          <Route path="*"><NotFoundPage/></Route>
          </Switch>
        </Router>
      </I18nextProvider>
    </StateProvider>
  );
}

export default App;
