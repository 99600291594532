import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { HiBuildingOffice2, HiUser } from 'react-icons/hi2'
import Container from '../../../../components/Tailwind/Container'
import { retrieveDashboard } from '../../../../models/organization'
import { StateContext } from '../../../../util/state'
import DashboardActivityFeed from './dashboardActivityFeed'
import DashboardMap from './dashboardMap'

const DashboardPage = () => {
  const [{ organization, isLoadingJobs, jobsAndMeta }, dispatch] = useContext(StateContext)
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true)
  const [dashboardStats, setDashboardStats] = useState(undefined)

  useEffect(() => {
    const getDashboardStats = async () => {
      if (organization) {
        try {
          const dashboardStats = await retrieveDashboard(organization?.id)
          setDashboardStats(dashboardStats)
          setIsLoadingDashboard(false)
        } catch (err) {
          console.log(err)
          setIsLoadingDashboard(false)
        }
      }
    }
    getDashboardStats()
  }, [organization])

  const stats = [
    { name: 'Total Jobs', stat: dashboardStats?.company_jobCount, changeType: 'increase', change: '1%', icon: HiBuildingOffice2},
    { name: 'Total Workers', stat: dashboardStats?.company_workerCount, changeType: 'increase', change: '1%', icon: HiBuildingOffice2},
    { name: 'Total Workers Approved', stat: dashboardStats?.company_confirmedWorkerCount, changeType: 'increase', change: '1%', icon: HiBuildingOffice2},
    { name: 'Your Jobs', stat: dashboardStats?.your_jobCount, changeType: 'increase', change: '1%', icon: HiUser},
    { name: 'Your Workers', stat: dashboardStats?.your_workerCount, changeType: 'increase', change: '1%', icon: HiUser},
    { name: 'Your Workers Approved', stat: dashboardStats?.your_confirmedWorkerCount, changeType: 'increase', change: '1%', icon: HiUser},
  ]

  return (
    <div>
      <Container className='bg-white shadow'>
        <div className='md:flex md:items-center md:justify-between'>
          <div className='flex-1 min-w-0 py-1.5'>
            <h2 className='text-xl font-medium text-gray-900'>Dashboard</h2>
          </div>
          <div className='flex mt-4 md:mt-0 md:ml-4'>{/* <Button onClick={() => history.push('/facilitator/add-jobs')}> Do Something </Button> */}</div>
        </div>
      </Container>
      {isLoadingDashboard && (
        <div className='flex-wrap justify-center w-full pt-6 text-center'>
          <AiOutlineLoading3Quarters className='inline w-5 h-5 animate-spin'></AiOutlineLoading3Quarters>
          <p className='mt-2 text-sm text-gray-600'>Loading Dashboard</p>
        </div>
      )}
      {!isLoadingDashboard && (
        <Container>
          <div className='grid grid-cols-12 gap-6'>
            <div className='col-span-12 space-y-5 lg:col-span-8'>
              <div>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>Summary of Jobs and Workers</h3>
                <dl className='grid grid-cols-1 gap-5 mt-5 sm:grid-cols-2 lg:grid-cols-3'>
                  {stats.map(item => (
                    <div key={item.name} className='relative px-4 pt-5 overflow-hidden bg-white rounded-lg shadow sm:px-6 sm:pt-5'>
                      <dt>
                        <div className={`absolute rounded-md ${item.name.includes('Your') ? 'bg-primary-300' : 'bg-accent-500'} p-3`}>
                          <item.icon className='w-6 h-6 text-white' aria-hidden='true' />
                        </div>
                        <p className='ml-16 text-sm font-medium text-gray-500 truncate'>{item.name}</p>
                      </dt>
                      <dd className='flex items-baseline pb-6 ml-16 sm:pb-6'>
                        <p className='text-2xl font-semibold text-gray-900'>{item.stat}</p>
                        {/* <p className={classNames(item.changeType === 'increase' ? 'text-green-600' : 'text-red-600', 'ml-2 flex items-baseline text-sm font-semibold')}>
                          {item.changeType === 'increase' ? (
                            <HiArrowUp className='self-center flex-shrink-0 w-5 h-5 text-green-500' aria-hidden='true' />
                          ) : (
                            <HiArrowDown className='self-center flex-shrink-0 w-5 h-5 text-red-500' aria-hidden='true' />
                          )}

                          <span className='sr-only'> {item.changeType === 'increase' ? 'Increased' : 'Decreased'} by </span>
                          {item.change}
                        </p> */}
                        {/* <div className='absolute inset-x-0 bottom-0 px-4 py-3 bg-gray-50 sm:px-6'>
                          <div className='text-sm'>
                            <a href='#' className='font-medium text-accent-600 hover:text-accent-500'>
                              {' '}
                              View all<span className='sr-only'> {item.name} stats</span>
                            </a>
                          </div>
                        </div> */}
                      </dd>
                    </div>
                  ))}
                </dl>
              </div>
              <div>
                <DashboardMap jobsWithCoordinates={[]} />
              </div>
            </div>
            <div className='col-span-12 lg:col-span-4'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>Recent activity</h3>
              <DashboardActivityFeed dashboardStats={dashboardStats} limitFeed={8} />
            </div>
          </div>
        </Container>
      )}
      {/* <Container>
        <div>
          <h3 className="text-lg font-medium leading-6 text-gray-900">Year to date</h3>
          <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-4">
            {stats.map((item) => (
              <div key={item.name} className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
                <dt className="text-sm font-medium text-gray-500 truncate">{item.name}</dt>
                <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">{item.stat}</dd>
              </div>
            ))}
          </dl>
        </div>


      </Container> */}
    </div>
  )
}

export default DashboardPage
