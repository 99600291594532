import React, { Component} from 'react'
import moment from 'moment'

export default class WorkerLogs extends Component {
    render() {
        const { worker } = this.props

        return (
            <section aria-labelledby="timeline-title" className="lg:col-start-3 lg:col-span-1">
                <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
                    <h2 className="text-lg font-medium text-gray-900 pb-4">
                        Audit History
                    </h2>
                    <ul role="list" className="divide-y divide-gray-200">
                        {worker?.fields?.logs.map((log) => (
                            <li
                                key={log.id}
                                className="relative bg-white py-2 px-4 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600"
                            >
                                <div className="flex justify-between space-x-3">
                                    <div className="min-w-0 flex-1">
                                        <a className="block focus:outline-none">
                                            <span className="absolute inset-0" aria-hidden="true" />
                                            <p className="text-sm font-medium text-gray-900 truncate">{log.details}</p>
                                            <p className="text-sm text-gray-500 truncate">{moment(log.createdAt).format('LL')}</p>
                                        </a>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>


                </div>
            </section>
        )
    }
}
