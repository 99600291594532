import React from 'react'
import { withTranslation } from 'react-i18next'
import { CheckIcon } from '@heroicons/react/outline'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { Button } from '../../components/Tailwind'
import LogoSquare from '../../img/logo_square.svg'
import { HiDevicePhoneMobile } from 'react-icons/hi2'
import { FcCellPhone } from 'react-icons/fc'
import { HiMail, HiOutlineMailOpen } from 'react-icons/hi'
import WhatsAppLogo from '../../img/whatsapp.svg'

class SytchForm extends React.Component {
  constructor(props) {
    super(props)
    this.confirmButtonRef = React.createRef()
  }

  componentDidMount = () => {
    let classes = [
      'block',
      'w-full',
      'px-3',
      'py-2',
      'placeholder-gray-400',
      'border',
      'border-gray-300',
      'rounded-md',
      'shadow-sm',
      'appearance-none',
      'focus:outline-none',
      'focus:ring-primary-300',
      'focus:border-primary-300',
      'sm:text-sm',
    ]

    for (const input of document.getElementsByClassName('PhoneInputInput')) {
      input.classList.add(...classes)
    }
  }

  render() {
    const { invitation } = this.props
    const { t } = this.props // translations

    return (
      <>
        {/* Phone Section */}
        <section>
          <div className={`${this.props.signInMethod === 'phone' && this.props.FORM_checkPhone ? '' : 'hidden'}`}>
            <form onSubmit={e => {e.preventDefault(); this.props.handleSubmitConfirmationCode(this.props.FORM_OTP)}}>
              <div className='flex items-center justify-center  mx-auto'>
                <FcCellPhone className="w-24 h-24" />
              </div>
              <div className='mt-3 text-center sm:mt-5'>
                <div as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  {t('Enter 6-digit code')}
                </div>
                <div className='mt-2 mb-4'>
                  <p className='text-sm text-gray-500'>
                    {t('A 6-digit passcode was sent to you at ')}
                    <b>{this.props.FORM_phoneNumber}</b>
                  </p>
                </div>
              </div>

              <div className='flex mb-6'>
                <div className='w-1/2 min-w-0 mx-auto'>
                  <input
                    id='FORM_OTP'
                    name='FORM_OTP'
                    type='text'
                    maxLength='6'
                    value={this.props.FORM_OTP}
                    onChange={this.props.handleChange}
                    required
                    className='relative block w-full px-3 py-2 text-center text-xl tracking-[0.4em] placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-300 focus:border-primary-300'
                  />
                </div>
              </div>
              <Button loading={this.props.loadingAuth} fluid onClick={e => {e.preventDefault(); this.props.handleSubmitConfirmationCode(this.props.FORM_OTP)}}>
                {t('Confirm')}
              </Button>
            </form>
          </div>
          <div className={`${this.props.signInMethod === 'phone' && !this.props.FORM_checkPhone ? '' : 'hidden'}`}>
            <form className={`space-y-6`} onSubmit={this.props.handleStytchLoginRequest}>
              <div>
                <label htmlFor='FORM_phoneNumber' className='block text-sm font-medium text-gray-700'>
                  {t('Phone Number')}
                </label>
                <div className='mt-1'>
                  <PhoneInput
                    placeholder='Enter phone number'
                    value={this.props.FORM_phoneNumber}
                    name='FORM_phoneNumber'
                    defaultCountry='MX'
                    international
                    error={this.props.FORM_phoneNumber ? (isValidPhoneNumber(this.props.FORM_phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                    onChange={value =>
                      this.props.handleChange({
                        target: {
                          name: 'FORM_phoneNumber',
                          value: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <Button loading={this.props.loadingAuth} fluid onClick={this.props.handleStytchLoginRequest}>
                  {t('Continue')}
                </Button>
              </div>
            </form>
          </div>
        </section>


        {/* WhatsApp Section */}
        <section>
          <div className={`${this.props.signInMethod === 'whatsapp' && this.props.FORM_checkPhone ? '' : 'hidden'}`}>
            <form onSubmit={e => {e.preventDefault(); this.props.handleSubmitConfirmationCode(this.props.FORM_OTP)}}>
              <div className='flex items-center justify-center w-24 h-24 mx-auto'>
                <img src={WhatsAppLogo} />
              </div>
              <div className='mt-3 text-center sm:mt-5'>
                <div as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  {t('Enter 6-digit code')}
                </div>
                <div className='mt-2 mb-4'>
                  <p className='text-sm text-gray-500'>
                    {t('A 6-digit passcode was sent to you at ')}
                    <b>{this.props.FORM_phoneNumber}</b>
                  </p>
                </div>
              </div>

              <div className='flex mb-6'>
                <div className='w-1/2 min-w-0 mx-auto'>
                  <input
                    id='FORM_OTP'
                    name='FORM_OTP'
                    type='text'
                    maxLength='6'
                    value={this.props.FORM_OTP}
                    onChange={this.props.handleChange}
                    required
                    className='relative block w-full px-3 py-2 text-center text-xl tracking-[0.4em] placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-300 focus:border-primary-300'
                  />
                </div>
              </div>
              <Button loading={this.props.loadingAuth} fluid onClick={e => {e.preventDefault(); this.props.handleSubmitConfirmationCode(this.props.FORM_OTP)}}>
                {t('Confirm')}
              </Button>
            </form>
          </div>
          <div className={`${this.props.signInMethod === 'whatsapp' && !this.props.FORM_checkPhone ? '' : 'hidden'}`}>
            <form className={`space-y-6`} onSubmit={this.props.handleStytchLoginRequest}>
              <div>
                <label htmlFor='FORM_phoneNumber' className='block text-sm font-medium text-gray-700'>
                  {t('WhatsApp Number')}
                </label>
                <div className='mt-1'>
                  <PhoneInput
                    placeholder='Enter phone number'
                    value={this.props.FORM_phoneNumber}
                    name='FORM_phoneNumber'
                    defaultCountry='MX'
                    international
                    error={this.props.FORM_phoneNumber ? (isValidPhoneNumber(this.props.FORM_phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                    onChange={value =>
                      this.props.handleChange({
                        target: {
                          name: 'FORM_phoneNumber',
                          value: value,
                        },
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <Button loading={this.props.loadingAuth} fluid onClick={this.props.handleStytchLoginRequest}>
                {t('Continue')}
                </Button>
              </div>
            </form>
          </div>
        </section>


        {/* Email Section  */}
        <section>
        <div className={`${this.props.signInMethod === 'email' && this.props.FORM_checkEmail ? '' : 'hidden'}`}>
            <form onSubmit={e => {e.preventDefault(); this.props.handleSubmitConfirmationCode(this.props.FORM_OTP)}}>
              <div className='flex items-center justify-center w-24 h-24 mx-auto'>
                <HiOutlineMailOpen className="h-10 w-10" />
              </div>
              <div className='mt-3 text-center sm:mt-5'>
                <div as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  {t('Enter 6-digit code')}
                </div>
                <div className='mt-2 mb-4'>
                  <p className='text-sm text-gray-500'>
                    {t('A 6-digit passcode was sent to you at ')}
                    <b>{this.props.FORM_email}</b>
                  </p>
                </div>
              </div>

              <div className='flex mb-6'>
                <div className='w-1/2 min-w-0 mx-auto'>
                  <input
                    id='FORM_OTP'
                    name='FORM_OTP'
                    type='text'
                    maxLength='6'
                    value={this.props.FORM_OTP}
                    onChange={this.props.handleChange}
                    required
                    className='relative block w-full px-3 py-2 text-center text-xl tracking-[0.4em] placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-300 focus:border-primary-300'
                  />
                </div>
              </div>
              <Button loading={this.props.loadingAuth} fluid onClick={e => {e.preventDefault(); this.props.handleSubmitConfirmationCode(this.props.FORM_OTP)}}>
                {t('Confirm')}
              </Button>
            </form>
          </div>

          {/* <div className={`${this.props.signInMethod === 'email' && this.props.FORM_checkEmail ? '' : 'hidden'}`}>
            <div>
              <div className='flex items-center justify-center w-12 h-12 mx-auto bg-green-100 rounded-full'>
                <CheckIcon className='w-6 h-6 text-green-600' aria-hidden='true' />
              </div>
              <div className='mt-3 text-center sm:mt-5'>
                <div as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                  {t('Email sent!')}
                </div>
                <div className='mt-2'>
                  <p className='text-sm text-gray-500'>We sent a confirmation email to {this.props.FORM_email}. Follow the link in the email you received to login to Ruta.</p>
                </div>
              </div>
            </div>
          </div> */}

          <div className={`${this.props.signInMethod === 'email' && !this.props.FORM_checkEmail ? '' : 'hidden'}`}>
            {!this.props.FORM_checkEmail && (
              <>
                <form className={`space-y-6 ${this.props.FORM_checkEmail || this.props.signInMethod !== 'email' ? 'hidden' : ''}`} onSubmit={this.props.handleStytchLoginRequest}>
                  <div>
                    <label htmlFor='email' className='block text-sm font-medium text-gray-700'>
                      {t('Email')}
                    </label>
                    <div className='mt-1'>
                      <input
                        id='FORM_email'
                        name='FORM_email'
                        type='email'
                        value={this.props.FORM_email}
                        onChange={this.props.handleChange}
                        autoComplete='email'
                        required
                        className='block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                      />
                    </div>
                  </div>
                  <div>
                    <Button loading={this.props.loadingAuth} fluid onClick={this.props.handleStytchLoginRequest}>
                    {t('Continue')}
                    </Button>
                  </div>
                </form>
              </>
            )}
          </div>
        </section>
      </>
    )
  }
}

export default withTranslation()(SytchForm)
