import axios from 'axios'
import moment from 'moment'
import { APIError, handleAxiosAPIError } from '../util/errors.js'

export const patchExpense = async (firebaseUser, tripId, expenseId, expenseData) => {
  try {
    let result = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_HOST}/trips/${tripId}/expenses/${expenseId}`,
      data: expenseData
    })
    let expense = result.data
    return expense
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const createExpense = async (firebaseUser, tripId, expenseData) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/trips/${tripId}/expenses`,
      data: expenseData
    })
    let expense = result.data
    return expense
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const deleteExpense = async (firebaseUser, tripId, expenseId) => {
  try {
    let result = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_HOST}/trips/${tripId}/expenses/${expenseId}`,
    })
    return true
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

