import React from 'react'
import { withRouter } from 'react-router-dom'
import { StateContext } from '../../../../util/state.jsx'
import { classNames } from '../../../../util/helpers'
import LogoWide from '../../../../img/logo_wide.svg'

class LeftNavigation extends React.Component {
  static contextType = StateContext

  setStep = () => {}

  state = {
    sidebarOpen: false,
  }

  handleNavClick = location => {
    const [{}, dispatch] = this.context
    if (location === '/logout') {
      // handle logout
      localStorage.removeItem('rutaSessionToken')
      localStorage.removeItem('rutaSessionJwt')
      dispatch({
        type: 'setUserAndToken',
        user: null,
        sessionToken: null,
        sessionJwt: null,
      })
    } else {
      this.props.history.push(`${location}`)
    }
  }

  componentDidMount = () => {}

  render() {
    const [{ user }] = this.context
    // const activePage = this.props.location.pathname.split("/")[2]

    const { navigation, userNavigation, subNavigation } = this.props

    return (
      <div className='hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col'>
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className='flex flex-col flex-1 min-h-0 border-r bg-stone-800 '>
          <div className='flex flex-col flex-1 pt-5 pb-4 overflow-y-auto'>
            <div className='flex items-center flex-shrink-0 px-4'>
              <img className='w-auto h-8 mx-auto' src={LogoWide} alt='Workflow' />
            </div>
            <nav className='flex-1 mt-5' aria-label='Sidebar'>
              <div className='px-2 space-y-1'>
                {navigation.map(item => (
                  <a
                    key={item.name}
                    onClick={() => this.handleNavClick(item.href)}
                    className={classNames(item.current ? 'bg-stone-900 text-white' : 'text-stone-300 hover:bg-stone-700 hover:text-white', 'group flex items-center px-2 py-2 text-sm font-medium rounded-sm cursor-pointer')}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    <item.icon className={classNames(item.current ? 'text-stone-300' : 'text-stone-400 group-hover:text-stone-300', 'mr-3 flex-shrink-0 h-6 w-6')} aria-hidden='true' />
                    {item.name}
                  </a>
                ))}
              </div>

              {subNavigation.map(item => (
                <>
                  <div className='relative my-5'>
                    <div className='absolute inset-0 flex items-center' aria-hidden='true'>
                      <div className='w-full border-t border-stone-700' />
                    </div>
                    <div className='relative flex justify-center'>
                      <span className='px-2 text-sm text-stone-300 bg-stone-800'>Actions</span>
                    </div>
                  </div>

                  <div className='px-2 space-y-1'>
                    <a
                      key={item.name}
                      onClick={() => this.handleNavClick(item.href)}
                      className={classNames(item.current ? 'bg-stone-900 text-white' : 'text-stone-300 hover:bg-stone-700 hover:text-white', 'group flex items-center px-2 py-2 text-sm font-medium rounded-sm cursor-pointer')}
                    >
                      <item.icon className={classNames(item.current ? 'text-stone-300' : 'text-stone-400 group-hover:text-stone-300', 'mr-3 flex-shrink-0 h-6 w-6')} aria-hidden='true' />
                      {item.name}
                    </a>
                  </div>
                </>
              ))}

              <hr className='my-5 border-t border-stone-700' aria-hidden='true' />
              <div className='flex-1 px-2 space-y-1'>
                {userNavigation.map(item => (
                  <a
                    key={item.name}
                    onClick={() => this.handleNavClick(item.href)}
                    className={classNames(item.current ? 'bg-stone-900 text-white' : 'text-stone-300 hover:bg-stone-700 hover:text-white', 'group flex items-center px-2 py-2 text-sm font-medium rounded-sm cursor-pointer')}
                  >
                    <item.icon className={classNames(item.current ? 'text-stone-300' : 'text-stone-400 group-hover:text-stone-300', 'mr-3 flex-shrink-0 h-6 w-6')} aria-hidden='true' />
                    {item.name}
                  </a>
                ))}
              </div>
            </nav>
          </div>
          <div className='flex flex-shrink-0 p-4 border-t border-stone-700'>
            <a href='#' className='flex-shrink-0 block w-full group'>
              <div className='flex items-center'>
                <div>{/* <img className="inline-block rounded-full h-9 w-9" src={user.imageUrl} alt="" /> */}</div>
                <div className='ml-3'>
                  <p className='text-sm font-medium text-stone-300 group-hover:text-stone-400'>{user?.fields?.displayName}</p>
                  {/* <p className='text-xs font-medium text-stone-400 group-hover:text-stone-500'>View profile</p> */}
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(LeftNavigation)
