

// Custom Errors
export const APIError = class APIError extends Error {
  constructor(APIResponse) {
    super(APIResponse?.message)
    this.name =APIResponse?.name
    this.code =APIResponse?.code
    this.details =APIResponse?.details
  }
}

export const handleAxiosAPIError = (error) => {
  if (error.response) {
    console.log(error.response)
    throw new APIError(error.response.data)
    // client received an error response (5xx, 4xx)
  } else if (error.request) {
    console.log(error.request)
    throw new APIError({message: 'Could not connect to API', name: 'NETWORK_ERROR', statusCode: 500})
    // client never received a response, or request never left
  } else {
    console.log('Something bad happened')
    console.log(error)
    throw new APIError({message: 'An unexpected exception happened in the front end. ', name: 'APP_ERROR', statusCode: 500})
  }    
}