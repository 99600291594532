import React from 'react'
import { FaQuoteLeft } from 'react-icons/fa'
import { CheckIcon } from '@heroicons/react/solid'
import { classNames } from '../../../util/helpers'
import { useContext } from 'react'
import { StateContext } from '../../../util/state'
import { useParams } from 'react-router-dom'
import AvatarImage from '../../../components/AvatarImage'
import { useTranslation } from 'react-i18next';

const Step1Overview = (props) => {
  const [{user}, dispatch] = useContext(StateContext)
  const { t, i18n } = useTranslation();
  const {steps} = props
  const overviewSteps = steps.filter(s => s.inSummary)
  const {jobId} = useParams()
  const job = user?.fields?.jobs.find(j => j?.job?.id === jobId)
  const assignedFacilitator = job?.job?.assignedFacilitator
  
  return (
    <div className="mb-6">
      {assignedFacilitator && 
        <div className='flex mb-6'>
          <div className='mr-4 flex-shrink-0 self-center'>
          {assignedFacilitator?.photo ?
                <img src={assignedFacilitator?.photo} alt="" className="flex-shrink-0 h-16 w-16 rounded-full" />
                :
                <AvatarImage size={16} />
            }
            {/* <img className='inline-block h-16 w-16 rounded-full' src={} alt='' /> */}
          </div>
          <div>
            <p className='text-xs italic font-medium text-gray-700 group-hover:text-gray-900'>
              <FaQuoteLeft className='w-5 h-5 mb-2' />
              {t('Hello,')} {user?.fields?.firstName}! {t('We use Ruta to help with your Visa application. If you have any questions, please call me.')}
            </p>
            <p className='text-sm text-right font-medium text-gray-500 group-hover:text-gray-700'>{assignedFacilitator?.firstName} {assignedFacilitator?.lastName}</p>
            <p className='text-xs text-right font-medium text-blue-400 cursor-pointer'> {assignedFacilitator?.phoneNumber}</p>
          </div>
        </div>
      }
      <div>
        <p className="text-center mb-6">{t('With Ruta, you will:')}</p>
        <nav aria-label='Progress'>
          <ol role='list' className='overflow-hidden'>
            {overviewSteps.map((step, stepIdx) => (
              <li key={step.id} className={classNames(stepIdx !== overviewSteps.length - 1 ? 'pb-8' : '', 'relative')}>
                <>
                  {stepIdx !== overviewSteps.length - 1 ? <div className='absolute top-4 left-4 -ml-px mt-0.5 h-full w-0.5 bg-primary-300' aria-hidden='true' /> : null}
                  <a href={step.href} className='group relative flex items-center'>
                    <span className='flex h-9 items-center'>
                      <span className='relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-primary-300 group-hover:bg-primary-400'>
                        <CheckIcon className='h-5 w-5 text-white' aria-hidden='true' />
                      </span>
                    </span>
                    <span className='ml-4 flex flex-col min-w-0 '>
                      <span className='text-sm font-medium'>{step.title}</span>
                      <span className='text-sm text-gray-500'>{step.shortDescription}</span>
                    </span>
                  </a>
                </>
              </li>
            ))}
          </ol>
        </nav>
      </div>
    </div>
  )
}

export default Step1Overview
