import { SearchIcon } from '@heroicons/react/outline'
import { FilterIcon } from '@heroicons/react/solid'
import { useContext, useLayoutEffect, useRef, useState } from 'react'
import { StateContext } from '../../../../util/state'
import { useHistory } from 'react-router-dom';
import WorkerListFilters from './components/workerListFilters.jsx'
import AvatarImage from '../../../../components/AvatarImage'

const people = [
  {
    name: 'Lindsay Walton',
    title: 'Front-end Developer',
    email: 'lindsay.walton@example.com',
    role: 'Member',
    imageUrl:
      'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  },
  // More people...
]

for (let index = 27; index < 100; index++) {
  people.push({
    id: index,
    name: 'Leslie Abbott',
    role: 'Co-Founder / CEO',
    imageUrl:
      'https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
  })
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function WorkerList() {
  const checkbox = useRef()
  const state = useContext(StateContext)

  const [searchText, setSearchText] = useState()
  const [sortMethod, setSortMethod] = useState()
  const [assignedFilter, setAssignedFilter] = useState('all')
  const [employerFilter, setEmployerFilter] = useState()

  const [checked, setChecked] = useState(false)
  const [indeterminate, setIndeterminate] = useState(false)
  const [selectedPeople, setSelectedPeople] = useState([])
  const history = useHistory();

  const [{ workersAndMeta, isLoadingWorkers, user }] = state
  const latestWorkersAndMeta = workersAndMeta?.result

  let workers = searchText ? workersAndMeta?.result.filter(w => w.fields?.displayName.toLowerCase().includes(searchText.toLowerCase())) : latestWorkersAndMeta

  // Sort
  switch (sortMethod) {
    case 'firstName':
      workers.sort((w1,w2) => w1?.fields?.firstName.localeCompare(w2?.fields?.firstName, undefined, {sensitivity: 'base'}))
      break;
    case 'lastName':
      workers.sort((w1,w2) => w1?.fields?.lastName.localeCompare(w2?.fields?.lastName, undefined, {sensitivity: 'base'}))
      break;
    case 'newest':
      workers.sort((w1,w2) => w1?.createdAt < w2?.createdAt)
      break;
    case 'oldest':
      workers.sort((w1,w2) => w1?.createdAt > w2?.createdAt)
      break;
    case 'updated':
      workers.sort((w1,w2) => w1?.updatedAt < w2?.updatedAt)
      break;
    default:
      break;
  }

  // Filter
  if (assignedFilter === 'me') {
    workers = workers.filter(w => user.id === w?.fields?.assignedFacilitatorUserId) 
    // Else don't filter anything out
  }

  if (employerFilter) {
    workers = workers.filter(w => w?.fields?.employerId === employerFilter?.id)
  }

  const handleSearchChange = (e) => {
    const searchText = e.target.value
    setSearchText(searchText)
  }

  useLayoutEffect(() => {
    const isIndeterminate = selectedPeople.length > 0 && selectedPeople.length < workers.length
    setChecked(selectedPeople.length === workers.length)
    setIndeterminate(isIndeterminate)
    checkbox.current.indeterminate = isIndeterminate
  }, [selectedPeople])

  function toggleAll() {
    setSelectedPeople(checked || indeterminate ? [] : workers)
    setChecked(!checked && !indeterminate)
    setIndeterminate(false)
  }

  return (

    <>
      <div className="px-6 pt-6 pb-4">
        <h2 className="text-lg font-medium text-gray-900">Directory</h2>
        <p className="mt-1 text-sm text-gray-600">Search directory of {(workers.length).toLocaleString(undefined, { minimumFractionDigits: 0 })} employees</p>
        <form className="flex mt-6 space-x-4" action="#">
          <div className="flex-1 min-w-0">
            <label htmlFor="search" className="sr-only">
              Search
            </label>
            <div className="relative rounded-md shadow-sm">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <SearchIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
              </div>
              <input
                type="search"
                name="search"
                id="search"
                onChange={handleSearchChange}
                value={searchText}
                className="block w-full pl-10 border-gray-300 rounded-md focus:ring-pink-500 focus:border-pink-500 sm:text-sm"
                placeholder="Search"
              />
            </div>
          </div>
          <button
            type="submit"
            className="inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-pink-500"
          >
            <FilterIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
            <span className="sr-only">Search</span>
          </button>
        </form>
      </div>
      <WorkerListFilters
        sortMethod={sortMethod}
        setSortMethod={setSortMethod}
        assignedFilter={assignedFilter}
        setAssignedFilter={setAssignedFilter}
        employerFilter={employerFilter}
        setEmployerFilter={setEmployerFilter}
      />

      {/* Directory list */}
      <nav className="flex-1 min-h-0 overflow-y-auto" aria-label="Directory">
        <div className="">
          <div className="flex flex-col">
            <div className="">
              <div className="inline-block min-w-full align-middle">
                <div className="relative overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                  {selectedPeople.length > 0 && (
                    <div className="absolute top-0 flex items-center h-12 space-x-3 left-12 bg-gray-50 sm:left-16">
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                      >
                        Bulk edit
                      </button>
                      <button
                        type="button"
                        className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-30"
                      >
                        Delete all
                      </button>
                    </div>
                  )}
                  <table className="min-w-full divide-y divide-gray-300 table-fixed">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="relative w-12 px-6 sm:w-16 sm:px-8">
                          <input
                            type="checkbox"
                            className="absolute w-4 h-4 -mt-2 text-indigo-600 border-gray-300 rounded left-4 top-1/2 focus:ring-indigo-500 sm:left-6"
                            ref={checkbox}
                            checked={checked}
                            onChange={toggleAll}
                          />
                        </th>
                        <th scope="col" className="min-w-[12rem] py-3.5 pr-3 text-left text-sm font-semibold text-gray-900">
                          Worker
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {isLoadingWorkers ? (<test></test>)
                        :

                        <>
                          {workers.map((person) => (
                            <tr 
                              key={person.id} 
                              className={selectedPeople.includes(person) ? 'bg-gray-50' : 'hover:bg-gray-50 focus-within:ring-2 focus-within:ring-inset focus-within:ring-pink-500'}
                              onClick={() => {console.log(history); history.push('/facilitator/workers/'+person.id)}}
                              >
                              <td className="relative w-12 px-6 sm:w-16 sm:px-8">
                                {selectedPeople.includes(person) && (
                                  <div className="absolute inset-y-0 left-0 w-0.5 bg-indigo-600" />
                                )}
                                <input
                                  type="checkbox"
                                  className="absolute w-4 h-4 -mt-2 text-indigo-600 border-gray-300 rounded left-4 top-1/2 focus:ring-indigo-500 sm:left-6"
                                  value={person.fields?.email}
                                  checked={selectedPeople.includes(person)}
                                  onChange={(e) =>
                                    setSelectedPeople(
                                      e.target.checked
                                        ? [...selectedPeople, person]
                                        : selectedPeople.filter((p) => p !== person)
                                    )
                                  }
                                />
                              </td>
                              <td
                                className={classNames(
                                  'whitespace-nowrap py-4 pr-3 text-sm font-medium',
                                  selectedPeople.includes(person) ? 'text-indigo-600' : 'text-gray-900'
                                )}
                              >
                                <div className="relative flex items-center space-x-3">
                                  <div className="flex flex-shrink-0">
                                    <AvatarImage size={10} />
                                  </div>
                                  <div className="flex-1 min-w-0">
                                    <a href="#" className="focus:outline-none">
                                      {/* Extend touch target to entire panel */}
                                      <span className="absolute inset-0" aria-hidden="true" />
                                      <p className="text-sm font-medium text-gray-900">{person.fields?.displayName}</p>
                                      <p className="text-sm text-gray-500 truncate">{person.fields?.employer?.name}</p>
                                    </a>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </>


  )
}
