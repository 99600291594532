import axios from 'axios'
import { APIError, handleAxiosAPIError } from '../util/errors.js'

export const retrieveJobForEmployer = async (employerId, jobId) => {
  try {
    let result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/employers/${employerId}/jobs/${jobId}`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const listJobsForEmployer = async (employerId) => {
  try {
    let result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/employers/${employerId}/jobs/`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const listJobsForPartner = async (partnerId) => {
  try {
    let result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/employers/partners/${partnerId}/jobs/`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

