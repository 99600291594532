
import DHSLogo from "../img/dhs_logo.png"

export const staffPhoneNumbers = [
    '+19702741280',
    '+12222220001',
    '+12108549880'
]

export const visaOptions = [
  {
    key: 'H-2A',
    text: 'H-2A',
    value: 'H-2A',
    image: { avatar: true, src: DHSLogo },
  },
  {
    key: 'H-2B',
    text: 'H-2B',
    value: 'H-2B',
    image: { avatar: true, src: DHSLogo },
  },
  {
    key: 'TN',
    text: 'TN',
    value: 'TN',
    image: { avatar: true, src: DHSLogo },
  },
  {
    key: 'H-1B',
    text: 'H-1B',
    value: 'H-1B',
    image: { avatar: true, src: DHSLogo },
  }
]

export const documentTypes = [
  {
    "key": "COVID_TEST",
    "value": "COVID_TEST",
    "text": "Covid Test Result"
  },
  {
    "key": "BORDER_CROSSING",
    "value": "BORDER_CROSSING",
    "text": "Border Crossing Document"
  },
  {
    "key": "COVID_VACCINE_PROOF",
    "value": "COVID_VACCINE_PROOF",
    "text": "Covid Vaccine Proof"
  },
  {
    "key": "VISA",
    "value": "VISA",
    "text": "Visa"
  },
  {
    "key": "PASSPORT",
    "value": "PASSPORT",
    "text": "Passport"
  },
  {
    "key": "OTHER",
    "value": "OTHER",
    "text": "Other Document"
  }
]

export const expenseCategoryOptions = [
  {
    "key": "FOOD",
    "value": "FOOD",
    "text": "Food"
  },
  {
    "key": "TRANSPORTATION",
    "value": "TRANSPORTATION",
    "text": "Transportation"
  },
  {
    "key": "STATE_DEPARTMENT_FEE",
    "value": "STATE_DEPARTMENT_FEE",
    "text": "State Department Fee"
  },
  {
    "key": "HOTEL",
    "value": "HOTEL",
    "text": "Hotel"
  },
  {
    "key": "SHUTTLE",
    "value": "SHUTTLE",
    "text": "Shuttle"
  },
  {
    "key": "DRUG_TEST",
    "value": "DRUG_TEST",
    "text": "Drug Test"
  },
  {
    "key":"COVID_TEST",
    "value":"COVID_TEST",
    "text":"Covid Test"
  },
  {
    "key":"BORDER_CROSSING_FEE",
    "value":"BORDER_CROSSING_FEE",
    "text":"Border Crossing Fee (I-94)"
  },
  {
    "key":"FACILITATORS_FEE",
    "value":"FACILITATORS_FEE",
    "text":"Facilitators fee"
  },
    {"key":"DHL_FEE",
    "value":"DHL_FEE",
    "text":"DHL fee"
  }
]

export const userGroupOptions = [
    {key:'EMPLOYEE', value:'EMPLOYEE', text:'Applicant'},
    {key:'FACILITATOR', value:'FACILITATOR', text:'Facilitator'},
]

export const userRoleOptions = {
  'EMPLOYEE': [
    {key:'EMPLOYEE_EMPLOYEE', value:'EMPLOYEE_EMPLOYEE', text:'Applicant'},
  ],
  'FACILITATOR': [
    {key:'FACILITATOR_ADMIN', value:'FACILITATOR_ADMIN', text:'Admin'},
    {key:'FACILITATOR_MEMBER', value:'FACILITATOR_MEMBER', text:'Member'}
  ]
}

export const countryOptions = [
  {
    key: 'United States',
    text: 'United States',
    value: 'USA',
    flag: 'us',
  },
  {
    key: 'Mexico',
    text: 'Mexico',
    value: 'MEX',
    flag: 'mx',
  }
]

export const stateOptions = {
  '': [],
  'All': [
    {key:'Alabama', value:'AL', text:'Alabama'},
    {key:'Alaska', value:'AK', text:'Alaska'},
    {key:'Arizona', value:'AZ', text:'Arizona'},
    {key:'Arkansas', value:'AR', text:'Arkansas'},
    {key:'California', value:'CA', text:'California'},
    {key:'Colorado', value:'CO', text:'Colorado'},
    {key:'Connecticut', value:'CT', text:'Connecticut'},
    {key:'Delaware', value:'DE', text:'Delaware'},
    {key:'District of Columbia', value:'DC', text:'District of Columbia'},
    {key:'Florida', value:'FL', text:'Florida'},
    {key:'Georgia', value:'GA', text:'Georgia'},
    {key:'Hawaii', value:'HI', text:'Hawaii'},
    {key:'Idaho', value:'ID', text:'Idaho'},
    {key:'Illinois', value:'IL', text:'Illinois'},
    {key:'Indiana', value:'IN', text:'Indiana'},
    {key:'Iowa', value:'IA', text:'Iowa'},
    {key:'Kansas', value:'KS', text:'Kansas'},
    {key:'Kentucky', value:'KY', text:'Kentucky'},
    {key:'Louisiana', value:'LA', text:'Louisiana'},
    {key:'Maine', value:'ME', text:'Maine'},
    {key:'Maryland', value:'MD', text:'Maryland'},
    {key:'Massachusetts', value:'MA', text:'Massachusetts'},
    {key:'Michigan', value:'MI', text:'Michigan'},
    {key:'Minnesota', value:'MN', text:'Minnesota'},
    {key:'Mississippi', value:'MS', text:'Mississippi'},
    {key:'Missouri', value:'MO', text:'Missouri'},
    {key:'Montana', value:'MT', text:'Montana'},
    {key:'Nebraska', value:'NE', text:'Nebraska'},
    {key:'Nevada', value:'NV', text:'Nevada'},
    {key:'New Hampshire', value:'NH', text:'New Hampshire'},
    {key:'New Jersey', value:'NJ', text:'New Jersey'},
    {key:'New Mexico', value:'NM', text:'New Mexico'},
    {key:'New York', value:'NY', text:'New York'},
    {key:'North Carolina', value:'NC', text:'North Carolina'},
    {key:'North Dakota', value:'ND', text:'North Dakota'},
    {key:'Ohio', value:'OH', text:'Ohio'},
    {key:'Oklahoma', value:'OK', text:'Oklahoma'},
    {key:'Oregon', value:'OR', text:'Oregon'},
    {key:'Pennsylvania', value:'PA', text:'Pennsylvania'},
    {key:'Puerto Rico', value:'PR', text:'Puerto Rico'},
    {key:'Rhode Island', value:'RI', text:'Rhode Island'},
    {key:'South Carolina', value:'SC', text:'South Carolina'},
    {key:'South Dakota', value:'SD', text:'South Dakota'},
    {key:'Tennessee', value:'TN', text:'Tennessee'},
    {key:'Texas', value:'TX', text:'Texas'},
    {key:'Utah', value:'UT', text:'Utah'},
    {key:'Vermont', value:'VT', text:'Vermont'},
    {key:'Virginia', value:'VA', text:'Virginia'},
    {key:'Washington', value:'WA', text:'Washington'},
    {key:'West Virginia', value:'WV', text:'West Virginia'},
    {key:'Wisconsin', value:'WI', text:'Wisconsin'},
    {key:'Wyoming', value:'WY', text:'Wyoming'},

    {key:'Aguascalientes', value:'AGU', text:'Aguascalientes'},
    {key:'Baja California', value:'BCN', text:'Baja California'},
    {key:'Baja California Sur', value:'BCS', text:'Baja California Sur'},
    {key:'Campeche', value:'CAM', text:'Campeche'},
    {key:'Chiapas', value:'CHP', text:'Chiapas'},
    {key:'Chihuahua', value:'CHH', text:'Chihuahua'},
    {key:'Coahuila', value:'COA', text:'Coahuila'},
    {key:'Colima', value:'COL', text:'Colima'},
    {key:'Ciudad de México', value:'CMX', text:'Ciudad de México'},
    {key:'Durango', value:'DUR', text:'Durango'},
    {key:'Guanajuato', value:'GUA', text:'Guanajuato'},
    {key:'Guerrero', value:'GRO', text:'Guerrero'},
    {key:'Hidalgo', value:'HID', text:'Hidalgo'},
    {key:'Jalisco', value:'JAL', text:'Jalisco'},
    {key:'México State', value:'México State', text:'México State'},
    {key:'Michoacán', value:'MIC', text:'Michoacán'},
    {key:'Morelos', value:'MOR', text:'Morelos'},
    {key:'Nayarit', value:'NAY', text:'Nayarit'},
    {key:'Nuevo León', value:'NLE', text:'Nuevo León'},
    {key:'Oaxaca', value:'OAX', text:'Oaxaca'},
    {key:'Puebla', value:'PUE', text:'Puebla'},
    {key:'Querétaro', value:'QUE', text:'Querétaro'},
    {key:'Quintana Roo', value:'ROO', text:'Quintana Roo'},
    {key:'San Luis Potosí', value:'SLP', text:'San Luis Potosí'},
    {key:'Sinaloa', value:'SIN', text:'Sinaloa'},
    {key:'Sonora', value:'SON', text:'Sonora'},
    {key:'Tabasco', value:'TAB', text:'Tabasco'},
    {key:'Tamaulipas', value:'TAM', text:'Tamaulipas'},
    {key:'Tlaxcala', value:'TLA', text:'Tlaxcala'},
    {key:'Veracruz', value:'VER', text:'Veracruz'},
    {key:'Yucatán', value:'YUC', text:'Yucatán'},
    {key:'Zacatecas  ', value:'ZAC  ', text:'Zacatecas'}

  ],
  'USA': [
    {key:'Alabama', value:'AL', text:'Alabama'},
    {key:'Alaska', value:'AK', text:'Alaska'},
    {key:'Arizona', value:'AZ', text:'Arizona'},
    {key:'Arkansas', value:'AR', text:'Arkansas'},
    {key:'California', value:'CA', text:'California'},
    {key:'Colorado', value:'CO', text:'Colorado'},
    {key:'Connecticut', value:'CT', text:'Connecticut'},
    {key:'Delaware', value:'DE', text:'Delaware'},
    {key:'District of Columbia', value:'DC', text:'District of Columbia'},
    {key:'Florida', value:'FL', text:'Florida'},
    {key:'Georgia', value:'GA', text:'Georgia'},
    {key:'Hawaii', value:'HI', text:'Hawaii'},
    {key:'Idaho', value:'ID', text:'Idaho'},
    {key:'Illinois', value:'IL', text:'Illinois'},
    {key:'Indiana', value:'IN', text:'Indiana'},
    {key:'Iowa', value:'IA', text:'Iowa'},
    {key:'Kansas', value:'KS', text:'Kansas'},
    {key:'Kentucky', value:'KY', text:'Kentucky'},
    {key:'Louisiana', value:'LA', text:'Louisiana'},
    {key:'Maine', value:'ME', text:'Maine'},
    {key:'Maryland', value:'MD', text:'Maryland'},
    {key:'Massachusetts', value:'MA', text:'Massachusetts'},
    {key:'Michigan', value:'MI', text:'Michigan'},
    {key:'Minnesota', value:'MN', text:'Minnesota'},
    {key:'Mississippi', value:'MS', text:'Mississippi'},
    {key:'Missouri', value:'MO', text:'Missouri'},
    {key:'Montana', value:'MT', text:'Montana'},
    {key:'Nebraska', value:'NE', text:'Nebraska'},
    {key:'Nevada', value:'NV', text:'Nevada'},
    {key:'New Hampshire', value:'NH', text:'New Hampshire'},
    {key:'New Jersey', value:'NJ', text:'New Jersey'},
    {key:'New Mexico', value:'NM', text:'New Mexico'},
    {key:'New York', value:'NY', text:'New York'},
    {key:'North Carolina', value:'NC', text:'North Carolina'},
    {key:'North Dakota', value:'ND', text:'North Dakota'},
    {key:'Ohio', value:'OH', text:'Ohio'},
    {key:'Oklahoma', value:'OK', text:'Oklahoma'},
    {key:'Oregon', value:'OR', text:'Oregon'},
    {key:'Pennsylvania', value:'PA', text:'Pennsylvania'},
    {key:'Puerto Rico', value:'PR', text:'Puerto Rico'},
    {key:'Rhode Island', value:'RI', text:'Rhode Island'},
    {key:'South Carolina', value:'SC', text:'South Carolina'},
    {key:'South Dakota', value:'SD', text:'South Dakota'},
    {key:'Tennessee', value:'TN', text:'Tennessee'},
    {key:'Texas', value:'TX', text:'Texas'},
    {key:'Utah', value:'UT', text:'Utah'},
    {key:'Vermont', value:'VT', text:'Vermont'},
    {key:'Virginia', value:'VA', text:'Virginia'},
    {key:'Washington', value:'WA', text:'Washington'},
    {key:'West Virginia', value:'WV', text:'West Virginia'},
    {key:'Wisconsin', value:'WI', text:'Wisconsin'},
    {key:'Wyoming', value:'WY', text:'Wyoming'},
  ],
  'MEX': [
    {key:'Aguascalientes', value:'AGU', text:'Aguascalientes'},
    {key:'Baja California', value:'BCN', text:'Baja California'},
    {key:'Baja California Sur', value:'BCS', text:'Baja California Sur'},
    {key:'Campeche', value:'CAM', text:'Campeche'},
    {key:'Chiapas', value:'CHP', text:'Chiapas'},
    {key:'Chihuahua', value:'CHH', text:'Chihuahua'},
    {key:'Coahuila', value:'COA', text:'Coahuila'},
    {key:'Colima', value:'COL', text:'Colima'},
    {key:'Ciudad de México', value:'CMX', text:'Ciudad de México'},
    {key:'Durango', value:'DUR', text:'Durango'},
    {key:'Guanajuato', value:'GUA', text:'Guanajuato'},
    {key:'Guerrero', value:'GRO', text:'Guerrero'},
    {key:'Hidalgo', value:'HID', text:'Hidalgo'},
    {key:'Jalisco', value:'JAL', text:'Jalisco'},
    {key:'México State', value:'México State', text:'México State'},
    {key:'Michoacán', value:'MIC', text:'Michoacán'},
    {key:'Morelos', value:'MOR', text:'Morelos'},
    {key:'Nayarit', value:'NAY', text:'Nayarit'},
    {key:'Nuevo León', value:'NLE', text:'Nuevo León'},
    {key:'Oaxaca', value:'OAX', text:'Oaxaca'},
    {key:'Puebla', value:'PUE', text:'Puebla'},
    {key:'Querétaro', value:'QUE', text:'Querétaro'},
    {key:'Quintana Roo', value:'ROO', text:'Quintana Roo'},
    {key:'San Luis Potosí', value:'SLP', text:'San Luis Potosí'},
    {key:'Sinaloa', value:'SIN', text:'Sinaloa'},
    {key:'Sonora', value:'SON', text:'Sonora'},
    {key:'Tabasco', value:'TAB', text:'Tabasco'},
    {key:'Tamaulipas', value:'TAM', text:'Tamaulipas'},
    {key:'Tlaxcala', value:'TLA', text:'Tlaxcala'},
    {key:'Veracruz', value:'VER', text:'Veracruz'},
    {key:'Yucatán', value:'YUC', text:'Yucatán'},
    {key:'Zacatecas  ', value:'ZAC  ', text:'Zacatecas'}
  ]
}

export const state_codes_to_names = {
  "AL": "Alabama",
  "AK": "Alaska",
  "AZ": "Arizona",
  "AR": "Arkansas",
  "CA": "California",
  "CO": "Colorado",
  "CT": "Connecticut",
  "DE": "Delaware",
  "DC": "District Of Columbia",
  "FL": "Florida",
  "GA": "Georgia",
  "HI": "Hawaii",
  "ID": "Idaho",
  "IL": "Illinois",
  "IN": "Indiana",
  "IA": "Iowa",
  "KS": "Kansas",
  "KY": "Kentucky",
  "LA": "Louisiana",
  "ME": "Maine",
  "MD": "Maryland",
  "MA": "Massachusetts",
  "MI": "Michigan",
  "MN": "Minnesota",
  "MS": "Mississippi",
  "MO": "Missouri",
  "MT": "Montana",
  "NE": "Nebraska",
  "NV": "Nevada",
  "NH": "New Hampshire",
  "NJ": "New Jersey",
  "NM": "New Mexico",
  "NY": "New York",
  "NC": "North Carolina",
  "ND": "North Dakota",
  "OH": "Ohio",
  "OK": "Oklahoma",
  "OR": "Oregon",
  "PA": "Pennsylvania",
  "PR": "Puerto Rico",
  "RI": "Rhode Island",
  "SC": "South Carolina",
  "SD": "South Dakota",
  "TN": "Tennessee",
  "TX": "Texas",
  "UT": "Utah",
  "VT": "Vermont",
  "VA": "Virginia",
  "WA": "Washington",
  "WV": "West Virginia",
  "WI": "Wisconsin",
  "WY": "Wyoming",
  "AGU": "Aguascalientes",
  "BCN": "Baja California",
  "BCS": "Baja California Sur",
  "CAM": "Campeche",
  "CHP": "Chiapas",
  "CHH": "Chihuahua",
  "COA": "Coahuila",
  "COL": "Colima",
  "CMX": "Mexico City",
  "DUR": "Durango",
  "GUA": "Guanajuato",
  "GRO": "Guerrero",
  "HID": "Hidalgo",
  "JAL": "Jalisco",
  "MIC": "Michoacán",
  "MOR": "Morelos",
  "NAY": "Nayarit",
  "NLE": "Nuevo León",
  "OAX": "Oaxaca",
  "PUE": "Puebla",
  "QUE": "Querétaro",
  "ROO": "Quintana Roo",
  "SLP": "San Luis Potosí",
  "SIN": "Sinaloa",
  "SON": "Sonora",
  "TAB": "Tabasco",
  "TAM": "Tamaulipas",
  "TLA": "Tlaxcala",
  "VER": "Veracruz",
  "YUC": "Yucatán",
  "ZAC": "Zacatecas",
}

export const country_codes_to_names= {
  'USA': 'United States',
  'MEX': 'Mexico'
}


export const mimeToFileExtension = {
    "text/html":                             "html",
    "text/css":                              "css",
    "text/xml":                              "xml",
    "image/gif":                             "gif",
    "image/jpeg":                            "jpeg",
    "application/x-javascript":              "js",
    "application/atom+xml":                  "atom",
    "application/rss+xml":                   "rss",

    "text/mathml":                           "mml",
    "text/plain":                            "txt",
    "text/vnd.sun.j2me.app-descriptor":      "jad",
    "text/vnd.wap.wml":                      "wml",
    "text/x-component":                      "htc",

    "image/png":                             "png",
    "image/tiff":                            "tif tiff",
    "image/vnd.wap.wbmp":                    "wbmp",
    "image/x-icon":                          "ico",
    "image/x-jng":                           "jng",
    "image/x-ms-bmp":                        "bmp",
    "image/svg+xml":                         "svg",
    "image/webp":                            "webp",

    "application/java-archive":              "jar war ear",
    "application/mac-binhex40":              "hqx",
    "application/msword":                    "doc",
    "application/pdf":                       "pdf",
    "application/postscript":                "ps eps ai",
    "application/rtf":                       "rtf",
    "application/vnd.ms-excel":              "xls",
    "application/vnd.ms-powerpoint":         "ppt",
    "application/vnd.wap.wmlc":              "wmlc",
    "application/vnd.google-earth.kml+xml":  "kml",
    "application/vnd.google-earth.kmz":      "kmz",
    "application/x-7z-compressed":           "7z",
    "application/x-cocoa":                   "cco",
    "application/x-java-archive-diff":       "jardiff",
    "application/x-java-jnlp-file":          "jnlp",
    "application/x-makeself":                "run",
    "application/x-perl":                    "pl pm",
    "application/x-pilot":                   "prc pdb",
    "application/x-rar-compressed":          "rar",
    "application/x-redhat-package-manager":  "rpm",
    "application/x-sea":                     "sea",
    "application/x-shockwave-flash":         "swf",
    "application/x-stuffit":                 "sit",
    "application/x-tcl":                     "tcl tk",
    "application/x-x509-ca-cert":            "der pem crt",
    "application/x-xpinstall":               "xpi",
    "application/xhtml+xml":                 "xhtml",
    "application/zip":                       "zip",

    "application/octet-stream":              "bin exe dll",
    "application/octet-stream":              "deb",
    "application/octet-stream":              "dmg",
    "application/octet-stream":              "eot",
    "application/octet-stream":              "iso img",
    "application/octet-stream":              "msi msp msm",

    "audio/midi":                            "mid midi kar",
    "audio/mpeg":                            "mp3",
    "audio/ogg":                             "ogg",
    "audio/x-realaudio":                     "ra",

    "video/3gpp":                            "3gp",
    "video/mpeg":                            "mpeg",
    "video/quicktime":                       "mov",
    "video/x-flv":                           "flv",
    "video/x-mng":                           "mng",
    "video/x-ms-asf":                        "asx asf",
    "video/x-ms-wmv":                        "wmv",
    "video/x-msvideo":                       "avi",
    "video/mp4":                             "mp4",
}