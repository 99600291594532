import React, { Fragment, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Modal } from '../../../components/Tailwind'
import { downloadContractForWorkerOnJob, listDocumentsInContract, updateWorkerStatusForJob } from '../../../models/organization'
import { StateContext } from '../../../util/state'
import moment from 'moment'
import { useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
// import { pdfjs } from 'react-pdf'
// import { Document, Page } from 'react-pdf'
// pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.min.js'

import { Viewer, Worker } from '@react-pdf-viewer/core'

import '@react-pdf-viewer/core/lib/styles/index.css'

const DownloadContractModal = props => {
  const [{ user }, dispatch] = useContext(StateContext)
  const { open, setOpen, job } = props
  const { t, i18n } = useTranslation()
  const [isLoadingContractDownload, setIsLoadingContractDownload] = useState(false)
  const cancelButtonRef = useRef(null)
  const [pdfUrl, setPdfUrl] = useState(null)
  const [pdfString, setPdfString] = useState('')
  const [showViewContractModal, setShowViewContractModal] = useState(false)

  const [documents, setDocuments] = useState([])
  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false)

  useEffect(() => {
    const loadDocs = async () => {
      setIsLoadingDocuments(true)
      if (open) {
        const response = await listDocumentsInContract(user?.fields?.facilitatorId, job?.jobId, user?.id)
        setDocuments(response?.documents)
        setIsLoadingDocuments(false)
      }
      setIsLoadingDocuments(false)
    }
    loadDocs()
  }, [open])

  const handleDownloadContract = async (filename, downloadURL) => {
    try {
      setIsLoadingContractDownload(true)

      const response = await downloadContractForWorkerOnJob(user?.fields?.facilitatorId, job?.jobId, user?.id, downloadURL)
      console.log(response)
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', filename) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)

      setIsLoadingContractDownload(false)
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to view contract. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  const handleViewContract = async (filename, downloadURL) => {
    try {
      setIsLoadingContractDownload(true)

      const response = await downloadContractForWorkerOnJob(user?.fields?.facilitatorId, job?.jobId, user?.id, downloadURL)
      const blob = response.data

      let base64String

      let reader = new FileReader()
      reader.readAsDataURL(blob)
      reader.onloadend = () => {
        base64String = reader.result
        setPdfString(base64String.substr(base64String.indexOf(',') + 1))
      }

      setPdfUrl(blob)
      setShowViewContractModal(true)
      setIsLoadingContractDownload(false)
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to view contract. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }


  console.log(pdfUrl)
  return (
    <Modal
      title={t('Save Contract')}
      // subtitle={this.props.profile ? "Update a worker's profile." : 'Add a new worker to Ruta'}
      buttonLabel={t('Done')}
      open={open}
      setOpen={setOpen}
      width='2xl'
    >
      {isLoadingDocuments && (
        <div className='w-full h-24'>
          <div className='w-full h-full flex text-center items-center justify-center pt-6 flex-wrap'>
            <AiOutlineLoading3Quarters className='flex animate-spin h-5 w-5'></AiOutlineLoading3Quarters>
            <p className='text-gray-600 mt-2 text-sm'>{props?.text}</p>
          </div>
        </div>
      )}
      {!isLoadingDocuments && documents.length > 0 && (
        <>
          <div className='flex flex-col py-4'>
            <div className='flex flex-col space-y-3'>
              {documents.map((doc, index) => (
                <div key={index} className='space-y-2'>
                  <div className='text-gray-700 text-sm pb-1'>{doc?.filename}</div>
                  <Button loading={isLoadingContractDownload} fluid color='light' onClick={() => handleDownloadContract(doc?.filename, doc?.downloadURL)}>
                    {t('Download')}
                  </Button>
                  <Button loading={isLoadingContractDownload} fluid color='light' onClick={() => handleViewContract(doc?.filename, doc?.downloadURL)}>
                    {t('View')}
                  </Button>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <Modal
      // title={t('View')}
      // subtitle={this.props.profile ? "Update a worker's profile." : 'Add a new worker to Ruta'}
      buttonLabel={t('Done')}
      open={showViewContractModal}
      setOpen={setShowViewContractModal}
      width='2xl'

      >
      {pdfUrl && (
          <Worker workerUrl='/pdf.worker.min.js'>
            <div style={{ height: '400px' }}>
              <Viewer fileUrl={`data:application/pdf;base64,${pdfString}`}  />
            </div>
          </Worker>
        )}
      </Modal>
    </Modal>
  )
}

export default DownloadContractModal
