import React from 'react';
import { StateContext } from '../../../../util/state.jsx';
import { withTranslation } from "react-i18next";
import { Modal } from '../../../../components/Tailwind'
import { withRouter } from 'react-router';
import { patchUser } from '../../../../models/user.jsx';
import { bulkSetFacilitatorForEmployer } from '../../../../models/organization.jsx'

import AssignFacilitatorForm from '../../forms/AssignFacilitatorForm/index.jsx';
import InviteEmployerForm from '../../forms/InviteEmployerForm/index.jsx';
import { createInvitationv2, sendInvitationSms } from '../../../../models/invitation.jsx';

class InviteEmployerUserModal extends React.Component {
    static contextType = StateContext;
    static defaultProps = {}

    state = {
        waitingOnAPIResult: false,
        FORM_firstName: undefined,
        FORM_lastName: undefined,
        FORM_email: undefined,
        FORM_phoneNumber: undefined,
    }

    handleChange = async (event) => this.setState({ [event.target.name]: event.target.value })

    componentDidMount = () => {
        if (this.document) {
            this.setState({
                FORM_firstName: undefined,
                FORM_lastName: undefined,
                FORM_email: undefined,
                FORM_phoneNumber: undefined,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open == true && prevProps.open == false) {
            this.setState({
                FORM_firstName: undefined,
                FORM_lastName: undefined,
                FORM_email: undefined,
                FORM_phoneNumber: undefined,
            })
        }
    }

    handleSendInvite = async () => {
        const [{ user, firebaseUser }, dispatch] = this.context;

        const { employerId } = this.props.match.params
        const FORM_recipientUserGroup = "EMPLOYER"
        const FORM_recipientUserRole = "EMPLOYER_ADMIN"


        this.setState({ waitingOnAPIResult: true })
        try {

            let invitationData = {
                firstName: this.state.FORM_firstName, // required
                lastName: this.state.FORM_lastName, // required
                // phoneNumber: this.state.FORM_phoneNumber.includes('+') ? this.state.FORM_phoneNumber : 
                email: this.state.FORM_email, 

                recipientOrganizationId: employerId,
                recipientUserGroup: FORM_recipientUserGroup, // required
                recipientUserRole: FORM_recipientUserRole,
                senderId: user.id, // required
            }
            const invitation = await createInvitationv2(firebaseUser, invitationData, false)
            await sendInvitationSms(firebaseUser, invitation.id, 'email')
            this.closeAndResetModal()
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `Failed to save an invitation. ${error.message} ${error.details}`, waitingOnAPIResult: false },
                showNotification: true
            })
            this.setState({ waitingOnAPIResult: false })
            return
        }

    }

    closeAndResetModal = async () => {
        const [{}, dispatch] = this.context;

        this.setState({
            FORM_firstName: undefined,
            FORM_lastName: undefined,
            FORM_email: undefined,
            FORM_phoneNumber: undefined,
        })
        dispatch({
            type: 'showNotification',
            notificationDetails: { title: 'Success!', content: `Sent invite.`, success: true },
            showNotification: true
        })
        this.props.setOpen(false)
    }

    render() {
        const { t } = this.props // translations

        return (
            <Modal
                title={'Invite Employer'}
                subtitle='Invite an employer to Ruta. Employers have view-only access to all the workers for their profile. '
                buttonLabel='Send Invitation Email'
                open={this.props.open}
                setOpen={this.props.setOpen}
                actionOnClick={this.handleSendInvite}
            >
                <InviteEmployerForm
                    FORM_firstName={this.state.FORM_firstName}
                    FORM_lastName={this.state.FORM_lastName}
                    FORM_email={this.state.FORM_email}
                    FORM_phoneNumber={this.state.FORM_phoneNumber}
                    handleChange={this.handleChange}
                    onSubmit={this.handleSendInvite}
                />
            </Modal>
        );
    }
}

export default withRouter(withTranslation()(InviteEmployerUserModal))