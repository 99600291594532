import React, { useContext } from 'react'
import { StateContext } from '../../../../util/state.jsx'
import { Message, Modal } from '../../../../components/Tailwind'
import { useState } from 'react'
import { createInvitationv2, sendInvitationSms } from '../../../../models/invitation.jsx'
import { CheckIcon, MailIcon, XIcon } from '@heroicons/react/outline'
import { RadioGroup } from '@headlessui/react'
import { classNames } from '../../../../util/helpers.js'
import { HiPhone } from 'react-icons/hi2'
import { FaWhatsapp } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import { sendRegistrationReminder } from '../../../../models/organization.jsx'

const contactMethodOptions = [
  { icon: <HiOutlineMail className='w-5 h-5 mr-2' />, method: 'Email', id: 'email' },
  { icon: <HiPhone className='w-5 h-5 mr-2' />, method: 'Phone', id: 'phone' },
  // { icon: <FaWhatsapp className='w-5 h-5 mr-2' />, method: 'WhatsApp', id: 'whatsapp' },
]

export default function BulkSendInvitationModal(props) {
  const [{ organization, user }, dispatch] = useContext(StateContext)
  const { open, setOpen, selectedWorkers, job } = props
  const [statusTracker, setStatusTracker] = useState({})
  const [count, setCount] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [contactMethod, setcontactMethod] = useState(contactMethodOptions[0])


  const closeAndResetModal = async open => {
    setStatusTracker({})
    setCount(0)
    setOpen(open)
  }

  const handleSendInvitations = async () => {
    try {
      setIsLoading(true)
      for (let i = 0; i < selectedWorkers.length; i++) {
        const worker = selectedWorkers[i]

        let sendResult = await sendRegistrationReminder(organization?.id, job?.id, worker?.id, {
          invitationMethod: contactMethod?.id,
          customMessage: null
        })

        // let invitation = await createInvitationv2(null, {
        //   firstName: worker?.fields?.firstName,
        //   lastName: worker?.fields?.lastName,
        //   // passportNumber: row[fieldToColumns?.passportNumber],
        //   // dateOfBirth: moment(moment.utc(row[fieldToColumns?.dateOfBirth])).format('YYYY-MM-DD'),
        //   // gender: row[fieldToColumns?.gender],
        //   // visaType: visaType,
        //   phoneNumber: worker?.fields?.phoneNumber,
        //   email: worker?.fields?.email,
        //   recipientFacilitatorId: organization.id,
        //   // recipientEmployerId: employerId,
        //   recipientUserRole: 'EMPLOYEE_EMPLOYEE', // required
        //   recipientUserGroup: 'EMPLOYEE', // required
        //   senderId: user.id,
        // })
        // let sendResult = await sendInvitationSms(null, invitation.id, contactMethod?.id)


        setStatusTracker(prevTracker => {
          return {
            ...prevTracker,
            [worker.id]: {
              messageSent: sendResult?.success
            },
          }
        })
        setCount(c => {
          return c + 1
        })
      }
      setIsLoading(false)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Successfully invited ${selectedWorkers.length} workers.`, waitingOnAPIResult: false, success: true },
        showNotification: true,
      })
    } catch (error) {
      console.log(error)
      setIsLoading(false)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to send an invite. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  return (
    <Modal title={'Send Invitations'} subtitle='Click send to invite the following workers to Ruta.' buttonLabel='Send' open={open} loading={isLoading} setOpen={closeAndResetModal} actionOnClick={handleSendInvitations}>
      <Message className="mt-2 text-sm">Note that workers can log in to Ruta using Email, Phone, or WhatsApp, regardless of how you send the invitation below.</Message>

      <h3 className='text-sm font-medium leading-6 pt-3'>Send invitation with</h3>

      <RadioGroup value={contactMethod} onChange={setcontactMethod} defaultValue={contactMethodOptions[0]}>
        <div className='relative -space-y-px rounded-md bg-white mt-2'>
          {contactMethodOptions.map((plan, planIdx) => (
            <RadioGroup.Option
              key={plan.id}
              value={plan}
              className={({ checked }) =>
                classNames(
                  planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                  planIdx === contactMethodOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                  checked ? 'bg-primary-50 border-primary-200 z-10' : 'border-gray-200',
                  'relative border p-4 flex flex-col cursor-pointer focus:outline-none'
                )
              }
            >
              {({ active, checked }) => (
                <>
                  <span className='flex items-center text-sm'>
                    <span
                      className={classNames(checked ? 'bg-primary-400 border-transparent' : 'bg-white border-gray-300', active ? 'ring-2 ring-offset-2 ring-primary-300' : '', 'h-4 w-4 rounded-full border flex items-center justify-center')}
                      aria-hidden='true'
                    >
                      <span className='rounded-full bg-white w-1.5 h-1.5' />
                    </span>
                    <RadioGroup.Label as='span' className={classNames(checked ? 'text-primary-800' : 'text-gray-900', 'ml-3 font-medium flex ')}>
                      {plan.icon} {plan.method}
                    </RadioGroup.Label>
                  </span>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
      <h3 className='text-sm font-medium leading-6 pt-3'>
        Workers{' '}
        <span className='text-gray-600'>
          {count} of {selectedWorkers.length}
        </span>
      </h3>
      <ul className='list-disc ml-4'>
        {selectedWorkers.map(worker => {
          return (
            <li className='mt-1 text-sm text-gray-800 flex'>
              {worker?.fields?.displayName}
              {Object.keys(statusTracker).includes(worker?.id) && (
                <>
                  {statusTracker[worker?.id].messageSent ? <CheckIcon className='w-5 h-5 ml-2 text-green-500' /> : <XIcon className='w-5 h-5 text-red-400' />}
                </>
              )}
            </li>
          )
        })}
      </ul>
    </Modal>
  )
}
