import React from 'react';
import { SingleDatePicker, DateRangePicker } from 'react-dates';
import { withTranslation } from "react-i18next";

import { countryOptions, stateOptions, visaOptions } from '../../../../util/constants.js'

class InvitationForm extends React.Component {
    state = {
        FORM_dateOfBirth_focused: false,
        FORM_passportIssueDate_focused: false,
        FORM_passportExpirationDate_focused: false,
        FORM_tripDatesIsFocused: null,
    }

    render() {
        const { t } = this.props // translations
        return (
            <form className="space-y-8 divide-y divide-gray-200" onSubmit={this.props.onSubmit}>
                <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                        <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">

                            <div className="sm:col-span-6">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                        <span className="px-2 text-sm text-gray-500 bg-white">Personal Information</span>
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_firstName" className="block text-sm font-medium text-gray-700">
                                    First name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_firstName"
                                        id="FORM_firstName"
                                        autoComplete="given-name"
                                        value={this.props.FORM_firstName || ''}
                                        onChange={this.props.handleChange}
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_lastName" className="block text-sm font-medium text-gray-700">
                                    Last name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_lastName"
                                        id="FORM_lastName"
                                        autoComplete="family-name"
                                        value={this.props.FORM_lastName || ''}
                                        onChange={this.props.handleChange}
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_passportNumber" className="block text-sm font-medium text-gray-700">
                                    Passport Number
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_passportNumber"
                                        id="FORM_passportNumber"
                                        autoComplete="family-name"
                                        value={this.props.FORM_passportNumber || ''}
                                        onChange={this.props.handleChange}
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="FORM_dateOfBirth" className="block text-sm font-medium text-gray-700">
                                    Date of Birth
                                </label>
                                <div className="mt-1">
                                    <SingleDatePicker
                                        id='FORM_dateOfBirth'
                                        orientation="vertical"
                                        date={this.props.FORM_dateOfBirth || null}
                                        numberOfMonths={2}
                                        onDateChange={date => this.props.handleDatePickerChange('FORM_dateOfBirth', date)}
                                        isOutsideRange={day => { return false }}
                                        focused={this.state.FORM_dateOfBirth_focused}
                                        onFocusChange={({ focused }) => this.setState({ FORM_dateOfBirth_focused: focused })}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_gender" className="block text-sm font-medium text-gray-700">
                                    Gender
                                </label>
                                <select
                                    id="FORM_gender"
                                    name="FORM_gender"
                                    value={this.props.FORM_gender}
                                    onChange={this.props.handleChange}
                                    className="block w-full py-2 pl-3 pr-10 mt-1 text-base border-gray-300 rounded-md focus:outline-none focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                >
                                    {[{ key: 'M', text: 'Male' }, { key: 'F', text: 'Female' }].map(gender => (
                                        <option key={gender?.key} value={gender?.key}>{gender?.text}</option>
                                    ))}
                                </select>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                    Visa Type
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="FORM_visaType"
                                        name="FORM_visaType"
                                        value={this.props.FORM_visaType}
                                        onChange={this.props.handleChange}
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    >
                                        {visaOptions.map(option => (
                                            <option key={option?.key} value={option?.value}>{option?.text}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_phoneNumber" className="block text-sm font-medium text-gray-700">
                                    Phone Number
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="FORM_phoneNumber"
                                        name="FORM_phoneNumber"
                                        value={this.props.FORM_phoneNumber}
                                        onChange={this.props.handleChange}
                                        type="tel"
                                        autoComplete="tel"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="FORM_email"
                                        name="FORM_email"
                                        value={this.props.FORM_email}
                                        onChange={this.props.handleChange}
                                        type="email"
                                        autoComplete="email"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                        <span className="px-2 text-sm text-gray-500 bg-white">Passport Address</span>
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_passportIssueDate" className="block text-sm font-medium text-gray-700">
                                    Passport Issue Date
                                </label>
                                <div className="mt-1">
                                    <SingleDatePicker
                                        id='FORM_passportIssueDate'
                                        orientation="vertical"
                                        date={this.props.FORM_passportIssueDate}
                                        numberOfMonths={2}
                                        onDateChange={date => this.props.handleDatePickerChange('FORM_passportIssueDate', date)}
                                        isOutsideRange={day => { return false }}
                                        focused={this.state.FORM_passportIssueDate_focused}
                                        onFocusChange={({ focused }) => this.setState({ FORM_passportIssueDate_focused: focused })}
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_passportExpirationDate" className="block text-sm font-medium text-gray-700">
                                    Passport Expiration Date
                                </label>
                                <div className="mt-1">
                                    <SingleDatePicker
                                        id='FORM_passportExpirationDate'
                                        orientation="vertical"
                                        date={this.props.FORM_passportExpirationDate}
                                        numberOfMonths={2}
                                        anchorDirection="right"
                                        onDateChange={date => this.props.handleDatePickerChange('FORM_passportExpirationDate', date)}
                                        isOutsideRange={day => { return false }}
                                        focused={this.state.FORM_passportExpirationDate_focused}
                                        onFocusChange={({ focused }) => this.setState({ FORM_passportExpirationDate_focused: focused })}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_country" className="block text-sm font-medium text-gray-700">
                                    Country
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="FORM_country"
                                        name="FORM_country"
                                        value={this.props.FORM_country}
                                        onChange={this.props.handleChange}
                                        autoComplete="country-name"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    >
                                        {countryOptions.map(option => (
                                            <option key={option?.key} value={option?.value}>{option?.text}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_stateProvinceRegion" className="block text-sm font-medium text-gray-700">
                                    State / Province
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="FORM_stateProvinceRegion"
                                        name="FORM_stateProvinceRegion"
                                        value={this.props.FORM_stateProvinceRegion}
                                        onChange={this.props.handleChange}
                                        autoComplete="address-level1"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    >
                                        {stateOptions[this.props.FORM_country ? this.props.FORM_country : 'All'].map(option => (
                                            <option key={option?.key} value={option?.value}>{option?.text}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="FORM_address1" className="block text-sm font-medium text-gray-700">
                                    Street address
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_address1"
                                        id="FORM_address1"
                                        value={this.props.FORM_address1}
                                        onChange={this.props.handleChange}
                                        autoComplete="street-address"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_city" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_city"
                                        id="FORM_city"
                                        value={this.props.FORM_city}
                                        onChange={this.props.handleChange}
                                        autoComplete="address-level2"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_postalCode" className="block text-sm font-medium text-gray-700">
                                    Postal code
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_postalCode"
                                        id="FORM_postalCode"
                                        value={this.props.FORM_postalCode}
                                        onChange={this.props.handleChange}
                                        autoComplete="postal-code"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                        <span className="px-2 text-sm text-gray-500 bg-white">Trip Info</span>
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="FORM_tripDates" className="block text-sm font-medium text-gray-700">
                                    Trip Dates
                                </label>
                                <div className="mt-1">
                                <DateRangePicker
                                    startDate={this.props.FORM_departureDate} // momentPropTypes.momentObj or null,
                                    startDateId="FORM_departureDate" // PropTypes.string.isRequired,
                                    endDate={this.props.FORM_arrivalDate} // momentPropTypes.momentObj or null,
                                    endDateId="FORM_arrivalDate" // PropTypes.string.isRequired,
                                    isOutsideRange={day => { return false }}
                                    numberOfMonths={2}
                                    onDatesChange={({ startDate, endDate }) => this.props.handleDateRangePickerChange('FORM_departureDate', startDate, 'FORM_arrivalDate', endDate)} // PropTypes.func.isRequired,
                                    focusedInput={this.state.FORM_tripDatesIsFocused} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                    onFocusChange={focusedInput => this.setState({ FORM_tripDatesIsFocused: focusedInput })} // PropTypes.func.isRequired,
                                />

                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="FORM_originCity" className="block text-sm font-medium text-gray-700">
                                    Origin City
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        id="FORM_originCity"
                                        name="FORM_originCity"
                                        value={this.props.FORM_originCity}
                                        onChange={this.props.handleChange}
                                        autoComplete="postal-code"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_originStateProvinceRegion" className="block text-sm font-medium text-gray-700">
                                    Origin Country
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="FORM_originCountry"
                                        name="FORM_originCountry"
                                        value={this.props.FORM_originCountry}
                                        onChange={this.props.handleChange}
                                        autoComplete="country-name"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    >
                                        {countryOptions.map(option => (
                                            <option key={option?.key} value={option?.value}>{option?.text}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_originCountry" className="block text-sm font-medium text-gray-700">
                                    Origin State / Province
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="FORM_originStateProvinceRegion"
                                        name="FORM_originStateProvinceRegion"
                                        value={this.props.FORM_originStateProvinceRegion}
                                        onChange={this.props.handleChange}
                                        autoComplete="address-level1"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    >
                                        {stateOptions[this.props.FORM_originCountry ? this.props.FORM_originCountry : 'All'].map(option => (
                                            <option key={option?.key} value={option?.value}>{option?.text}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="FORM_originCity" className="block text-sm font-medium text-gray-700">
                                    Destination City
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        id="FORM_odestinationnCity"
                                        name="FORM_destinationCity"
                                        value={this.props.FORM_destinationCity}
                                        onChange={this.props.handleChange}
                                        autoComplete="postal-code"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_destinationStateProvinceRegion" className="block text-sm font-medium text-gray-700">
                                    Destination Country
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="FORM_destinationCountry"
                                        name="FORM_destinationCountry"
                                        value={this.props.FORM_destinationCountry}
                                        onChange={this.props.handleChange}
                                        autoComplete="country-name"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    >
                                        {countryOptions.map(option => (
                                            <option key={option?.key} value={option?.value}>{option?.text}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_destinationCountry" className="block text-sm font-medium text-gray-700">
                                    Destination State / Province
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="FORM_destinationStateProvinceRegion"
                                        name="FORM_destinationStateProvinceRegion"
                                        value={this.props.FORM_destinationStateProvinceRegion}
                                        onChange={this.props.handleChange}
                                        autoComplete="address-level1"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                    >
                                        {stateOptions['All'].find(option => option?.value === this.props.FORM_destinationCountry) ? null : (<option key={0}>Not Selected</option>)}
                                        {stateOptions[Object.keys(stateOptions).includes(this.props.FORM_destinationCountry) ? this.props.FORM_destinationCountry : 'All'].map(option => (
                                            <option key={option?.key} value={option?.value}>{option?.text}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <button className={'hidden'} type="submit" />
            </form>
        );
    }
}

export default withTranslation()(InvitationForm)