import axios from 'axios'
import { APIError, handleAxiosAPIError } from '../util/errors.js'

export const retrieveInvitation = async (invitationId) => {
  try {
    let result = await axios.get(`${process.env.REACT_APP_API_HOST}/invitations/${invitationId}`)
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const createInvitation = async (firebaseUser, invitationData, sendTextInvite) => {
  try {
    const url = sendTextInvite ? `${process.env.REACT_APP_API_HOST}/invitations?sendTextInvite=true` : `${process.env.REACT_APP_API_HOST}/invitations`
    let result = await axios({
      method: 'post',
      url: url,
      data: invitationData
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const createInvitationv2 = async (firebaseUser, invitationData) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/invitations/v2`,
      data: invitationData
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const patchInvitation = async (firebaseUser, invitationId, invitationData) => {
  try {
    const url = `${process.env.REACT_APP_API_HOST}/invitations/${invitationId}`
    let result = await axios({
      method: 'put',
      url: url,
      data: invitationData
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const deleteInvitation = async (firebaseUser, invitationId) => {
  try {
    let result = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_HOST}/invitations/${invitationId}`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const sendInvitationSms = async (firebaseUser, invitationId, method) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/invitations/${invitationId}/send?invitationMethod=${method}`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}
