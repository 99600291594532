import React from 'react'
import { StateContext } from '../../util/state.jsx'
import { withTranslation } from 'react-i18next'
import qs from 'qs'
import { authenticate, loginOrCreateStytchUser } from '../../models/user'
import FullPageLoader from '../../components/FullPageLoader'
import { Redirect } from 'react-router'
import LogoSquare from '../../img/logo_wide.svg'
import StytchForm from './stytchForm.jsx'
import { Message } from '../../components/Tailwind'
import { retrieveInvitation } from '../../models/invitation.jsx'

class LoginPage extends React.Component {
  static contextType = StateContext
  state = {
    FORM_email: '',
    FORM_checkEmail: false,
    FORM_phoneNumber: null,
    FORM_checkPhone: false,
    FORM_OTP: '',
    isLoadingInvitation: true,
    signInMethod: 'whatsapp',
    authenticationResult: undefined, // this will store the phone one time code phone id
    apiErrorMessage: null,
    loadingAuth: false,
  }
  handleChange = async event => this.setState({ [event.target.name]: event.target.value })

  componentDidMount = () => {
    // Get invite
    const { i, token } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })
    if (token) {
      this.handleAuthenticate(token)
    }
    if (i) {
      this.retrieveInvite(i)
    } else {
      this.setState({ isLoadingInvitation: false })
    }
  }

  retrieveInvite = async invitationId => {
    this.setState({ isLoadingInvitation: true })
    try {
      let invitation = await retrieveInvitation(invitationId)
      this.setState({
        invitation: invitation,
        isLoadingInvitation: false,
      })
    } catch (error) {
      if (error.code === 404) {
        this.setState({ invitation: null, isLoadingInvitation: false, preventSignUp: true, apiErrorMessage: 'Your invitation is not working. Contact your facilitator. ' })
      } else {
        this.setState({ invitation: null, isLoadingInvitation: false, preventSignUp: true, apiErrorMessage: `Something went wrong when trying to load the app. ${error.message}` })
      }
    }
  }

  handleAuthenticate = async token => {
    const [{}, dispatch] = this.context
    this.setState({ loadingAuth: true, apiErrorMessage: null })
    try {
      const authenticationResult = await authenticate({
        methodId: this.state.signInMethod === 'email' ? this.state?.authenticationResult?.email_id : this.state?.authenticationResult?.phone_id,
        token: token,
      })

      // Now save token
      console.log(authenticationResult)
      dispatch({
        type: 'showNotification',
        notificationDetails: null,
        showNotification: false,
      })
      dispatch({
        type: 'setTokens',
        sessionToken: authenticationResult?.authenticationResult?.session_token,
        sessionJwt: authenticationResult?.authenticationResult?.session_jwt,
      })
      // Save to local storage
      localStorage.setItem('rutaSessionToken', authenticationResult?.authenticationResult?.session_token)
      localStorage.setItem('rutaSessionJwt', authenticationResult?.authenticationResult?.session_jwt)
    } catch (error) {
      console.log(error)
      this.setState({ apiErrorMessage: error.message, loadingAuth: false })
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to login. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  handleStytchLoginRequest = async e => {
    e.preventDefault()
    const [{}, dispatch] = this.context

    this.setState({ loadingAuth: true, apiErrorMessage: null })
    try {
      let authenticationResult = undefined
      if (this.state.signInMethod === 'email') {
        authenticationResult = await loginOrCreateStytchUser({
          email: this.state.FORM_email.toLowerCase().trim(),
          invitationId: this.state?.invitation ? this.state?.invitation.id : null,
          signInMethod: this.state.signInMethod
        })
        this.setState({ FORM_checkEmail: true, authenticationResult: authenticationResult, loadingAuth: false })
      } else if (this.state.signInMethod === 'phone' || this.state.signInMethod === 'whatsapp') {
        authenticationResult = await loginOrCreateStytchUser({
          phoneNumber: this.state.FORM_phoneNumber,
          invitationId: this.state?.invitation ? this.state?.invitation.id : null,
          signInMethod: this.state.signInMethod
        })
        this.setState({ FORM_checkPhone: true, authenticationResult: authenticationResult, loadingAuth: false })
      } 
    } catch (error) {
      console.log(error)
      this.setState({ apiErrorMessage: error.message, loadingAuth: false })
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to login. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  render() {
    const { t } = this.props // translations
    const [{ user, showNotification, notificationDetails }, dispatch] = this.context
    const { redirect } = qs.parse(this.props.location.search, { ignoreQueryPrefix: true })

    if (typeof user === 'undefined') {
      return <FullPageLoader />
    }

    if (user) {
      if (user.fields.group === 'EMPLOYEE') {
        if (redirect) {
          return <Redirect to={redirect} />
        }
        return <Redirect to={{ pathname: '/account', query: this.props.location.query, search: this.props.location.search }} />
      } else if (user.fields.group === 'FACILITATOR') {
        if (redirect && redirect.startsWith('/facilitator')) {
          return <Redirect to={redirect} />
        }
        return <Redirect to={{ pathname: '/facilitator' }} />
      } else if (user.fields.group === 'EMPLOYER' || user.fields.group === 'PARTNER') {
        if (redirect && redirect.startsWith('/employer')) {
          return <Redirect to={redirect} />
        }
        return <Redirect to={{ pathname: '/employer', query: this.props.location.query, search: this.props.location.search }} />
      }
    }

    return (
      <>
      {/* TODO: add a info alert */}
        <div className='flex flex-col justify-center min-h-full px-4 py-12 sm:px-6 lg:px-8 bg-light'>
          { typeof(user) === 'undefined' && <FullPageLoader text='Logging in...' />}
          <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-md'>
            <div className='px-4 py-8 bg-white shadow sm:rounded-lg sm:px-10'>
              {!(this.state.FORM_checkEmail || this.state.FORM_checkPhone) && (
                this.state.signInMethod === 'email' ? 
                <>
                  <img className='w-auto h-10' src={LogoSquare} alt='Workflow' />
                  <h2 className='mt-6 text-3xl font-extrabold text-gray-900'>{t('Sign in to Ruta')}</h2>
                  <p className='mt-2 mb-1 text-sm text-gray-600'>
                  {t('Please enter your email address.')}
                  </p>
                  <p className='mt-2 mb-6 text-sm text-gray-600'>
                    {t('Or choose to use')}
                    <a onClick={() => this.setState({ signInMethod: 'phone' })} className='font-bold text-blue-500 cursor-pointer'> {t('Phone')} </a>
                    {t('or')}<a onClick={() => this.setState({ signInMethod: 'whatsapp' })} className='font-bold text-blue-500 cursor-pointer'> {t('WhatsApp')}</a>.
                  </p>
                </>
                : this.state.signInMethod === 'phone' ?
                <>
                  <img className='w-auto h-10' src={LogoSquare} alt='Workflow' />
                  <h2 className='mt-6 text-3xl font-extrabold text-gray-900'>{t('Sign in to Ruta')}</h2>
                  <p className='mt-2 mb-1 text-sm text-gray-600'>
                  {t('Please confirm your country code and enter your phone number.')}
                  </p>
                  <p className='mt-2 mb-6 text-sm text-gray-600'>
                    {t('Or choose to use')}
                    <a onClick={() => this.setState({ signInMethod: 'email' })} className='font-bold text-blue-500 cursor-pointer'> {t('Email')} </a>
                    {t('or')}<a onClick={() => this.setState({ signInMethod: 'whatsapp' })} className='font-bold text-blue-500 cursor-pointer'> {t('WhatsApp')}</a>.
                  </p>
                </>
                : // WhatsApp
                <>
                  <img className='w-auto h-10' src={LogoSquare} alt='Workflow' />
                  <h2 className='mt-6 text-3xl font-extrabold text-gray-900'>{t('Sign in to Ruta')}</h2>
                  <p className='mt-2 mb-1 text-sm text-gray-600'>
                  {t('Please confirm your country code and enter your WhatsApp phone number.')}
                  </p>
                  <p className='mt-2 mb-6 text-sm text-gray-600'>
                    {t('Or choose to use')}
                    <a onClick={() => this.setState({ signInMethod: 'email' })} className='font-bold text-blue-500 cursor-pointer'> {t('Email')} </a>
                    {t('or')}<a onClick={() => this.setState({ signInMethod: 'phone' })} className='font-bold text-blue-500 cursor-pointer'> {t('Phone')}</a>.
                  </p>
                </>
              )}
              {this.state.apiErrorMessage && <Message color='red' className='my-6' >{this.state.apiErrorMessage}</Message>}

              <StytchForm
                FORM_email={this.state.FORM_email}
                FORM_checkEmail={this.state.FORM_checkEmail}
                FORM_phoneNumber={this.state.FORM_phoneNumber}
                FORM_checkPhone={this.state.FORM_checkPhone}
                FORM_OTP={this.state.FORM_OTP}
                signInMethod={this.state.signInMethod}
                invitation={this.state.invitation}
                handleChange={this.handleChange}
                handleStytchLoginRequest={this.handleStytchLoginRequest}
                handleSubmitConfirmationCode={this.handleAuthenticate}
                loadingAuth={this.state.loadingAuth}
              />
              {(this.state.FORM_checkPhone || this.state.FORM_checkEmail) && <p className="mt-4 text-xs text-gray-600">Did not receive your code? <a className='text-blue-500 cursor-pointer' onClick={() => this.setState({ FORM_checkEmail: false, FORM_checkPhone: false, authenticationResult: null, loadingAuth: false, FORM_OTP: '' })}> Try again.</a></p>}
              <p className="mt-4 text-xs text-gray-600">
                {t("By clicking 'continue' you agree to our ")}<a className='text-blue-500 cursor-pointer' onClick={() => this.props.history.push('/terms')}>{t('Terms of Service')}</a>{t(' and ')}<a className='text-blue-500 cursor-pointer' onClick={() => this.props.history.push('/privacy')}>{t('Privacy Policy.')}</a>
              </p>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default withTranslation()(LoginPage)
