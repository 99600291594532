import React, { Component } from 'react'
import { Modal } from '../../../../components/Tailwind'

export default class AvatarWithViewModal extends Component {
    state = {
        viewPhotoModalIsOpen: false,
    }

    toggleViewPhotoModal = (open) => this.setState({ viewPhotoModalIsOpen: open })

    render() {
        let {height, width} = this.props
        height = height ? height : 16
        width = width ? width : 16
        return (
            <>
                {!this.props.worker.fields?.photo ?
                    <span className={`inline-block w-${width || 16} h-${height || 16} ${this.props.className} overflow-hidden bg-gray-100 rounded-full`}>
                        <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                    </span>
                    :
                    <>
                        <img
                            className={`w-${width} h-${height} ${this.props.className} rounded-full`}
                            src={this.props.worker.fields?.photo?.url}
                            alt=""

                        />
                        <span onClick={() => this.toggleViewPhotoModal(true)} className="absolute inset-0 rounded-full shadow-inner cursor-pointer" aria-hidden="true" />
                    </>
                }
                <Modal
                    title={'View Photo'}
                    buttonLabel='Done'
                    open={this.state.viewPhotoModalIsOpen}
                    setOpen={this.toggleViewPhotoModal}
                    actionOnClick={null}
                >
                    <div className="relative w-full mt-5 overflow-hidden bg-white rounded-lg h-96">
                        <img
                            src={this.props.worker.fields?.photo?.url}
                            className="object-contain object-center w-full h-full"
                        />
                    </div>
                </Modal>
            </>
        )
    }
}
