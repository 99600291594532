import { useContext } from 'react'
import { useState } from 'react'
import { HiChatBubbleLeftEllipsis, HiOutlinePaperClip, HiOutlinePencilSquare, HiOutlineTrash, HiTag, HiUserCircle } from 'react-icons/hi2'
import AvatarImage from '../../../../../components/AvatarImage'
import { Badge, Button, ConfirmModal } from '../../../../../components/Tailwind'
import { addNoteForJob, deleteNoteForJob } from '../../../../../models/organization'
import { StateContext } from '../../../../../util/state'
import moment from 'moment'
import { AiOutlineMacCommand } from 'react-icons/ai'
import { useHotkeys } from 'react-hotkeys-hook'

const NotesSection = props => {
  const [{ organization }, dispatch] = useContext(StateContext)
  const { worker, pullLatestAfterUpdate } = props
  const [newNote, setnewNote] = useState('')
  const [isLoading, setisLoading] = useState(false)
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState({ open: false, noteId: null })
  const handleChange = async e => setnewNote(e.target.value)

  const addNote = async e => {
    if (newNote === '') return

    try {
      setisLoading(true)
      await addNoteForJob(organization.id, worker?.jobDetails?.jobId, worker?.id, {
        content: newNote,
      })
      await pullLatestAfterUpdate()
      setisLoading(false)
      setnewNote('')
    } catch (error) {
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to add note. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      setisLoading(false)
    }
  }
  const handleDeleteNote = async id => {
    try {
      setisLoading(true)
      await deleteNoteForJob(organization.id, worker?.jobDetails?.jobId, worker?.id, id)
      await pullLatestAfterUpdate()
      setisLoading(false)
      setConfirmModalIsOpen({ open: false, noteId: null })
    } catch (error) {
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to delete note. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      setisLoading(false)
    }
  }
  useHotkeys('meta+enter', addNote, { enableOnFormTags: ['input', 'select', 'textarea'] }, [newNote])

  return (
    <section aria-labelledby='applicant-information-title'>
      <div className=''>
        <div className='flex flex-wrap items-center justify-between px-4 py-4 sm:px-6 sm:flex-nowrap'>
          <div className='mt-2'>
            <h2 className='text-lg font-medium leading-6 text-gray-900'>Private Notes</h2>
            <p className='text-gray-500 text-sm'>Not shared with worker. These are private to your team.</p>
          </div>
          <div className='flex-shrink-0 ml-4'>
            {/* <button
              type='button'
              onClick={() => this.toggleModal(true)}
              className={`inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500`}
            >
              Add Note
            </button> */}
            {/* <UserProfileModal 
                    open={this.state.editProfileModalIsOpen}
                    setOpen={this.toggleModal}
                    profile={worker}
                    pullLatestAfterUpdate={this.props.pullLatestAfterUpdate}
                /> */}
          </div>
        </div>

        <div className='px-4 py-5 border-t border-gray-200 sm:px-6'>
          <form action='#' className='relative mb-6' onSubmit={addNote}>
            <div className='overflow-hidden rounded-lg border border-gray-300 shadow-sm focus-within:border-primary-300 focus-within:ring-1 focus-within:ring-primary-300'>
              <label htmlFor='description' className='sr-only'>
                Description
              </label>
              <textarea
                rows={2}
                value={newNote}
                onChange={handleChange}
                name='description'
                id='description'
                className='block w-full resize-none border-0 pb-0 pt-4 placeholder-gray-500 focus:ring-0 sm:text-sm'
                placeholder='Write a note...'
              />

              {/* Spacer element to match the height of the toolbar */}
              <div aria-hidden='true'>
                <div className='py-2'>
                  <div className='h-2' />
                </div>
                <div className='h-px' />
                <div className='py-2'>
                  <div className='py-px'>
                    <div className='h-9' />
                  </div>
                </div>
              </div>
            </div>

            <div className='absolute inset-x-px bottom-0'>
              {/* Actions: These are just examples to demonstrate the concept, replace/wire these up however makes sense for your project. */}

              <div className='flex items-center justify-between space-x-3 border-t border-gray-200 px-2 py-2 sm:px-3'>
                <div className='flex'>
                  <button type='button' className='group -my-2 -ml-2 inline-flex items-center rounded-full px-3 py-2 text-left text-gray-400'>
                    {/* <HiOutlinePaperClip className='-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500' aria-hidden='true' /> */}
                    {/* <span className='text-sm italic text-gray-500 group-hover:text-gray-600'>Attach a file</span> */}
                  </button>
                </div>
                <div className='flex-shrink-0 flex align-middle'>
                  {/* <a type='a' className='group -ml-2 inline-flex items-center rounded-full px-3 text-left text-gray-400'>
                    <AiOutlineMacCommand className='-ml-1 mr-2 h-5 w-5 group-hover:text-gray-500' aria-hidden='true' />
                    <span className='text-sm text-gray-500 group-hover:text-gray-600'>Enter</span>
                  </a> */}

                  <Button loading={isLoading} onClick={addNote}>
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </form>

          <div className='flow-root'>
            <ul role='list' className='-mb-8'>
              {worker?.jobDetails.notes.map((note, noteIdx) => (
                <li key={note.id}>
                  <div className='relative pb-8'>
                    {noteIdx !== worker?.jobDetails.notes.length - 1 ? <span className='absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200' aria-hidden='true' /> : null}
                    <div className='relative flex items-start space-x-3'>
                      <>
                        <div className='relative'>
                          {note?.author?.photo ? <img src={note?.author?.photo} className='flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white' alt='' /> : <AvatarImage size={10} />}
                        </div>
                        <div className='min-w-0 flex-1'>
                          <div>
                            <div className='text-sm flex'>
                              <a className='font-medium text-gray-900'>
                                {note.author?.firstName} {note.author?.lastName}
                              </a>
                              <a className='font-medium text-gray-500 ml-2 cursor-pointer' onClick={() => setConfirmModalIsOpen({ open: true, noteId: note.id })}>
                                <HiOutlineTrash className='h-5 w-5' />
                              </a>
                            </div>
                            <p className='mt-0.5 text-sm text-gray-500'>Added {moment(note.createdAt).format('LLL')}</p>
                          </div>
                          <div className='mt-2 text-sm text-gray-700'>
                            <p>{note.content}</p>
                          </div>
                        </div>
                      </>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <ConfirmModal
        title={'Delete Note?'}
        content={'This will permanently delete this note. '}
        buttonLabel={'Delete'}
        open={confirmModalIsOpen?.open}
        setOpen={() => setConfirmModalIsOpen({ open: false, noteId: null })}
        actionOnClick={() => handleDeleteNote(confirmModalIsOpen?.noteId)}
      />
    </section>
  )
}

export default NotesSection
