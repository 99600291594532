import React from 'react';
import ReactDOM from 'react-dom';
import App from './App.jsx';
import reportWebVitals from './reportWebVitals';

import './index.css';
import './css/fonts/Inter.ttf'
import './css/custom.css';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'handsontable/dist/handsontable.full.css';
import './css/customHandsOnTable.css'
import 'react-image-crop/dist/ReactCrop.css';
import 'react-phone-number-input/style.css'
import 'react-day-picker/dist/style.css';

ReactDOM.render(<React.StrictMode><App /></React.StrictMode>, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
