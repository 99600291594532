import React from 'react';
import { withTranslation } from "react-i18next";
import { Message } from '../../../../components/Tailwind'

class ApproveForTravelForm extends React.Component {
    state = {
        focused: false,
    }
    render() {
        const { t } = this.props // translations
        const { approvingUser } = this.props
        return (
            <form className="space-y-8 divide-y divide-gray-200" onSubmit={this.props.onSubmit} >
                <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <Message
                                    color='gray'
                                    title={null}
                                    details='Only approve a worker who is ready to travel to the United States and has completed their requirements.'
                                />
                            </div>
                            <div className="sm:col-span-6">
                                
                            </div>
                        </div>

                    </div>
                </div>
                <button className={'hidden'} type="submit" />
            </form>
        );
    }
}

export default withTranslation()(ApproveForTravelForm)