import React, { useContext, useState } from 'react'
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom'
import { Button, ConfirmModal, Modal } from '../../../components/Tailwind'
import { wait } from '../../../util/helpers'
import { StateContext } from '../../../util/state'
import BubbleNav from './bubbleNav'
import Step1Overview from './step1Overview'
import Step2Verify from './step2Verify'
import Step3ConfirmInfo from './step3ConfirmInfo'
import Step4PreferredContact from './step4PreferredContact'
import Step5SetPhoto from './step5setPhoto'
import Step7ReviewAndConfirm from './step7ReviewAndConfirm'
import {compareAsc} from 'date-fns'
import { uploadFile } from '../../../components/Firebase'
import { patchUser, retrieveUser } from '../../../models/user'
import Step0JobPicker from './step0JobPicker'
import { updateWorkerStatusForJob } from '../../../models/organization'
import { HiPhone } from 'react-icons/hi2'
import { HiOutlineDocumentDownload, HiOutlineMail } from 'react-icons/hi'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { FaWhatsapp } from 'react-icons/fa'
import Step6SignContract from './step6SignContract'
import '@anvilco/react-signature-modal/dist/styles.css'
import Step8Confirmed from './step8Confirmed'
import DownloadContractModal from './downloadContractModal'

const contactMethodOptions = [
  { icon: <HiPhone className='w-5 h-5 mr-2' />, method: 'Phone', id: 'PHONE' },
  { icon: <HiOutlineMail className='w-5 h-5 mr-2' />, method: 'Email', id: 'EMAIL' },
  { icon: <FaWhatsapp className='w-5 h-5 mr-2' />, method: 'WhatsApp', id: 'WHATSAPP' },
]

const OnboardingPage = () => {
  const [{ user, showNotification, notificationDetails }, dispatch] = useContext(StateContext)
  const history = useHistory()
  const { t, i18n } = useTranslation();

  const [formValues, setFormValues] = useState({
    FORM_dateOfBirth: null,
    FORM_dateOfBirth_error: null, // not used right now
    FORM_preferredContactMethod: null,
    FORM_photo: null
  })
  const [isLoadingVerify, setIsLoadingVerify] = useState(false) 
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [confirmModalIsOpen, setConfirmModalIsOpen] = useState(false)

  const [downloadContractModalIsOpen, setDownloadContractModalIsOpen] = useState(false)

  useEffect(() => {
    setFormValues({ ...formValues, FORM_preferredContactMethod: contactMethodOptions.find(opt => opt.id === user?.fields?.preferredContactMethod) })
  }, [user]);

  const steps = [
    { id: '0', title: `${t('Hello,')} ${user?.fields?.firstName}!`, inSummary: false, shortDescription: '', description: `${t('Ana Associates, LLC is using Ruta to help your visa be faster and more secure.')}` },
    { id: '1', title: `${t('Overview')}`, inSummary: false, shortDescription: '', description: `${t('Follow the steps below to so that Ana Associates LLC can prepare your visa application.')}` },
    // {
    //   id: '2',
    //   title: 'Verify Your Identity',
    //   inSummary: true,
    //   shortDescription: 'To keep your information safe, Ruta will verify your identity with your date of birth.',
    //   description: "To keep your information safe, Ruta will verify your identity. Enter your date of birth. ",
    // },
    {
      id: '3',
      title: `${t('Confirm Your Information')}`,
      inSummary: true,
      shortDescription: `${t('Ana Associates LLC must have your latest information for your visa.')}`,
      description: `${t('This is the information Ana Associates, LLC has on record for your visa application. Is it correct?')}`,
    },
    {
      id: '4',
      title: `${t('Set Preferred Contact Method')}`,
      inSummary: true,
      shortDescription: `${t('Make sure Ana Associates knows how to contact you.')}`,
      description: `${t('Ana Associates, LLC will need to communicate with you. How would you like to be reached?')}`,
    },
    { id: '5', title: `${t('Upload Selfie')}`, inSummary: true, shortDescription: `${t('A picture of yourself.')}`, description: `${t('Take a picture of your face like a passport photo.')}` },
    { id: '6', title: `${t('Sign Contract')}`, inSummary: true, shortDescription: `${t('Your employment contract.')}`, description: `${t('Sign your employment contract for your current job.')}` },
    { id: '7', title: `${t('Review & Confirm')}`, inSummary: false, shortDescription: '', description: `${t('Please review everything below and submit when you are ready.')}` },
    { id: '8', title: `${t('Finished')}`, inSummary: false, shortDescription: '', description: `${t('Thank you for confirming your job details.')}` },
  ]

  const onboardingSteps = steps.map(s => s.id)
  const currentStepNumber = history.location.pathname.split('/')[3]
  const currentJobId = history.location.pathname.split('/')[4]
  
  if (!onboardingSteps.includes(currentStepNumber)) {
    return <Redirect to='/account/onboarding/0' />
  }

  const job = user?.fields?.jobs.find(j => j?.job?.id === currentJobId)
  if (currentJobId) {
    if (!job) return <Redirect to='/account/onboarding/0'/>
  }
  const isVerified = true

  const changeStep = newStep => {
    history.push(`/account/onboarding/${newStep}`)
  }

  const handleChange = async (e, target) => setFormValues({ ...formValues, [target.name]: target.value })
  const handleFileUploadChange = async event => {
    console.log(event)
    setFormValues({ ...formValues, [event.target.name]: event.target.value })
  }

  const handleVerification = async () => {
    setIsLoadingVerify(true)
    await wait(2000)
    if (compareAsc(new Date(user?.fields?.dateOfBirth), formValues?.FORM_dateOfBirth) === 0) {
      await updateWorkerStatusForJob(user?.fields?.facilitatorId, currentJobId, user?.id, {workerDidVerifyIdentity: true})
      const retrievedUser = await retrieveUser(null, user.id)
      dispatch({ type: 'setUser', user: retrievedUser })
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Verified!`, waitingOnAPIResult: false, success: true },
        showNotification: true
      })      
    } else {
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Not Verified!`, waitingOnAPIResult: false, success: false },
        showNotification: true
      })      
    }
    setIsLoadingVerify(false)
  }

  const handlePhotoUpload = async () => {
    let fileURL = undefined
    if (formValues?.FORM_photo && (formValues?.FORM_photo instanceof File || formValues?.FORM_photo instanceof Blob)) {
      try {
        fileURL = await uploadFile(user.id, formValues?.FORM_photo)
        console.log(fileURL)
        return fileURL
      } catch (error) {
        console.log(error)
        return null
      }
    }
    return null
  }  

  const handleOnboardingSubmit = async () => {
    // First, update the user
    setIsLoadingSubmit(true)
    try {
      let photoUrl = await handlePhotoUpload() // null if not set
      await patchUser(null, user.id, {'photo': photoUrl, 'preferredContactMethod': formValues?.FORM_preferredContactMethod?.id })
      await updateWorkerStatusForJob(user?.fields?.facilitatorId, currentJobId, user?.id, {workerDidConfirm: true})
      const retrievedUser = await retrieveUser(null, user.id)
      dispatch({ type: 'setUser', user: retrievedUser })
      setIsLoadingSubmit(false)
      setConfirmModalIsOpen(true)
    } catch(error) {
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to update user.`, waitingOnAPIResult: false, success: false },
        showNotification: true
      })
    }
  }

  return (
    <div className='w-full'>
      <div className='px-6 mx-auto max-w-sm pb-12'>
        <BubbleNav steps={steps} isVerified={isVerified} currentStepNumber={currentStepNumber} />
        <Switch>
          <Route exact path='/account/onboarding/0'>
            <Step0JobPicker changeStep={changeStep}/>
          </Route>
          <Route exact path='/account/onboarding/1/:jobId'>
            <Step1Overview steps={steps} />
            <Button fluid onClick={() => changeStep(`3/${currentJobId}`)}>
              {t('Next')}
            </Button>
          </Route>
          <Route exact path='/account/onboarding/3/:jobId'>
            <Step3ConfirmInfo steps={steps} />
            <Button fluid onClick={() => changeStep(`4/${currentJobId}`)}>
              {t('Next')}
            </Button>
          </Route>
          <Route exact path='/account/onboarding/4/:jobId'>
            <Step4PreferredContact formValues={formValues} handleChange={handleChange} contactMethodOptions={contactMethodOptions} />
            <Button fluid onClick={() => changeStep(`5/${currentJobId}`)}>
              {t('Next')}
            </Button>
          </Route>
          <Route exact path='/account/onboarding/5/:jobId'>
            <Button className='mb-6' fluid onClick={() => changeStep(`6/${currentJobId}`)}>
              {t('Next')}
            </Button>
            <Step5SetPhoto handleChange={handleFileUploadChange} />
          </Route>
          <Route exact path='/account/onboarding/6/:jobId'>
            <Step6SignContract changeStep={changeStep} />
          </Route>
          <Route exact path='/account/onboarding/7/:jobId'>
            <Step7ReviewAndConfirm formValues={formValues} />
            <Button color='light' className='my-4' fluid onClick={() => setDownloadContractModalIsOpen(true)}>
              {t('Save Contract')}
              <HiOutlineDocumentDownload className='ml-2 h-5 w-5' />
            </Button>
            <Button fluid loading={isLoadingSubmit} onClick={handleOnboardingSubmit}>
              {t('Submit')}
            </Button>
            <DownloadContractModal 
              open={downloadContractModalIsOpen}
              setOpen={setDownloadContractModalIsOpen}
              job={job}
            />
            <ConfirmModal
                title={t('Thank you!')}
                content={t('Thank you for verifying your identify, choosing a preferred contact method, and confirming your latest information. You are all done!')}
                buttonLabel={t('Continue')}
                success={true}
                open={confirmModalIsOpen}
                setOpen={setConfirmModalIsOpen}
                actionOnClick={() => changeStep(`8/${job?.job?.id}`)}
            />
          </Route>
          <Route exact path='/account/onboarding/8/:jobId'>
            <Step8Confirmed formValues={formValues} />
            <Button color='light' className='my-4' fluid onClick={() => setDownloadContractModalIsOpen(true)}>
              {t('Save Contract')}
              <HiOutlineDocumentDownload className='ml-2 h-5 w-5' />
            </Button>
            <DownloadContractModal 
              open={downloadContractModalIsOpen}
              setOpen={setDownloadContractModalIsOpen}
              job={job}
            />
            <Button fluid onClick={() => history.push('/account/onboarding/0')}>
              {t('Back')}
            </Button>
          </Route>
          <Route path="*">
            <Redirect to="/account/onboarding" />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default OnboardingPage
