import React, { useContext } from 'react'
import { StateContext } from '../../../util/state'
import { format, parseISO } from 'date-fns'
import { useTranslation } from 'react-i18next'

const Step3ConfirmInfo = props => {
  const [{ user }] = useContext(StateContext)
  const { t, i18n } = useTranslation()

  return (
    <div className='mb-6'>
      <div className='mb-6 space-y-6'>
        <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
          <div className='px-4 py-5 sm:px-6'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>{t('Personal Information')}</h3>
          </div>
          <div className='border-t border-gray-200 px-4 py-4 sm:p-0'>
            <dl className='sm:divide-y sm:divide-gray-200'>
              <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>{t('Full name')}</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.displayName}</dd>
              </div>
              {user?.fields?.passportNumber && (
                <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>{t('Passport #')}</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.passportNumber}</dd>
                </div>
              )}
              {/* <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                <dt className='text-sm font-medium text-gray-500'>{t('Date of Birth')}</dt>
                <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.dateOfBirth ? format(parseISO(user?.fields?.dateOfBirth), 'PP') : ''}</dd>
              </div> */}
              {user?.fields?.country && (
                <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>{t('Address')}</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.country ? user?.fields?.displayAddress : '--'}</dd>
                </div>
              )}
              {user?.fields?.visaType && (
                <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>{t('Visa Type')}</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.visaType}</dd>
                </div>
              )}
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step3ConfirmInfo
