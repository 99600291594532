import React, { Component, useContext, useEffect } from 'react';
import { StateContext } from '../../../util/state.jsx';
import { withTranslation } from "react-i18next";
import { Modal } from '../../../components/Tailwind'
import { withRouter } from 'react-router';

import { patchTrip } from '../../../models/trip'

import TripForm from '../forms/TripForm/index.jsx';

import moment from 'moment'

class TripModal extends React.Component{
  static contextType = StateContext;
  static defaultProps = {}

  state = {
    waitingOnAPIResult: false,
    FORM_departureDate: null,
    FORM_arrivalDate: null,
    FORM_originCity: '',
    FORM_originStateProvinceRegion: '',
    FORM_originCountry: '',
    FORM_destinationCity: '',
    FORM_destinationStateProvinceRegion: '',
    FORM_destinationCountry: ''    
  }

  handleChange = async (event) => this.setState({[event.target.name]: event.target.value})
  handleDatePickerChange = async (startDate, endDate) => this.setState({FORM_departureDate: startDate, FORM_arrivalDate: endDate})

  componentDidMount = () => {
    this.setState({
      FORM_departureDate: this.props.trip.fields.departureDate ? moment.utc(this.props.trip.fields.departureDate) : null, 
      FORM_arrivalDate: this.props.trip.fields.arrivalDate ? moment.utc(this.props.trip.fields.arrivalDate) : null,
      FORM_originCity: this.props.trip.fields.originCity,
      FORM_originStateProvinceRegion: this.props.trip.fields.originStateProvinceRegion,
      FORM_originCountry: this.props.trip.fields.originCountry,
      FORM_destinationCity: this.props.trip.fields.destinationCity,
      FORM_destinationStateProvinceRegion: this.props.trip.fields.destinationStateProvinceRegion,
      FORM_destinationCountry: this.props.trip.fields.destinationCountry,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open == true && prevProps.open == false) {
      this.setState({
        FORM_departureDate: this.props.trip.fields.departureDate ? moment.utc(this.props.trip.fields.departureDate) : null, 
        FORM_arrivalDate: this.props.trip.fields.arrivalDate ? moment.utc(this.props.trip.fields.arrivalDate) : null,
        FORM_originCity: this.props.trip.fields.originCity,
        FORM_originStateProvinceRegion: this.props.trip.fields.originStateProvinceRegion,
        FORM_originCountry: this.props.trip.fields.originCountry,
        FORM_destinationCity: this.props.trip.fields.destinationCity,
        FORM_destinationStateProvinceRegion: this.props.trip.fields.destinationStateProvinceRegion,
        FORM_destinationCountry: this.props.trip.fields.destinationCountry,
      })
    }
  }

  handlePatchTrip = async (e) => {
    e.preventDefault()
    const [{firebaseUser}, dispatch] = this.context;
    this.setState({waitingOnAPIResult: true})

    try {
        let trip = await patchTrip(firebaseUser, {
            departureDate: (this.state.FORM_departureDate) ? this.state.FORM_departureDate : null,
            arrivalDate: (this.state.FORM_arrivalDate) ? this.state.FORM_arrivalDate : null,
            originCity: this.state.FORM_originCity,
            originStateProvinceRegion: this.state.FORM_originStateProvinceRegion,
            originCountry: this.state.FORM_originCountry,
            destinationCity: this.state.FORM_destinationCity,
            destinationStateProvinceRegion: this.state.FORM_destinationStateProvinceRegion,
            destinationCountry: this.state.FORM_destinationCountry,
          }, this.props.trip.id)
        this.closeAndResetModal()
    } catch (error) {
        console.log(error)
        dispatch({
            type: 'showNotification',
            notificationDetails: { title: `Failed to update trip. ${error.message} ${error.details}`, waitingOnAPIResult: false },
            showNotification: true
        })
        return
    }
  }

  closeAndResetModal = async () => {
    const [{ employerWorkersAndMeta }, dispatch] = this.context;
    const { employerId } = this.props.match.params
    const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

    this.setState({
      waitingOnAPIResult: false,
      FORM_departureDate: null,
      FORM_arrivalDate: null,
      FORM_originCity: '',
      FORM_originStateProvinceRegion: '',
      FORM_originCountry: '',
      FORM_destinationCity: '',
      FORM_destinationStateProvinceRegion: '',
      FORM_destinationCountry: ''    
    })
    this.props.setOpen(false)
    dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Success!', content: `Updated trip!`, success: true },
        showNotification: true
    })
    this.props.pullLatestAfterUpdate()
    this.props.setOpen(false)    
  }

  render() {
    const [{user}, dispatch] = this.context
    const {t} = this.props // translations
    const {waitingOnAPIResult, apiErrorMessage} = this.state

    return (
        <Modal
        title='Edit Trip'
        subtitle='Information about the trip.'
        buttonLabel='Submit'
        open={this.props.open}
        setOpen={this.props.setOpen}
        actionOnClick={this.handlePatchTrip}
    >
        <TripForm 
            FORM_departureDate={this.state.FORM_departureDate}
            FORM_arrivalDate={this.state.FORM_arrivalDate}
            FORM_originCity={this.state.FORM_originCity}
            FORM_originStateProvinceRegion={this.state.FORM_originStateProvinceRegion}
            FORM_originCountry={this.state.FORM_originCountry}
            FORM_destinationCity={this.state.FORM_destinationCity}
            FORM_destinationStateProvinceRegion={this.state.FORM_destinationStateProvinceRegion}
            FORM_destinationCountry={this.state.FORM_destinationCountry}        
            handleDatePickerChange={this.handleDatePickerChange}
            handleChange={this.handleChange}
            onSubmit={this.handlePatchTrip}
        />
    </Modal>
    );
  }
}

export default withRouter(withTranslation()(TripModal))