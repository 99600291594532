import { Fragment, useContext, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { SearchIcon, UsersIcon } from '@heroicons/react/outline'
import { ChevronRightIcon } from '@heroicons/react/solid'
import { StateContext } from '../../../../util/state'
import AvatarImage from '../../../../components/AvatarImage'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { FaCloudUploadAlt } from 'react-icons/fa'
import LogoWide from '../../../../img/logo_wide.svg'
import { classNames } from '../../../../util/helpers'
import { addWorkerToJob } from '../../../../models/organization'
import { Button } from '../../../../components/Tailwind'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import UserProfileModal from '../userProfileModal'

export default function AddWorkerToJobModal(props) {
  const [query, setQuery] = useState('')
  const { open, setOpen, job, retrieveJobDetails } = props
  const [isLoading, setIsLoading] = useState(false)

  // We use the user profile modal to add new workers if one doesn't exist.
  const [showUserProfileModal, setShowUserProfileModal] = useState(false)

  const [{ workersAndMeta, isLoadingWorkers, organization, isLoadingJobs }, dispatch] = useContext(StateContext)

  const workers = !isLoadingWorkers ? workersAndMeta?.result : []

  const recent = workersAndMeta?.result.slice(0, 25)

  const handleAddWorker = async worker => {
    try {
      setIsLoading(true)
      const result = await addWorkerToJob(organization.id, job.id, worker?.id)
      await retrieveJobDetails(null)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Added ${worker?.fields?.displayName}. `, waitingOnAPIResult: false, success: true },
        showNotification: true,
      })
      setIsLoading(false)
      setQuery('')
    } catch (error) {
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to add worker. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      setIsLoading(false)
    }
  }

  const workersNotOnJob = workers.filter(function (array_el) {
    return (
      job?.fields?.workers.filter(function (anotherOne_el) {
        return anotherOne_el.id == array_el.id
      }).length == 0
    )
  })

  const filteredWorkers =
    query === '' || query.length < 2
      ? []
      : workersNotOnJob.filter(worker => {
          return (
            (worker?.fields?.displayName ? worker?.fields?.displayName.toLowerCase().includes(query.toLowerCase()) : false) ||
            (worker?.fields?.displayPhoneNumber ? worker?.fields?.displayPhoneNumber.toLowerCase().includes(query.toLowerCase()) : false) ||
            (worker?.fields?.email ? worker?.fields?.email.toLowerCase().includes(query.toLowerCase()) : false)
          )
        })

  const pullLatestAfterUpdate = async () => {
    await retrieveJobDetails()
    await props.listWorkers()
  }

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 p-4 overflow-y-auto sm:p-6 md:p-20'>
          <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
            <Dialog.Panel className='max-w-3xl mx-auto overflow-hidden transition-all transform bg-white divide-y divide-gray-100 shadow-2xl rounded-xl ring-1 ring-black ring-opacity-5'>
              <Combobox onChange={person => handleAddWorker(person)}>
                {({ activeOption }) => (
                  <>
                    <div className='relative'>
                      <SearchIcon className='pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400' aria-hidden='true' />
                      <Combobox.Input
                        className='w-full h-12 pr-4 text-gray-800 placeholder-gray-400 bg-transparent border-0 pl-11 focus:ring-0 sm:text-sm'
                        placeholder='Type at least 2 letters to search...'
                        onChange={event => setQuery(event.target.value)} // set the search
                      />
                    </div>

                    {!isLoading && filteredWorkers.length > 0 && (
                      <Combobox.Options as='div' static hold className='flex divide-x divide-gray-100'>
                        <div className={classNames('max-h-96 min-w-0 flex-auto scroll-py-4 overflow-y-auto px-6 py-4', activeOption && 'sm:h-96')}>
                          <div className='-mx-2 text-sm text-gray-700'>
                            {filteredWorkers.map(worker => (
                              <Combobox.Option as='div' key={worker?.id} value={worker} className={({ active }) => classNames('flex cursor-default select-none items-center rounded-md p-2', active && 'bg-gray-100 text-gray-900')}>
                                {({ active }) => (
                                  <>
                                    {/* <img src={worker?.fields?.photo?.url} alt="" className="flex-none w-6 h-6 rounded-full" /> */}
                                    <AvatarImage size={6} />
                                    <span className='flex-auto ml-3 truncate'>{worker?.fields?.displayName}</span>
                                    {active && <ChevronRightIcon className='flex-none w-5 h-5 ml-3 text-gray-400' aria-hidden='true' />}
                                  </>
                                )}
                              </Combobox.Option>
                            ))}
                          </div>
                        </div>

                        {activeOption && (
                          <div className='flex-col flex-none hidden w-1/2 overflow-y-auto divide-y divide-gray-100 h-96 sm:flex'>
                            <div className='flex-none p-6 text-center'>
                              {/* <img src={activeOption?.fields?.imageUrl} alt="" className="w-16 h-16 mx-auto rounded-full" /> */}
                              <AvatarImage size={16} />
                              <h2 className='mt-3 font-semibold text-gray-900'>{activeOption?.fields?.displayName}</h2>
                              <p className='text-sm leading-6 text-gray-500'>{activeOption?.fields?.visaType}</p>
                            </div>
                            <div className='flex flex-col justify-between flex-auto p-6'>
                              <dl className='grid grid-cols-1 text-sm text-gray-700 gap-x-6 gap-y-3'>
                                <dt className='col-end-1 font-semibold text-gray-900'>Phone</dt>
                                <dd>{activeOption?.fields?.displayPhoneNumber}</dd>
                                <dt className='col-end-1 font-semibold text-gray-900'>Passport #: </dt>
                                <dd className='truncate'>
                                  <a href={activeOption?.fields?.passportNumber} className='text-blue-600 underline'>
                                    {activeOption?.fields?.passportNumber}
                                  </a>
                                </dd>
                                <dt className='col-end-1 font-semibold text-gray-900'>Email</dt>
                                <dd className='truncate'>
                                  <a href={`mailto:${activeOption?.fields?.email}`} className='text-blue-600 underline'>
                                    {activeOption?.fields?.email}
                                  </a>
                                </dd>
                              </dl>
                              <Button onClick={() => handleAddWorker(activeOption)} loading={isLoadingJobs} fluid>
                                Add to Job
                              </Button>
                            </div>
                          </div>
                        )}
                      </Combobox.Options>
                    )}

                    {!isLoading && query.length >= 2 && filteredWorkers.length === 0 && (
                      <div className='px-6 text-sm text-center py-14 sm:px-14'>
                        <UsersIcon className='w-6 h-6 mx-auto text-gray-400' aria-hidden='true' />
                        <p className='mt-4 font-semibold text-gray-900'>No people found</p>
                        <p className='mt-2 text-gray-500'>We couldn’t find anything with that term. Please try again.</p>
                        <Button className='mt-4' onClick={() => setShowUserProfileModal(true)}>
                          Add New Worker to Ruta
                        </Button>
                      </div>
                    )}

                    {!isLoading && query.length < 2 && filteredWorkers.length === 0 && (
                      <div className='px-6 text-sm text-center py-14 sm:px-14'>
                        <HiMagnifyingGlass className='w-6 h-6 mx-auto text-gray-400' aria-hidden='true' />
                        <p className='mt-4 font-semibold text-gray-900'>Search for a worker</p>
                        <p className='mt-2 text-gray-500'>Type a worker's name, email or phone to add them.</p>
                        <Button className='mt-4' onClick={() => setShowUserProfileModal(true)}>
                          Add New Worker to Ruta
                        </Button>
                      </div>
                    )}

                    {isLoading && (
                      <div className='px-6 text-sm text-center py-14 sm:px-14'>
                        <img src={LogoWide} className='h-8 mx-auto mb-6 text-gray-400' aria-hidden='true' />
                        <p className='mt-4 font-semibold text-gray-400'>
                          <AiOutlineLoading3Quarters class='inline animate-spin h-5 w-5 mr-3'></AiOutlineLoading3Quarters>Loading...{' '}
                        </p>
                      </div>
                    )}
                    <UserProfileModal open={showUserProfileModal} setOpen={open => setShowUserProfileModal(open)} pullLatestAfterUpdate={pullLatestAfterUpdate} />
                  </>
                )}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
