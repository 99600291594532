import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/storage";
import { mimeToFileExtension } from '../../util/constants';

function makeid(length) {
  var result = ''
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  var charactersLength = characters.length
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

// https://www.robinwieruch.de/complete-firebase-authentication-react-tutorial#provide-firebase-in-react

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID
};
 
firebase.initializeApp(config);

const storage = firebase.storage()

export const auth = firebase.auth();
auth.useDeviceLanguage();

export const attachRecaptchaToWindow = (containerRef, handleSuccessfulRecaptcha, handleFailedRecaptcha) => {
  window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(containerRef, {
    'size': 'normal',
    'callback': handleSuccessfulRecaptcha,
    'expired-callback': handleFailedRecaptcha
  });
  window.recaptchaVerifier.render().then((widgetId) => {
		window.recaptchaWidgetId = widgetId;
	});
}

export const uploadFile = async (userId, file) => {
  var storageRef = storage.ref()
  var name = file.name ? file.name : `${makeid(10)}.${mimeToFileExtension[file.type]}`
  var fileRef = storageRef.child(`uploads/users/${userId}/${name}`);
  try {
    let result = await fileRef.put(file)
    let downloadURL = await fileRef.getDownloadURL()
    console.log("Successfully uploaded file to: ", downloadURL)
    return downloadURL
  } catch (error) {
    console.log(error)
    throw error
  }
  // var uploadTask = fileRef.put(file)

  // // Register three observers:
  // // 1. 'state_changed' observer, called any time the state changes
  // // 2. Error observer, called on failure
  // // 3. Completion observer, called on successful completion
  // uploadTask.on('state_changed', 
  //   (snapshot) => {
  //     // Observe state change events such as progress, pause, and resume
  //     // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //     var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //     console.log('Upload is ' + progress + '% done');
  //     switch (snapshot.state) {
  //       case firebase.storage.TaskState.PAUSED: // or 'paused'
  //         console.log('Upload is paused');
  //         break;
  //       case firebase.storage.TaskState.RUNNING: // or 'running'
  //         console.log('Upload is running');
  //         break;
  //     }
  //   }, 
  //   (error) => {
  //     // Handle unsuccessful uploads
  //   }, 
  //   () => {
  //     // Handle successful uploads on complete
  //     // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  //     uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
  //       console.log('File available at', downloadURL);
  //     });
  //   }
  // );


}


// (response) => {
//       // reCAPTCHA solved, allow signInWithPhoneNumber.
//       console.log(response);
//       console.log("attempt to sign in now! ");
//       // onSignInSubmit();
//     }

// class Firebase {
//   constructor() {
//     try {
//       firebase.initializeApp(config);
//       this.auth = firebase.auth;
//     } catch (err) {
//       // we skip the "already exists" message which is
//       // not an actual error when we're hot-reloading
//       if (!/already exists/.test(err.message)) {
//         console.error('Firebase initialization error', err.stack)
//       }
//     }    
//   }
//   doCreateUserWithEmailAndPassword = (email, password) =>
//     this.auth().createUserWithEmailAndPassword(email, password);  

//   doSignInWithEmailAndPassword = (email, password) =>
//     this.auth().signInWithEmailAndPassword(email, password);

//   doSignOut = () => this.auth().signOut();

//   doPasswordReset = email => this.auth().sendPasswordResetEmail(email);
 
//   doPasswordUpdate = password =>
//     this.auth().currentUser.updatePassword(password);       

//   attachRecaptchaToWindow = (containerRef) => {
//     window.recaptchaVerifier = new this.auth.RecaptchaVerifier(containerRef, {
//       'size': 'invisible',
//       'callback': (response) => {
//         // reCAPTCHA solved, allow signInWithPhoneNumber.
//         console.log(response);
//         console.log("attempt to sign in now! ");
//         // onSignInSubmit();
//       }
//     });
//   }

//   doSignInWithPhone = (phoneNumber, appVerifier) => {
//     return this.auth().signInWithPhoneNumber(phoneNumber, appVerifier);
//   }

// }
 
// export default Firebase;