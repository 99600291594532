import React from 'react';
import { StateContext } from '../../../../util/state.jsx';
import { withTranslation } from "react-i18next";
import { Modal } from '../../../../components/Tailwind'
import { withRouter } from 'react-router';
import { patchUser } from '../../../../models/user.jsx';
import { bulkSetFacilitatorForEmployer, listEmployersForOrganization } from '../../../../models/organization.jsx'

import ChangeEmployerForm from '../../forms/ChangeEmployerForm/index.jsx';

class ChangeEmployerModal extends React.Component {
    static contextType = StateContext;
    static defaultProps = {}

    state = {
        waitingOnAPIResult: false,
        FORM_employerId: undefined,
        employers: []
    }

    handleChange = async (event) => this.setState({ FORM_employerId: event.id })

    componentDidMount = async () => {
        const [{ firebaseUser, user }, dispatch] = this.context;
        let newEmployersAndPagination = await listEmployersForOrganization(firebaseUser, user.fields?.organizationId, {
            currentPage: 1,
            pageSize:1000
        })
        this.setState({
            employers: newEmployersAndPagination.result,
            FORM_employerId: this.props.initialEmployerId
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open == true && prevProps.open == false) {
            if (this.props.initialEmployerId) {
                this.setState({
                    FORM_employerId: this.props.initialEmployerId
                })
            }
        }
    }

    handlePatchUser = async (e) => {
        e.preventDefault();
        const [{ firebaseUser, organization }, dispatch] = this.context;
        const { workerId } = this.props.match.params
        this.setState({ waitingOnAPIResult: true })

        try {
            await patchUser(firebaseUser, workerId, {
                "employerId": this.state.FORM_employerId,
            })
            this.closeAndResetModal()
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `Failed to change employer. ${error.message} ${error.details}`, waitingOnAPIResult: false },
                showNotification: true
            })
            return
        }
    }

    closeAndResetModal = async () => {
        const [{ employerWorkersAndMeta }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

        dispatch({
            type: 'showNotification',
            notificationDetails: { title: 'Success!', content: `Assigned worker.`, success: true },
            showNotification: true
        })
        // this.props.listWorkersForOrganizationAndEmployer(employerId, filtersAndPagination)
        // const newCoFiltersAndPagination = employerWorkersAndMeta[this.state.FORM_employerId]?.meta
        // this.props.listWorkersForOrganizationAndEmployer(this.state.FORM_employerId, newCoFiltersAndPagination)

        this.props.pullLatestAfterUpdate()
        this.setState({
            waitingOnAPIResult: false,
            FORM_employerId: undefined
        })

        this.props.setOpen(false)
    }

    render() {
        const [{ user, organization}, dispatch] = this.context
        const { t } = this.props // translations
        const { waitingOnAPIResult, apiErrorMessage } = this.state

        return (
            <Modal
                title={'Change Employer'}
                subtitle='Did a worker change employers? Select the new employer from the dropdown. '
                buttonLabel='Change'
                open={this.props.open}
                setOpen={this.props.setOpen}
                actionOnClick={this.handlePatchUser}
            >
                <ChangeEmployerForm
                    FORM_employerId={this.state.FORM_employerId}
                    employers={this.state.employers}
                    handleChange={this.handleChange}
                    onSubmit={this.handlePatchUser}
                />
            </Modal>
        );
    }
}

export default withRouter(withTranslation()(ChangeEmployerModal))