import React, { Component } from 'react'
import InvitationModal from '../../../modals/InvitationModal'
import moment from 'moment'

export default class InvitationSection extends Component {
    state = {
        editInvitationModalIsOpen: false,
        waitingOnAPIResult: false,
    }
    toggleModal = (open) => this.setState({ editInvitationModalIsOpen: open })

    render() {
        const { invitation } = this.props
        return (
            <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                    <div className="flex flex-wrap items-center justify-between px-4 py-5 sm:px-6 sm:flex-nowrap">
                        <div className="mt-2">
                            <h2 className="text-lg font-medium leading-6 text-gray-900">Invitation Details</h2>
                        </div>
                        <div className="flex-shrink-0 ml-4">
                            <button
                                type="button"
                                onClick={() => this.toggleModal(true)}
                                className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"
                            >
                                Update
                            </button>
                            <InvitationModal
                                open={this.state.editInvitationModalIsOpen}
                                setOpen={this.toggleModal}
                                invitation={invitation}
                                listInvitationsForOrganizationAndEmployer={this.props.listInvitationsForOrganizationAndEmployer}
                            />
                        </div>
                    </div>

                    <div className="px-4 py-5 border-t border-gray-200 sm:px-6">
                        <dl className="grid grid-cols-1 gap-x-4 gap-y-4 sm:grid-cols-2">
                            <div className="sm:col-span-2">
                            <span className="text-sm">This worker will be invited to Ruta to confirm all the following details.</span>
                            </div>
                            <div className="sm:col-span-2">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                        <span className="px-2 text-sm text-gray-500 bg-white">Personal Info</span>
                                    </div>
                                </div>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">First Name</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.firstName || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Last Name</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.lastName || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Passport Number</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.passportNumber || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Date of Birth</dt>
                                <dd className="mt-1 text-sm text-gray-900">{moment(invitation?.fields?.dateOfBirth).format('LL') || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Gender</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.gender || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Visa Type</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.visaType || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.displayPhoneNumber || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Email</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.email || 'Not provided'}</dd>
                            </div>

                            <div className="sm:col-span-2">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                        <span className="px-2 text-sm text-gray-500 bg-white">Passport</span>
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Street Address</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.displayAddress || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Passport Issue Date</dt>
                                <dd className="mt-1 text-sm text-gray-900">{moment(invitation?.fields?.passportIssueDate).format('LL') || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Passport Expiration Date</dt>
                                <dd className="mt-1 text-sm text-gray-900">{moment(invitation?.fields?.passportExpirationDate).format('LL') || 'Not provided'}</dd>
                            </div>

                            <div className="sm:col-span-2">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                        <span className="px-2 text-sm text-gray-500 bg-white">Trip Details</span>
                                    </div>
                                </div>
                            </div>

                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Origin City</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.originCity || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Origin State / Province</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.originStateProvinceRegion || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Origin Country</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.originCountry || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Destination City </dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.destinationCity || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Destination State / Province</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.destinationStateProvinceRegion || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Destination Country</dt>
                                <dd className="mt-1 text-sm text-gray-900">{invitation?.fields?.destinationCountry || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Departure Date</dt>
                                <dd className="mt-1 text-sm text-gray-900">{moment(invitation?.fields?.departureDate).format('LL') || 'Not provided'}</dd>
                            </div>
                            <div className="sm:col-span-1">
                                <dt className="text-sm font-medium text-gray-500">Arrival Date</dt>
                                <dd className="mt-1 text-sm text-gray-900">{moment(invitation?.fields?.arrivalDate).format('LL') || 'Not provided'}</dd>
                            </div>
                        </dl>
                    </div>
                </div>
            </section>
        )
    }
}
