import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
// import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { HiBars3, HiBell, HiEllipsisVertical, HiLanguage, HiXMark } from 'react-icons/hi2'
import LogoWide from '../../../img/logo_wide.svg'
import { useHistory } from 'react-router-dom'
import { classNames } from '../../../util/helpers'
import { useContext } from 'react'
import { StateContext } from '../../../util/state'
import AvatarImage from '../../../components/AvatarImage'
import { useTranslation } from 'react-i18next'

export default function TopNavigation() {
  const { i18n } = useTranslation()
  const history = useHistory()
  const [{ user }, dispatch] = useContext(StateContext)

  const handleLogout = () => {
    localStorage.removeItem('rutaSessionToken')
    localStorage.removeItem('rutaSessionJwt')
    dispatch({
      type: 'setUserAndToken',
      user: null,
      sessionToken: null,
      sessionJwt: null,
    })
  }

  const navigation = [
    { name: 'Jobs', href: '/account/onboarding', current: history.location.pathname.includes('onboarding') },
    { name: 'Your Profile', href: '/account/profile', current: history.location.pathname.includes('profile') },
    { name: 'Documents', href: '/account/documents', current: history.location.pathname.includes('documents') },
    // { name: 'Calendar', href: '#', current: false },
  ]
  return (
    <Disclosure as='nav' className='bg-stone-800'>
      {({ open }) => (
        <>
          <div className='px-2 mx-auto max-w-7xl sm:px-6 lg:px-8'>
            <div className='relative flex items-center justify-between h-16'>
              <div className='absolute inset-y-0 left-0 flex items-center sm:hidden'>
                {/* Mobile menu button*/}
                <Disclosure.Button className='inline-flex items-center justify-center p-2 text-gray-400 rounded-md hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'>
                  <span className='sr-only'>Open main menu</span>
                  {open ? <HiXMark className='block w-6 h-6' aria-hidden='true' /> : <HiBars3 className='block w-6 h-6' aria-hidden='true' />}
                </Disclosure.Button>
              </div>
              <div className='flex items-center justify-center flex-1 sm:items-stretch sm:justify-start'>
                <div className='flex items-center flex-shrink-0'>
                  <img className='block w-auto h-8 cursor-pointer lg:hidden' onClick={() => history.push('/account')} src={LogoWide} alt='Your Company' />
                  <img className='hidden w-auto h-8 cursor-pointer lg:block' onClick={() => history.push('/account')} src={LogoWide} alt='Your Company' />
                </div>
                <div className='hidden sm:ml-6 sm:block'>
                  <div className='flex space-x-4'>
                    {navigation.map(item => (
                      <a
                        key={item.name}
                        onClick={() => history.push(item.href)}
                        className={classNames(item.current ? 'bg-stone-900 text-white' : 'text-gray-300 hover:bg-stone-900 hover:text-white', 'px-3 py-2 rounded-md text-sm font-medium cursor-pointer')}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className='absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0'>
                <Menu as='div' className='relative inline-block text-left'>
                  <div>
                    <Menu.Button className='flex items-center p-1 text-gray-400 rounded-full bg-stone-800 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <span className='sr-only'>Choose Language</span>
                      <HiLanguage className='w-6 h-6' aria-hidden='true' />
                    </Menu.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <div className='py-1'>
                        <Menu.Item>
                          {({ active }) => (
                            <a onClick={() => i18n.changeLanguage('en-US')} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer')}>
                              English
                            </a>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                            <a onClick={() => i18n.changeLanguage('es-MX')} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm cursor-pointer')}>
                              Español
                            </a>
                          )}
                        </Menu.Item>
                      </div>
                    </Menu.Items>
                  </Transition>
                </Menu>

                {/* Profile dropdown */}
                <Menu as='div' className='relative ml-3'>
                  <div>
                    <Menu.Button className='flex text-sm rounded-full bg-stone-800 focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800'>
                      <span className='sr-only'>Open user menu</span>
                      {/* {user?.fields?.photo ? <img className='flex-shrink-0 w-8 h-8 bg-gray-300 rounded-full' src={user?.fields?.photo?.url} alt='' /> : <AvatarImage size='8' />} */}
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter='transition ease-out duration-100'
                    enterFrom='transform opacity-0 scale-95'
                    enterTo='transform opacity-100 scale-100'
                    leave='transition ease-in duration-75'
                    leaveFrom='transform opacity-100 scale-100'
                    leaveTo='transform opacity-0 scale-95'
                  >
                    <Menu.Items className='absolute right-0 z-10 w-48 py-1 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                      <Menu.Item>
                        {({ active }) => (
                          <a 
                          onClick={() => handleLogout()}
                          className={classNames(active ? 'bg-gray-100' : '', 'block px-4 py-2 text-sm text-gray-700 cursor-pointer')}>
                            Log out
                          </a>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <Disclosure.Panel className='sm:hidden'>
            <div className='px-2 pt-2 pb-3 space-y-1'>
              {navigation.map(item => (
                <Disclosure.Button
                  key={item.name}
                  as='a'
                  onClick={() => history.push(item.href)}
                  className={classNames(item.current ? 'bg-stone-900 text-white' : 'text-gray-300 hover:bg-stone-900 hover:text-white', 'block px-3 py-2 rounded-md text-base font-medium cursor-pointer')}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
