import { StateContext } from '../../../../util/state.jsx'
import React from 'react'
import WorkerList from './workerList'
import WorkerArticle from './workerArticle'
import { Route } from 'react-router-dom/cjs/react-router-dom.min'
import Container from '../../../../components/Tailwind/Container'
import { Button } from '../../../../components/Tailwind'
import WorkerSearchInput from './components/workerSearchInput.jsx'
import { useState } from 'react'
import { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { SiMicrosoftexcel } from 'react-icons/si'
import ImportWorkersModal from '../../modals/ImportWorkersModal/index.jsx'

const WorkerDirectory = () => {
  const [{ isLoadingWorkers }] = useContext(StateContext)
  const [importNewWorkersModalIsOpen, setimportWorkersModalIsOpen] = useState(false)
  const history = useHistory()
  const [workerFilter, setworkerFilter] = useState(null)

  return (
    <>
      <Container className='fixed bg-white shadow flex w-full z-10'>
        <div className='md:flex md:items-center md:justify-between w-full'>
          <div className='flex-1 min-w-0 py-1.5'>
            <h2 className='text-xl font-medium text-gray-900'>Workers</h2>
          </div>
          <div className='flex mt-4 md:mt-0 md:ml-4'>
            <Button className='mr-4' color='white' onClick={() => setimportWorkersModalIsOpen(true)} disabled={isLoadingWorkers}>
              Import New Workers 
              <SiMicrosoftexcel className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
            </Button>
          </div>
        </div>
      </Container>

      <div className='flex w-full h-full pt-[72px]'>
        <div className='flex flex-col flex-1 min-w-0 overflow-hidden'>
          <div className='relative z-0 flex flex-1 overflow-hidden'>
            <main className='relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last'>
              <nav className='flex items-center px-4 py-3 sm:px-6 lg:px-8 xl:hidden' aria-label='Breadcrumb'>
                <a href='#' className='inline-flex items-center space-x-3 text-sm font-medium text-gray-900'>
                  <HiMagnifyingGlass className='w-5 h-5 -ml-2 text-gray-400' aria-hidden='true' />
                  <span>Search: </span>
                  <WorkerSearchInput workerFilter={workerFilter} setworkerFilter={setworkerFilter} />
                </a>
                <Button color='white' className='mt-1 ml-4' onClick={() => history.push(`/facilitator/workers/${workerFilter.id}`)}>
                  Go to Worker
                </Button>
              </nav>

              <Route path={`/facilitator/workers/:workerId`} render={props => <WorkerArticle {...props} />} />
            </main>

            <aside className='flex-shrink-0 hidden border-r border-gray-200 xl:order-first xl:flex xl:flex-col w-96'>{!isLoadingWorkers && <WorkerList />}</aside>
          </div>
        </div>
      </div>
      <ImportWorkersModal open={importNewWorkersModalIsOpen} setOpen={setimportWorkersModalIsOpen} />
    </>
  )
}

export default WorkerDirectory
