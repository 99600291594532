import React, { Component } from 'react'
import {
    CheckIcon,
} from '@heroicons/react/outline'
import { classNames } from '../../../../../util/helpers'
import moment from 'moment'

export default class TaskTracker extends Component {
    render() {
        const { worker } = this.props

        const steps = [
            { name: 'Add Selfie', description: 'Add a current self picture.', href: '#', status: worker?.fields?.taskTracker?.hasSelfie },
            { name: 'Passport', description: 'Upload a picture of your passport.', href: '#', status: worker?.fields?.taskTracker?.hasPassport },
            { name: 'Work Visa', description: 'Upload a picture of your work visa.', href: '#', status: worker?.fields?.taskTracker?.hasVisa },
            { name: 'COVID-19 Vaccination', description: 'Upload official proof of Covid19 vaccine.', href: '#', status: worker?.fields?.taskTracker?.hasCovidVaccine },
            { name: 'Border Crossing', description: 'Upon crossing into the United States, upload your border crossing document.', href: '#', status: worker?.fields?.taskTracker?.hasBorderCrossing },
        ]

        return (
            
                <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:px-6">
                    <h2 className="pb-4 text-lg font-medium text-gray-900">
                        Travel Requirements
                    </h2>

                    {/* Activity Feed */}
                    <nav aria-label="Progress">
                        <ol role="list" className="overflow-hidden">
                            {steps.map((step, stepIdx) => (
                                <li key={step.name} className={classNames(stepIdx !== steps.length - 1 ? 'pb-5' : '', 'relative')}>
                                    {step.status === true ? (
                                        <>
                                            {stepIdx !== steps.length - 1 ? (
                                                <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-green-600" aria-hidden="true" />
                                            ) : null}
                                            <a href={step.href} className="relative flex items-start group">
                                                <span className="flex items-center h-9">
                                                    <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-green-600 rounded-full group-hover:bg-green-800">
                                                        <CheckIcon className="w-5 h-5 text-white" aria-hidden="true" />
                                                    </span>
                                                </span>
                                                <span className="flex flex-col min-w-0 ml-4">
                                                    <span className="text-xs font-semibold tracking-wide uppercase">{step.name}</span>
                                                    <span className="text-sm text-gray-500">{step.description}</span>
                                                </span>
                                            </a>
                                        </>
                                    ) : step.status === 'current' ? (
                                        <>
                                            {stepIdx !== steps.length - 1 ? (
                                                <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                                            ) : null}
                                            <a href={step.href} className="relative flex items-start group" aria-current="step">
                                                <span className="flex items-center h-9" aria-hidden="true">
                                                    <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 border-green-600 rounded-full">
                                                        <span className="h-2.5 w-2.5 bg-green-600 rounded-full" />
                                                    </span>
                                                </span>
                                                <span className="flex flex-col min-w-0 ml-4">
                                                    <span className="text-xs font-semibold tracking-wide text-green-600 uppercase">{step.name}</span>
                                                    <span className="text-sm text-gray-500">{step.description}</span>
                                                </span>
                                            </a>
                                        </>
                                    ) : (
                                        <>
                                            {stepIdx !== steps.length - 1 ? (
                                                <div className="-ml-px absolute mt-0.5 top-4 left-4 w-0.5 h-full bg-gray-300" aria-hidden="true" />
                                            ) : null}
                                            <a href={step.href} className="relative flex items-start group">
                                                <span className="flex items-center h-9" aria-hidden="true">
                                                    <span className="relative z-10 flex items-center justify-center w-8 h-8 bg-white border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                                                        <span className="h-2.5 w-2.5 bg-transparent rounded-full group-hover:bg-gray-300" />
                                                    </span>
                                                </span>
                                                <span className="flex flex-col min-w-0 ml-4">
                                                    <span className="text-xs font-semibold tracking-wide text-gray-500 uppercase">{step.name}</span>
                                                    <span className="text-sm text-gray-500">{step.description}</span>
                                                </span>
                                            </a>
                                        </>
                                    )}
                                </li>
                            ))}
                        </ol>
                    </nav>
                    <div className="flex flex-col mt-6 justify-stretch">
                        {/* <button
                            type="button"
                            className="inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                        >
                            Send reminder
                        </button> */}
                    </div>
                </div>
        )
    }
}
