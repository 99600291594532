import React from 'react';
import { StateContext } from '../../../../util/state.jsx';
import { Modal } from '../../../../components/Tailwind'
import moment from 'moment'
import { withRouter } from 'react-router';
import InvitationForm from '../../forms/InvitationForm/index.jsx';
import { patchInvitation } from '../../../../models/invitation.jsx';

class InvitationModal extends React.Component {
    static contextType = StateContext;
    static defaultProps = {}

    state = {
        waitingOnAPIResult: false,
        FORM_firstName: '',
        FORM_lastName: '',
        FORM_passportNumber: '',
        FORM_dateOfBirth: null,
        FORM_gender: '',
        FORM_visaType: '',
        FORM_phoneNumber: '',
        FORM_address1: '',
        FORM_address2: '',
        FORM_city: '',
        FORM_postalCode: '',
        FORM_stateProvinceRegion: '',
        FORM_country: '',
        FORM_passportIssueDate: null,
        FORM_passportExpirationDate: null,
        FORM_email: '',
        FORM_departureDate: null,
        FORM_arrivalDate: null,
        FORM_originCity: '',
        FORM_originStateProvinceRegion: '',
        FORM_originCountry: '',
        FORM_destinationCity: '',
        FORM_destinationStateProvinceRegion: '',
        FORM_destinationCountry: '',
    }

    handleChange = async (event) => this.setState({ [event.target.name]: event.target.value })
    handleFileUploadChange = async (event) => this.setState({ FORM_photo: event.target.files[0] })
    handleDatePickerChange = async (stateVariable, date) => this.setState({ [stateVariable]: moment(`${date.format('YYYY-MM-DD')}T20:00:00Z`) }) // note we force 8pm UTC that day
    handleDateRangePickerChange = async (startDateName, startDate, endDateName, endDate) => {
        this.setState({ // Only set these if start or end date is not null using ... notation
            ...startDate && {[startDateName]: moment(`${startDate.format('YYYY-MM-DD')}T20:00:00Z`)}, 
            ...endDate && {[endDateName]: moment(`${endDate.format('YYYY-MM-DD')}T20:00:00Z`)},
        }) // note we force 8pm UTC that day        
    }

    componentDidMount = () => {
        this.setState({
            FORM_firstName: this.props.invitation.fields?.firstName,
            FORM_lastName: this.props.invitation.fields?.lastName,
            FORM_passportNumber: this.props.invitation.fields?.passportNumber,
            FORM_dateOfBirth: this.props.invitation.fields?.dateOfBirth ? moment.utc(this.props.invitation.fields?.dateOfBirth) : null,
            FORM_gender: this.props.invitation.fields?.gender,
            FORM_visaType: this.props.invitation.fields?.visaType,
            FORM_phoneNumber: this.props.invitation.fields?.phoneNumber,
            FORM_address1: this.props.invitation.fields?.address1,
            FORM_address2: this.props.invitation.fields?.address2,
            FORM_city: this.props.invitation.fields?.city,
            FORM_postalCode: this.props.invitation.fields?.postalCode,
            FORM_stateProvinceRegion: this.props.invitation.fields?.stateProvinceRegion,
            FORM_country: this.props.invitation.fields?.country,
            FORM_passportIssueDate: this.props.invitation.fields?.passportIssueDate ? moment.utc(this.props.invitation.fields?.passportIssueDate) : null,
            FORM_passportExpirationDate: this.props.invitation.fields?.passportExpirationDate ? moment.utc(this.props.invitation.fields?.passportExpirationDate) : null,
            FORM_email: this.props.invitation.fields?.email,
            FORM_departureDate: this.props.invitation.fields?.departureDate ? moment.utc(this.props.invitation.fields?.departureDate) : null,
            FORM_arrivalDate: this.props.invitation.fields?.arrivalDate ? moment.utc(this.props.invitation.fields?.arrivalDate) : null,
            FORM_originCity: this.props.invitation.fields?.originCity,
            FORM_originStateProvinceRegion: this.props.invitation.fields?.originStateProvinceRegion,
            FORM_originCountry: this.props.invitation.fields?.originCountry,
            FORM_destinationCity: this.props.invitation.fields?.destinationCity,
            FORM_destinationStateProvinceRegion: this.props.invitation.fields?.destinationStateProvinceRegion,
            FORM_destinationCountry: this.props.invitation.fields?.destinationCountry,
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open == true && prevProps.open == false) {
            this.setState({
                FORM_firstName: this.props.invitation.fields?.firstName,
                FORM_lastName: this.props.invitation.fields?.lastName,
                FORM_passportNumber: this.props.invitation.fields?.passportNumber,
                FORM_dateOfBirth: this.props.invitation.fields?.dateOfBirth ? moment.utc(this.props.invitation.fields?.dateOfBirth) : null,
                FORM_gender: this.props.invitation.fields?.gender,
                FORM_visaType: this.props.invitation.fields?.visaType,
                FORM_phoneNumber: this.props.invitation.fields?.phoneNumber,
                FORM_address1: this.props.invitation.fields?.address1,
                FORM_address2: this.props.invitation.fields?.address2,
                FORM_city: this.props.invitation.fields?.city,
                FORM_postalCode: this.props.invitation.fields?.postalCode,
                FORM_stateProvinceRegion: this.props.invitation.fields?.stateProvinceRegion,
                FORM_country: this.props.invitation.fields?.country,
                FORM_passportIssueDate: this.props.invitation.fields?.passportIssueDate ? moment.utc(this.props.invitation.fields?.passportIssueDate) : null,
                FORM_passportExpirationDate: this.props.invitation.fields?.passportExpirationDate ? moment.utc(this.props.invitation.fields?.passportExpirationDate) : null,
                FORM_email: this.props.invitation.fields?.email,
                FORM_departureDate: this.props.invitation.fields?.departureDate ? moment.utc(this.props.invitation.fields?.departureDate) : null,
                FORM_arrivalDate: this.props.invitation.fields?.arrivalDate ? moment.utc(this.props.invitation.fields?.arrivalDate) : null,    
                FORM_originCity: this.props.invitation.fields?.originCity,
                FORM_originStateProvinceRegion: this.props.invitation.fields?.originStateProvinceRegion,
                FORM_originCountry: this.props.invitation.fields?.originCountry,
                FORM_destinationCity: this.props.invitation.fields?.destinationCity,
                FORM_destinationStateProvinceRegion: this.props.invitation.fields?.destinationStateProvinceRegion,
                FORM_destinationCountry: this.props.invitation.fields?.destinationCountry,    
            })
        }
    }

    handlePatchUser = async (e) => {
        e.preventDefault();
        const [{ firebaseUser }, dispatch] = this.context;
        this.setState({ waitingOnAPIResult: true })

        try {
            let patchedUserResult = await patchInvitation(firebaseUser, this.props.invitation.id, {
                "firstName":this.state.FORM_firstName !== this.props.invitation.fields?.firstName ? this.state.FORM_firstName : null,
                "lastName":this.state.FORM_lastName !== this.props.invitation.fields?.lastName ? this.state.FORM_lastName : null,
                "passportNumber":this.state.FORM_passportNumber !== this.props.invitation.fields?.passportNumber ? this.state.FORM_passportNumber : null,
                "dateOfBirth": this.state.FORM_dateOfBirth ? this.state.FORM_dateOfBirth.format() : null,
                "gender":this.state.FORM_gender !== this.props.invitation.fields?.gender ? this.state.FORM_gender : null,
                "visaType":this.state.FORM_visaType !== this.props.invitation.fields?.visaType ? this.state.FORM_visaType : null,
                "phoneNumber":this.state.FORM_phoneNumber !== this.props.invitation.fields?.phoneNumber ? this.state.FORM_phoneNumber : null,
                "address1":this.state.FORM_address1 !== this.props.invitation.fields?.address1 ? this.state.FORM_address1 : null,
                "address2":this.state.FORM_address2 !== this.props.invitation.fields?.address2 ? this.state.FORM_address2 : null,
                "city":this.state.FORM_city !== this.props.invitation.fields?.city ? this.state.FORM_city : null,
                "postalCode":this.state.FORM_postalCode !== this.props.invitation.fields?.postalCode ? this.state.FORM_postalCode : null,
                "stateProvinceRegion":this.state.FORM_stateProvinceRegion !== this.props.invitation.fields?.stateProvinceRegion ? this.state.FORM_stateProvinceRegion : null,
                "country":this.state.FORM_country !== this.props.invitation.fields?.country ? this.state.FORM_country : null,
                "passportIssueDate":(this.state.FORM_passportIssueDate) ? this.state.FORM_passportIssueDate.format() : null,
                "passportExpirationDate":(this.state.FORM_passportExpirationDate) ? this.state.FORM_passportExpirationDate.format() : null,
                "email":this.state.FORM_email !== this.props.invitation.fields?.email ? this.state.FORM_email : null,
                "departureDate":this.state.FORM_departureDate ? this.state.FORM_departureDate.format() : null,
                "arrivalDate":this.state.FORM_arrivalDate ? this.state.FORM_arrivalDate.format() : null,
                "originCity":this.state.FORM_originCity !== this.props.invitation.fields?.originCity ? this.state.FORM_originCity : null,
                "originStateProvinceRegion":this.state.FORM_originStateProvinceRegion !== this.props.invitation.fields?.originStateProvinceRegion ? this.state.FORM_originStateProvinceRegion : null,
                "originCountry":this.state.FORM_originCountry !== this.props.invitation.fields?.originCountry ? this.state.FORM_originCountry : null,
                "destinationCity":this.state.FORM_destinationCity !== this.props.invitation.fields?.destinationCity ? this.state.FORM_destinationCity : null,
                "destinationStateProvinceRegion":this.state.FORM_destinationStateProvinceRegion !== this.props.invitation.fields?.destinationStateProvinceRegion ? this.state.FORM_destinationStateProvinceRegion : null,
                "destinationCountry":this.state.FORM_destinationCountry !== this.props.invitation.fields?.destinationCountry ? this.state.FORM_destinationCountry : null,
            })
            this.closeAndResetModal()
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `Failed to update invitation. ${error.message} ${error.details}`, waitingOnAPIResult: false },
                showNotification: true
            })
            return
        }
    }

    closeAndResetModal = async () => {
        const [{ employerWorkersAndMeta }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

        this.setState({
            waitingOnAPIResult: false,
            FORM_firstName: '',
            FORM_lastName: '',
            FORM_passportNumber: '',
            FORM_dateOfBirth: null,
            FORM_gender: '',
            FORM_visaType: '',
            FORM_phoneNumber: '',
            FORM_address1: '',
            FORM_address2: '',
            FORM_city: '',
            FORM_postalCode: '',
            FORM_stateProvinceRegion: '',
            FORM_country: '',
            FORM_passportIssueDate: null,
            FORM_passportExpirationDate: null,
            FORM_email: '',
            FORM_departureDate: null,
            FORM_arrivalDate: null,
            FORM_originCity: '',
            FORM_originStateProvinceRegion: '',
            FORM_originCountry: '',
            FORM_destinationCity: '',
            FORM_destinationStateProvinceRegion: '',
            FORM_destinationCountry: '',
        })
        dispatch({
            type: 'showNotification',
            notificationDetails: { title: 'Success!', content: `Updated invitation.`, success: true },
            showNotification: true
        })
        this.props.listInvitationsForOrganizationAndEmployer(employerId, filtersAndPagination)
        this.props.setOpen(false)
    }

    render() {
        return (
            <Modal
                title='Edit Invitation'
                subtitle='Update any details about this invited user.'
                buttonLabel='Submit'
                open={this.props.open}
                setOpen={this.props.setOpen}
                actionOnClick={this.handlePatchUser}
            >
                <InvitationForm
                    FORM_firstName={this.state.FORM_firstName}
                    FORM_lastName={this.state.FORM_lastName}
                    FORM_passportNumber={this.state.FORM_passportNumber}
                    FORM_dateOfBirth={this.state.FORM_dateOfBirth}
                    FORM_gender={this.state.FORM_gender}
                    FORM_visaType={this.state.FORM_visaType}
                    FORM_phoneNumber={this.state.FORM_phoneNumber}

                    FORM_address1={this.state.FORM_address1}
                    FORM_address2={this.state.FORM_address2}
                    FORM_city={this.state.FORM_city}
                    FORM_postalCode={this.state.FORM_postalCode}
                    FORM_stateProvinceRegion={this.state.FORM_stateProvinceRegion}
                    FORM_country={this.state.FORM_country}

                    FORM_passportIssueDate={this.state.FORM_passportIssueDate}
                    FORM_passportExpirationDate={this.state.FORM_passportExpirationDate}                    

                    FORM_email={this.state.FORM_email}

                    FORM_departureDate={this.state.FORM_departureDate}
                    FORM_arrivalDate={this.state.FORM_arrivalDate}
                    FORM_originCity={this.state.FORM_originCity}
                    FORM_originStateProvinceRegion={this.state.FORM_originStateProvinceRegion}
                    FORM_originCountry={this.state.FORM_originCountry}
                    FORM_destinationCity={this.state.FORM_destinationCity}
                    FORM_destinationStateProvinceRegion={this.state.FORM_destinationStateProvinceRegion}
                    FORM_destinationCountry={this.state.FORM_destinationCountry}                    

                    handleChange={this.handleChange}
                    handleFileUploadChange={this.handleFileUploadChange}
                    handleDatePickerChange={this.handleDatePickerChange}
                    handleDateRangePickerChange={this.handleDateRangePickerChange}
                    onSubmit={this.handlePatchUser}
                />
            </Modal>
        );
    }
}

export default withRouter(InvitationModal)