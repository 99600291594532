import {
  HiSparkles,
  HiOutlineUserPlus,
  HiOutlineBriefcase,
  HiOutlineClipboardDocumentCheck,
  HiOutlineArrowRightOnRectangle,
  HiOutlineHandThumbUp,
  HiOutlineMagnifyingGlass,
} from 'react-icons/hi2'
import { useHistory } from 'react-router-dom'
import { Button } from '../../../../components/Tailwind'
import {  formatRelative, parseISO } from 'date-fns'
import { useState } from 'react'
import moment from 'moment'

const DashboardActivityFeed = props => {
  const history = useHistory()

  const [searchText, setSearchText] = useState('')
  
  const feed = props.limitFeed ? props.dashboardStats?.activityFeedItems.slice(0, props.limitFeed) : props.dashboardStats?.activityFeedItems

  const handleSearchChange = e => {
    const searchText = e.target.value
    setSearchText(searchText)
  }

  let filteredFeed
  // filter for activity in the last 7 days using moment
  if (searchText) {
    filteredFeed = feed.filter(activityItem => {
          return activityItem?.receiver?.displayName.toLowerCase().includes(searchText.toLowerCase())
          || activityItem?.actor?.displayName?.toLowerCase().includes(searchText.toLowerCase())
          || activityItem?.job?.employer?.name.toLowerCase().includes(searchText.toLowerCase())
      })
  } else {
    filteredFeed = feed
  }

  filteredFeed = filteredFeed.filter(activityItem => {
    return activityItem?.createdAt ? moment(activityItem?.createdAt).isAfter(moment().startOf('day').subtract(7,'day')) : false
  })

  return (
    <div className='flow-root px-4 py-5 mt-5 overflow-hidden bg-white rounded-lg shadow sm:p-6'>
      <div className={`${props.dashboardStats?.your_workerCount - props.dashboardStats?.your_confirmedWorkerCount > 0 ? '' : 'hidden'} rounded-md bg-accent-100 p-4 mb-6`}>
        <div className='flex'>
          <div className='flex-shrink-0'>
            <HiSparkles className='h-5 w-5 text-accent-600' aria-hidden='true' />
          </div>
          <div className='ml-3'>
            <h3 className='text-sm font-bold text-accent-700'>Action Required! </h3>
            <p className='mt-2 text-sm text-accent-700'>
              Good work! You have {props.dashboardStats?.your_confirmedWorkerCount} workers approved across {props.dashboardStats?.your_jobCount} jobs, but you still have more work to do!{' '}
            </p>
            <div className='mt-2 text-sm text-accent-700'>
              <ul role='list' className='list-disc space-y-1 pl-5'>
                <li>You have {props.dashboardStats?.your_workerCount - props.dashboardStats?.your_confirmedWorkerCount} workers who are not yet approved.</li>
                <li>You have {props.dashboardStats?.your_jobsWithSomeUsersNotRegistered} jobs assigned to you with workers who are not registered for Ruta</li>
                <li>You have {props.dashboardStats?.your_jobsWithSomeWorkersNotApproved} jobs assigned to you with unconfirmed workers</li>
              </ul>
            </div>
            <Button onClick={() => history.push('/facilitator/jobs')} fluid color='accent' className='mt-8 mb-2 animate-bounce'>
              Go to jobs
            </Button>
          </div>
        </div>
      </div>
      <div className='md:flex md:items-center md:justify-between mb-8'>
        {/* <div className='flex-1 min-w-0'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>Workers</h3>
        </div> */}
        <div className=''>
          <label htmlFor='search' className='sr-only'>
            Search
          </label>
          <div className='relative rounded-md shadow-sm w-96'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
              <HiOutlineMagnifyingGlass className='w-5 h-5 text-gray-400' aria-hidden='true' />
            </div>
            <input
              type='search'
              name='search'
              id='search'
              onChange={handleSearchChange}
              value={searchText}
              className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
              placeholder='Search for workers or facilitators'
            />
          </div>
          <p className='mt-2 text-sm text-gray-600'>Showing last 7 days</p>
        </div>
        
      </div>
      <ul role='list' className='-mb-8'>
        {filteredFeed.map((activityItem, activityItemIdx) => (
          <li key={activityItem.id}>
            <div className='relative pb-6'>
              {activityItemIdx !== filteredFeed.length - 1 ? <span className='absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200' aria-hidden='true' /> : null}
              <div className='relative flex items-start space-x-3'>
                {activityItem.type === 'FACILITATOR_INVITED_WORKER' ? (
                  <>
                    <div>
                      <div className='relative px-1'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-light ring-8 ring-white'>
                          <HiOutlineUserPlus className='h-5 w-5 text-stone-800' aria-hidden='true' />
                        </div>
                      </div>
                    </div>
                    <div className='min-w-0 flex-1 py-1.5'>
                      <div className='text-sm text-gray-500'>
                        <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.actor?.displayName}</a> invited worker <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.receiver?.displayName}</a> to Ruta
                        <span className='whitespace-nowrap'> {formatRelative(parseISO(activityItem?.createdAt), new Date())}</span>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'USER_REGISTERED' ? (
                  <>
                    <div>
                      <div className='relative px-1'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-light ring-8 ring-white'>
                          <HiOutlineArrowRightOnRectangle className='h-5 w-5 text-stone-800' aria-hidden='true' />
                        </div>
                      </div>
                    </div>
                    <div className='min-w-0 flex-1 py-1.5'>
                      <div className='text-sm text-gray-500'>
                        <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.actor?.displayName}</a> registered for Ruta
                        <span className='whitespace-nowrap'> {formatRelative(parseISO(activityItem?.createdAt), new Date())}</span>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'USER_LOGIN' ? (
                  <>
                    <div>
                      <div className='relative px-1'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-light ring-8 ring-white'>
                          <HiOutlineArrowRightOnRectangle className='h-5 w-5 text-stone-800' aria-hidden='true' />
                        </div>
                      </div>
                    </div>
                    <div className='min-w-0 flex-1 py-1.5'>
                      <div className='text-sm text-gray-500'>
                        <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.actor?.displayName}</a> logged into Ruta
                        <span className='whitespace-nowrap'> {formatRelative(parseISO(activityItem?.createdAt), new Date())}</span>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'JOB_FACILITATOR_APPROVED_WORKER' ? (
                  <>
                    <div>
                      <div className='relative px-1'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-light ring-8 ring-white'>
                          <HiOutlineHandThumbUp className='h-5 w-5 text-stone-800' aria-hidden='true' />
                        </div>
                      </div>
                    </div>
                    <div className='min-w-0 flex-1 py-1.5'>
                      <div className='text-sm text-gray-500'>
                        <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.actor?.displayName}</a> approved worker <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.receiver?.displayName}</a> for job{' '}
                        <a onClick={() => history.push(`/facilitator/jobs/${activityItem?.job?.id}`)} className='cursor-pointer font-medium text-gray-900'>
                          #{activityItem?.job?.number} for {activityItem?.job?.employer?.name}
                        </a>
                        <span className='whitespace-nowrap'> {formatRelative(parseISO(activityItem?.createdAt), new Date())}</span>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'JOB_ASSIGNED_TO_FACILITATOR' ? (
                  <>
                    <div>
                      <div className='relative px-1'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-light ring-8 ring-white'>
                          <HiOutlineClipboardDocumentCheck className='h-5 w-5 text-stone-800' aria-hidden='true' />
                        </div>
                      </div>
                    </div>
                    <div className='min-w-0 flex-1 py-1.5'>
                      <div className='text-sm text-gray-500'>
                        <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.actor?.displayName}</a> assigned <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.receiver?.displayName}</a> to job{' '}
                        <a onClick={() => history.push(`/facilitator/jobs/${activityItem?.job?.id}`)} className='cursor-pointer font-medium text-gray-900'>
                          #{activityItem?.job?.number} for {activityItem?.job?.employer?.name}
                        </a>
                        <span className='whitespace-nowrap'> {formatRelative(parseISO(activityItem?.createdAt), new Date())}</span>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'JOB_WORKER_CONFIRMED' ? (
                  <>
                    <div>
                      <div className='relative px-1'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-light ring-8 ring-white'>
                          <HiOutlineClipboardDocumentCheck className='h-5 w-5 text-stone-800' aria-hidden='true' />
                        </div>
                      </div>
                    </div>
                    <div className='min-w-0 flex-1 py-1.5'>
                      <div className='text-sm text-gray-500'>
                        <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.actor?.displayName}</a> confirmed information for job{' '}
                        <a onClick={() => history.push(`/facilitator/jobs/${activityItem?.job?.id}`)} className='cursor-pointer font-medium text-gray-900'>
                          #{activityItem?.job?.number} for {activityItem?.job?.employer?.name}
                        </a>
                        <span className='whitespace-nowrap'> {formatRelative(parseISO(activityItem?.createdAt), new Date())}</span>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'JOB_WORKER_IDENTITY_VERIFIED' ? (
                  <>
                    <div>
                      <div className='relative px-1'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-light ring-8 ring-white'>
                          <HiOutlineClipboardDocumentCheck className='h-5 w-5 text-stone-800' aria-hidden='true' />
                        </div>
                      </div>
                    </div>
                    <div className='min-w-0 flex-1 py-1.5'>
                      <div className='text-sm text-gray-500'>
                        <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.actor?.displayName}</a> veririfed their identity for job{' '}
                        <a onClick={() => history.push(`/facilitator/jobs/${activityItem?.job?.id}`)} className='cursor-pointer font-medium text-gray-900'>
                          #{activityItem?.job?.number} for {activityItem?.job?.employer?.name}
                        </a>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'JOB_FACILITATOR_ADDED_WORKER' ? (
                  <>
                    <div>
                      <div className='relative px-1'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-light ring-8 ring-white'>
                          <HiOutlineBriefcase className='h-5 w-5 text-stone-800' aria-hidden='true' />
                        </div>
                      </div>
                    </div>
                    <div className='min-w-0 flex-1 py-1.5'>
                      <div className='text-sm text-gray-500'>
                        <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.actor?.displayName}</a> added worker <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.receiver?.displayName}</a> to job{' '}
                        <a onClick={() => history.push(`/facilitator/jobs/${activityItem?.job?.id}`)} className='cursor-pointer font-medium text-gray-900'>
                          #{activityItem?.job?.number} for {activityItem?.job?.employer?.name}
                        </a>
                        <span className='whitespace-nowrap'> {formatRelative(parseISO(activityItem?.createdAt), new Date())}</span>
                      </div>
                    </div>
                  </>
                ) : activityItem.type === 'JOB_FACILITATOR_REMOVED_WORKER' ? (
                  <>
                    <div>
                      <div className='relative px-1'>
                        <div className='flex h-8 w-8 items-center justify-center rounded-full bg-light ring-8 ring-white'>
                          <HiOutlineBriefcase className='h-5 w-5 text-stone-800' aria-hidden='true' />
                        </div>
                      </div>
                    </div>
                    <div className='min-w-0 flex-1 py-1.5'>
                      <div className='text-sm text-gray-500'>
                        <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.actor?.displayName}</a> removed worker <a className='cursor-pointer font-medium text-gray-900'>{activityItem?.receiver?.displayName}</a> to job{' '}
                        <a onClick={() => history.push(`/facilitator/jobs/${activityItem?.job?.id}`)} className='cursor-pointer font-medium text-gray-900'>
                          #{activityItem?.job?.number} for {activityItem?.job?.employer?.name}
                        </a>
                        <span className='whitespace-nowrap'> {formatRelative(parseISO(activityItem?.createdAt), new Date())}</span>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default DashboardActivityFeed
