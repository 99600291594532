import React, { Component, useContext, useEffect } from 'react';
import { StateContext } from '../../../util/state.jsx';
import { withTranslation } from "react-i18next";
import { Modal } from '../../../components/Tailwind'
import { withRouter } from 'react-router';
import { uploadFile } from '../../../components/Firebase/index.js';
import { createDocument, patchDocument } from '../../../models/document.jsx';

import { patchTrip } from '../../../models/trip'

import TripForm from '../forms/TripForm/index.jsx';

import moment from 'moment'
import DocumentForm from '../forms/DocumentForm/index.jsx';
import { mimeToFileExtension } from '../../../util/constants.js';

class TripModal extends React.Component {
    static contextType = StateContext;
    static defaultProps = {}

    state = {
        waitingOnAPIResult: false,
        FORM_type: undefined,
        FORM_document: undefined,
        FORM_date: null,
    }

    handleChange = async (event) => this.setState({ [event.target.name]: event.target.value })
    handleDatePickerChange = async (date) => this.setState({ FORM_date: moment(`${date.format('YYYY-MM-DD')}T20:00:00Z`) }) // note we force 8pm UTC that day
    handleFileUploadChange = async (event) => this.setState({ FORM_document: event.target.files[0] })

    componentDidMount = () => {
        if (this.document) {
            this.setState({
                FORM_type: this.props.documentType,
                FORM_document: this.props.document?.fields?.document,
                FORM_initialDocumentURL: this.props.initialDocumentUrl ? this.props.initialDocumentUrl : this.props.document?.fields?.document?.url,
                FORM_date: this.props.document?.fields?.date ? moment.utc(this.props.document?.fields?.date) : null,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open == true && prevProps.open == false) {
            if (this.props.document) {
                this.setState({
                    FORM_type: this.props.documentType,
                    FORM_document: this.props.document?.fields?.document,
                    FORM_initialDocumentURL: this.props.initialDocumentUrl ? this.props.initialDocumentUrl : this.props.document?.fields?.document?.url,
                    FORM_date: this.props.document?.fields?.date ? moment.utc(this.props.document?.fields?.date) : null,
                })
            } else {
                this.setState( {
                    FORM_date: moment(),
                    FORM_document: this.props.document?.fields?.document,
                    FORM_initialDocumentURL: this.props.initialDocumentUrl ? this.props.initialDocumentUrl : this.props.document?.fields?.document?.url,
                    FORM_type: this.props.documentType
                })
            }
        }
    }

    handleCreateDocument = async () => {
        const [{ firebaseUser }, dispatch] = this.context;
        const { FORM_type, FORM_document, FORM_date } = this.state

        this.setState({ waitingOnAPIResult: true })

        let fileURL = undefined
        if (this.state.FORM_document &&  (this.state.FORM_document instanceof File || this.state.FORM_document instanceof Blob)) {
            try {
                fileURL = await uploadFile(this.props.trip.fields?.UserId, FORM_document)
                console.log(fileURL)
            } catch (error) {
                console.log(error)
            }
        }

        try {
            let body = {
                type: this.state.FORM_type,
                date: (this.state.FORM_date) ? this.state.FORM_date : null,
                document: fileURL ? fileURL : null
            }
            if (this.props.document) {
                let document = await patchDocument(firebaseUser, this.props.trip.id, this.props.document.id, body)
            } else {
                let document = await createDocument(firebaseUser, this.props.trip.id, body)
            }
            this.closeAndResetModal()
        } catch (error) {
            console.log(error)
            this.setState({ waitingOnAPIResult: false })
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `Failed to create document. ${error.message} ${error.details}`, waitingOnAPIResult: false },
                showNotification: true
            })
            return
        }
    }

    closeAndResetModal = async () => {
        const [{ employerWorkersAndMeta }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

        this.setState({
            waitingOnAPIResult: false,
            FORM_type: '',
            FORM_document: '',
            FORM_date: null,
        })
        dispatch({
            type: 'showNotification',
            notificationDetails: { title: 'Success!', content: `Uploaded document!`, success: true },
            showNotification: true
        })
        this.props.pullLatestAfterUpdate()
        this.props.setOpen(false)
    }

    render() {
        const [{ user }, dispatch] = this.context
        const { t } = this.props // translations
        const { waitingOnAPIResult, apiErrorMessage } = this.state

        return (
            <Modal
                title={this.props.document ? 'Modify Document' : 'Upload Document'}
                subtitle='Upload a new document by selecting a picture of it from your computer or phone.'
                buttonLabel='Submit'
                open={this.props.open}
                setOpen={this.props.setOpen}
                actionOnClick={this.handleCreateDocument}
            >
                <DocumentForm
                    FORM_date={this.state.FORM_date}
                    FORM_type={this.state.FORM_type}
                    FORM_document={this.state.FORM_document}
                    trip={this.props.trip}
                    initialDocumentUrl={this.state.FORM_initialDocumentURL}
                    handleChange={this.handleChange}
                    handleDatePickerChange={this.handleDatePickerChange}
                    handleFileUploadChange={this.handleFileUploadChange}
                    onSubmit={this.handleCreateDocument}
                />
            </Modal>
        );
    }
}

export default withRouter(withTranslation()(TripModal))