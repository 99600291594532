import React from 'react';
import { withTranslation } from "react-i18next";
import { SingleDatePicker } from 'react-dates';
import ReactCrop from 'react-image-crop';
import { dataURItoBlob, makeid, getCroppedImg } from '../../../../util/helpers'
import { CheckIcon, PhotographIcon, PlusIcon } from '@heroicons/react/outline';
import { documentTypes } from '../../../../util/constants';
import ImageCropper from '../../../../components/ImageCropper';
import { uploadFile } from '../../../../components/Firebase';
import Dropzone from 'react-dropzone'

class DocumentForm extends React.Component {
    constructor(props) {
        super(props);
        this.dropzoneRef = React.createRef()
    }

    state = {
        focused: false,
        dragAndDroppedFileUrl: null,
    }

    onDrop = async (acceptedFiles) => {
        // Assume only 1 for now
        if (this.props.initialDocumentUrl) return
        const file = acceptedFiles[0]
        if (!file) return
        if (this.dropzoneRef.isDragReject) return
        const { trip } = this.props

        let fileURL = undefined
        if (file instanceof File) {
            try {
                fileURL = await uploadFile(trip.fields?.UserId, file)
                console.log(fileURL)
            } catch (error) {
                console.log(error)
            }
        }
        if (!fileURL) return

        console.log(file, fileURL)
        if (file.type === 'application/pdf') {
            const event = { target: { name: 'FORM_document', value: file } }
            this.props.handleChange(event)
        }
        this.setState({
            dragAndDroppedFileUrl: fileURL,
        })
    }


    render() {
        const { t } = this.props // translations
        return (
            <Dropzone
                onDrop={this.onDrop}
                ref={this.dropzoneRef}
                noClick
                noKeyboard
                noDragEventsBubbling
                accept={['image/*', 'application/pdf']}
            >
                {({ getRootProps, getInputProps, isDragAccept }) =>
                (<div className="relative" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className={`${isDragAccept && !this.props.initialDocumentUrl ? '' : 'hidden'} z-50 text-lg text-gray-900 absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2`}>Drop your file here</p>
                    <div className={`${isDragAccept && !this.props.initialDocumentUrl ? '' : 'hidden'} absolute left-0 top-0 w-[105%] h-[110%] bg-sky-400 z-50 rounded-lg opacity-30 -m-3 border-dashed border-2 border-slate-900`} />

                    <form className="space-y-8 divide-y divide-gray-200" style={{ minHeight: '200px' }} onSubmit={this.props.onSubmit} ref={this.formRef} >
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div>
                                <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-6">
                                        <label htmlFor="FORM_departureDate" className="block text-sm font-medium text-gray-700">
                                            Document Date
                                        </label>
                                        <div className="mt-1">
                                            <SingleDatePicker
                                                id='FORM_date'
                                                orientation="vertical"
                                                date={this.props.FORM_date}
                                                numberOfMonths={2}
                                                onDateChange={date => this.props.handleDatePickerChange(date)}
                                                isOutsideRange={day => { return false }}
                                                focused={this.state.focused}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-span-4">
                                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                            Type
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="FORM_type"
                                                name="FORM_type"
                                                value={this.props.FORM_type}
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                                onChange={this.props.handleChange}
                                            >
                                                {documentTypes.map(option => (
                                                    <option key={option?.key} value={option?.value}>{option?.text}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <ImageCropper
                                        initialDocumentUrl={this.props.initialDocumentUrl ? this.props.initialDocumentUrl : this.state.dragAndDroppedFileUrl}
                                        formInputName='FORM_document'
                                        aspectRatio={null}
                                        initialCrop={{
                                            unit: '%', // default, can be 'px' or '%'
                                            x: 0,
                                            y: 0,
                                            width: this.props.FORM_document ? 100 : 80,
                                            height: this.props.FORM_document ? 100 : 80
                                        }}
                                        handleChange={this.props.handleChange}
                                    />



                                </div>

                            </div>
                        </div>
                        <button className={'hidden'} type="submit" />
                    </form>
                </div>
                )}
            </Dropzone>


        );
    }
}

export default withTranslation()(DocumentForm)