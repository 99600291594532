import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition, Tab } from '@headlessui/react'
import { HiArrowLeft, HiArrowRight, HiEllipsisVertical, HiXMark } from 'react-icons/hi2'
import { classNames } from '../../../../../util/helpers'
import WorkerSection from '../../Employers/WorkerDetail/workerSection'
import AvatarWithViewModal from '../../../components/AvatarWithViewModal'
import AvatarImage from '../../../../../components/AvatarImage'
import NotesSection from '../../Employers/WorkerDetail/notesSection'
import { Button } from '../../../../../components/Tailwind'

const tabs = [
  { name: 'Profile', index: 0 },
  { name: 'Notes', index: 1 },
  // { name: 'Chat', index: 2 },
]

const WorkerSlideover = props => {
  const { open, setOpen, worker, pullLatestAfterUpdate } = props
  const [currentTab, setcurrentTab] = useState(0)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={() => setOpen({ open: false, worker: undefined })}>
        <Transition.Child as={Fragment} enter='ease-in-out duration-200' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in-out duration-100' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
        </Transition.Child>

        <div className='fixed inset-0' />

        <div className='fixed inset-0 overflow-hidden'>
          <div className='absolute inset-0 overflow-hidden'>
            <div className='pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16'>
              <Transition.Child
                as={Fragment}
                enter='transform transition ease-in-out duration-200 sm:duration-300'
                enterFrom='translate-x-full'
                enterTo='translate-x-0'
                leave='transform transition ease-in-out duration-200 sm:duration-300'
                leaveFrom='translate-x-0'
                leaveTo='translate-x-full'
              >
                <Dialog.Panel className='pointer-events-auto w-screen max-w-4xl'>
                  <div className='flex h-full flex-col overflow-y-scroll bg-white shadow-xl'>
                    <div className='px-4 py-6 sm:px-6'>
                      <div className='flex items-start justify-between'>
                        <Dialog.Title className='text-lg font-medium text-gray-900'>Worker Profile</Dialog.Title>
                        <div className='ml-3 flex h-7 items-center space-x-4'>
                          <Button color='white' onClick={() => props.changeSlideoverWorker(-1)}>
                          <HiArrowLeft className='mr-2 w-5 h-5' />
                            Previous Worker
                          </Button>
                          <Button color='white' onClick={() => props.changeSlideoverWorker(1)}>
                            Next Worker
                            <HiArrowRight className='ml-2 w-5 h-5' />
                          </Button>
                          <button type='button' className='rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500' onClick={() => setOpen({ open: false, worker: undefined })}>
                            <span className='sr-only'>Close panel</span>
                            <HiXMark className='h-6 w-6' aria-hidden='true' />
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* Main */}
                    <div className='divide-y divide-gray-200'>
                      <div className='pb-6'>
                        <div className='h-24 bg-stone-700 sm:h-20 lg:h-28' />
                        <div className='lg:-mt-15 -mt-12 flow-root px-4 sm:-mt-8 sm:flex sm:items-end sm:px-6'>
                          <div>
                            <div className='-m-1 flex'>
                              <div className='inline-flex overflow-hidden rounded-lg border-4 border-white'>
                                {worker?.fields?.photo ? (
                                  <img className='h-24 w-24 flex-shrink-0 sm:h-24 sm:w-24' src={worker?.fields?.photo?.url} alt='' />
                                ) : (
                                  <span className={`inline-block h-24 w-24 flex-shrink-0 sm:h-24 sm:w-24 overflow-hidden bg-gray-100`}>
                                    <svg className='h-full w-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                                      <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                                    </svg>
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className='mt-6 sm:ml-6 sm:flex-1'>
                            <div>
                              <div className='flex items-center'>
                                <h3 className='text-xl font-bold text-gray-900 sm:text-2xl'>{worker?.fields?.displayName}</h3>
                                <span className='ml-2.5 inline-block h-2 w-2 flex-shrink-0 rounded-full bg-green-400'>
                                  <span className='sr-only'>Online</span>
                                </span>
                              </div>
                              <p className='text-sm text-gray-500'>{worker?.fields?.passportNumber}</p>
                            </div>
                            {/* <div className='mt-5 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3'>
                              <button
                                type='button'
                                className='inline-flex w-full flex-shrink-0 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:flex-1'
                              >
                                Message
                              </button>
                              <button
                                type='button'
                                className='inline-flex w-full flex-1 items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                              >
                                Call
                              </button>
                              <div className='ml-3 inline-flex sm:ml-0'>
                                <Menu as='div' className='relative inline-block text-left'>
                                  <Menu.Button className='inline-flex items-center rounded-md border border-gray-300 bg-white p-2 text-sm font-medium text-gray-400 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'>
                                    <span className='sr-only'>Open options menu</span>
                                    <HiEllipsisVertical className='h-5 w-5' aria-hidden='true' />
                                  </Menu.Button>
                                  <Transition
                                    as={Fragment}
                                    enter='transition ease-out duration-100'
                                    enterFrom='transform opacity-0 scale-95'
                                    enterTo='transform opacity-100 scale-100'
                                    leave='transition ease-in duration-75'
                                    leaveFrom='transform opacity-100 scale-100'
                                    leaveTo='transform opacity-0 scale-95'
                                  >
                                    <Menu.Items className='absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                      <div className='py-1'>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a href='#' className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                              View profile
                                            </a>
                                          )}
                                        </Menu.Item>
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a href='#' className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                              Copy profile link
                                            </a>
                                          )}
                                        </Menu.Item>
                                      </div>
                                    </Menu.Items>
                                  </Transition>
                                </Menu>
                              </div>
                            </div> */}
                          </div>
                        </div>
                        <Tab.Group defaultIndex={currentTab} onChange={setcurrentTab}>
                          <div className='mt-6 sm:mt-2 2xl:mt-5'>
                            <div className='border-b border-gray-200'>
                              <div className='px-4 mx-auto sm:px-6'>
                                <Tab.List className='flex -mb-px space-x-8'>
                                  {tabs.map(tab => (
                                    <Tab
                                      key={tab.name}
                                      className={classNames(
                                        tab.index === currentTab ? 'border-primary text-gray-900' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                                        'whitespace-nowrap py-4 px-1 border-b-4 font-medium text-sm'
                                      )}
                                      aria-current={tab.index === currentTab ? 'page' : undefined}
                                    >
                                      {tab.name}
                                    </Tab>
                                  ))}
                                </Tab.List>
                              </div>
                            </div>
                          </div>

                          <Tab.Panels>
                            <Tab.Panel>
                              {/* Worker Info */}
                              <WorkerSection readonly={false} worker={worker} pullLatestAfterUpdate={pullLatestAfterUpdate} />
                            </Tab.Panel>
                            <Tab.Panel>
                              <NotesSection worker={worker} pullLatestAfterUpdate={pullLatestAfterUpdate} />
                            </Tab.Panel>

                            <Tab.Panel>Content 3</Tab.Panel>
                          </Tab.Panels>
                        </Tab.Group>
                      </div>
                      {/* <div className='px-4 py-5 sm:px-0 sm:py-0'>
                        <dl className='space-y-8 sm:space-y-0 sm:divide-y sm:divide-gray-200'>
                          <div className='sm:flex sm:px-6 sm:py-5'>
                            <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48'>Bio</dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6'>
                              <p>Enim feugiat ut ipsum, neque ut. Tristique mi id elementum praesent. Gravida in tempus feugiat netus enim aliquet a, quam scelerisque. Dictumst in convallis nec in bibendum aenean arcu.</p>
                            </dd>
                          </div>
                          <div className='sm:flex sm:px-6 sm:py-5'>
                            <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48'>Location</dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6'>New York, NY, USA</dd>
                          </div>
                          <div className='sm:flex sm:px-6 sm:py-5'>
                            <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48'>Website</dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6'>ashleyporter.com</dd>
                          </div>
                          <div className='sm:flex sm:px-6 sm:py-5'>
                            <dt className='text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0 lg:w-48'>Birthday</dt>
                            <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 sm:ml-6'>
                              <time dateTime='1982-06-23'>June 23, 1982</time>
                            </dd>
                          </div>
                        </dl>
                      </div> */}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default WorkerSlideover
