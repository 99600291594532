import React from 'react';
import { StateContext } from '../../../../util/state.jsx';
import { withTranslation } from "react-i18next";
import { Modal } from '../../../../components/Tailwind'
import { withRouter } from 'react-router';
import { approveWorkerForTravel } from '../../../../models/organization.jsx'
import ApproveForTravelForm from '../../forms/ApproveForTravelForm/index.jsx';


class ApproveForTravelModal extends React.Component {
    static contextType = StateContext;
    static defaultProps = {}

    state = {
        waitingOnAPIResult: false,
    }

    handleChange = async (event) => this.setState({ [event.target.name]: event.target.value })

    handleApproveUser = async (e) => {
        e.preventDefault();
        const [{ firebaseUser, organization, user }, dispatch] = this.context;
        const { workerId } = this.props.match.params
        this.setState({ waitingOnAPIResult: true })

        try {
            await approveWorkerForTravel(firebaseUser, organization.id, workerId, user?.fields?.displayName)
            this.closeAndResetModal()
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `Failed to approve. ${error.message} ${error.details}`, waitingOnAPIResult: false },
                showNotification: true
            })
            return
        }
    }

    closeAndResetModal = async () => {
        const [{ employerWorkersAndMeta }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

        this.setState({
            waitingOnAPIResult: false,
        })
        dispatch({
            type: 'showNotification',
            notificationDetails: { title: 'Success!', content: `Approved for travel.`, success: true },
            showNotification: true
        })
        // this.props.listWorkersForOrganizationAndEmployer(employerId, filtersAndPagination)
        this.props.pullLatestAfterUpdate()
        this.props.setOpen(false)
    }

    render() {
        const [{ user }, dispatch] = this.context
        const { worker } = this.props
        const { t } = this.props // translations
        const { waitingOnAPIResult, apiErrorMessage } = this.state

        return (
            <Modal
                title={`Approve ${worker?.fields?.firstName} for travel`}
                subtitle='Once this worker has completed all requirements for travel, approve them. Your signature as the facilitator is required. '
                buttonLabel='Approve'
                warning={true}
                open={this.props.open}
                setOpen={this.props.setOpen}
                actionOnClick={this.handleApproveUser}
            >
                <ApproveForTravelForm
                    approvingUser={user}
                    handleChange={this.handleChange}
                    onSubmit={this.handleApproveUser}
                />
            </Modal>
        );
    }
}

export default withRouter(withTranslation()(ApproveForTravelModal))