import React, { useState, useContext } from 'react'
import { Modal } from '../../../../components/Tailwind/index.jsx'
import AssignFacilitatorForm from '../../forms/AssignFacilitatorForm/index.jsx'
import { StateContext } from '../../../../util/state.jsx'
import { createJob, patchJob } from '../../../../models/organization.jsx'
import { useEffect } from 'react'
import AddJobForm from '../../forms/AddJobForm/index.jsx'

const AddJobModal = props => {
  const [{ organization, employersAndPagination, analytics }, dispatch] = useContext(StateContext)
  const { open, job} = props
  const [waitingOnAPIResult, setWaitingOnAPIResult] = useState(false)
  const [formValues, setFormValues] = useState({
    FORM_assignedFacilitatorUserId: null,
    FORM_employerId: null,
    FORM_season: '2023',
    FORM_petitionId: null,
    FORM_externalContractTemplateReferenceId: null
  })
  const employers = employersAndPagination?.result.sort((a,b) => a?.fields?.name.localeCompare(b?.fields?.name))

  useEffect(() => {
    if (job) {
      setFormValues({ ...formValues, 
        FORM_assignedFacilitatorUserId: job?.fields?.assignedFacilitatorUserId, 
        FORM_employerId: job?.fields?.employerId,
        FORM_season: job?.fields?.season,
        FORM_petitionId: job?.fields?.referenceId,
        FORM_externalContractTemplateReferenceId: job?.fields?.externalContractTemplateReferenceId,
      })
    } else {
      setFormValues({ ...formValues, 
        FORM_assignedFacilitatorUserId: null, 
        FORM_employerId: null ,
        FORM_season: '2023',
        FORM_petitionId: null,
        FORM_externalContractTemplateReferenceId: null
      })
    }
  }, [open])

  const handleChange = async (name, value) => setFormValues({ ...formValues, [name]: value })

  const close = async () => {
    setFormValues({
      FORM_assignedFacilitatorUserId: null,
      FORM_employerId: null ,
      FORM_season: '2023',
      FORM_petitionId: null,
      FORM_externalContractTemplateReferenceId: null
    })
    props.setOpen(false)
  }

  const handlePatchJob = async e => {
    e.preventDefault()
    setWaitingOnAPIResult(true)
    try {

      if (job) {
        await patchJob(organization.id, job.id, {
          assignedFacilitatorUserId: formValues?.FORM_assignedFacilitatorUserId,
          employerId: formValues?.FORM_employerId,
          season: formValues?.FORM_season,
          referenceId: formValues?.FORM_petitionId,
          externalContractTemplateReferenceId: formValues?.FORM_externalContractTemplateReferenceId,
        })  
      } else {
        await createJob(organization.id, {
          assignedFacilitatorUserId: formValues?.FORM_assignedFacilitatorUserId,
          employerId: formValues?.FORM_employerId,
          season: formValues?.FORM_season,
          referenceId: formValues?.FORM_petitionId,
          externalContractTemplateReferenceId: formValues?.FORM_externalContractTemplateReferenceId,
        })  
      }
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Success!', content: `Updated job.`, success: true },
        showNotification: true,
      })
      await props.listJobs(null)
      setWaitingOnAPIResult(false)
      if (analytics) analytics.track('Added Job')
      close()
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to update job.`, content: error.message, waitingOnAPIResult: false },
        showNotification: true,
      })
      setWaitingOnAPIResult(false)
      return
    }
  }

  return (
    <Modal
      title={job ? 'Edit Job' : 'Add Job'}
      subtitle='Add a new job to represent a new season of work for each employer. Each job has workers assigned to it and a facilitator who manages it. '
      buttonLabel={job ? 'Save' : 'Add'}
      open={props.open}
      setOpen={close} // this only closes the modal
      actionOnClick={handlePatchJob}
      loading={waitingOnAPIResult}
    >
      <AddJobForm 
        FORM_assignedFacilitatorUserId={formValues?.FORM_assignedFacilitatorUserId} 
        FORM_employerId={formValues?.FORM_employerId}
        FORM_season={formValues?.FORM_season}
        FORM_petitionId={formValues?.FORM_petitionId}
        FORM_externalContractTemplateReferenceId={formValues?.FORM_externalContractTemplateReferenceId}
        people={organization?.fields?.members} 
        employers={employers}
        handleChange={handleChange} 
        onSubmit={handlePatchJob} 
      />
    </Modal>
  )
}

export default AddJobModal
