import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Badge, Button } from '../../../../components/Tailwind'
import Container from '../../../../components/Tailwind/Container'
import { classNames } from '../../../../util/helpers'
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, SearchIcon } from '@heroicons/react/outline'
import { FilterIcon } from '@heroicons/react/solid'
import { StateContext } from '../../../../util/state'
import { useHistory } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import JobDropdownMenu from './jobDropdownMenu'
import AddJobModal from '../../modals/AddJobModal'
import { HiCheck, HiCheckCircle, HiOutlineCheckCircle, HiOutlineMagnifyingGlass, HiOutlineMinusCircle, HiOutlineUserCircle } from 'react-icons/hi2'
import moment from 'moment'
import EmployerModal from '../../modals/employerModal'
import { listJobs } from '../../../../models/organization'
import CommandPaletteSearch from '../../../../components/Tailwind/CommandPaletteSearch'

const JobsPage = props => {
  const [{ user, isLoadingJobs, jobsAndMeta }, dispatch] = useContext(StateContext)
  const history = useHistory()
  const location = useLocation()
  const [addJobModalIsOpen, setAddJobModalIsOpen] = useState(false)
  const [addEmployerModalIsOpen, setAddEmployerModalIsOpen] = useState(false)
  const [showSearch, setShowSearch] = useState(false)

  // Pagination
  const [filtersAndPagination, setFiltersAndPagination] = useState({
    pageSize: 50,
    currentPage: 1,
    // sortBy: '-createdAt',
  })
  const firstRecord = filtersAndPagination?.currentPage  * filtersAndPagination?.pageSize - filtersAndPagination?.pageSize +1
  const lastRecord = firstRecord+filtersAndPagination?.pageSize-1

  // Filters
  const [searchText, setSearchText] = useState()
  const [filter_myJobs, setFilter_my_jobs] = useState(false)

  useEffect(() => {
    const refreshPageData = async () => {
      dispatch({ type: 'setIsLoadingJobs', isLoadingJobs: true })
      console.log('getting jobs')
      try {
        let jobsAndMeta = await listJobs(user.fields?.organizationId, filtersAndPagination)
        dispatch({
          type: 'setJobsAndMeta',
          isLoadingJobs: false,
          jobsAndMeta: jobsAndMeta,
        })
      } catch (error) {
        dispatch({
          type: 'setJobsAndMeta',
          isLoadingJobs: false,
          jobsAndMeta: null,
        })
      }
    }
    refreshPageData()
  }, [filtersAndPagination])

  const handleSearchChange = e => {
    const searchText = e.target.value
    setSearchText(searchText)
  }

  let filteredJobs
  const tableRows = []
  if (!isLoadingJobs) {
    let filteredJobs = searchText
      ? jobsAndMeta.result.filter(j => {
          return j.fields?.employer
            ? j.fields?.employer?.name.toLowerCase().includes(searchText.toLowerCase())
            : false || j.fields?.referenceId
            ? j.fields?.referenceId.toLowerCase().includes(searchText.toLowerCase())
            : false || j.fields?.number
            ? `${j.fields?.number}`.includes(searchText)
            : false || j.fields?.season
            ? j.fields?.season.toLowerCase().includes(searchText.toLowerCase())
            : false
        })
      : jobsAndMeta?.result

    // Sort by date
    filteredJobs.sort((a, b) => (new Date(a.createdAt) > new Date(b.createdAt) ? -1 : 1))

    if (filter_myJobs) {
      filteredJobs = filteredJobs.filter(j => j?.fields?.assignedFacilitatorUserId === user.id)
    }
    filteredJobs?.forEach((job, i) => {
      let workersSigned = job?.fields?.stats?.totalSigned
      let progress = workersSigned > 0 ? (workersSigned / job?.fields?.stats?.totalWorkers) * 100 : 0

      tableRows.push(
        <tr key={job.id} className='cursor-pointer hover:bg-gray-50' onClick={() => history.push(`${location.pathname}/${job.id}`)}>
          <td className='py-4 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-6'>#{job?.fields?.number}</td>
          <td className='px-2 py-4 text-sm font-medium text-gray-900 whitespace-nowrap'>
            <p>{job?.fields?.employer?.fields?.name}</p>
            <p className='text-gray-400'>{job?.fields?.referenceId}</p>
          </td>
          <td className='px-2 py-4 whitespace-nowrap'>
            <div className='flex items-center'>
              <div className='flex-shrink-0 w-8 h-8'>
                {job?.fields?.assignedFacilitator?.fields?.photo ? (
                  <img className='object-cover w-8 h-8 rounded-full' src={job?.fields?.assignedFacilitator?.fields?.photo.url} alt='' />
                ) : (
                  <span className='inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full'>
                    <svg className='w-full h-full text-gray-300' fill='currentColor' viewBox='0 0 24 24'>
                      <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
                    </svg>
                  </span>
                )}
              </div>
              <div className='ml-4'>
                <div className='text-sm font-medium text-gray-900'>{job?.fields?.assignedFacilitator?.fields ? job?.fields?.assignedFacilitator?.fields?.displayName : <Badge color='gray' label='Not assigned' />}</div>
              </div>
            </div>
          </td>
          {/* <td className='px-2 py-4 text-sm text-gray-900 whitespace-nowrap'>{job?.fields?.workers.length}</td> */}
          <td className='px-2 py-4 text-sm text-gray-900 whitespace-nowrap'>
            <div className='flex items-center justify-between my-1'>
              <p className='text-xs text-stone-500'>
                <b className='mr-1'>
                  {workersSigned}/{job?.fields?.workers.length}
                </b>
                signed
              </p>
            </div>
            <div className='w-24 h-2 bg-gray-200 rounded-full'>
              <div className={`h-full text-center w-full text-xs text-secondary bg-primary rounded-full`} style={{ width: `${progress}%` }}></div>
            </div>
          </td>
          <td className='px-2 py-4 text-sm font-medium text-gray-500 whitespace-nowrap'>
            {job?.fields?.externalContractTemplateReferenceId ? <HiCheck className='w-5 h-5 mr-2 text-green-500' /> : <HiOutlineMinusCircle className='w-5 h-5 mr-2 text-gray-700' />}
          </td>
          {/* <td className='px-2 py-4 text-sm text-gray-500 whitespace-nowrap'>{job?.fields?.season}</td> */}
          <td className='px-2 py-4 text-sm text-gray-500 whitespace-nowrap'>
            <p>{moment(job?.createdAt).format('LL')}</p>
            <p className='text-xs text-gray-400'>{job?.fields?.season} season</p>
          </td>
          <td className='relative py-4 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6' onClick={e => e.stopPropagation()}>
            <JobDropdownMenu job={job} listJobs={props.listJobs} />
          </td>
        </tr>
      )
    })
  } else {
    for (let index = 0; index < 5; index++) {
      tableRows.push(
        <tr>
          <td colspan='8'>
            <div class='flex animate-pulse space-x-4 p-4'>
              <div class='h-10 w-10 rounded-full bg-slate-200'></div>
              <div class='flex-1 space-y-4 py-1'>
                <div class='h-2 rounded bg-slate-200'></div>
                <div class='space-y-3'>
                  <div class='grid grid-cols-3 gap-4'>
                    <div class='col-span-2 h-2 rounded bg-slate-200'></div>
                    <div class='col-span-1 h-2 rounded bg-slate-200'></div>
                  </div>
                </div>
              </div>
            </div>
          </td>
        </tr>
      )
    }
  }


  return (
    <div className=''>
      <Container className='bg-white shadow'>
        <div className='w-full md:flex md:items-center md:justify-between'>
          <div className='flex-1 min-w-0 py-1.5'>
            <h2 className='text-xl font-medium text-gray-900'>Jobs</h2>
          </div>
          <div className='flex mt-4 space-x-4 md:mt-0 md:ml-4'>
            <Button color='white' onClick={() => setAddEmployerModalIsOpen(true)}>
              Add Employer
            </Button>
            <EmployerModal open={addEmployerModalIsOpen} setOpen={setAddEmployerModalIsOpen} listEmployers={props.listEmployers} />
            <Button onClick={() => setAddJobModalIsOpen(true)}>Add Job</Button>
            <AddJobModal open={addJobModalIsOpen} setOpen={setAddJobModalIsOpen} listJobs={props.listJobs} />
          </div>
        </div>
      </Container>
      <Container>
        <div className='grid grid-cols-12 space-x-6'>
          <div className='col-span-12 xl:col-span-9'>
            <div className='md:flex md:items-center md:justify-between'>
              <div className='flex-1 min-w-0'>
                <h3 className='text-lg font-medium leading-6 text-gray-900'>All</h3>
              </div>
              <div className='flex mt-4 md:mt-0 md:ml-4'>
                {/* <Button>
              Add Jobs
            </Button> */}
              </div>
            </div>

            <form className='flex justify-between w-full mt-3 space-x-4' action='#'>
              <div className='flex'>
              <div className='flex'>
              <Button color='white' onClick={() => setShowSearch(true)}><HiOutlineMagnifyingGlass className='w-5 h-5 mr-2' /> Search</Button>
                  <CommandPaletteSearch open={showSearch} setOpen={setShowSearch} />
              </div>
              <div className='flex items-center pl-4 space-x-4'>
                <button className='inline-flex justify-center px-3.5 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-300'>
                  <FilterIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                  <span className='sr-only'>Search</span>
                </button>

                <span className='inline-flex rounded-md shadow-sm isolate'>
                  <button
                    type='button'
                    onClick={() => setFilter_my_jobs(true)}
                    className={classNames(
                      filter_myJobs ? 'bg-gray-100 text-gray-700 font-bold ' : 'bg-white text-gray-700',
                      'relative inline-flex items-center rounded-l-md border border-gray-300 px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-10 focus:border-primary-300 focus:outline-none focus:ring-1 focus:ring-primary-300'
                    )}
                  >
                    <HiOutlineUserCircle className='w-5 h-5 mr-2 text-gray-500' />
                    My Jobs
                  </button>
                  <button
                    type='button'
                    onClick={() => setFilter_my_jobs(false)}
                    className={classNames(
                      !filter_myJobs ? 'bg-gray-100 text-gray-700 font-bold ' : 'bg-white text-gray-700',
                      'relative inline-flex items-center rounded-r-md -ml-px border border-gray-300 px-4 py-2 text-sm font-medium hover:bg-gray-50 focus:z-10 focus:border-primary-300 focus:outline-none focus:ring-1 focus:ring-primary-300'
                    )}
                  >
                    All Jobs
                  </button>
                </span>
              </div>

              </div>
              <nav className='flex items-center justify-end' aria-label='Pagination'>
                <div className='hidden sm:block'>
                  <p className='mr-4 text-sm text-gray-700'>
                    Showing <span className='font-medium'>{filtersAndPagination?.currentPage}</span> of <span className='font-medium'>{jobsAndMeta?.meta?.pageCount} pages</span>
                  </p>
                </div>
                <div className='flex justify-between flex-1 space-x-4 sm:justify-end'>
                  <Button color='white' disabled={filtersAndPagination?.currentPage === 1} onClick={() => setFiltersAndPagination({...filtersAndPagination, currentPage: filtersAndPagination?.currentPage-1})}>
                    Previous
                  </Button>
                  <Button color='white' disabled={filtersAndPagination?.currentPage === jobsAndMeta?.meta?.pageCount} onClick={() => setFiltersAndPagination({...filtersAndPagination, currentPage: filtersAndPagination?.currentPage+1})}>
                    Next
                  </Button>
                </div>
              </nav>
            </form>

            <div className='flex flex-col mt-3'>
              <div className='-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8'>
                <div className='inline-block min-w-full py-2 align-middle md:px-6 lg:px-8'>
                  <div className='rounded-sm shadow ring-1 ring-black ring-opacity-5'>
                    <table className='min-w-full divide-y divide-gray-300'>
                      <thead className='bg-gray-50'>
                        <tr>
                          <th scope='col' className='whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6'>
                            Number
                          </th>
                          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'>
                            Employer
                          </th>
                          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'>
                            Facilitator
                          </th>
                          {/* <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Workers
                      </th> */}
                          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'>
                            Status
                          </th>
                          <th scope='col' className='whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                            Contract
                          </th>
                          {/* <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'>
                        Season
                      </th> */}
                          <th scope='col' className='whitespace-nowrap px-2 py-3.5 text-left text-sm font-semibold text-gray-900'>
                            Started On
                          </th>
                          <th scope='col' className='relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6'>
                            <span className='sr-only'>Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className='bg-white divide-y divide-gray-200'>{tableRows}</tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='hidden col-span-3 xl:block'>
            <div>
              <h3 className='pt-10 text-base font-semibold leading-6 text-gray-900'>Summary</h3>
              <dl className='grid grid-cols-1 gap-5 mt-5'>
                <div className='px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6'>
                  <dt className='text-sm font-medium text-gray-500 truncate'>Workers on Jobs</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                    {jobsAndMeta?.meta?.totalWorkersonJobs}
                  </dd>
                </div>
                <div className='px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6'>
                  <dt className='text-sm font-medium text-gray-500 truncate'>Total With Contracts</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                    {jobsAndMeta?.meta?.countWorkersWithContracts }
                  </dd>
                </div>
                {/* <div className='px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6'>
                  <dt className='text-sm font-medium text-gray-500 truncate'>Total Not Signed</dt>
                  <dd className='mt-1 text-3xl font-semibold tracking-tight text-gray-900'>
                    {jobsAndMeta?.meta?.countWorkersWithContractsNotSigned}
                  </dd>
                </div> */}
              </dl>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default JobsPage
