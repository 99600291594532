import React from 'react'
import { StateContext } from '../../../util/state.jsx'
import { createMemberForEmployer, createEmployer, patchOrganization } from '../../../models/organization.jsx'
import { Button, Modal } from '../../../components/Tailwind/index.jsx'
import EmployerForm from '../forms/EmployerForm'
import moment from 'moment'
import { XIcon } from '@heroicons/react/solid'

export default class EmployerModal extends React.Component {
  static contextType = StateContext
  static defaultProps = {}

  state = {
    waitingOnAPIResult: false,
    loadingAddMember: false,
    apiErrorMessage: undefined,

    FORM_name: '',
    FORM_country: 'USA',
    FORM_stateProvinceRegion: 'AL',
    FORM_address: '',
    FORM_city: '',
    FORM_postalCode: '',

    FORM_email: '',
  }

  handleChange = async event => this.setState({ [event.target.name]: event.target.value })

  componentDidMount = () => {
    if (this.props.employer) {
      this.setState({
        FORM_name: this.props.employer?.fields?.name,
        FORM_country: this.props.employer?.fields?.country,
        FORM_stateProvinceRegion: this.props.employer?.fields?.stateProvinceRegion,
        FORM_address: this.props.employer?.fields?.address,
        FORM_city: this.props.employer?.fields?.city,
        FORM_postalCode: this.props.employer?.fields?.postalCode,
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open == true && prevProps.open == false) {
      if (this.props.employer) {
        this.setState({
          FORM_name: this.props.employer?.fields?.name,
          FORM_country: this.props.employer?.fields?.country,
          FORM_stateProvinceRegion: this.props.employer?.fields?.stateProvinceRegion,
          FORM_address: this.props.employer?.fields?.address,
          FORM_city: this.props.employer?.fields?.city,
          FORM_postalCode: this.props.employer?.fields?.postalCode,
        })
      } else {
        this.setState({
          FORM_name: '',
          FORM_country: 'United States',
          FORM_stateProvinceRegion: 'Alabama',
          FORM_address: '',
          FORM_city: '',
          FORM_postalCode: '',
        })
      }
    }
  }

  handleCreateEmployer = async e => {
    e.preventDefault()
    const [{ firebaseUser, organization }, dispatch] = this.context

    this.setState({ waitingOnAPIResult: true })
    try {
      let employerData = {
        name: this.state.FORM_name,
        country: this.state.FORM_country,
        stateProvinceRegion: this.state.FORM_stateProvinceRegion,
        address: this.state.FORM_address,
        city: this.state.FORM_city,
        postalCode: this.state.FORM_postalCode,
      }
      if (this.props.employer) {
        await patchOrganization(firebaseUser, this.props.employer.id, employerData)
      } else {
        await createEmployer(firebaseUser, organization.id, employerData)
      }

      this.closeAndResetModal()
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to update employer. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  handleAddMember = async e => {
    e.preventDefault()
    const [{ }, dispatch] = this.context

    this.setState({ loadingAddMember: true })
    try {
      await createMemberForEmployer(this.props.employer.id, {
        email: this.state.FORM_email
      })

      this.props.refreshData()
      this.setState({ loadingAddMember: false })
    } catch (error) {
      this.setState({ loadingAddMember: false })
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to add member. ${error.message} `, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }

  }

  closeAndResetModal = async () => {
    const [{ employersAndPagination }, dispatch] = this.context
    const filtersAndPagination = employersAndPagination.meta

    this.setState({
      waitingOnAPIResult: false,
      FORM_name: undefined,
      FORM_email: '',
    })
    dispatch({
      type: 'showNotification',
      notificationDetails: { title: 'Success!', content: `Employer was updated.`, success: true },
      showNotification: true,
    })
    this.props.listEmployers(filtersAndPagination)
    this.props.setOpen(false)
  }

  render() {
    const { waitingOnAPIResult } = this.state

    return (
      <Modal
        title={this.props.employer ? 'Modify Employer' : 'Add Employer'}
        subtitle='Once you create an employer, you can invite workers. '
        buttonLabel='Submit'
        open={this.props.open}
        setOpen={this.props.setOpen}
        actionOnClick={this.handleCreateEmployer}
      >
        <EmployerForm
          FORM_name={this.state.FORM_name}
          FORM_country={this.state.FORM_country}
          FORM_stateProvinceRegion={this.state.FORM_stateProvinceRegion}
          FORM_address={this.state.FORM_address}
          FORM_city={this.state.FORM_city}
          FORM_postalCode={this.state.FORM_postalCode}
          handleChange={this.handleChange}
          onSubmit={this.handleCreateEmployer}
        />

        <div className={`pt-3 mt-6 border-t ${!this.props.employer && 'hidden'}`}>
          <label className='block text-sm font-medium text-gray-700'>Members</label>

          <table className='min-w-full mt-2 divide-y divide-gray-300'>
            <thead>
              <tr>
                <th scope='col' className='py-1 pl-4 pr-3 text-sm font-semibold text-left text-gray-500 whitespace-nowrap sm:pl-0'>
                  Member
                </th>
                <th scope='col' className='px-2 py-1 text-sm font-semibold text-left text-gray-500 whitespace-nowrap'>
                  Added on
                </th>
                <th scope='col' className='relative py-1 pl-3 pr-4 whitespace-nowrap sm:pr-0'>
                  <span className='sr-only'>Edit</span>
                </th>
              </tr>
            </thead>
            <tbody className='bg-white divide-y divide-gray-200'>
              {this.props.employer?.fields?.members.map((member, idx) => (
                <tr key={idx}>
                  <td className='py-2 pl-4 pr-3 text-sm text-gray-500 whitespace-nowrap sm:pl-0'>{member?.fields?.email}</td>
                  <td className='px-2 py-2 text-sm text-gray-500 whitespace-nowrap'>{moment(member?.createdAt).format('L')}</td>
                  <td className='relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-0'>
                    <a href='#' className='text-indigo-600 hover:text-indigo-900'>
                      {/* <XIcon className='w-5 h-5' /> */}
                    </a>
                  </td>
                </tr>
              ))}
            </tbody>
            {this.props.employer?.fields?.members?.length === 0 && (
              <tbody className='bg-white divide-y divide-gray-200'>
                <tr>
                  <td colSpan={3} className='py-8 pl-4 pr-3 text-sm text-center text-gray-500 whitespace-nowrap sm:pl-0'>
                    No members yet.
                  </td>
                </tr>
              </tbody>
            )}
          </table>
          <div className='mt-2 sm:col-span-6'>
            {/* <label htmlFor='FORM_name' className='block text-sm font-medium text-gray-700'>
              Add new member:
            </label> */}
            <div className='flex justify-between mt-1'>
              <input
                type='text'
                name='FORM_email'
                id='FORM_email'
                placeholder='Type an email address...'
                className='block w-2/3 border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                value={this.state.FORM_email || ''}
                onChange={this.handleChange}
              />
              <Button className='w-1/3' fluid loading={this.state.loadingAddMember} onClick={this.handleAddMember}>
                Add Member
              </Button>
            </div>
            <label className='block pt-2 text-sm font-medium text-gray-500'>Adding a member will not send them an invitation to Ruta.</label>
          </div>
        </div>
      </Modal>
    )
  }
}
