import React from 'react'
import { withTranslation } from 'react-i18next'
import { stateOptions, visaOptions, countryOptions } from '../../../../util/constants.js'
import ImageCropper from '../../../../components/ImageCropper'
import { SingleDatePicker } from 'react-dates'
import { Button } from '../../../../components/Tailwind/index.jsx'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

class UserProfileForm extends React.Component {
  state = {
    FORM_passportIssueDate_focused: false,
    FORM_passportExpirationDate_focused: false,
    changePhoto: false,
  }

  render() {
    const { t } = this.props // translations

    return (
      <>
        <form className='space-y-8 divide-y divide-gray-200' onSubmit={this.props.onSubmit}>
          <div className='space-y-8  '>
            <div className='space-y-2 divide-y divide-gray-200 pt-4'>
              {/* <div>
            <h3 className="text-base leading-6 text-gray-900">Contact Information</h3>
          </div> */}
              <div className='space-y-2 pt-2'>
                <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-2'>
                  <label htmlFor='FORM_firstName' className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 required'>
                    Name
                  </label>
                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <input
                      type='text'
                      name='FORM_firstName'
                      id='FORM_firstName'
                      placeholder='First name'
                      autoComplete='given-name'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                      value={this.props.FORM_firstName || ''}
                      onChange={this.props.handleChange}
                    />
                  </div>
                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <input
                      type='text'
                      name='FORM_lastName'
                      id='FORM_lastName'
                      placeholder='Last name'
                      autoComplete='family-name'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                      value={this.props.FORM_lastName || ''}
                      onChange={this.props.handleChange}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-2'>
                  <label htmlFor='FORM_phoneNumber' className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5 required'>
                    Contact
                  </label>
                  <div className='mt-2 sm:col-span-1 sm:mt-0'>

                  <PhoneInput
                    placeholder='Enter phone number'
                    value={this.props.FORM_phoneNumber}
                    name='FORM_phoneNumber'
                    defaultCountry='MX'
                    international
                    error={this.props.FORM_phoneNumber ? (isValidPhoneNumber(this.props.FORM_phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                    onChange={value =>
                      this.props.handleChange({
                        target: {
                          name: 'FORM_phoneNumber',
                          value: value,
                        },
                      })
                    }
                  />

                    {/* <input
                      id='FORM_phoneNumber'
                      name='FORM_phoneNumber'
                      type='tel'
                      autoComplete='tel'
                      placeholder='Phone / WhatsApp'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                      value={this.props.FORM_phoneNumber}
                      onChange={this.props.handleChange}
                    /> */}
                  </div>

                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <input
                      id='FORM_email'
                      name='FORM_email'
                      type='email'
                      placeholder='Email'
                      autoComplete='email'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                      value={this.props.FORM_email || ''}
                      onChange={this.props.handleChange}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-2'>
                  <label htmlFor='FORM_email' className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'>
                    Photo
                  </label>
                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <div className={`${this.props.shouldUpdatePhoto ? 'hidden' : ''} sm:col-span-4`}>
                      <Button color='light' onClick={() => this.props.handleChange({ target: { name: 'shouldUpdatePhoto', value: true } })} className='mt-1'>
                        Change Photo
                      </Button>
                    </div>

                    {this.props.shouldUpdatePhoto && (
                      <ImageCropper
                        initialDocumentUrl={this.props.initialPhotoUrl}
                        formInputName='FORM_photo'
                        formLabel='Choose a photo'
                        initialCrop={{
                          unit: '%', // default, can be 'px' or '%'
                          x: 0,
                          y: 0,
                          width: 100,
                          aspect: 1,
                        }}
                        handleChange={this.props.handleChange}
                      />
                    )}
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-2'>
                  <label htmlFor='FORM_dateOfBirth' className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'>
                    Date of Birth
                  </label>
                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <SingleDatePicker
                      id='FORM_dateOfBirth'
                      orientation='vertical'
                      date={this.props.FORM_dateOfBirth || null}
                      numberOfMonths={2}
                      onDateChange={date => this.props.handleDatePickerChange('FORM_dateOfBirth', date)}
                      isOutsideRange={day => {
                        return false
                      }}
                      focused={this.state.FORM_dateOfBirth_focused}
                      onFocusChange={({ focused }) => this.setState({ FORM_dateOfBirth_focused: focused })}
                    />
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-2'>
                  <label htmlFor='FORM_dateOfBirth' className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'>
                    Passport & Visa
                  </label>

                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <input
                      type='text'
                      name='FORM_passportNumber'
                      id='FORM_passportNumber'
                      placeholder='Passport #'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                      value={this.props.FORM_passportNumber || ''}
                      onChange={this.props.handleChange}
                    />
                  </div>
                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <select
                      id='FORM_visaType'
                      name='FORM_visaType'
                      value={this.props.FORM_visaType}
                      onChange={this.props.handleChange}
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                    >
                      {visaOptions.find(option => option?.value === this.props.FORM_visaType) ? null : <option key={0}>Not Selected</option>}

                      {visaOptions.map(option => (
                        <option key={option?.key} value={option?.value}>
                          {option?.text}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className='sm:grid sm:grid-cols-3 sm:items-start sm:gap-4  sm:pt-2'>
                  <label htmlFor='FORM_dateOfBirth' className='block text-sm font-medium leading-6 text-gray-900 sm:pt-1.5'>
                    Address
                  </label>

                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <select
                      id='FORM_country'
                      name='FORM_country'
                      value={this.props.FORM_country}
                      onChange={this.props.handleChange}
                      autoComplete='country-name'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                    >
                      {countryOptions.find(option => option?.value === this.props.FORM_country) ? null : <option key={0}>Not Selected</option>}

                      {countryOptions.map(option => (
                        <option key={option?.key} value={option?.value}>
                          {option?.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <select
                      id='FORM_stateProvinceRegion'
                      name='FORM_stateProvinceRegion'
                      value={this.props.FORM_stateProvinceRegion}
                      onChange={this.props.handleChange}
                      autoComplete='address-level1'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                    >
                      {stateOptions['All'].find(option => option?.value === this.props.FORM_stateProvinceRegion) ? null : <option key={0}>Not Selected</option>}
                      {stateOptions[Object.keys(stateOptions).includes(this.props.FORM_country) ? this.props.FORM_country : 'All'].map(option => (
                        <option key={option?.key} value={option?.value}>
                          {option?.text}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className='mt-2 sm:col-span-2 sm:col-start-2 sm:mt-0'>
                    <input
                      type='text'
                      name='FORM_address1'
                      id='FORM_address1'
                      placeholder='Street Address'
                      value={this.props.FORM_address1}
                      onChange={this.props.handleChange}
                      autoComplete='street-address'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                    />
                  </div>

                  <div className='mt-2 sm:col-span-1 sm:col-start-2 sm:mt-0'>
                    <input
                      type='text'
                      name='FORM_city'
                      id='FORM_city'
                      placeholder='City'
                      value={this.props.FORM_city}
                      onChange={this.props.handleChange}
                      autoComplete='address-level2'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                    />
                  </div>
                  <div className='mt-2 sm:col-span-1 sm:mt-0'>
                    <input
                      type='text'
                      name='FORM_postalCode'
                      id='FORM_postalCode'
                      placeholder='Postal Code'
                      value={this.props.FORM_postalCode}
                      onChange={this.props.handleChange}
                      autoComplete='postal-code'
                      className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button className={'hidden'} type='submit' />
        </form>
      </>
    )
  }
}

export default withTranslation()(UserProfileForm)
