import React from 'react';
import { StateContext } from '../../../../util/state.jsx';
import { withTranslation } from "react-i18next";
import { Modal } from '../../../../components/Tailwind'
import { withRouter } from 'react-router';
import { patchUser } from '../../../../models/user.jsx';
import { bulkSetFacilitatorForEmployer } from '../../../../models/organization.jsx'

import AssignFacilitatorForm from '../../forms/AssignFacilitatorForm/index.jsx';

class AssignFacilitatorModal extends React.Component {
    static contextType = StateContext;
    static defaultProps = {}

    state = {
        waitingOnAPIResult: false,
        FORM_assignedFacilitatorUserId: undefined,
    }

    handleChange = async (event) => this.setState({ FORM_assignedFacilitatorUserId: event.id })

    componentDidMount = () => {
        if (this.document) {
            this.setState({
                FORM_assignedFacilitatorUserId: this.props.assignedFacilitatorUserId,
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.open == true && prevProps.open == false) {
            if (this.props.assignedFacilitatorUserId) {
                this.setState({
                    FORM_assignedFacilitatorUserId: this.props.assignedFacilitatorUserId,
                })
            } else {
                this.setState( {
                    FORM_assignedFacilitatorUserId: undefined,
                })
            }
        }
    }

    handlePatchUser = async (e) => {
        e.preventDefault();
        const [{ firebaseUser, organization }, dispatch] = this.context;
        const { workerId } = this.props.match.params
        this.setState({ waitingOnAPIResult: true })

        try {
            if (this.props.employerId) {
                await bulkSetFacilitatorForEmployer(firebaseUser, organization.id, this.props.employerId, this.state.FORM_assignedFacilitatorUserId)
            } else {
                await patchUser(firebaseUser, workerId, {
                    "assignedFacilitatorUserId": this.state.FORM_assignedFacilitatorUserId,
                })    
            }
            this.closeAndResetModal()
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `Failed to assign facilitator. ${error.message} ${error.details}`, waitingOnAPIResult: false },
                showNotification: true
            })
            return
        }
    }

    closeAndResetModal = async () => {
        const [{ employerWorkersAndMeta }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

        this.setState({
            waitingOnAPIResult: false,
            FORM_assignedFacilitatorUserId: undefined
        })
        dispatch({
            type: 'showNotification',
            notificationDetails: { title: 'Success!', content: `Assigned worker.`, success: true },
            showNotification: true
        })
        this.props.pullLatestAfterUpdate()
        this.props.setOpen(false)
    }

    render() {
        const [{ user, organization}, dispatch] = this.context
        const { t } = this.props // translations
        const { waitingOnAPIResult, apiErrorMessage } = this.state

        return (
            <Modal
                title={this.props.employerId ? 'Assign Facilitator for Employer' : 'Assign Facilitator'}
                subtitle='Assign a facilitator so that the worker has a point of contact with your company to help them complete their travel tasks.'
                buttonLabel='Assign'
                open={this.props.open}
                setOpen={this.props.setOpen}
                actionOnClick={this.handlePatchUser}
            >
                <AssignFacilitatorForm 
                    FORM_assignedFacilitatorUserId={this.state.FORM_assignedFacilitatorUserId}
                    people={organization?.fields?.members}
                    handleChange={this.handleChange}
                    onSubmit={this.handlePatchUser}
                />
            </Modal>
        );
    }
}

export default withRouter(withTranslation()(AssignFacilitatorModal))