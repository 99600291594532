import React, { useState, useContext } from 'react'
import { Modal } from '../../../../components/Tailwind/index.jsx'
import { StateContext } from '../../../../util/state.jsx'
import { updateWorkerStatusForJob } from '../../../../models/organization.jsx'
import { useEffect } from 'react'
import WorkerOnJobForm from '../../forms/WorkerOnJobForm'

const WorkerOnJobModal = props => {
  const [{ organization }, dispatch] = useContext(StateContext)
  const { open, workerOnJob } = props
  const [waitingOnAPIResult, setWaitingOnAPIResult] = useState(false)
  const [formValues, setFormValues] = useState({
    FORM_externalContractTemplateOverrideReferenceId: null,
  })

  useEffect(() => {
    setFormValues({ ...formValues, FORM_externalContractTemplateOverrideReferenceId: workerOnJob?.externalContractTemplateOverrideReferenceId })
  }, [open])

  const handleChange = async (name, value) => setFormValues({ ...formValues, [name]: value })

  const close = async () => {
    setFormValues({
      FORM_externalContractTemplateOverrideReferenceId: null,
    })
    props.setOpen(false)
  }

  const handlePatchWorkerOnJob = async e => {
    e.preventDefault()
    setWaitingOnAPIResult(true)
    try {
      await updateWorkerStatusForJob(organization.id, workerOnJob?.jobId, workerOnJob?.workerId, {
        externalContractTemplateOverrideReferenceId: formValues?.FORM_externalContractTemplateOverrideReferenceId,
      })
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Success!', content: `Updated worker details.`, success: true },
        showNotification: true,
      })
      await props.pullLatestAfterUpdate()
      setWaitingOnAPIResult(false)
      close()
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to update.`, content: error.message, waitingOnAPIResult: false },
        showNotification: true,
      })
      setWaitingOnAPIResult(false)
      return
    }
  }

  return (
    <Modal
      title={'Edit Worker & Job'}
      subtitle='Change details about this worker on this job.'
      buttonLabel={'Save'}
      open={props.open}
      setOpen={close} // this only closes the modal
      actionOnClick={handlePatchWorkerOnJob}
      loading={waitingOnAPIResult}
    >
      <WorkerOnJobForm FORM_externalContractTemplateOverrideReferenceId={formValues?.FORM_externalContractTemplateOverrideReferenceId} handleChange={handleChange} onSubmit={handlePatchWorkerOnJob} />
    </Modal>
  )
}

export default WorkerOnJobModal
