import React from 'react'
import { StateContext } from '../../../util/state.jsx'
import { withTranslation } from 'react-i18next'
import { Modal } from '../../../components/Tailwind'

import { createUser, patchUser } from '../../../models/user'
import { uploadFile } from '../../../components/Firebase'

import UserProfileForm from '../forms/UserProfileForm/index.jsx'
import { withRouter } from 'react-router'
import moment from 'moment'

class UserProfileModal extends React.Component {
  static contextType = StateContext
  static defaultProps = {}

  state = {
    waitingOnAPIResult: false,
    FORM_firstName: undefined,
    FORM_lastName: undefined,
    FORM_email: undefined,
    FORM_visaType: undefined,
    FORM_photo: 'H-2A',
    FORM_phoneNumber: undefined,
    FORM_passportNumber: undefined,
    FORM_dateOfBirth: undefined,
    FORM_gender: undefined,
    FORM_address1: undefined,
    FORM_city: undefined,
    FORM_postalCode: undefined,
    FORM_stateProvinceRegion: undefined,
    FORM_country: undefined,
    FORM_passportIssueDate: undefined,
    FORM_passportExpirationDate: undefined,

    shouldUpdatePhoto: false,
  }

  handleChange = async event => this.setState({ [event.target.name]: event.target.value })
  handleFileUploadChange = async event => this.setState({ FORM_photo: event.target.files[0] })
  handleDatePickerChange = async (stateVariable, date) => this.setState({ [stateVariable]: date.hours(12).minutes(0).seconds(0).milliseconds(0)}) // note we set the time to noon to avoid timezone issues

  componentDidMount = () => {
    if (this.props.profile) {
      this.setState({
        FORM_firstName: this.props.profile.fields.firstName,
        FORM_lastName: this.props.profile.fields.lastName,
        FORM_email: this.props.profile.fields.email,
        FORM_visaType: this.props.profile.fields.visaType,
        FORM_photo: this.props.profile.fields.photo,
        FORM_phoneNumber: this.props.profile.fields?.phoneNumber,

        FORM_passportNumber: this.props.profile.fields?.passportNumber,
        FORM_dateOfBirth: this.props.profile.fields?.dateOfBirth ? moment.utc(this.props.profile.fields?.dateOfBirth) : null,
        FORM_gender: this.props.profile.fields?.gender,
        FORM_address1: this.props.profile.fields?.address1,
        FORM_city: this.props.profile.fields?.city,
        FORM_postalCode: this.props.profile.fields?.postalCode,
        FORM_stateProvinceRegion: this.props.profile.fields?.stateProvinceRegion,
        FORM_country: this.props.profile.fields?.country,
        FORM_passportIssueDate: this.props.profile.fields?.passportIssueDate ? moment.utc(this.props.profile.fields?.passportIssueDate) : null,
        FORM_passportExpirationDate: this.props.profile.fields?.passportExpirationDate ? moment.utc(this.props.profile.fields?.passportExpirationDate) : null,

        shouldUpdatePhoto: false,
      })
    } 
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.open == true && prevProps.open == false && this.props.profile) {
      this.setState({
        FORM_firstName: this.props.profile.fields.firstName,
        FORM_lastName: this.props.profile.fields.lastName,
        FORM_email: this.props.profile.fields.email,
        FORM_visaType: this.props.profile.fields.visaType,
        FORM_photo: this.props.profile.fields.photo,
        FORM_phoneNumber: this.props.profile.fields?.phoneNumber,
        FORM_passportNumber: this.props.profile.fields?.passportNumber,
        FORM_dateOfBirth: this.props.profile.fields?.dateOfBirth ? moment.utc(this.props.profile.fields?.dateOfBirth) : null,
        FORM_gender: this.props.profile.fields?.gender,
        FORM_address1: this.props.profile.fields?.address1,
        FORM_city: this.props.profile.fields?.city,
        FORM_postalCode: this.props.profile.fields?.postalCode,
        FORM_stateProvinceRegion: this.props.profile.fields?.stateProvinceRegion,
        FORM_country: this.props.profile.fields?.country,
        FORM_passportIssueDate: this.props.profile.fields?.passportIssueDate ? moment.utc(this.props.profile.fields?.passportIssueDate) : null,
        FORM_passportExpirationDate: this.props.profile.fields?.passportExpirationDate ? moment.utc(this.props.profile.fields?.passportExpirationDate) : null,

        shouldUpdatePhoto: false,
      })
    } 
  }

  handlePatchUser = async e => {
    e.preventDefault()
    console.log('test')
    console.log(this.props.profile)
    const [{ firebaseUser, user }, dispatch] = this.context
    this.setState({ waitingOnAPIResult: true })

    // For later https://codesandbox.io/s/vmvjl2q023?file=/src/index.js
    let fileURL = undefined
    if (this.state.shouldUpdatePhoto && this.state.FORM_photo && (this.state.FORM_photo instanceof File || this.state.FORM_photo instanceof Blob)) {
      try {
        fileURL = await uploadFile(this.props.profile.id, this.state.FORM_photo)
        console.log(fileURL)
      } catch (error) {
        console.log(error)
      }
    }

    // Only update phone number if it changed to be extra safe
    let newPhoneNumber = `+${this.state?.FORM_phoneNumber.replace(/\D/g, '')}`
    if (this.state?.FORM_phoneNumber && this.props.profile && this.props?.profile?.fields?.phoneNumber) {
      let oldPhoneNumber = `${this.props.profile.fields?.phoneNumber.replace(/[^\d+]/g, '')}`
      newPhoneNumber = newPhoneNumber !== oldPhoneNumber ? newPhoneNumber : null
    }

    let sanitizedEmail
    if (this.state?.FORM_email) {
      sanitizedEmail = this.state?.FORM_email.toLowerCase().trim()
    }

    try {
      console.log(this.props.profile)
      if (this.props.profile) {
        await patchUser(null, this.props.profile.id, {
          firstName: this.state.FORM_firstName.trim(),
          lastName: this.state.FORM_lastName.trim(),
          email: sanitizedEmail,
          visaType: this.state.FORM_visaType,
          photo: fileURL ? fileURL : null,
          ...(newPhoneNumber && { phoneNumber: newPhoneNumber }),
          passportNumber: this.state.FORM_passportNumber,
          ...(this.state.FORM_dateOfBirth && { dateOfBirth: moment(this.state.FORM_dateOfBirth).format() }),
          gender: this.state.FORM_gender,
          address1: this.state.FORM_address1,
          city: this.state.FORM_city,
          postalCode: this.state.FORM_postalCode,
          stateProvinceRegion: this.state.FORM_stateProvinceRegion,
          country: this.state.FORM_country,
          ...(this.state.FORM_passportIssueDate && { passportIssueDate: moment(this.state.FORM_passportIssueDate).format() }),
          ...(this.state.FORM_passportExpirationDate && { passportExpirationDate: moment(this.state.FORM_passportExpirationDate).format() }),
        })
      } else {
        await createUser(null, {
          group: 'EMPLOYEE',
          role: 'EMPLOYEE_EMPLOYEE',
          facilitatorId: user?.fields?.organization?.id,
          firstName: this.state.FORM_firstName.trim(),
          lastName: this.state.FORM_lastName.trim(),
          email: sanitizedEmail,
          visaType: this.state.FORM_visaType,
          photo: fileURL ? fileURL : null,
          ...(newPhoneNumber && { phoneNumber: newPhoneNumber }),
          passportNumber: this.state.FORM_passportNumber,
          ...(this.state.FORM_dateOfBirth && { dateOfBirth: moment(this.state.FORM_dateOfBirth).format() }),
          gender: this.state.FORM_gender,
          address1: this.state.FORM_address1,
          city: this.state.FORM_city,
          postalCode: this.state.FORM_postalCode,
          stateProvinceRegion: this.state.FORM_stateProvinceRegion,
          country: this.state.FORM_country,
          ...(this.state.FORM_passportIssueDate && { passportIssueDate: moment(this.state.FORM_passportIssueDate).format() }),
          ...(this.state.FORM_passportExpirationDate && { passportExpirationDate: moment(this.state.FORM_passportExpirationDate).format() }),
        })
      }
      this.closeAndResetModal()
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to update profile. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  closeAndResetModal = async () => {
    const [{ employerWorkersAndMeta }, dispatch] = this.context
    const { employerId } = this.props.match.params
    const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

    this.setState({
      waitingOnAPIResult: false,
      FORM_firstName: undefined,
      FORM_lastName: undefined,
      FORM_email: undefined,
      FORM_visaType: undefined,
      FORM_photo: 'H-2A',
      FORM_phoneNumber: undefined,
      FORM_passportNumber: undefined,
      FORM_dateOfBirth: undefined,
      FORM_gender: undefined,
      FORM_address1: undefined,
      FORM_city: undefined,
      FORM_postalCode: undefined,
      FORM_stateProvinceRegion: undefined,
      FORM_country: undefined,
      FORM_passportIssueDate: undefined,
      FORM_passportExpirationDate: undefined,
      fileURL: undefined,

      shouldUpdatePhoto: false,
    })
    dispatch({
      type: 'showNotification',
      notificationDetails: { title: 'Success!', content: `Updated worker profile.`, success: true },
      showNotification: true,
    })
    this.props.pullLatestAfterUpdate()
    this.props.setOpen(false)
  }

  render() {
    return (
      <Modal
        title={this.props.profile ? 'Edit Worker' : 'New Worker'}
        subtitle={this.props.profile ? "Update a worker's profile." : 'Add a new worker to Ruta'}
        buttonLabel='Submit'
        open={this.props.open}
        setOpen={this.props.setOpen}
        actionOnClick={this.handlePatchUser}
        width='3xl'
      >
        <UserProfileForm
          FORM_firstName={this.state.FORM_firstName}
          FORM_lastName={this.state.FORM_lastName}
          FORM_email={this.state.FORM_email}
          FORM_phoneNumber={this.state.FORM_phoneNumber}
          FORM_visaType={this.state.FORM_visaType}
          FORM_passportNumber={this.state.FORM_passportNumber}
          FORM_dateOfBirth={this.state.FORM_dateOfBirth}
          FORM_gender={this.state.FORM_gender}
          FORM_address1={this.state.FORM_address1}
          FORM_city={this.state.FORM_city}
          FORM_postalCode={this.state.FORM_postalCode}
          FORM_stateProvinceRegion={this.state.FORM_stateProvinceRegion}
          FORM_country={this.state.FORM_country}
          FORM_passportIssueDate={this.state.FORM_passportIssueDate}
          FORM_passportExpirationDate={this.state.FORM_passportExpirationDate}
          shouldUpdatePhoto={this.state.shouldUpdatePhoto}
          initialPhotoUrl={this.props.profile ? (this.props.profile.fields?.photo ? this.props.profile.fields?.photo?.url : null) : null}
          handleChange={this.handleChange}
          handleFileUploadChange={this.handleFileUploadChange}
          handleDatePickerChange={this.handleDatePickerChange}
          onSubmit={this.handlePatchUser}
        />
      </Modal>
    )
  }
}

export default withRouter(UserProfileModal)
