
const WorkerOnJobForm = props => {
  const {onSubmit, handleChange, FORM_externalContractTemplateOverrideReferenceId} = props
  return (
    <form className='space-y-8 divide-y divide-gray-200' style={{ minHeight: '200px' }} onSubmit={onSubmit}>
      <div className='space-y-8 divide-y divide-gray-200'>
        <div>
          <div className='mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6'>
            <div className='sm:col-span-6'>
              <label htmlFor='FORM_externalContractTemplateOverrideReferenceId' className='block text-sm font-medium text-gray-700'>
                Contract Template ID
              </label>
              <div className='mt-1'>
                <input
                  type='text'
                  name='FORM_externalContractTemplateOverrideReferenceId'
                  id='FORM_externalContractTemplateOverrideReferenceId'
                  className='block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                  value={FORM_externalContractTemplateOverrideReferenceId || ''}
                  onChange={event => handleChange(event.target.name, event.target.value)}
                />
                <p className='mt-2 text-sm text-gray-500'>This identifier must be provided to you by a Ruta staff member.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button className={'hidden'} type='submit' />
    </form>
  )
}

export default WorkerOnJobForm