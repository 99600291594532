import React from 'react';
import { DateRangePicker } from 'react-dates';
import { withTranslation } from "react-i18next";
import moment from 'moment'
import { countryOptions, stateOptions } from '../../../../util/constants.js';

class TripForm extends React.Component {
    state = {
        focusedDepartureDate: false,
        focusedArrivalDate: false,
        FORM_originCity: '',
        FORM_originStateProvinceRegion: '',
        FORM_originCountry: '',
        FORM_destinationCity: '',
        FORM_destinationStateProvinceRegion: '',
        FORM_destinationCountry: ''
    }

    render() {
        const { t } = this.props // translations

        return (
            <form className="space-y-8 divide-y divide-gray-200" onSubmit={this.props.onSubmit}>
                <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <label htmlFor="FORM_departureDate" className="block text-sm font-medium text-gray-700">
                                    Trip Dates
                                </label>
                                <div className="mt-1">
                                    <DateRangePicker
                                        startDate={this.props.FORM_departureDate}
                                        startDateId='FORM_departureDate'
                                        endDate={this.props.FORM_arrivalDate}
                                        endDateId='FORM_arrivalDate'
                                        orientation="vertical"
                                        onDatesChange={({ startDate, endDate }) => this.props.handleDatePickerChange(startDate, endDate)}
                                        focusedInput={this.state.focusedInput}
                                        onFocusChange={focusedInput => this.setState({ focusedInput })}
                                        isOutsideRange={day => { return false }}
                                        minDate={moment('2010-01-01')}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="FORM_originCountry" className="block text-sm font-medium text-gray-700">
                                    Origin Country
                                </label>
                                    <select
                                        id="FORM_originCountry"
                                        name="FORM_originCountry"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={this.props.FORM_originCountry}
                                        onChange={this.props.handleChange}
                                    >
                                        {countryOptions.map(country => (
                                            <option key={country?.key} value={country?.key}>{country?.text}</option>
                                        ))}
                                    </select>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="FORM_originStateProvinceRegion" className="block text-sm font-medium text-gray-700">
                                    Origin State
                                </label>
                                    <select
                                        id="FORM_originStateProvinceRegion"
                                        name="FORM_originStateProvinceRegion"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={this.props.FORM_originStateProvinceRegion}
                                        onChange={this.props.handleChange}
                                    >
                                        {stateOptions[this.props.FORM_originCountry].map(country => (
                                            <option key={country?.key} value={country?.key}>{country?.text}</option>
                                        ))}
                                    </select>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="FORM_originCity" className="block text-sm font-medium text-gray-700">
                                    Origin City
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="FORM_originCity"
                                        name="FORM_originCity"
                                        type="text"
                                        className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        onChange={this.props.handleChange}
                                        value={this.props.FORM_originCity || ''}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="FORM_destinationCountry" className="block text-sm font-medium text-gray-700">
                                    Destination Country
                                </label>
                                    <select
                                        id="FORM_destinationCountry"
                                        name="FORM_destinationCountry"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={this.props.FORM_destinationCountry}
                                        onChange={this.props.handleChange}
                                    >
                                        {countryOptions.map(country => (
                                            <option key={country?.key} value={country?.key}>{country?.text}</option>
                                        ))}
                                    </select>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="FORM_destinationStateProvinceRegion" className="block text-sm font-medium text-gray-700">
                                    Destination State
                                </label>
                                    <select
                                        id="FORM_destinationStateProvinceRegion"
                                        name="FORM_destinationStateProvinceRegion"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                        value={this.props.FORM_destinationStateProvinceRegion}
                                        onChange={this.props.handleChange}
                                    >
                                        {stateOptions[this.props.FORM_destinationCountry].map(country => (
                                            <option key={country?.key} value={country?.key}>{country?.text}</option>
                                        ))}
                                    </select>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="FORM_destinationCity" className="block text-sm font-medium text-gray-700">
                                    Destination City
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="FORM_destinationCity"
                                        name="FORM_destinationCity"
                                        type="text"
                                        className="shadow-sm focus:ring-green-500 focus:border-green-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        onChange={this.props.handleChange}
                                        value={this.props.FORM_destinationCity || ''}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>
                </div>
                <button className={'hidden'} type="submit" />
            </form>
        );
    }
}

export default withTranslation()(TripForm)