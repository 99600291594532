import React from 'react'
import { useContext } from 'react'
import { Redirect, Route, Switch, useHistory } from 'react-router-dom'
import { StateContext } from '../../util/state'
import NotificationToast from '../FacilitatorV2/components/NotificationToast'
import AccountProfile from './AccountProfile'
import OnboardingPage from './Onboarding'
import TopNavigation from './TopNavigation'

const AccountPortal = () => {
  const [{ user, showNotification, notificationDetails }, dispatch] = useContext(StateContext)
  const history = useHistory()

  if (history.location.pathname.endsWith('/account') || history.location.pathname.endsWith('/account/')) {
    return (<Redirect to='/account/onboarding' />)
  }

  return (
    <>
      <div className='w-full'>
        <TopNavigation />
        <main className=''>
          <Switch>
            <Route path={'/account/onboarding'}>
              <OnboardingPage />
            </Route>
            <Route path={'/account/profile'}>
              <AccountProfile />
            </Route>
            <Route path={'/account/documents'}>
              {/* <WorkerDirectory /> */}
            </Route>
            <Route path="*">
              <Redirect to="/account/onboarding" />
            </Route>
          </Switch>
        </main>
      </div>

      {/* Global Notification */}
      <NotificationToast
        show={showNotification}
        setShow={() => dispatch({ type: 'showNotification', showNotification: false, notificationDetails: {} })}
        title={notificationDetails?.title}
        content={notificationDetails?.content}
        success={notificationDetails?.success}
      />
    </>
  )
}

export default AccountPortal
