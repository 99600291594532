import React, { useContext, useState } from 'react'
import { useEffect } from 'react'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import Container from '../../../../components/Tailwind/Container'
import { retrieveDashboard } from '../../../../models/organization'
import { StateContext } from '../../../../util/state'
import DashboardActivityFeed from '../Dashboard/dashboardActivityFeed'

const ActivityFeedPage = () => {
  const [{ organization }] = useContext(StateContext)
  const [isLoadingDashboard, setIsLoadingDashboard] = useState(true)
  const [dashboardStats, setDashboardStats] = useState(undefined)

  useEffect(() => {
    const getDashboardStats = async () => {
      if (organization) {
        try {
          const dashboardStats = await retrieveDashboard(organization?.id)
          setDashboardStats(dashboardStats)
          setIsLoadingDashboard(false)
        } catch (err) {
          console.log(err)
          setIsLoadingDashboard(false)
        }
      }
    }
    getDashboardStats()
  }, [organization])


  return (
    <div>
      <Container className='bg-white shadow'>
        <div className='md:flex md:items-center md:justify-between'>
          <div className='flex-1 min-w-0 py-1.5'>
            <h2 className='text-xl font-medium text-gray-900'>Dashboard</h2>
          </div>
          <div className='flex mt-4 md:mt-0 md:ml-4'>{/* <Button onClick={() => history.push('/facilitator/add-jobs')}> Do Something </Button> */}</div>
        </div>
      </Container>
      {isLoadingDashboard && (
        <div className='w-full text-center justify-center pt-6 flex-wrap'>
          <AiOutlineLoading3Quarters className='inline animate-spin h-5 w-5'></AiOutlineLoading3Quarters>
          <p className='text-gray-600 mt-2 text-sm'>Loading Activity Feed</p>
        </div>
      )}
      {!isLoadingDashboard && (
        <Container>
          <div className='grid grid-cols-12 gap-6'>
            
            <div className='col-span-12 lg:col-span-12'>
              <h3 className='text-lg font-medium leading-6 text-gray-900'>Recent activity</h3>
              <DashboardActivityFeed dashboardStats={dashboardStats} />
            </div>
          </div>
        </Container>
      )}
    </div>
  )
}

export default ActivityFeedPage
