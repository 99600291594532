import React, { useState, useContext, useCallback } from 'react'
import { Button, Modal } from '../../../../components/Tailwind/index.jsx'
import { StateContext } from '../../../../util/state.jsx'
import { addWorkerToJob, createJob } from '../../../../models/organization.jsx'
import { formatISO, parseISO } from 'date-fns'
import { useEffect } from 'react'
import Fuse from 'fuse.js'
import { HiOutlineDocumentAdd, HiOutlineDocumentDownload, HiX } from 'react-icons/hi'
import { HiCheck, HiOutlineCheck, HiOutlineCheckCircle, HiOutlineDocumentArrowUp, HiOutlineQuestionMarkCircle } from 'react-icons/hi2'
import { useDropzone } from 'react-dropzone'
import { classNames } from '../../../../util/helpers.js'
import moment from 'moment'
import readXlsxFile from 'read-excel-file'
import { phone } from 'phone'
import { createUser, patchUser } from '../../../../models/user.jsx'
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'
import { visaOptions } from '../../../../util/constants.js'

function splitFullName(fullName) {
  const nameParts = fullName.split(' ')
  const firstName = nameParts[0]
  const lastName = nameParts.slice(1).join(' ')
  return { firstName: firstName, lastName: lastName }
}

const CSVToArray = (data, delimiter = ',', omitFirstRow = false) =>
  data
    .slice(omitFirstRow ? data.indexOf('\n') + 1 : 0)
    .split('\n')
    .map(v => v.trim().split(delimiter))

function readFileAsync(file) {
  return new Promise((resolve, reject) => {
    let reader = new FileReader()

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onerror = reject

    reader.readAsText(file)
  })
}

const ImportWorkersModal = props => {
  const [{ organization, employersAndPagination }, dispatch] = useContext(StateContext)
  const { open, autoAddToJob, retrieveJobDetails } = props
  const [waitingOnAPIResult, setWaitingOnAPIResult] = useState(false)
  const [newWorkers, setNewWorkers] = useState([])
  const [statusTracker, setStatusTracker] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCreateWorker, setIsLoadingCreateWorker] = useState({ loading: false, workerId: null })

  const handleUpdateWorker = (worker, field, value) => {
    let newWorkersCopy = [...newWorkers]
    newWorkersCopy.forEach(w => {
      if (w.id === worker.id) {
        if (field === 'phoneNumber') {
          w[field] = value ? value.replace(/\D/g, '') : ''
        } else {
          w[field] = value
        }
      }
    })
    setNewWorkers(newWorkersCopy)
  }

  // Dropzone
  const onDrop = useCallback(acceptedFiles => {
    handleExcelFileImport(acceptedFiles[0])
    // Do something with the files
  }, [])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop })

  useEffect(() => {
    // setNewWorkers([])
  }, [open])

  const close = () => {
    setNewWorkers([])
    setIsLoading(false)
    setStatusTracker({})
    props.setOpen(false)
  }

  const handleExcelFileImport = async file => {
    let rows = undefined
    if (file.type === 'text/csv') {
      let textFile = await readFileAsync(file)
      rows = await CSVToArray(textFile, ',')
    } else {
      rows = await readXlsxFile(file)
    }

    // Reset errors
    setStatusTracker({})

    // first build a map of fields to col numbers
    const fieldToColumns = {}
    const headers = rows[0]
    for (const [i, column] of headers.entries()) {
      if (!column) continue
      if (column.toLowerCase().includes('full name')) fieldToColumns.fullName = i
      if (column.toLowerCase().includes('given name') || column.toLowerCase().includes('first') || column.toLowerCase().includes('nombre dado')) fieldToColumns.firstName = i
      if (column.toLowerCase().includes('surname') || column.toLowerCase().includes('apellido') || column.toLowerCase().includes('last')) fieldToColumns.lastName = i
      if (column.toLowerCase().includes('passport number')) fieldToColumns.passportNumber = i
      if (column.toLowerCase().includes('dob foreign')) fieldToColumns.dateOfBirth = i
      // if (column.includes('Gender')) fieldToColumns.gender = i
      if (column.toLowerCase().includes('renovación') || column.toLowerCase().includes('h2a o h2b o n')) fieldToColumns.visaType = i
      if ((column.toLowerCase().includes('cell phone 1') || column.toLowerCase().includes('tel. celular 1')) && !column.toLowerCase().includes('nota')) fieldToColumns.phoneNumber = i
      // if (column.includes('Address on Passport')) fieldToColumns.address1 = i
      // if (column.includes('City')) fieldToColumns.city = i
      // if (column.includes('Postal Code')) fieldToColumns.postalCode = i
      // if (column.includes('State')) fieldToColumns.stateProvinceRegion = i
      // if (column.includes('country')) fieldToColumns.country = i
      // if (column.includes('Passport Issue Date')) fieldToColumns.passportIssueDate = i
      // if (column.includes('Passport Exp Date') && !column.includes('Passport Exp Date US')) fieldToColumns.passportExpirationDate = i
      if (column.toLowerCase().includes('e-mail') || column.toLowerCase().includes('email')) fieldToColumns.email = i
      // if (column.includes('originCity')) fieldToColumns.originCity = i
      // if (column.includes('originStateProvinceRegion')) fieldToColumns.originStateProvinceRegion = i
      // if (column.includes('originCountry')) fieldToColumns.originCountry = i
      // if (column.includes('destinationCity')) fieldToColumns.destinationCity = i
      // if (column.includes('destinationStateProvinceRegion')) fieldToColumns.destinationStateProvinceRegion = i
      // if (column.includes('destinationCountry')) fieldToColumns.destinationCountry = i
    }

    console.log(fieldToColumns)
    console.log(rows)

    let newData = []
    rows.forEach((row, i) => {
      if (i === 0) return
      if (!row[1]) return

      // TODO
      // - try to do phone number formatting
      // Then run validate on the spreadsheet to highlight any errors

      // const stateSearchIndex = new Fuse(stateOptions['All'], { keys: ['text'], includeScore: true })
      // let fuzzyMatchedStateProvinceRegion = row[fieldToColumns?.stateProvinceRegion] ? stateSearchIndex.search(row[fieldToColumns?.stateProvinceRegion])[0]?.item?.text : null

      // Upper case names
      let firstName = null
      let lastName = null
      if (Object.keys(fieldToColumns).includes('fullName') && row[fieldToColumns?.firstName]) {
        let splitName = splitFullName(row[fieldToColumns?.fullName].toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()))
        firstName = splitName?.firstName
        lastName = splitName?.lastName
      } else if (fieldToColumns?.firstName && fieldToColumns?.lastName) {
        firstName = row?.[fieldToColumns?.firstName] ? row[fieldToColumns?.firstName].toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ''
        lastName = row?.[fieldToColumns?.lastName] ? row[fieldToColumns?.lastName].toLowerCase().replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase()) : ''
      }

      // console.log(row)
      // First, get the phone number and clean it up
      let phoneNumber = Object.keys(fieldToColumns).includes('phoneNumber') && row[fieldToColumns?.phoneNumber] ? `${row[fieldToColumns?.phoneNumber]}`.replace(/\D/g, '').trim() : null

      // Then, check if it's a valid US or Mexican number
      if (phoneNumber) {
        if (phoneNumber.startsWith('1')) {
          // If the length is 11, great. If it's 10, add a 1 to the front. If it's more than 11, slice it down to 11. If it's less than 10, null it out
          phoneNumber = phoneNumber.length === 11 ? phoneNumber : phoneNumber.length === 10 ? `1${phoneNumber}` : phoneNumber?.length > 11 ? phoneNumber?.slice(0, 11) : null
        } else if (phoneNumber.startsWith('52')) {
          // If the length is 12, great. If it's more than 12, slice it down to 12. If it's less than 12, null it out
          phoneNumber = phoneNumber.length === 12 ? phoneNumber : phoneNumber?.length > 12 ? phoneNumber?.slice(0, 12) : null
        } else {
          phoneNumber = null
        }
      }

      // Handle visa type by checking if it's a valid visa type
      let visaType = Object.keys(fieldToColumns).includes('visaType') && row[fieldToColumns?.visaType] ? row[fieldToColumns?.visaType].toLowerCase().trim() : null
      if (visaType) {
        visaType =
          visaOptions.find(option =>
            option.text
              .replace(/[^\w\s]/gi, '')
              .toLowerCase()
              .includes(visaType)
          )?.value || null
      }

      // phoneNumber = Object.keys(fieldToColumns).includes('phoneNumber') ? `+${phoneNumber.replace(/\D/g, '')}` : null

      // Check to see if there's a date of birth and safely format it
      let dateOfBirth
      if (Object.keys(fieldToColumns).includes('dateOfBirth') && row?.[fieldToColumns?.dateOfBirth]) {
        // console.log(firstName + ' ' + lastName, row?.[fieldToColumns?.dateOfBirth], moment(row[fieldToColumns?.dateOfBirth]).hours(12).minutes(0).seconds(0).milliseconds(0).toISOString(), moment.utc(row[fieldToColumns?.dateOfBirth]).hours(12).minutes(0).seconds(0).milliseconds(0).toISOString())
        // For dates, we parse the date and set it to noon that day to avoid timezone issues
        dateOfBirth = moment(row[fieldToColumns?.dateOfBirth]).isValid() ? moment.utc(row[fieldToColumns?.dateOfBirth]).hours(12).minutes(0).seconds(0).milliseconds(0) : null
      }

      let email = Object.keys(fieldToColumns).includes('email') && row?.[fieldToColumns?.email] ? row[fieldToColumns?.email] : null

      let passportNumber = Object.keys(fieldToColumns).includes('passportNumber') && row?.[fieldToColumns?.passportNumber] ? row[fieldToColumns?.passportNumber] : null

      newData.push({
        id: `${i}`,
        firstName: firstName,
        lastName: lastName,
        passportNumber: passportNumber,
        dateOfBirth: dateOfBirth,
        // gender: 'M',
        visaType: visaType,
        phoneNumber: phoneNumber,

        // address1: row[fieldToColumns?.address1],
        // address2: row[],
        // city: row[fieldToColumns?.city],
        // postalCode: row[fieldToColumns?.postalCode],
        // stateProvinceRegion: fuzzyMatchedStateProvinceRegion,
        // country: 'Mexico', // Hardcoded

        // passportIssueDate: moment(moment.utc(row[fieldToColumns?.passportIssueDate])).format('YYYY-MM-DD'),
        // passportExpirationDate: moment(moment.utc(row[fieldToColumns?.passportExpirationDate])).format('YYYY-MM-DD'),

        email: email,

        // originCity: row[fieldToColumns?.city],
        // originStateProvinceRegion: fuzzyMatchedStateProvinceRegion,
        // originCountry: 'Mexico',
        // destinationCity: employer?.fields?.city || '',
        // destinationStateProvinceRegion: employer?.fields?.stateProvinceRegion || '',
        // destinationCountry: employer?.fields?.country || '',
      })
    })
    setNewWorkers(newData)
  }

  // the workerId is the worker to add to the job.
  // Use the alternate status tracker id to update the status of a different worker
  const handleAddWorkerToJobAndRefresh = async (workerId, alternateStatusTrackerWorkerId = null) => {
    const workerIdForStatusTracker = alternateStatusTrackerWorkerId ? alternateStatusTrackerWorkerId : workerId
    try {
      setIsLoadingCreateWorker({ loading: true, workerId: workerId })
      await addWorkerToJob(organization.id, autoAddToJob.id, workerId)
      await retrieveJobDetails()
      setIsLoadingCreateWorker({ loading: false, workerId: null })
      setStatusTracker(prevTracker => {
        return {
          ...prevTracker,
          [workerIdForStatusTracker]: { status: true, message: null, errors: null },
        }
      })
    } catch (error) {
      console.log(error)
      setStatusTracker(prevTracker => {
        return {
          ...prevTracker,
          [workerIdForStatusTracker]: { status: false, message: error.message, errors: error.details },
        }
      })
      setIsLoadingCreateWorker({ loading: false, workerId: null })
    }
  }

  const handlePatchWorkerProfile = async (workerId, data, alternateStatusTrackerWorkerId = null) => {
    console.log(workerId)
    const workerIdForStatusTracker = alternateStatusTrackerWorkerId ? alternateStatusTrackerWorkerId : workerId
    try {
      setIsLoadingCreateWorker({ loading: true, workerId: workerId })

      await patchUser(null, workerId, {
        ...(data?.passportNumber && { passportNumber: data?.passportNumber }),
        ...(data?.phoneNumber && { phoneNumber: `+${data?.phoneNumber.replace(/\D/g, '')}` }), // send only digits w/ + sign
        ...(data?.email && { email: data?.email }),
      })

      setStatusTracker(prevTracker => {
        return {
          ...prevTracker,
          [workerIdForStatusTracker]: { ...prevTracker[workerIdForStatusTracker], didPatchWorkerProfile: true },
        }
      })

      setIsLoadingCreateWorker({ loading: false, workerId: null })
    } catch (error) {
      console.log(error)
      setStatusTracker(prevTracker => {
        return {
          ...prevTracker,
          [workerIdForStatusTracker]: { status: false, message: error.message, errors: error.details },
        }
      })
      setIsLoadingCreateWorker({ loading: false, workerId: null })
    }
  }

  const handleCreateWorker = async workerToHandle => {
    setIsLoadingCreateWorker({ loading: true, workerId: workerToHandle.id })
    try {
      let createResult = await createUser(null, {
        ...(workerToHandle?.firstName && { firstName: workerToHandle?.firstName }),
        ...(workerToHandle?.lastName && { lastName: workerToHandle?.lastName }),
        ...(workerToHandle?.dateOfBirth && { dateOfBirth: workerToHandle?.dateOfBirth.toISOString() }),
        ...(workerToHandle?.passportNumber && { passportNumber: workerToHandle?.passportNumber }),
        ...('visaType' in workerToHandle && { visaType: workerToHandle?.visaType }),
        ...(workerToHandle?.phoneNumber && { phoneNumber: `+${workerToHandle?.phoneNumber.replace(/\D/g, '')}` }), // send only digits w/ + sign
        ...(workerToHandle?.email && { email: workerToHandle?.email }),
        facilitatorId: organization?.id,
        group: 'EMPLOYEE',
        role: 'EMPLOYEE_EMPLOYEE',
      })
      console.log(createResult)
      setStatusTracker(prevTracker => {
        return {
          ...prevTracker,
          [workerToHandle.id]: { status: true, message: null, errors: null },
        }
      })
      if (autoAddToJob) {
        handleAddWorkerToJobAndRefresh(createResult?.id, workerToHandle.id)
      }
      setIsLoadingCreateWorker({ loading: false, workerId: null })
    } catch (error) {
      console.log(error)
      setStatusTracker(prevTracker => {
        return {
          ...prevTracker,
          [workerToHandle.id]: { status: false, message: error.message, errors: error.details },
        }
      })
      setIsLoadingCreateWorker({ loading: false, workerId: null })
    }
  }

  const handleCreateWorkers = async workerToHandle => {
    setIsLoading(true)
    for (let i = 0; i < newWorkers.length; i++) {
      const worker = newWorkers[i]
      await handleCreateWorker(worker)
    }
    setIsLoading(false)
    dispatch({
      type: 'showNotification',
      notificationDetails: { title: `Done`, waitingOnAPIResult: false, success: true },
      showNotification: true,
    })
  }

  return (
    <Modal
      title={'Import New Workers'}
      subtitle='Import workers if they are not already on Ruta.'
      buttonLabel='Add Workers'
      open={props.open}
      setOpen={close} // this only closes the modal
      // actionOnClick={handleCreateWorkers}
      loading={isLoading}
      width='6xl'
    >
      <div className='my-4'>
        <h3 className='my-2'>Step 1. Download Excel Template</h3>
        <Button color='white' fluid onClick={() => window.open('https://jpsvjriemgjjwdrybhsw.supabase.co/storage/v1/object/public/product-assets/Ruta%20Worker%20Import%20Template.xlsx', '_blank')}>
          Download <HiOutlineDocumentDownload className='w-5 h-5' />
        </Button>
      </div>
      <div className='my-4'>
        <h3 className='my-2'>Step 2. Upload Excel</h3>
        <div {...getRootProps()} className={classNames('flex justify-center items-center py-12 rounded-md border border-dashed', isDragActive ? 'bg-blue-100 border-blue-600' : 'border-stone-600')}>
          <input type='file' name='FORM_file' {...getInputProps()} accept='.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel' />
          <div className='text-center py-8p'>
            <HiOutlineDocumentArrowUp className='mx-auto w-16 h-16 text-stone-600' />
            <p className='font-bold text-md text-stone-600'>Select CSV or Excel File to Upload</p>
            <p className='mb-2 text-gray-400'>or drag and drop it here</p>
            <Button color='white'>Choose File</Button>
          </div>
        </div>
      </div>
      <div className={'my-4'}>
        <h3 className='my-2'>Step 3. Review Workers</h3>
        <table className={`min-w-full divide-y divide-gray-300 ${newWorkers.length > 0 ? '' : 'hidden'}`}>
          <thead className=''>
            <tr>
              <th scope='col' className='py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap'></th>
              <th scope='col' className='py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap'>
                First
              </th>
              <th scope='col' className='py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap'>
                Last
              </th>
              <th scope='col' className='px-2 py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap'>
                Phone / WhatsApp
                <p className='text-xs text-gray-400'>Must be +52 or +1</p>
              </th>
              <th scope='col' className='px-2 py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap'>
                Email
              </th>
              <th scope='col' className='px-2 py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap'>
                Passport
              </th>
              <th scope='col' className='px-2 py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap'>
                Visa Type
              </th>
              <th scope='col' className='px-2 py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap'>
                Date of Birth
              </th>
              <th scope='col' className='px-2 py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap'></th>
            </tr>
          </thead>
          <tbody className='bg-white'>
            {newWorkers.map(worker => (
              // <>
              //   <tr key={worker?.id} className='border-t'>
              //     <td className='px-2 py-2 text-sm whitespace-nowrap'>
              //       {Object.keys(statusTracker).includes(worker?.id) && <>{statusTracker[worker?.id]?.status ? <HiCheck className='ml-2 w-5 h-5 text-green-500' /> : <HiX className='w-5 h-5 text-red-400' />}</>}
              //     </td>
              //     <td className='py-2 text-sm whitespace-nowrap'>
              //       {worker.firstName} {worker.lastName}
              //     </td>
              //     <td className='px-2 py-2 text-sm whitespace-nowrap'>{worker.phoneNumber}</td>
              //     <td className='px-2 py-2 text-sm whitespace-nowrap'>{worker.email}</td>
              //     <td className='px-2 py-2 text-sm whitespace-nowrap'>{worker.dateOfBirth}</td>
              //   </tr>
              //   {statusTracker[worker?.id]?.message && (
              //     <tr key={`${worker?.id}err`}>
              //       <th colSpan={5} scope='colgroup' className='px-4 py-2 text-sm font-semibold text-left text-red-400 sm:px-6'>
              //         {statusTracker[worker?.id]?.message ? statusTracker[worker?.id]?.message : 'Failed to create worker -- reason unknown.'}
              //       </th>
              //     </tr>
              //   )}
              // </>
              <>
                <tr key={worker?.id} className='border-t'>
                  <td className='text-sm whitespace-nowrap'>
                    {statusTracker?.[worker?.id]?.status ? (
                      <HiCheck className='mx-auto ml-2 w-5 h-5 text-green-500' />
                    ) : statusTracker?.[worker?.id]?.status === false ? (
                      <HiX className='mx-auto w-5 h-5 text-red-400' />
                    ) : (
                      <HiOutlineQuestionMarkCircle className='mx-auto w-5 h-5 text-gray-400' />
                    )}
                  </td>
                  <td className='py-2 text-sm whitespace-nowrap'>
                    <input
                      type='text'
                      name='firstName'
                      id='firstName'
                      disabled={statusTracker?.[worker?.id]?.status}
                      className='block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm disabled:text-gray-400'
                      value={worker?.firstName}
                      onChange={event => handleUpdateWorker(worker, event.target.name, event.target.value)}
                    />
                  </td>
                  <td className='py-2 text-sm whitespace-nowrap'>
                    <input
                      type='text'
                      name='lastName'
                      id='lastName'
                      disabled={statusTracker?.[worker?.id]?.status}
                      className='block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm disabled:text-gray-400'
                      value={worker?.lastName}
                      onChange={event => handleUpdateWorker(worker, event.target.name, event.target.value)}
                    />
                  </td>

                  <td className='px-2 py-2 text-sm whitespace-nowrap'>
                    <PhoneInput
                      placeholder='Enter phone number'
                      value={`+${worker?.phoneNumber}`}
                      name='phoneNumber'
                      disabled={statusTracker?.[worker?.id]?.status}
                      // defaultCountry='MX'
                      // international
                      // error={this.props.FORM_phoneNumber ? (isValidPhoneNumber(this.props.FORM_phoneNumber) ? undefined : 'Invalid phone number') : 'Phone number required'}
                      onChange={value => handleUpdateWorker(worker, 'phoneNumber', value)}
                    />
                  </td>

                  <td className='px-2 py-2 text-sm whitespace-nowrap'>
                    <input
                      type='text'
                      name='email'
                      id='email'
                      disabled={statusTracker?.[worker?.id]?.status}
                      className='block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm disabled:text-gray-400'
                      value={worker?.email}
                      onChange={event => handleUpdateWorker(worker, event.target.name, event.target.value)}
                    />
                  </td>

                  <td className='px-2 py-2 text-sm whitespace-nowrap'>
                    <input
                      type='text'
                      name='passportNumber'
                      id='passportNumber'
                      disabled={statusTracker?.[worker?.id]?.status}
                      className='block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm disabled:text-gray-400'
                      value={worker?.passportNumber}
                      onChange={event => handleUpdateWorker(worker, event.target.name, event.target.value)}
                    />
                  </td>

                  <td className='px-2 py-2 text-sm whitespace-nowrap'>
                    <input
                      type='text'
                      name='visaType'
                      id='visaType'
                      disabled={true}
                      className='block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm disabled:text-gray-400'
                      value={worker?.visaType}
                      onChange={event => handleUpdateWorker(worker, event.target.name, event.target.value)}
                    />
                  </td>

                  <td className='px-2 py-2 text-sm whitespace-nowrap'>
                    <input
                      type='text'
                      name='dateOfBirth'
                      id='dateOfBirth'
                      disabled={true}
                      className='block w-full rounded-md border-gray-300 shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm disabled:text-gray-400'
                      value={worker?.dateOfBirth && worker?.dateOfBirth.format('YYYY-MM-DD')}
                      // onChange={event => handleUpdateWorker(worker, event.target.name, event.target.value)}
                    />
                  </td>
                  <td className='px-2 py-2 text-sm whitespace-nowrap'>
                    <Button loading={isLoadingCreateWorker?.loading && isLoadingCreateWorker?.workerId === worker.id} disabled={statusTracker?.[worker?.id]?.status} onClick={() => handleCreateWorker(worker)}>
                      Add
                    </Button>
                  </td>
                </tr>
                {statusTracker?.[worker?.id]?.status === false && (
                  <tr key={`${worker?.id}err`}>
                    <th colSpan={7} scope='colgroup' className='pr-4 pb-2 pl-8 text-sm font-semibold text-left text-red-400'>
                      {statusTracker[worker?.id]?.message ? statusTracker[worker?.id]?.message : 'Failed to create worker -- reason unknown.'}
                      {statusTracker[worker?.id]?.details}
                      {statusTracker[worker?.id]?.message.includes('already in use') && (
                        <div className='text-secondary-7000'>
                          <b>Existing worker</b>: {statusTracker?.[worker?.id]?.errors?.displayName} / {statusTracker?.[worker?.id]?.errors?.email} / {statusTracker?.[worker?.id]?.errors?.phoneNumber} <br />
                          <b>Passport</b>: {statusTracker?.[worker?.id]?.errors?.passportNumber} / {moment(statusTracker?.[worker?.id]?.errors?.dateOfBirth).format('YYYY-MM-DD')} <br />
                          <div className='py-2 space-x-4'>
                            <a className='inline text-blue-500 cursor-pointer' onClick={() => handleAddWorkerToJobAndRefresh(statusTracker?.[worker?.id]?.errors?.userId, worker.id)}>
                              Add to job &rarr;{' '}
                              {isLoadingCreateWorker?.loading && isLoadingCreateWorker?.workerId === statusTracker?.[worker?.id]?.errors?.userId && <AiOutlineLoading3Quarters className='inline ml-2 w-4 h-4 animate-spin' />}
                            </a>
                            <a
                              className='inline text-blue-500 cursor-pointer'
                              onClick={() =>
                                handlePatchWorkerProfile(statusTracker?.[worker?.id]?.errors?.userId, {
                                  passportNumber: worker.passportNumber,
                                  email: worker.email,
                                  phoneNumber: worker.phoneNumber,
                                }, worker.id)
                              }
                            >
                              Update Passport, Email and Phone &rarr; {statusTracker?.[worker?.id]?.didPatchWorkerProfile && <HiOutlineCheckCircle className='inline w-5 h-5 text-green-500' />}
                              {isLoadingCreateWorker?.loading && isLoadingCreateWorker?.workerId === statusTracker?.[worker?.id]?.errors?.userId && <AiOutlineLoading3Quarters className='inline ml-2 w-4 h-4 animate-spin' />}
                            </a>
                          </div>
                        </div>
                      )}
                    </th>
                  </tr>
                )}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </Modal>
  )
}

export default ImportWorkersModal
