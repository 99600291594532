import React, { Component } from 'react'
import { StateContext } from '../../../../../util/state.jsx'
import { withRouter, Link } from "react-router-dom"

function classNames(...classes) {return classes.filter(Boolean).join(' ')}  

class EmployerTabBar extends Component {
    static contextType = StateContext
    render() {
        const [{ employerInvitationsAndPagination, employerWorkersAndMeta }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const numberOfInvitedworkers = employerInvitationsAndPagination[employerId]?.meta.count
        const numberOfActiveWorkers = employerWorkersAndMeta[employerId]?.meta.count

        const tabs = [
            { text: 'Active', name: 'active', count: numberOfActiveWorkers},
            { text: 'Invited', name: 'invited', count: numberOfInvitedworkers},
            // { text: 'Inactive', name: 'inactive', count: '1' }
        ]        

        const activeTab = this.props.location.pathname.split("/")[5]
        return (
            <div className="pt-4">
                <div className="sm:block">
                    <div className="border-b border-gray-200">
                        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                            {tabs.map((tab) => (
                                <Link
                                    key={tab.name}
                                    // onClick={() => this.handleNavClick(tab.name)}
                                    to={`../summary/${tab.name}`}
                                    className={classNames(
                                        tab.name == activeTab
                                            ? 'border-green-500 text-green-600'
                                            : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-200',
                                        'whitespace-nowrap flex py-4 px-1 border-b-2 font-medium text-sm'
                                    )}
                                    aria-current={tab.name == activeTab ? 'page' : undefined}
                                >
                                    {tab.text}
                                    <span
                                        className={classNames(
                                            tab.name == activeTab ? 'bg-green-100 text-green-600' : 'bg-gray-100 text-gray-900',
                                            'hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                                        )}
                                    >
                                        {tab.count}
                                    </span>
                                </Link>
                            ))}
                        </nav>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(EmployerTabBar)