import React from 'react';
import { withTranslation } from "react-i18next";
import { countryOptions, stateOptions } from '../../../../util/constants.js'

class EmployerForm extends React.Component {
    render() {
        const { t } = this.props // translations

        return (
            <form className="space-y-8 divide-y divide-gray-200" onSubmit={this.props.onSubmit}>
                <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                        <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <label htmlFor="FORM_name" className="block text-sm font-medium text-gray-700">
                                    Company Name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_name"
                                        id="FORM_name"
                                        autoComplete="given-name"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm"
                                        value={this.props.FORM_name || ''}
                                        onChange={this.props.handleChange}                 
                                    />
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_country" className="block text-sm font-medium text-gray-700">
                                    Country
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="FORM_country"
                                        name="FORM_country"
                                        value={this.props.FORM_country}
                                        onChange={this.props.handleChange}
                                        autoComplete="country-name"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm"
                                    >
                                        {countryOptions.find(option => option?.value === this.props.FORM_country) ? null : (<option key={0}>Not Selected</option>)}

                                        {countryOptions.map(option => (
                                            <option key={option?.key} value={option?.value}>{option?.text}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_stateProvinceRegion" className="block text-sm font-medium text-gray-700">
                                    State / Province
                                </label>
                                <div className="mt-1">
                                    <select
                                        id="FORM_stateProvinceRegion"
                                        name="FORM_stateProvinceRegion"
                                        value={this.props.FORM_stateProvinceRegion}
                                        onChange={this.props.handleChange}
                                        autoComplete="address-level1"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm"
                                    >
                                        {stateOptions['All'].find(option => option?.value === this.props.FORM_stateProvinceRegion) ? null : (<option key={0}>Not Selected</option>)}

                                        {stateOptions[Object.keys(stateOptions).includes(this.props.FORM_country) ? this.props.FORM_country : 'All'].map(option => (
                                            <option key={option?.key} value={option?.value}>{option?.text}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:col-span-6">
                                <label htmlFor="FORM_address" className="block text-sm font-medium text-gray-700">
                                    Street address
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_address"
                                        id="FORM_address"
                                        value={this.props.FORM_address}
                                        onChange={this.props.handleChange}
                                        autoComplete="street-address"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_city" className="block text-sm font-medium text-gray-700">
                                    City
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_city"
                                        id="FORM_city"
                                        value={this.props.FORM_city}
                                        onChange={this.props.handleChange}
                                        autoComplete="address-level2"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm"
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_postalCode" className="block text-sm font-medium text-gray-700">
                                    Postal code
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_postalCode"
                                        id="FORM_postalCode"
                                        value={this.props.FORM_postalCode}
                                        onChange={this.props.handleChange}
                                        autoComplete="postal-code"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-primary-300 focus:border-primary-300 sm:text-sm"
                                    />
                                </div>
                            </div>                        
                        </div>
                    </div>
                </div>
                <button className={'hidden'} type="submit"/>
            </form>
        );
    }
}

export default withTranslation()(EmployerForm)