import React, { useContext, useState } from 'react'
import { Button } from '../../../../../components/Tailwind'
import { SiMicrosoftexcel } from 'react-icons/si';
import Container from '../../../../../components/Tailwind/Container';
import { useHistory } from 'react-router-dom';
import { CheckCircleIcon, CheckIcon, XIcon, PlusIcon, ExclamationIcon } from '@heroicons/react/solid';
import readXlsxFile from 'read-excel-file'
import { StateContext } from '../../../../../util/state';
import Fuse from 'fuse.js'
import { createJob } from '../../../../../models/organization';

export default function AddJobsFlow(props) {
  const history = useHistory()
  const [step, setStep] = useState(0)
  const [data, setData] = useState([])
  const [createdJobs, setCreatedJobs] = useState([])
  const [warningBanner, setWarningBanner] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [{ employersAndPagination, organization }, dispatch] = useContext(StateContext)
  const employersFlatList = employersAndPagination?.result.map(e => e?.fields?.name)
  const employersIdList = employersAndPagination?.result.map(e => e?.id)
  const employerSearchIndex = new Fuse(employersFlatList, { keys: ['text'], includeScore: true })

  const handleExcelFileImport = async (event) => {
    let rows = await readXlsxFile(event.target.files[0])
    setWarningBanner(false)

    // first build a map of fields to col numbers
    const fieldToColumns = {}
    const headers = rows[0]
    headers.forEach((column, i) => {
      if (column.match(/Employer/i)) fieldToColumns.employer = i
      if (column.match(/Petition/i)) fieldToColumns.referenceId = i
      if (column.match(/Season/i)) fieldToColumns.season = i
    })

    let newData = []
    rows.forEach((row, i) => {
      if (i === 0) return

      let fuzzyMatchedEmployer = null
      let employerError = null
      let employerDetails = null
      if (row[fieldToColumns?.employer]) {
        let searchResults = employerSearchIndex.search(row[fieldToColumns?.employer])[0]
        console.log(`Searching for employer... found`, searchResults)
        fuzzyMatchedEmployer = searchResults?.item
        employerDetails = employersIdList[searchResults?.refIndex] // set as the employer id

        if (!searchResults) { // no match found
          employerError = 'ERROR'
          employerDetails = 'No employer of this name found.'
          setWarningBanner(true)
        } else if (employerSearchIndex.search(row[fieldToColumns?.employer])[0]?.score > .7) {
          employerError = 'ERROR'
          employerDetails = `We could not match '${row[fieldToColumns?.employer]}'.'`
          fuzzyMatchedEmployer = null
          setWarningBanner(true)
        } else if (employerSearchIndex.search(row[fieldToColumns?.employer])[0]?.score > .5) {
          employerError = 'WARNING'
          employerDetails = `Please check if this employer is correct. We tried matching '${row[fieldToColumns?.employer]}' and found '${fuzzyMatchedEmployer}.'`
          setWarningBanner(true)
        }
      } else {
        employerError = 'ERROR'
        employerDetails = 'No employer name was provided.'
        setWarningBanner(true)
      }

      console.log(`Raw: ${row[fieldToColumns?.employer]} Fuzzy: ${fuzzyMatchedEmployer}, Row: ${row}`)
      newData.push({
        employer: {
          value: fuzzyMatchedEmployer,
          error: employerError,
          details: employerDetails
        },
        referenceId: {
          value: row[fieldToColumns?.referenceId],
          error: null,
          details: null,
        },
        season: {
          value: `${row[fieldToColumns?.season]}`,
          error: null,
          details: null,
        }
      })
    })
    setData(newData)
    setStep(1)
  }

  const handleImport = async () => {
    let tempCreatedJobs = []
    setIsLoading(true)
    try {
      for (const job of data) {
        let createdJob = await createJob(organization.id, {
          ...job?.referenceId?.value && { referenceId: job?.referenceId?.value },
          ...job?.season?.value && { season: job?.season?.value },
          ...job?.employer?.value && { employerId: job?.employer?.details }, // set as the employer id 
        })
        tempCreatedJobs.push(createdJob)
      }
      setStep(2)
    } catch (error) {
      console.log(error)
      // TODO: update this so we show errors for each failed job created
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to save a job. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true
      })
      this.setState({ waitingOnAPIResult: false })
      return
    }
    setCreatedJobs(tempCreatedJobs)
    setIsLoading(false)
    props.listJobs(null)
  }


  const stepMap = {
    0: [
      { number: 0, name: 'Import', href: '#', status: 'current' },
      { number: 1, name: 'Review & Submit', href: '#', status: 'upcoming' },
      { number: 2, name: 'Finished', href: '#', status: 'upcoming' }
    ],
    1: [
      { number: 0, name: 'Import', href: '#', status: 'complete' },
      { number: 1, name: 'Review & Submit', href: '#', status: 'current' },
      { number: 2, name: 'Finished', href: '#', status: 'upcoming' }
    ],
    2: [
      { number: 0, name: 'Import', href: '#', status: 'complete' },
      { number: 1, name: 'Review & Submit', href: '#', status: 'complete' },
      { number: 2, name: 'Finished', href: '#', status: 'current' }
    ],
  }
  const stepPageMeta = {
    0: {
      title: 'Step 1. Import Jobs from Excel',
      subtitle: 'Adding new jobs is easy. All you need is to upload an Excel workbook with details for all the jobs you want to import.'
    },
    1: {
      title: 'Step 2. Review Before Import',
      subtitle: 'Confirm that the data you imported is correct. Simply upload a new file to fix any errors you find. '
    },
    2: {
      title: 'Step 3. Jobs were imported! ',
      subtitle: 'See below to confirm all the new jobs were create as you expected. '
    }
  }
  let steps = stepMap[step]
  console.log(createdJobs)

  return (
    <div className="fixed top-0 left-0 w-full h-screen">
      <div className="flex flex-col h-full">
        <Container className="z-50 flex-initial bg-white shadow">
          <div className="md:flex md:items-center md:justify-between">
            <div className="flex-1 min-w-0">
              <h2 className="text-xl font-medium text-gray-900">
                Add Jobs
              </h2>
            </div>
            <div className="flex mt-4 md:mt-0 md:ml-4">
              <Button color='gray' onClick={() => history.push('/facilitator/jobs')}>
                {step === 2 ? 'Done' : 'Cancel'}
                {step === 2 ? <CheckIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" /> : <XIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />}
              </Button>
            </div>
          </div>
        </Container>
        <Container className="flex-1 overflow-scroll bg-white">
          <div className="flex h-full">
            <div className="flex flex-col w-64">
              <div className="px-4 pt-12 sm:px-6 lg:px-8">
                <nav className="flex justify-center" aria-label="Progress">
                  <ol role="list" className="space-y-6">
                    {steps.map((step) => (
                      <li key={step.name} onClick={() => setStep(step.number)}>
                        {step.status === 'complete' ? (
                          <a href={step.href} className="group">
                            <span className="flex items-start">
                              <span className="relative flex items-center justify-center flex-shrink-0 w-5 h-5">
                                <CheckCircleIcon
                                  className="w-full h-full text-gray-600 group-hover:text-gray-800"
                                  aria-hidden="true"
                                />
                              </span>
                              <span className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                                {step.name}
                              </span>
                            </span>
                          </a>
                        ) : step.status === 'current' ? (
                          <a href={step.href} className="flex items-start" aria-current="step">
                            <span className="relative flex items-center justify-center flex-shrink-0 w-5 h-5" aria-hidden="true">
                              <span className="absolute w-4 h-4 bg-gray-200 rounded-full" />
                              <span className="relative block w-2 h-2 bg-gray-600 rounded-full" />
                            </span>
                            <span className="ml-3 text-sm font-medium text-gray-600">{step.name}</span>
                          </a>
                        ) : (
                          <a href={step.href} className="group">
                            <div className="flex items-start">
                              <div className="relative flex items-center justify-center flex-shrink-0 w-5 h-5" aria-hidden="true">
                                <div className="w-2 h-2 bg-gray-300 rounded-full group-hover:bg-gray-400" />
                              </div>
                              <p className="ml-3 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step.name}</p>
                            </div>
                          </a>
                        )}
                      </li>
                    ))}
                  </ol>
                </nav>
              </div>

            </div>
            <div className="flex flex-col flex-1 min-w-0">
              <div className="max-w-3xl px-4 pt-12">
                <h2 className="text-2xl font-medium leading-8 tracking-tight text-gray-900">
                  {stepPageMeta[step]?.title}
                </h2>
                <p className="mt-2 mb-6 text-lg text-gray-600">
                  {stepPageMeta[step]?.subtitle}
                </p>

                <div className={`${step === 2 ? 'hidden' : ''} grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6`}>

                  <div className="sm:col-span-3">
                    <label className="block mb-2 text-sm font-medium text-gray-700">
                      Choose Excel file to import
                    </label>
                    <div className="flex items-center mt-1">
                      <input type="file" className="block w-full text-sm cursor-pointer text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-gray-600 file:text-gray-50 hover:file:bg-gray-700"
                        onChange={handleExcelFileImport}
                      />
                    </div>
                  </div>
                  <div className="flex align-bottom sm:col-span-3">
                    <Button loading={isLoading} className={`${step === 1 ? '' : 'hidden'} mt-auto ml-auto`} onClick={handleImport}>
                      Import Jobs
                      <CheckIcon className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                    </Button>
                    <Button className={`${step === 0 ? '' : 'hidden'} mt-auto ml-auto`}>
                      Download Template CSV
                      <SiMicrosoftexcel className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                    </Button>

                  </div>
                </div>
              </div>
              <div className="max-w-3xl px-4 pt-6 pb-24">

                {/* Warning banner */}
                <div className={`${warningBanner ? '' : 'hidden'} p-4 mb-6 rounded-md bg-yellow-50`}>
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <ExclamationIcon className="w-5 h-5 text-yellow-400" aria-hidden="true" />
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-yellow-800">Attention needed</h3>
                      <div className="mt-2 text-sm text-yellow-700">
                        <p>
                          There may be an issue with the data we imported from the Excel file. Resolve the issue by uploading a new Excel file with any fixes. If you don't change anything, we'll import anything that's not marked in red.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>


                <div className="flex flex-col">
                  <div className="inline-block min-w-full py-2 align-middle">
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className={`${step === 2 ? '' : 'hidden'} whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6`}
                            >
                              Job ID
                            </th>

                            <th
                              scope="col"
                              className="whitespace-nowrap py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Employer
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900"
                            >
                              Petition
                            </th>
                            <th
                              scope="col"
                              className="whitespace-nowrap px-2 py-3.5 pl-4 text-left text-sm font-semibold text-gray-900"
                            >
                              Season
                            </th>
                            {/* <th
                              scope="col"
                              className="whitespace-nowrap py-3.5 pr-3 text-left text-sm font-semibold text-gray-900"
                            >
                              Number
                            </th> */}
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          <tr className={`${step === 0 ? '' : 'hidden'}`}>
                            <td colspan="8">
                              <div className="py-12 text-center">
                                <svg
                                  className="w-12 h-12 mx-auto text-gray-400"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                  aria-hidden="true"
                                >
                                  <path
                                    vectorEffect="non-scaling-stroke"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                                  />
                                </svg>
                                <h3 className="mt-2 text-sm font-medium text-gray-900">Import Jobs</h3>
                                <p className="mt-1 text-sm text-gray-500">Browse for an Excel file on your computer to import new jobs.</p>

                              </div>
                            </td>
                          </tr>
                          {data.map(job => (
                            <tr className={`${step === 1 ? '' : 'hidden'} divide-x divide-gray-200`}>
                              <td className={`${job?.employer?.error === 'WARNING' ? 'bg-yellow-300' : ''}
                                ${job?.employer?.error === 'ERROR' ? 'bg-red-300' : ''} 
                                py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6`}>
                                {job?.employer?.value}
                                {job?.employer?.error && <p className="max-w-xs text-xs">{job?.employer?.details}</p>}
                              </td>
                              <td className="px-2 py-4 pl-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                                {job?.referenceId?.value}
                              </td>
                              <td className="px-2 py-4 pl-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                                {job?.season?.value}
                              </td>
                            </tr>
                          ))
                          }
                          {createdJobs.map(job => (
                            <tr className={`${step === 2 ? '' : 'hidden'} divide-x divide-gray-200`}>
                              <td className={`flex py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6`}>
                                <CheckCircleIcon className="w-5 h-5 mr-2 text-green-600" /> #{job?.fields?.number}
                              </td>
                              <td className={`py-4 pl-4 pr-3 text-sm text-gray-500 sm:pl-6`}>
                                {job?.fields?.employer?.fields?.name}
                              </td>
                              <td className="px-2 py-4 pl-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                                {job?.fields?.referenceId}
                              </td>
                              <td className="px-2 py-4 pl-4 text-sm font-medium text-gray-900 whitespace-nowrap">
                                {job?.fields?.season}
                              </td>
                            </tr>
                          ))
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>


        </Container>
      </div>




    </div>
  )
}
