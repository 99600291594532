import axios from 'axios'
import moment from 'moment'
import { APIError, handleAxiosAPIError } from '../util/errors.js'

export const patchDocument = async (firebaseUser, tripId, documentId, documentData) => {
  try {
    let result = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_HOST}/trips/${tripId}/documents/${documentId}`,
      data: documentData
    })
    let document = result.data
    return document
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const createDocument = async (firebaseUser, tripId, documentData) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/trips/${tripId}/documents`,
      data: documentData
    })
    let document = result.data
    return document
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const deleteDocument = async (firebaseUser, tripId, documentId) => {
  try {
    let result = await axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_HOST}/trips/${tripId}/documents/${documentId}`,
    })
    return true
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

