import React, { Fragment } from 'react';
import { withTranslation } from "react-i18next";
import { classNames } from '../../../../util/helpers.js'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Listbox, Transition } from '@headlessui/react'
import AvatarImage from '../../../../components/AvatarImage/index.jsx';

class ChangeEmployerForm extends React.Component {
    state = {
        focused: false,
    }
    render() {
        const { t } = this.props // translations
        const selectedEmployer = this.props.employers.find(i => i.id === this.props.FORM_employerId)
        return (
            <form className="space-y-8 divide-y divide-gray-200" style={{ minHeight: '200px' }} onSubmit={this.props.onSubmit} >
                <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                        <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-6">
                                <Listbox value={selectedEmployer} onChange={this.props.handleChange}>
                                    {({ open }) => (
                                        <>
                                            <Listbox.Label className="block text-sm font-medium text-gray-700">Assign to</Listbox.Label>
                                            <div className="relative mt-1">
                                                <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                    {selectedEmployer ?
                                                        <>
                                                            <span className="flex items-center">
                                                                <span className="block ml-3 truncate">{selectedEmployer?.fields?.name}</span>
                                                            </span>
                                                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                                                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                                            </span>
                                                        </>
                                                        :
                                                        <>
                                                            <span className="flex items-center">
                                                                <span className="inline-block w-6 h-6 overflow-hidden bg-gray-100 rounded-full">
                                                                    <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                    </svg>
                                                                </span>

                                                                <span className="block ml-3 truncate">Not Assigned</span>
                                                            </span>
                                                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                                                <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                                            </span>
                                                        </>
                                                    }

                                                </Listbox.Button>

                                                <Transition
                                                    show={open}
                                                    as={Fragment}
                                                    leave="transition ease-in duration-100"
                                                    leaveFrom="opacity-100"
                                                    leaveTo="opacity-0"
                                                >
                                                    <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                        {this.props?.employers.map(employer => (
                                                            <Listbox.Option
                                                                key={employer.id}
                                                                className={({ active }) =>
                                                                    classNames(
                                                                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                        'cursor-default select-none relative py-2 pl-3 pr-9'
                                                                    )
                                                                }
                                                                value={employer}
                                                            >
                                                                {({ selected, active }) => (
                                                                    <>
                                                                        <div className="flex items-center">
                                                                            <span
                                                                                className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                                            >
                                                                                {employer?.fields?.name}
                                                                            </span>
                                                                        </div>

                                                                        {selected ? (
                                                                            <span
                                                                                className={classNames(
                                                                                    active ? 'text-white' : 'text-indigo-600',
                                                                                    'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                )}
                                                                            >
                                                                                <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                                            </span>
                                                                        ) : null}
                                                                    </>
                                                                )}
                                                            </Listbox.Option>
                                                        ))}
                                                    </Listbox.Options>
                                                </Transition>
                                            </div>
                                        </>
                                    )}
                                </Listbox>
                            </div>
                        </div>

                    </div>
                </div>
                <button className={'hidden'} type="submit" />
            </form>
        );
    }
}

export default withTranslation()(ChangeEmployerForm)