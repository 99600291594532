import React, { Component } from 'react';
import { StateContext } from '../../../../../util/state.jsx'
import { Redirect, Route, Link, withRouter } from "react-router-dom"
import InvitationsLedger from './InvitationsLedger'
import WorkerLedger from './WorkerLedger'
import EmployerTabBar from './EmployerTabBar'
import WorkerDetailPage from '../WorkerDetail';
import { Button, Dropdown, ConfirmModal } from '../../../../../components/Tailwind'
import { Menu } from '@headlessui/react'

import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/outline'
import InvitationModal from '../../../modals/InvitationModal'
import EmployerModal from '../../../modals/employerModal.jsx'
import AssignFacilitatorModal from '../../../modals/AssignFacilitatorModal/index.jsx';
import { deleteEmployer } from '../../../../../models/organization.jsx';
import InviteEmployerUserModal from '../../../modals/InviteEmployerUserModal/index.jsx';
import CSVBoxImporter from '@csvbox/csvboxjs'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

class EmployerDetailPage extends Component {
    constructor(props) {
        super(props);
        this.csvBoxImporter = new CSVBoxImporter("ukoIspolw8v6gVkktSpc8WM7mFzwZR", {}, this.handleImportSuccess)
    }

    static contextType = StateContext

    state = {
        invitationModalIsOpen: false,
        addEmployerModalIsOpen: false,
        inviteEmployerUserModalIsOpen: false,
        assignFacilitatorModalIsOpen: false,
        deleteEmployerModalIsOpen: false,
    }

    toggleInvitationModal = (open) => this.setState({ invitationModalIsOpen: open })
    toggleAddEmployerModal = (open) => this.setState({ addEmployerModalIsOpen: open })
    toggleInviteEmployerUserModal = (open) => this.setState({ inviteEmployerUserModalIsOpen: open })
    toggleAssignFacilitatorModal = (open) => this.setState({ assignFacilitatorModalIsOpen: open })
    toggleDeleteEmployerModalOpen = (open) => this.setState({ deleteEmployerModalIsOpen: open })

    componentDidMount = () => {
        const [{ employerWorkersAndMeta, employerInvitationsAndPagination, employersAndPagination, user }, dispatch] = this.context;
        const { employerId } = this.props.match.params

        const employer = employersAndPagination.result.find(e => e.fields?.id === employerId)
        if (typeof (employer) === 'undefined') {
            // If the user navigates to this page and we don't have the employer, go fetch it. 
            this.props.listEmployers({
                employerId: employerId
            }, true)
        }

        let employerData = employerWorkersAndMeta[employerId]
        if (!employerData) {
            // If we don't have any workers for this employer, go get it. Provide filters as null to get the defaults
            this.props.listWorkersForOrganizationAndEmployer(employerId, null)
        }

        let invitations = employerInvitationsAndPagination[employerId]
        if (!invitations) {
            // If we don't have any invitations for this employer, go get it. Provide filters as null to get the defaults
            this.props.listInvitationsForOrganizationAndEmployer(employerId, null)
        }

        this.csvBoxImporter.setUser({
            user_id: user.id,
            recipientFacilitatorId: user?.fields?.organization?.id,
            recipientEmployerId: employerId,
            senderId: user.id,
        });        

    }

    handleDeleteEmployer = async (e) => {
        e.preventDefault()
        const [{ firebaseUser, organization }, dispatch] = this.context;
        const { employerId } = this.props?.match.params

        this.setState({ waitingOnAPIResult: true })
        try {
            await deleteEmployer(firebaseUser, organization.id, employerId)
            this.closeAndResetModal()
        } catch (error) {
            console.log(error)
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `Failed to remove employer. ${error.message} ${error.details}`, waitingOnAPIResult: false },
                showNotification: true
            })
            return
        }
    }
    closeAndResetModal = async () => {
        const [{ employersAndPagination }, dispatch] = this.context;
        const filtersAndPagination = employersAndPagination.meta

        this.setState({
            waitingOnAPIResult: false,
            deleteEmployerModalIsOpen: false
        })
        dispatch({
            type: 'showNotification',
            notificationDetails: { title: 'Success!', content: `Employer was deleted.`, success: true },
            showNotification: true
        })
        this.props.listEmployers(filtersAndPagination)
        this.props.history.push(`../..`)
    }

    handleImportSuccess = async (result, data) => {
        const [{ employerInvitationsAndPagination }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerInvitationsAndPagination[employerId]?.meta

        console.log(result, data)
        if(result){
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `Success!`, content: `Imported ${data.row_success} worker invitations.`, success: true },
                showNotification: true
            })            
            console.log("Sheet uploaded successfully");
            console.log(data.row_success + " rows uploaded");
            console.dir(data)
            this.props.listInvitationsForOrganizationAndEmployer(employerId, filtersAndPagination)
        }else{
            dispatch({
                type: 'showNotification',
                notificationDetails: { title: `There was a problem uploading the data.`, content: `Something is broken in the app.`, waitingOnAPIResult: false },
                showNotification: true
            })            
            console.log("There was some problem uploading the sheet");
        }
    }

    render() {
        const { employerId } = this.props?.match.params
        const [{ isLoadingEmployers, employersAndPagination, isLoadingOrganization, organization, isLoadingEmployerWorkersAndMeta, employerWorkersAndMeta, isLoadingInvitations, employerInvitationsAndPagination }, dispatch] = this.context;

        // Wait until all the loading is done
        if (isLoadingEmployers || isLoadingOrganization || isLoadingEmployerWorkersAndMeta || isLoadingInvitations) {
            return (
                <p>Loading...</p>
            )
        }

        if (this.props.location.pathname.endsWith('summary')) {
            return (<Redirect to={`${this.props.location.pathname}/active`} />)
        }

        const employer = employersAndPagination.result.find(e => e.fields?.id === employerId)
        if (typeof (employer) === 'undefined') {
            return (<h1 error >Employer {employerId} Not Found. Go back to <Link to={`/facilitator/applicants/employers`} >All Employers</Link></h1>)
        }

        let totalWorkerCount = employerWorkersAndMeta[employerId]?.meta?.count
        let totalInvitedCount = employerInvitationsAndPagination[employerId]?.meta.count

        return (
            <div className="grid w-full grid-flow-row auto-rows-max">
                <Route path={`/facilitator/employers/:employerId/workers/:workerId`} component={WorkerDetailPage} />
                <div className="px-4 pt-4 pb-4 shadow bg-slate-50">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                        <div className="flex items-center justify-between">
                            <div className="flex-1 min-w-0">
                                <nav className="sm:flex" aria-label="Breadcrumb">
                                    <ol role="list" className="flex items-center space-x-4">
                                        <li>
                                            <div className="flex">
                                                <Link to='/facilitator/employers' className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    <h2 className="font-bold leading-7 text-gray-900 text-l sm:truncate">Employers</h2>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                                                <Link to="../summary" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    <h2 className="font-bold leading-7 text-l sm:truncate">{employer.fields?.name}</h2>
                                                </Link>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>

                            </div>
                            <div className="flex mt-4 md:mt-0 md:ml-4">
                                <div className="flex items-center justify-center mr-4">
                                    {/* Put stuff on the right if needed */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                    <header className="pt-4">
                        <div className="md:flex md:justify-between">
                            <div className="flex-1 min-w-0">
                                <h1 className="text-3xl font-bold leading-tight text-gray-900">{employer.fields?.name}</h1>
                                <h2 className="font-medium leading-7 text-gray-500 text-l sm:truncate">{employer.fields?.displayAddress}</h2>
                            </div>
                            <div className="mt-4 space-x-4 md:mt-0 md:ml-4">
                                <Button
                                    onClick={() => this.toggleAssignFacilitatorModal(true)}
                                >
                                    Set Facilitator
                                </Button>
                                <AssignFacilitatorModal
                                    assignedFacilitatorUserId={null}
                                    open={this.state.assignFacilitatorModalIsOpen}
                                    setOpen={this.toggleAssignFacilitatorModal}
                                    listWorkersForOrganizationAndEmployer={this.props.listWorkersForOrganizationAndEmployer}
                                    employerId={employerId} // We provide this prop so that the modala updates for all workers in this employer
                                />
                                <Dropdown label='Manage Employer' >
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                onClick={() => this.toggleAddEmployerModal(true)}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                            >
                                                Edit Employer
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                onClick={() => this.toggleInviteEmployerUserModal(true)}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                            >
                                                Invite Employer to Ruta
                                            </a>
                                        )}
                                    </Menu.Item>
                                    <Menu.Item>
                                        {({ active }) => (
                                            <a
                                                onClick={() => this.toggleDeleteEmployerModalOpen(true)}
                                                className={classNames(
                                                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                                    'block px-4 py-2 text-sm cursor-pointer'
                                                )}
                                            >
                                                Delete Employer
                                            </a>
                                        )}
                                    </Menu.Item>
                                </Dropdown>
                                <EmployerModal
                                    open={this.state.addEmployerModalIsOpen}
                                    setOpen={this.toggleAddEmployerModal}
                                    listEmployers={this.props.listEmployers}
                                    employer={employer}
                                />
                                <InviteEmployerUserModal
                                    open={this.state.inviteEmployerUserModalIsOpen}
                                    setOpen={this.toggleInviteEmployerUserModal}
                                />
                                <ConfirmModal
                                    title='Delete Employer'
                                    content='Are you sure you delete this employer? You can only delete employers without any workers.'
                                    buttonLabel='Delete'
                                    open={this.state.deleteEmployerModalIsOpen}
                                    setOpen={this.toggleDeleteEmployerModalOpen}
                                    actionOnClick={this.handleDeleteEmployer}
                                />
                            </div>
                        </div>
                    </header>

                    {/* Replace with your content */}
                    <div>
                        <dl className="grid grid-cols-1 gap-5 mt-5 sm:grid-cols-3">
                            <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
                                <dt className="text-sm font-medium text-gray-500 truncate">Active Workers</dt>
                                <dd className="mt-1 text-3xl font-semibold text-gray-900">{totalWorkerCount}</dd>
                            </div>
                            <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
                                <dt className="text-sm font-medium text-gray-500 truncate">Pending Invitations</dt>
                                <dd className="mt-1 text-3xl font-semibold text-gray-900">{totalInvitedCount}</dd>
                            </div>
                            <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Invite Workers</h3>
                                <div className="max-w-xl mt-2 text-sm text-gray-500">
                                    <p>Invite workers to use Ruta.</p>
                                </div>
                                <div className="mt-5">

                                    <Button
                                        onClick={() => this.props.history.push(`../invite`)}
                                    >
                                        Invite Workers
                                    </Button>
                                </div>
                            </div>
                        </dl>
                    </div>

                    {/* Tabs */}
                    <EmployerTabBar />

                    <Route
                        path="/facilitator/employers/:employerId/summary"
                        render={({ match: { url } }) => (
                            <>
                                <Route exact path={`/facilitator/employers/:employerId/summary/active`}>
                                    <WorkerLedger
                                        listWorkersForOrganizationAndEmployer={this.props.listWorkersForOrganizationAndEmployer}
                                    />
                                </Route>
                                <Route exact path={`/facilitator/employers/:employerId/summary/invited`}>
                                    <InvitationsLedger
                                        listInvitationsForOrganizationAndEmployer={this.props.listInvitationsForOrganizationAndEmployer}
                                    />
                                </Route>
                                <Route exact path={`/facilitator/employers/:employerId/summary/inactive`}>
                                    <h2 >Empty</h2>
                                </Route>
                            </>
                        )}
                    />
                    {/* /End replace */}
                </div>


            </div>
        );
    }
}

EmployerDetailPage.propTypes = {

};

export default withRouter(EmployerDetailPage);