import { CheckCircleIcon } from '@heroicons/react/solid'
import { useParams } from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

const BubbleNav = props => {
  const { steps, currentStepNumber } = props
  const history = useHistory()
  const currentStep = steps.find(s => s.id == currentStepNumber)
  const jobId  = history.location.pathname.split('/')[3]

  const getStatus = step => {
    let s = parseInt(currentStepNumber) > parseInt(step.id) ? 'complete' : parseInt(currentStepNumber) == parseInt(step.id) ? 'current' : 'upcoming'
    return s
  }

  return (
    <div className='mb-6'>
      <nav className='flex items-center justify-center py-6' aria-label='Progress'>
        <ol role='list' className='flex items-center space-x-5'>
          {steps.map(step => {
            let status = getStatus(step)
            return (
              <li key={step.id}>
                {status === 'complete' ? (
                  <a onClick={() => history.push(`${step.id!=='0' ? `/account/onboarding/${step.id}/${jobId}` : `/account/onboarding/${step.id}`}`)} className='cursor-pointer block h-2.5 w-2.5 rounded-full bg-stone-300 hover:bg-stone-400'>
                    <span className='sr-only'>{step.id}</span>
                  </a>
                ) : status === 'current' ? (
                  <a onClick={() => history.push(`${step.id!=='0' ? `/account/onboarding/${step.id}/${jobId}` : `/account/onboarding/${step.id}`}`)} className='cursor-pointer relative flex items-center justify-center' aria-current='step'>
                    <span className='absolute flex h-5 w-5 p-px' aria-hidden='true'>
                      <span className='h-full w-full rounded-full bg-primary-100' />
                    </span>
                    <span className='relative block h-2.5 w-2.5 rounded-full bg-primary-300' aria-hidden='true' />
                    <span className='sr-only'>{step.id}</span>
                  </a>
                ) : (
                  <a onClick={() => props.isVerified && history.push(`/account/onboarding/${step.id}/${jobId}`)} className='cursor-pointer block h-2.5 w-2.5 rounded-full bg-stone-300 hover:bg-stone-400'>
                    <span className='sr-only'>{step.id}</span>
                  </a>
                )}
              </li>
            )
          })}
        </ol>
      </nav>
      <div className='text-center justify-center'>
        <h2 className='font-bold text-xl leading-10'>{currentStep.title}</h2>
        <p>{currentStep.description}</p>
      </div>
    </div>
  )
}

export default BubbleNav
