import { useContext, useState } from 'react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { Combobox } from '@headlessui/react'
import { StateContext } from '../../../../../util/state'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function WorkerSearchInput(props) {
  const [{ workersAndMeta, isLoadingWorkers }] = useContext(StateContext)
  const [query, setQuery] = useState('')

  if (isLoadingWorkers) {
    return <></>
  }

  const { workerFilter, setworkerFilter } = props
  const latestWorkersAndMeta = workersAndMeta?.result
  const filteredWorkers =
    query === ''
      ? latestWorkersAndMeta
      : latestWorkersAndMeta.filter(worker => {
          return worker?.fields?.displayName.toLowerCase().includes(query.toLowerCase())
        })

  return (
    <Combobox as='div' value={workerFilter} onChange={setworkerFilter} nullable>
      {/* <Combobox.Label className="block text-sm font-medium text-gray-700">Assigned to</Combobox.Label> */}
      <div className='relative mt-1'>
        <Combobox.Input
          className='w-full py-2 pl-3 pr-10 bg-white border border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm'
          onChange={event => setQuery(event.target.value)}
          displayValue={worker => worker?.fields?.displayName}
        />
        <Combobox.Button className='absolute inset-y-0 right-0 flex items-center px-2 rounded-r-md focus:outline-none'>
          <SelectorIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
        </Combobox.Button>

        {filteredWorkers.length > 0 && (
          <Combobox.Options className='absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm'>
            {filteredWorkers.map(worker => (
              <Combobox.Option key={worker.id} value={worker} className={({ active }) => classNames('relative cursor-default select-none py-2 pl-8 pr-4', active ? 'bg-indigo-600 text-white' : 'text-gray-900')}>
                {({ active, selected }) => (
                  <>
                    <span className={classNames('block truncate', selected && 'font-semibold')}>{worker?.fields?.displayName}</span>

                    {selected && (
                      <span className={classNames('absolute inset-y-0 left-0 flex items-center pl-1.5', active ? 'text-white' : 'text-indigo-600')}>
                        <CheckIcon className='w-5 h-5' aria-hidden='true' />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  )
}
