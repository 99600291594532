import { Fragment, useContext, useEffect, useState } from 'react'
import { Combobox, Dialog, Transition } from '@headlessui/react'
import { HiMagnifyingGlass } from 'react-icons/hi2'
import { listJobs } from '../../../models/organization'
import useDebounce from '../../../util/hooks/useDebounce'
import { StateContext } from '../../../util/state'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'

const people = [
  { id: 1, name: 'Leslie Alexander', url: '#' },
  // More people...
]

// Write a 

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const CommandPaletteSearch = props => {
  const [{ user }, dispatch] = useContext(StateContext)
  const history = useHistory()
  const [query, setQuery] = useState('')
  const { open, setOpen } = props
  const [jobs, setJobs] = useState([])

  // debounce search against api endpoint
  const debouncedSearchTerm = useDebounce(query, 300)

  // async call api endpoint with debounced search term
  const searchJobs = async () => {
    const jobsRequest = await listJobs(user.fields?.organizationId,{ searchText: debouncedSearchTerm })
    setJobs(jobsRequest?.result)
    // console.log('jobs', jobsRequest)
  }
  useEffect(() => {
    if (debouncedSearchTerm) {
      searchJobs()
    }
  }, [debouncedSearchTerm])

  console.log(jobs)

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={() => setQuery('')} appear>
      <Dialog as='div' className='relative z-10' onClose={setOpen}>
        <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0' enterTo='opacity-100' leave='ease-in duration-200' leaveFrom='opacity-100' leaveTo='opacity-0'>
          <div className='fixed inset-0 transition-opacity bg-gray-500 bg-opacity-25' />
        </Transition.Child>

        <div className='fixed inset-0 z-10 p-4 overflow-y-auto sm:p-6 md:p-20'>
          <Transition.Child as={Fragment} enter='ease-out duration-300' enterFrom='opacity-0 scale-95' enterTo='opacity-100 scale-100' leave='ease-in duration-200' leaveFrom='opacity-100 scale-100' leaveTo='opacity-0 scale-95'>
            <Dialog.Panel className='max-w-xl mx-auto overflow-hidden transition-all transform bg-white divide-y divide-gray-100 shadow-2xl rounded-xl ring-1 ring-black ring-opacity-5'>
              <Combobox onChange={job => history.push(`/facilitator/jobs/${job?.id}`)}>
                <div className='relative'>
                  <HiMagnifyingGlass className='pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  <Combobox.Input className='w-full h-12 pr-4 text-gray-900 bg-transparent border-0 pl-11 placeholder:text-gray-400 focus:ring-0 sm:text-sm' placeholder='Search...' onChange={event => setQuery(event.target.value)} />
                </div>

                {jobs.length > 0 && (
                  <Combobox.Options static className='py-2 overflow-y-auto text-sm text-gray-800 max-h-72 scroll-py-2'>
                    {jobs.map(job => (
                      <Combobox.Option key={job?.id} value={job} className={({ active }) => classNames('cursor-default select-none px-4 py-2', active && 'bg-blue-400 text-white')}>
                        {job?.fields?.employer?.fields?.name} - {job?.fields?.number} - {job?.fields?.referenceId}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                )}

                {query !== '' && jobs.length === 0 && <p className='p-4 text-sm text-gray-500'>No people found.</p>}
              </Combobox>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default CommandPaletteSearch
