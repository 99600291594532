import { RadioGroup } from '@headlessui/react'
import { useContext } from 'react'
import { StateContext } from '../../../util/state'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Step4PreferredContact = props => {
  const [{user}] = useContext(StateContext)
  const {FORM_preferredContactMethod} = props?.formValues

  return (
    <div className='mb-6'>
    <RadioGroup value={FORM_preferredContactMethod} onChange={selected => props.handleChange(null, {name: 'FORM_preferredContactMethod', value: selected})}>
      <div className="relative -space-y-px rounded-md bg-white">
        {props.contactMethodOptions.map((plan, planIdx) => (
          <RadioGroup.Option
            key={plan.id}
            value={plan}
            className={({ checked }) =>
              classNames(
                planIdx === 0 ? 'rounded-tl-md rounded-tr-md' : '',
                planIdx === props.contactMethodOptions.length - 1 ? 'rounded-bl-md rounded-br-md' : '',
                checked ? 'bg-primary-50 border-primary-200 z-10' : 'border-gray-200',
                'relative border p-4 flex flex-col cursor-pointer focus:outline-none'
              )
            }
          >
            {({ active, checked }) => (
              <>
                <span className="flex items-center text-sm">
                  <span
                    className={classNames(
                      checked ? 'bg-primary-400 border-transparent' : 'bg-white border-gray-300',
                      active ? 'ring-2 ring-offset-2 ring-primary-300' : '',
                      'h-4 w-4 rounded-full border flex items-center justify-center'
                    )}
                    aria-hidden="true"
                  >
                    <span className="rounded-full bg-white w-1.5 h-1.5" />
                  </span>
                  <RadioGroup.Label
                    as="span"
                    className={classNames(checked ? 'text-primary-800' : 'text-gray-900', 'ml-3 font-medium')}
                  >
                    { plan.id === 'PHONE' ? user?.fields?.displayPhoneNumber : plan.id === 'EMAIL' ? user?.fields?.email : user?.fields?.displayPhoneNumber }
                  </RadioGroup.Label>
                </span>
                <RadioGroup.Description as="span" className="ml-6 pl-1 pt-1 text-sm ">
                  <span className={classNames(checked ? 'text-primary-800' : 'text-gray-500', 'font-medium flex')}>
                    {plan.icon} {plan.method}
                  </span>
                </RadioGroup.Description>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
    </div>
  )
}

export default Step4PreferredContact

