import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { StateContext } from '../../util/state.jsx'

import { retrieveOrganization, listWorkersForOrganizationAndEmployer } from '../../models/organization'

// import TeamPage from './pages/Team'
// import EmployersList from './pages/Employers/EmployerList'
// import EmployerDetailPage from './pages/Employers/EmployerDetail'
// import BulkSendInvitationsPage from './pages/Employers/EmployerDetail/bulkSendInvitations.jsx'
import WorkerDetailPage from '../FacilitatorV2/pages/Employers/WorkerDetail'
import MyProfile from '../FacilitatorV2/pages/MyProfile'
// import InvitationDetailPage from './pages/Employers/WorkerDetail/invitationDetail'

import NotificationToast from '../FacilitatorV2/components/NotificationToast/index.jsx'
import TopNavigation from '../FacilitatorV2/components/TopNavigation/index.jsx'
import EmployerPortalWorkerDashboard from './EmployerPortalWorkerDashboard/index.jsx'
import EmployerJobDetailPage from './EmployerJobDetailPage/index.jsx'
import NotFoundPage from '../Misc/NotFoundPage/index.jsx'
import LeftNavigation from '../FacilitatorV2/components/LeftNavigation/index.jsx'
import { LogoutIcon, UsersIcon } from '@heroicons/react/solid'
import EmployerPortalJobsPage from './EmployerPortalJobsList/index.jsx'
import { HiOutlineUsers } from 'react-icons/hi2'

export default class EmployerPortal extends React.Component {
  static contextType = StateContext

  state = {
    sidebarOpen: false,
  }

  componentDidMount = () => {
    // this.retrieveThisOrganization()
    // this.listWorkersForOrganizationAndEmployer(null, true)
  }

  retrieveThisOrganization = async () => {
    const [{ firebaseUser, user }, dispatch] = this.context
    dispatch({ type: 'isLoadingOrganization', isLoadingOrganization: true })
    try {
      let organization = await retrieveOrganization(firebaseUser, user.fields?.organizationId)
      dispatch({
        type: 'setOrganization',
        isLoadingOrganization: false,
        organization: organization,
      })
      //   Hack to make the fv2 worker detail page work
      dispatch({
        type: 'setEmployersAndPagination',
        isLoadingEmployers: false,
        employersAndPagination: { result: [organization], meta: null },
      })
    } catch (error) {
      dispatch({ type: 'setApiErrorMessage', apiErrorMessage: `Something went wrong. ${error.message}` })
      dispatch({
        type: 'setOrganization',
        isLoadingOrganization: false,
        organization: null,
      })
    }
  }

  listWorkersForOrganizationAndEmployer = async (employerId, filtersAndPagination) => {
    const [{ firebaseUser, user }, dispatch] = this.context
    dispatch({ type: 'setIsLoadingEmployerWorkersAndMeta', isLoadingEmployerWorkersAndMeta: true })
    try {
      let workersAndMeta = await listWorkersForOrganizationAndEmployer(firebaseUser, user.fields?.organizationId, employerId, filtersAndPagination)
      dispatch({
        type: 'setEmployerWorkersAndMeta',
        isLoadingEmployerWorkersAndMeta: false,
        workersAndMeta: workersAndMeta,
        employerId: employerId,
      })
    } catch (error) {
      dispatch({
        type: 'setEmployerWorkersAndMeta',
        isLoadingEmployerWorkersAndMeta: false,
        workersAndMeta: null,
        employerId: employerId,
      })
    }
  }

  handleNavClick = location => {
    this.props.history.push(`/employer/${location}`)
  }

  render() {
    const [{ user, showNotification, notificationDetails, firebaseUser, isLoadingOrganization, organization }, dispatch] = this.context

    // if (isLoadingOrganization) {
    //   return <p>Loading...</p>
    // }

    if (!user && typeof user !== 'undefined') {
      console.log('sending user to login')
      return <Redirect to={`/login?redirect=${window.location.pathname}${window.location.search}`} />
    }

    // if (this.props.location.pathname === '/employer') {
    //   return <Redirect to={`/employer/${organization.id}/workers`} />
    // }

    const activePage = this.props.location.pathname.split('/')[3]
    return (
      <>
        <div className='h-screen'>
          {/* <TopNavigation
            navigation={[
              { name: 'My Workers', href: '/employer', current: activePage === 'workers' },
              // { name: 'My Team', href: '/employer/team', current: activePage === 'team' },
            ]}
            userNavigation={[
              { name: 'Your Settings', href: '/employer/profile' },
              { name: 'Sign Out', href: '/logout' },
            ]}
          /> */}
          <LeftNavigation
            navigation={
              [
                { name: 'Jobs', href: '/employer/jobs', icon: HiOutlineUsers, current: this.props.location.pathname.startsWith('/employer/') },
              ]
            }
            userNavigation={[
              // { name: 'Job Details', icon: UsersIcon, href: '.' },
              { name: 'Log Out', icon: LogoutIcon, href: '/logout' },
            ]}
            subNavigation={
              [
                // { name: 'Add Employers', href: '/facilitator/addemployers', current: activePage === 'addemployers', icon: OfficeBuildingIcon },
                // { name: 'Add Jobs', href: '/facilitator/addjobs', current: activePage === 'addjobs', icon: NewspaperIcon },
                // { name: 'Add Workers', href: '/facilitator/addworkers', current: activePage === 'addworkers', icon: UserAddIcon },
              ]
            }
          />
          <main className='h-full md:pl-64'>
            <div className='h-full'>
              <Switch>
                <Route
                  path='/employer'
                  render={({ match: { url } }) => (
                    <>
                      {/* <Route path={`${url}/:employerId/workers`} exact>
                        <EmployerPortalWorkerDashboard listWorkersForOrganizationAndEmployer={this.listWorkersForOrganizationAndEmployer} />
                      </Route> */}
                      <Route path={`${url}/jobs`} exact>
                        <EmployerPortalJobsPage />
                      </Route>
                      <Route path={`${url}/:employerId/jobs/:jobId`} exact>
                        <EmployerJobDetailPage />
                      </Route>
                      {/* <Route exact path={`${url}/profile`}>
                        <MyProfile retrieveThisOrganization={this.retrieveThisOrganization} />
                      </Route> */}
                      <Route path="*"><Redirect to="/employer/jobs" /></Route>
                    </>
                  )}
                />
              </Switch>
            </div>
          </main>
        </div>

        {/* Global Notification */}
        <NotificationToast
          show={showNotification}
          setShow={() => dispatch({ type: 'showNotification', showNotification: false, notificationDetails: {} })}
          title={notificationDetails?.title}
          content={notificationDetails?.content}
          success={notificationDetails?.success}
        />
      </>
    )
  }
}
