import axios from 'axios'
import React, { useContext } from 'react'
import { StateContext } from '../util/state.jsx'
import { APIError, handleAxiosAPIError } from '../util/errors.js'

export const createUser = async (firebaseUser, userData) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/users`,
      data: userData
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

// Note this requires session token
export const retrieveUser = async (sessionToken, userId) => {
  try {
    let result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/users/${userId}`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const patchUser = async (firebaseUser, userId, userData) => {
  try {
    let result = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_HOST}/users/${userId}`,
      data: userData
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const sendReminderSms = async (firebaseUser, userId) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/users/${userId}/remind`,
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}


export const loginOrCreateStytchUser = async (userData) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/auth/loginOrCreate`,
      headers: {'Content-Type': 'application/json' }, 
      data: userData
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const authenticate = async (userData) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/auth/authenticate`,
      headers: {'Content-Type': 'application/json' }, 
      data: userData
    })
    return result.data
  } catch(error) {
    handleAxiosAPIError(error)
  }
}
