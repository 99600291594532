import React from 'react'
import { Button } from '../../../../components/Tailwind'
import { SiMicrosoftexcel } from 'react-icons/si';
import { FaRegKeyboard } from 'react-icons/fa'
import WorkerIllustration1 from '../../../../img/illustrations/worker1.jpg'

export default function AddWorkersStepOne(props) {

    const listOfContext = [
        { number: 1, description: 'Worker\'s Name' },
        { number: 2, description: 'Worker\'s Contact Method (phone, WhatsApp, or Email' },
    ]

    return (
        <div>
            <header>
                <div className="px-4 py-6 mx-auto max-w-7xl sm:px-6 lg:px-8">
                    <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
                        Add Workers
                    </p>
                </div>
            </header>
            <main className="grid grid-cols-2 gap-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="">
                    {/* Replace with your content */}
                    <div className="px-4 py-8 sm:px-0">
                        <p className="mb-6 text-xl text-gray-600">
                            Inviting workers to Ruta is easy. All you need is:
                        </p>
                        <ul role="list" className="">
                            {listOfContext.map((item) => (
                                <li key={item.description} className="flex py-4">
                                    <a className="relative flex items-center justify-center group">
                                        <span className="flex items-center h-9">
                                            <span className="relative z-10 flex items-center justify-center w-8 h-8 text-sm font-black text-white rounded-full bg-emerald-600 group-hover:bg-emerald-800">
                                                {item.number}
                                            </span>
                                        </span>
                                        <span className="flex flex-col min-w-0 ml-4">
                                            <span className="text-gray-600 text-l">{item.description}</span>
                                        </span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                        <div className="mt-6">
                            <button
                                type="button"
                                onClick={() => props.setPageStep(2)}
                                className="inline-flex items-center px-3 py-2 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Upload Excel File
                                <SiMicrosoftexcel className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                            </button>
                            <button
                                type="button"
                                onClick={() => props.setPageStep(2)}
                                className="inline-flex items-center px-3 py-2 mx-4 text-sm font-medium leading-4 text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                            >
                                Add Workers Manually
                                <FaRegKeyboard className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                    {/* /End replace */}
                </div>
                <div>
                    <img className="object-contain w-full h-72" src={WorkerIllustration1} alt="" />
                </div>

            </main>

        </div>
    )
}
