import React, { useContext, useState } from 'react'
import { Button } from '../../../../../components/Tailwind'
import Container from '../../../../../components/Tailwind/Container'
import { StateContext } from '../../../../../util/state'
import { useRouteMatch } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import WorkerTable from './workerTable'
import ImportWorkersModal from '../../../modals/ImportWorkersModal'
import { useEffect } from 'react'
import { retrieveJob } from '../../../../../models/organization'
import AddWorkerToJobModal from '../../../modals/AddWorkerToJobModal'
import { SiMicrosoftexcel } from 'react-icons/si'
import { classNames } from '../../../../../util/helpers'
import { Menu } from '@headlessui/react'
import { HiBriefcase, HiCalendar, HiCurrencyDollar, HiLink, HiMapPin, HiOutlineUserPlus, HiOutlineUsers, HiPencil, HiUser, HiUserPlus, HiUsers } from 'react-icons/hi2'
import AddJobModal from '../../../modals/AddJobModal'
import EmployerModal from '../../../modals/employerModal'

const publishingOptions = [
  { name: 'Published', description: 'This job posting can be viewed by anyone who has the link.', current: true },
  { name: 'Draft', description: 'This job posting will no longer be publicly accessible.', current: false },
]
function JobDetailPage(props) {
  const [addWorkersDialogIsOpen, setAddWorkersDialogIsOpen] = useState(false)
  const [addMultipleWorkersDialogIsOpen, setaddMultipleWorkersDialogIsOpen] = useState(false)
  const [importWorkersModalIsOpen, setimportWorkersModalIsOpen] = useState(false)
  const [addEmployerModalIsOpen, setAddEmployerModalIsOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState('all')
  const match = useRouteMatch()
  const history = useHistory()
  const [{ isLoadingWorkers, organization }, dispatch] = useContext(StateContext)
  const { jobId } = match.params
  const [job, setJob] = useState(undefined)
  const [isLoadingJob, setIsLoadingJob] = useState(true)
  const [addJobModalIsOpen, setAddJobModalIsOpen] = useState(false)

  const retrieveJobDetails = async () => {
    setIsLoadingJob(true)
    if (organization) {
      try {
        const job = await retrieveJob(organization?.id, jobId)
        setJob(job)
        setIsLoadingJob(false)
      } catch (err) {
        console.log(err)
        // setIsLoadingJob(false)
        dispatch({
          type: 'showNotification',
          notificationDetails: { title: `Failed to get job.`, waitingOnAPIResult: false },
          showNotification: true,
        })
      }
    }
  }

  useEffect(() => {
    retrieveJobDetails()
  }, [organization])

  const pages = [
    { name: 'Jobs', href: '/facilitator/jobs', current: false },
    { name: `#${job?.fields?.number} - ${job?.fields?.employer?.fields?.name}`, href: '#', current: true },
  ]

  const workersByActiveTab = {
    all: job?.fields?.workers,
    not_registered: job?.fields?.workers.filter(w => w?.fields?.stytchUserId === null),
    not_approved: job?.fields?.workers,
    ready_for_visa: [],
  }

  const tabs = [
    { id: 'all', name: 'All', href: '#', current: currentTab === 'all' },
    { id: 'not_registered', name: 'Not Registered', href: '#', current: currentTab === 'not_registered' },
    { id: 'not_approved', name: 'Not Approved', href: '#', current: currentTab === 'not_approved' },
    { id: 'ready_for_visa', name: 'Ready for Visa', href: '#', current: currentTab === 'ready_for_visa' },
  ]

  const currentTabWorkers = workersByActiveTab[currentTab]

  const pullLatestAfterUpdate = async () => {
    await retrieveJobDetails()
    await props.listJobs()
  }

  return (
    <div className=''>
      <Container className='bg-white shadow'>
        <div className='w-full md:flex md:items-center md:justify-between'>
          <div className='flex min-w-0'>
            <nav className='flex' aria-label='Breadcrumb'>
              <ol role='list' className='flex items-center space-x-4'>
                {pages.map((page, i) => (
                  <li key={page.name}>
                    <div className='flex items-center cursor-pointer'>
                      <svg className={`flex-shrink-0 w-5 h-5 text-gray-300 mr-4 ${i === 0 && 'hidden'}`} xmlns='http://www.w3.org/2000/svg' fill='currentColor' viewBox='0 0 20 20' aria-hidden='true'>
                        <path d='M5.555 17.776l8-16 .894.448-8 16-.894-.448z' />
                      </svg>
                      <a
                        onClick={() => history.push('/facilitator/jobs')}
                        className={`text-base font-medium py-2 ${page.current ? 'text-gray-800 hover:text-gray-900' : 'text-gray-500 hover:text-gray-700'}  `}
                        aria-current={page.current ? 'page' : undefined}
                      >
                        {page.name}
                      </a>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          </div>
          <div className='flex mt-4 md:mt-0 md:ml-4'>
            {/* <Button className='mr-4' color='white' onClick={() => setimportWorkersModalIsOpen(true)} disabled={isLoadingWorkers}>
              Import New Workers 
              <SiMicrosoftexcel className="w-5 h-5 ml-2 -mr-1" aria-hidden="true" />
            </Button> */}
            {/* <Button className='mr-4' onClick={() => setAddWorkersDialogIsOpen(true)} disabled={isLoadingWorkers}>
              Add Workers
            </Button> */}
            {/* <Button color='light'>Edit Job</Button> */}
          </div>
        </div>
      </Container>

      <Container>
        {/* Page heading */}
        <header className='pb-6'>
          <div className='flex items-center justify-between'>
            <div className='flex-1 min-w-0'>
              <h1 className='mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>{job?.fields?.employer?.fields?.name}</h1>
              <div className='flex flex-col mt-1 space-x-4 sm:mt-0 sm:flex-row sm:flex-wrap'>
                <div className='flex items-center mt-2 text-sm text-gray-500'>
                  <HiBriefcase className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                  Petition # {job?.fields?.referenceId || '--'}
                </div>
                <div className='flex items-center mt-2 text-sm text-gray-500'>
                  <HiMapPin className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                  {job?.fields?.employer?.fields?.address || '' + ', ' + job?.fields?.employer?.fields?.city || '' + ', ' + job?.fields?.employer?.fields?.stateProvinceRegion || '' + ' ' + job?.fields?.employer?.fields?.postalCode || ''}
                </div>
                <div className='flex items-center mt-2 text-sm text-gray-500'>
                  <HiUsers className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                  {job?.fields?.workers.length} Total Workers
                </div>
                <div className='flex items-center mt-2 text-sm text-gray-500'>
                  <HiCalendar className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                  {job?.fields?.season}
                </div>
                <div className='flex items-center mt-2 text-sm text-gray-500'>
                  <HiUser className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                  {job?.fields?.assignedFacilitator?.fields?.displayName}
                </div>
              </div>
            </div>
            <div className='flex mt-5 xl:mt-0 xl:ml-4'>
              {/* <span className='hidden sm:block'>
                <button type='button' className='inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
                  <HiPencil className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  Edit
                </button>
              </span>

              <span className='hidden ml-3 sm:block'>
                <button type='button' className='inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50'>
                  <HiLink className='-ml-0.5 h-5 w-5 text-gray-400' aria-hidden='true' />
                  View
                </button>
              </span> */}

              <Button color='white' className='mr-4' onClick={() => setAddEmployerModalIsOpen(true)}>
                  Edit Employer
                </Button>

              <Button color='white' className='mr-4' onClick={() => setAddJobModalIsOpen(true)} disabled={isLoadingWorkers}>
                <HiPencil  className='-ml-0.5 mr-2 h-5 w-5 text-gray-600' />
                Edit Job
              </Button>

              <Button className='mr-4' onClick={() => setimportWorkersModalIsOpen(true)} disabled={isLoadingWorkers}>
                <HiOutlineUsers  className='-ml-0.5 mr-2 h-5 w-5 text-slate-700' />
                Add Many Workers
              </Button>

              <Button className='mr-4' onClick={() => setAddWorkersDialogIsOpen(true)} disabled={isLoadingWorkers}>
                <HiOutlineUserPlus  className='-ml-0.5 mr-2 h-5 w-5 text-slate-700' />
                Add One Worker
              </Button>

            </div>
          </div>
        </header>

        <WorkerTable job={job} isLoadingJob={isLoadingJob} retrieveJobDetails={retrieveJobDetails} listJobs={props.listJobs} />
      </Container>

      <EmployerModal open={addEmployerModalIsOpen} setOpen={setAddEmployerModalIsOpen} listEmployers={() => console.log('test')} refreshData={retrieveJobDetails} employer={job?.fields?.employer} />
      <AddWorkerToJobModal open={addWorkersDialogIsOpen} setOpen={setAddWorkersDialogIsOpen} job={job} retrieveJobDetails={retrieveJobDetails} listWorkers={props.listWorkers} />
      <ImportWorkersModal open={importWorkersModalIsOpen} setOpen={setimportWorkersModalIsOpen} autoAddToJob={job} retrieveJobDetails={retrieveJobDetails} />
      <AddJobModal open={addJobModalIsOpen} setOpen={setAddJobModalIsOpen} listJobs={pullLatestAfterUpdate} job={job} />
    </div>
  )
}

export default JobDetailPage
