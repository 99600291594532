import React, { Component, useContext, useEffect } from 'react';
import { StateContext } from '../../../util/state.jsx';
import { withTranslation } from "react-i18next";
import { Modal } from '../../../components/Tailwind'
import { withRouter } from 'react-router';
import { uploadFile } from '../../../components/Firebase/index.js';
import { createDocument, patchDocument } from '../../../models/document.jsx';

import { patchTrip } from '../../../models/trip'

import TripForm from '../forms/TripForm/index.jsx';

import moment from 'moment'
import DocumentForm from '../forms/DocumentForm/index.jsx';
import ExpenseForm from '../forms/ExpenseForm/index.jsx';
import { createExpense, patchExpense } from '../../../models/expense.jsx';

class TripModal extends React.Component {
  static contextType = StateContext;
  static defaultProps = {}

  state = {
    waitingOnAPIResult: false,
    // FORM_merchant: expense.merchant,
    // FORM_receipt: expense.receipt,
    FORM_total: undefined,
    FORM_currency: undefined,
    FORM_date: moment(),
    FORM_category: undefined,
    FORM_payerId: undefined,
    // FORM_approved: expense.approved,
    // FORM_type: expense.type,
    // FORM_payment_method: expense.payment_method,
    // FORM_trip: expense.trip,
    shouldUpdatePhoto: false,
  }

  handleChange = async (event) => {
    if (event.target.name === 'FORM_total') {
      var rgx = /^\s*-?(\d+(\.\d{0,2})?|\.\d{0,2})\s*$/;
      if ((event.target.value.match(rgx) || event.target.value === '') && event.target.value.length < 7) {
        this.setState({ [event.target.name]: event.target.value })
      }
    } else {
      this.setState({ [event.target.name]: event.target.value })
    }
  }
  handleListBoxChange = async (object) => this.setState({ FORM_payerId: object.id })
  handleDatePickerChange = async (date) => this.setState({ FORM_date: moment(`${date.format('YYYY-MM-DD')}T20:00:00Z`) }) // note we force 8pm UTC that day
  handleFileUploadChange = async (event) => this.setState({ FORM_receipt: event.target.files[0] })
  handleRadio = async (event) => this.setState({ [event.target.name]: event.target.id })

  componentDidMount = () => {
    const [{ user, organization }, dispatch] = this.context
    if (this.props.expenseToEdit?.fields) {
      let expense = this.props.expenseToEdit.fields
      this.setState({
        // FORM_merchant: expense.merchant,
        FORM_receipt: expense.receipt,
        FORM_initialReceiptUrl: this.props.initialReceiptUrl ? this.props.initialReceiptUrl : expense?.receipt?.url,
        FORM_total: expense.total,
        FORM_currency: expense.currency,
        FORM_date: expense.date ? moment.utc(expense.date) : null,
        FORM_category: expense.category,
        FORM_payerId: expense.payerId,
        // FORM_approved: expense.approved,
        // FORM_type: expense.type,
        // FORM_payment_method: expense.payment_method,
        // FORM_trip: expense.trip,
        FORM_possiblePayers: [this.props.worker].concat(organization?.fields?.members),
        shouldUpdatePhoto: false,
      })
    } else {
      this.setState({
        FORM_receipt: null,
        FORM_initialReceiptUrl: this.props.initialReceiptUrl ? this.props.initialReceiptUrl : null,
        FORM_payerId: user.id,
        FORM_currency: 'USD',
        FORM_total: '',
        FORM_category: 'FOOD',
        FORM_date: moment(),
        FORM_possiblePayers: [this.props.worker].concat(organization?.fields?.members),
        shouldUpdatePhoto: true,
      })
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const [{ user, organization }] = this.context
    if (this.props.open && !prevProps.open) {
      if (this.props.expenseToEdit?.fields) {
        let expense = this.props.expenseToEdit.fields
        this.setState({
          //   FORM_merchant: expense.merchant,
          FORM_receipt: expense.receipt,
          FORM_initialReceiptUrl: this.props.initialReceiptUrl ? this.props.initialReceiptUrl : expense?.receipt?.url,
          FORM_total: expense.total,
          FORM_currency: expense.currency,
          FORM_date: expense.date ? moment.utc(expense.date) : null,
          FORM_category: expense.category,
          FORM_payerId: expense.payerId,
          // FORM_approved: expense.approved,
          // FORM_type: expense.type,
          // FORM_payment_method: expense.payment_method,
          // FORM_trip: expense.trip,
          FORM_possiblePayers: [this.props.worker].concat(organization?.fields?.members),
          shouldUpdatePhoto: false,
        })
      } else {
        this.setState({
          FORM_receipt: null,
          FORM_initialReceiptUrl: this.props.initialReceiptUrl ? this.props.initialReceiptUrl : null,
          FORM_payerId: user.id,
          FORM_currency: 'USD',
          FORM_total: '',
          FORM_category: 'FOOD',
          FORM_date: moment(),
          FORM_possiblePayers: [this.props.worker].concat(organization?.fields?.members),
          shouldUpdatePhoto: true,
        })
      }
    }
  }

  validateForm = () => {
    const { FORM_merchant, FORM_total, FORM_currency, FORM_date, FORM_category, FORM_receipt } = this.state
    if (!FORM_total) {
      this.setState({ FORM_ERROR_total: 'Provide a total' })
      return false
    }
    return true
  }


  handleCreateExpense = async (e) => {
    e.preventDefault()
    const [{ firebaseUser }, dispatch] = this.context;

    // if (!this.validateForm()){ return }

    this.setState({ waitingOnAPIResult: true })

    let fileURL = undefined
    if (this.state.shouldUpdatePhoto && this.state.FORM_receipt) {
      try {
        fileURL = await uploadFile(this.props.trip.fields?.UserId, this.state.FORM_receipt)
        console.log(fileURL)
      } catch (error) {
        console.log(error)
      }
    }

    try {
      let body = {
        merchant: '',
        total: parseFloat(this.state.FORM_total),
        currency: this.state.FORM_currency,
        date: (this.state.FORM_date) ? this.state.FORM_date : null,
        category: this.state.FORM_category,
        payerId: this.state.FORM_payerId,
        receipt: fileURL ? fileURL : null
      }
      if (this.props.expenseToEdit) {
        let document = await patchExpense(firebaseUser, this.props.trip.id, this.props.expenseToEdit.id, body)
      } else {
        let document = await createExpense(firebaseUser, this.props.trip.id, body)
      }
      this.closeAndResetModal()
    } catch (error) {
      console.log(error)
      this.setState({ waitingOnAPIResult: false })
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to create document. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true
      })
      return
    }
  }

  closeAndResetModal = async () => {
    const [{ employerWorkersAndMeta }, dispatch] = this.context;
    const { employerId } = this.props.match.params
    const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

    this.setState({
      waitingOnAPIResult: false,
      FORM_type: '',
      FORM_document: '',
      FORM_date: null,
    })
    dispatch({
      type: 'showNotification',
      notificationDetails: { title: 'Success!', content: `Updated expense!`, success: true },
      showNotification: true
    })
    this.props.pullLatestAfterUpdate()
    this.props.setOpen(false)
  }

  render() {
    const [{ organization }, dispatch] = this.context;
    const { t } = this.props // translations
    const { waitingOnAPIResult, apiErrorMessage } = this.state

    return (
      <Modal
        title={this.props.document ? 'Modify Expense' : 'Add Expense'}
        subtitle='Upload a receipt of something you purchased.'
        buttonLabel='Submit'
        open={this.props.open}
        setOpen={this.props.setOpen}
        actionOnClick={this.handleCreateExpense}
      >
        <ExpenseForm
          handleChange={this.handleChange}
          handleListBoxChange={this.handleListBoxChange}
          handleDatePickerChange={this.handleDatePickerChange}
          handleFileUploadChange={this.handleFileUploadChange}
          handleRadio={this.handleRadio}
          onSubmit={this.handleCreateExpense}
          possiblePayers={this.state.FORM_possiblePayers}
          trip={this.props.trip}
          FORM_total={this.state.FORM_total}
          FORM_currency={this.state.FORM_currency}
          FORM_date={this.state.FORM_date}
          FORM_category={this.state.FORM_category}
          FORM_payerId={this.state.FORM_payerId}
          FORM_receipt={this.state.FORM_receipt}
          initialReceiptUrl={this.state.FORM_initialReceiptUrl}
          shouldUpdatePhoto={this.state.shouldUpdatePhoto}
        />
      </Modal>
    );
  }
}

export default withRouter(withTranslation()(TripModal))