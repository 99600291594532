/*
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
*/
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, FilterIcon } from '@heroicons/react/solid'
import WorkerListFiltersEmployerSearch from './workerListFiltersEmployerSearch.jsx'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function WorkerListFilters(props) {
    // filters
    // my assigned workers => checkbox
    // updated in X days => checkbox
    // for employer => dropdown search

    // Phase  => Radio

const sortOptions = [
    { name: 'First Name', value: 'firstName', current: (props.sortMethod === 'firstName' || !props.sortMethod) },
    { name: 'Last Name', value: 'lastName', current: props.sortMethod === 'lastName' },
    { name: 'Newest', value: 'newest', current: props.sortMethod === 'newest' },
    { name: 'Oldest', value: 'oldest', current: props.sortMethod === 'oldest' },
    { name: 'Recently Updated', value: 'updated', current: props.sortMethod === 'updated' },
]

const filters = {
    assignment: [
      { value: 'me', label: 'Only Me', checked: props.assignedFilter === 'me' },
      { value: 'all', label: 'Everyone', checked: props.assignedFilter === 'all' },
    ],
    size: [
      { value: 'today', label: 'Today', checked: false },
      { value: '2', label: '2 days', checked: true },
      { value: '5', label: '3 days', checked: false },
    ],
  }
  const clearFilters = () => {
    props.setAssignedFilter('all')
    props.setEmployerFilter(null)
  }
  let countFilters = 0
  if (props.assignedFilter !== 'all') {
    countFilters += 1
  }
  if (props.employerFilter) {
    countFilters += 1
  }
      
  return (
    <div className="bg-white">

      {/* Filters */}
      <Disclosure
        as="section"
        aria-labelledby="filter-heading"
        className="relative z-10 grid items-center border-t border-b border-gray-200"
      >
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>
        <div className="relative col-start-1 row-start-1 py-4">
          <div className="flex px-6 mx-auto space-x-6 text-sm divide-x divide-gray-200 max-w-7xl">
            <div>
              <Disclosure.Button className={`flex items-center font-medium group ${countFilters == 0 ? "text-gray-700" : "text-blue-500"}`}>
                <FilterIcon
                  className={`flex-none w-5 h-5 mr-2  group-hover:text-gray-500 ${countFilters == 0 ? "text-gray-400" : "text-blue-500"}`}
                  aria-hidden="true"
                />
                {countFilters} Filters
              </Disclosure.Button>
            </div>
            <div className="pl-6">
              <button type="button" className="text-gray-500" onClick={() => clearFilters()}>
                Clear all
              </button>
            </div>
          </div>
        </div>
        <Disclosure.Panel className="py-4 border-t border-gray-200">
          <div className="grid grid-cols-1 px-6 mx-auto text-sm max-w-7xl gap-x-4 ">
            <div className="grid grid-cols-2 gap-y-10 auto-rows-min">
              <fieldset>
                <legend className="block font-medium">Assigned to</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  {filters.assignment.map((option, optionIdx) => (
                    <div key={option.value} className="flex items-center text-base sm:text-sm">
                      <input
                        id={`assignment-${optionIdx}`}
                        name="assignment[]"
                        defaultValue={option.value}
                        type="radio"
                        onChange={() => props.setAssignedFilter(option.value)}
                        className="w-4 h-4 text-blue-600 border-gray-300 cursor-pointer focus:ring-blue-500 "
                        defaultChecked={option.checked}
                        checked={option.checked}
                      />
                      <label htmlFor={`assignment-${optionIdx}`} className="flex-1 min-w-0 ml-3 text-gray-600 cursor-pointer ">
                        {option.label}
                      </label>
                    </div>
                  ))}
                </div>
              </fieldset>
              <fieldset>
                <legend className="block font-medium">Employer</legend>
                <div className="pt-6 space-y-6 sm:pt-4 sm:space-y-4">
                  <WorkerListFiltersEmployerSearch 
                    employerFilter={props.employerFilter}
                    setEmployerFilter={props.setEmployerFilter}
                  />
                </div>
              </fieldset>
            </div>
          </div>
        </Disclosure.Panel>
        <div className="col-start-1 row-start-1 py-4">
          <div className="flex justify-end px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
            <Menu as="div" className="relative inline-block">
              <div className="flex">
                <Menu.Button className="inline-flex justify-center text-sm font-medium text-gray-700 group hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="flex-shrink-0 w-5 h-5 ml-1 -mr-1 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 w-40 mt-2 origin-top-right bg-white rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.value}>
                        {({ active }) => (
                          <a
                            onClick={() => props.setSortMethod(option.value)}
                            className={classNames(
                              option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm cursor-pointer'
                            )}
                          >
                            {option.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
        </div>
      </Disclosure>
    </div>
  )
}