import React from 'react'
import { Link } from "react-router-dom"
import { StateContext } from '../../../../util/state.jsx'
import { ChevronDownIcon, ChevronRightIcon } from '@heroicons/react/outline'

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default class TeamPage extends React.Component {
    static contextType = StateContext

    state = {
    }

    componentDidMount = () => {
    }

    render() {
        const [{ isLoadingOrganization, organization }, dispatch] = this.context;
        const teamList = organization?.fields?.members

        return (
            <div className="grid w-full grid-flow-row auto-rows-max">
                <div className="px-4 pt-4 pb-4 shadow bg-slate-50">
                    <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                        <div className="flex items-center justify-between">
                            <div className="flex-1 min-w-0">
                                <nav className="sm:flex" aria-label="Breadcrumb">
                                    <ol role="list" className="flex items-center space-x-4">
                                        <li>
                                            <div className="flex">
                                                <Link to='/facilitator/employers' className="text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    <h2 className="font-bold leading-7 text-gray-900 text-l sm:truncate">My Team</h2>
                                                </Link>
                                            </div>
                                        </li>
                                        <li>
                                            <div className="flex items-center">
                                                <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                                                <Link to="/facilitator/employers" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                                                    <h2 className="font-bold leading-7 text-l sm:truncate">All team members</h2>
                                                </Link>
                                            </div>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <div className="flex mt-4 md:mt-0 md:ml-4">
                                <div className="flex items-center justify-center mr-4">
                                {/* <button
                                    type="button"
                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                >
                                    Invite Team Member
                                </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
                    {/* Replace with your content */}
                    <div className="py-5">
                        <div className="flex flex-col">
                            <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                                    <div className="overflow-hidden border-b border-gray-200 rounded-sm shadow">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                    >
                                                        Name
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                    >
                                                        Contact
                                                    </th>
                                                    <th
                                                        scope="col"
                                                        className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                                    >
                                                        Role
                                                    </th>
                                                </tr>
                                            </thead>
                                            {teamList &&
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {teamList.map(person => (
                                                        <tr className="cursor-pointer hover:bg-gray-50" key={person.id}>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="flex items-center">
                                                                    <div className="flex-shrink-0 w-10 h-10">
                                                                        {person?.fields?.photo ? (
                                                                            <img className="object-cover w-10 h-10 rounded-full" src={person?.fields?.photo.url} alt="" />
                                                                        ) : (
                                                                            <span className="inline-block w-10 h-10 overflow-hidden bg-gray-100 rounded-full">
                                                                                <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                                </svg>
                                                                            </span>
                                                                        )}
                                                                    </div>
                                                                    <div className="ml-4">
                                                                        <div className="text-sm font-medium text-gray-900">{person?.fields?.displayName}</div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                <div className="text-sm text-gray-900">{person?.fields?.displayPhoneNumber}</div>
                                                                <div className="text-sm text-gray-500">{person?.fields?.email}</div>
                                                            </td>
                                                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{person?.fields?.role === 'FACILITATOR_ADMIN' ? 'Admin' : 'Member'}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            }
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* {employersAndPagination &&
            <nav
              className="flex items-center justify-between px-4 py-3 bg-white border-gray-200 sm:px-6"
              aria-label="Pagination"
            >
              <div className="hidden sm:block">
                <p className="text-sm text-gray-700">
                  Showing <span className="font-medium">{firstRecordCount}</span> to <span className="font-medium">{lastRecordCount}</span> of{' '}
                  <span className="font-medium">{totalCount}</span> employers
                </p>
              </div>
              <div className="flex justify-between flex-1 sm:justify-end">
                <a
                  onClick={() => this.handlePaginationChange(-1)}
                  className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Previous
                </a>
                <a
                  onClick={() => this.handlePaginationChange(1)}
                  className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                >
                  Next
                </a>
              </div>
            </nav>
          } */}
                    </div>
                    {/* /End replace */}
                </div>
            </div>
        )
    }
}


