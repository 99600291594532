import React from 'react'
import { useContext } from 'react'
import { StateContext } from '../../../util/state'
import { format, parseISO } from 'date-fns'
import { Badge, Message } from '../../../components/Tailwind'
import { HiInformationCircle } from 'react-icons/hi2'


const AccountProfile = () => {
  const [{ user }] = useContext(StateContext)
  return (
    <div className='px-6 mx-auto max-w-lg py-6 space-y-6'>
      <Message color='blue' icon={<HiInformationCircle className={`h-5 w-5 text-blue-400`} aria-hidden="true" />} details='Contact your facilitator if this information is incorrect. '/>
      <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
        <div className='px-4 py-5 sm:px-6'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>Your Profile</h3>
          <p className='mt-1 max-w-2xl text-sm text-gray-500'>Personal details and information for your visa application.</p>
        </div>
        <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
          <dl className='sm:divide-y sm:divide-gray-200'>
            <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Full name</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.displayName}</dd>
            </div>
            <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Date of Birth</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.dateOfBirth ? format(parseISO(user?.fields?.dateOfBirth), 'PP') : '--'}</dd>
            </div>
          </dl>
        </div>
      </div>

      <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
        <div className='px-4 py-5 sm:px-6'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>Contact Information</h3>
          <p className='mt-1 max-w-2xl text-sm text-gray-500'>You may be contacted by Phone, WhatsApp, or Email.</p>
        </div>
        <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
          <dl className='sm:divide-y sm:divide-gray-200'>
            <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Phone Number {user?.fields?.preferredContactMethod === 'phone' && <Badge className='ml-2' color='green' label='Preferred' />}</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.displayPhoneNumber}</dd>
            </div>
            <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>WhatsApp Number {user?.fields?.preferredContactMethod === 'whatsapp' && <Badge className='ml-2' color='green' label='Preferred' />}</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.displayPhoneNumber}</dd>
            </div>
            <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Email {user?.fields?.preferredContactMethod === 'email' && <Badge className='ml-2' color='green' label='Preferred' />}</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.email}</dd>
            </div>
          </dl>
        </div>
      </div>

      <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
        <div className='px-4 py-5 sm:px-6'>
          <h3 className='text-lg font-medium leading-6 text-gray-900'>Passport Details</h3>
          <p className='mt-1 max-w-2xl text-sm text-gray-500'>Your passport information used for your Visa.</p>
        </div>
        <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
          <dl className='sm:divide-y sm:divide-gray-200'>
            <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Passport Number</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.passportNumber || '--'}</dd>
            </div>
            <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Address</dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.displayAddress || '--'}</dd>
            </div>
            <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Issue Date </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.passportIssueDate || '--'}</dd>
            </div>
            <div className='py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
              <dt className='text-sm font-medium text-gray-500'>Expiration Date </dt>
              <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{user?.fields?.passportExpirationDate || '--'}</dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  )
}

export default AccountProfile
