import React from 'react'
import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { CheckIcon, ExclamationIcon, XIcon } from '@heroicons/react/outline'
import { classNames } from '../../../util/helpers'

function FullScreenModal(props) {
    const cancelButtonRef = useRef(null)

    const { title, subtitle, buttonLabel, children, open, setOpen, actionOnClick, warning } = props;

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} onClose={setOpen}>
                <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        enterTo="opacity-100 translate-y-0 sm:scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                        leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                    >
                        <div className="inline-block align-top bg-white text-left shadow-xl transform transition-all h-screen w-full">
                            <div class="flex flex-col">
                                <div className="flex-initial">
                                    <div className="bg-white px-4 pt-4 pb-4 shadow">
                                        <div className="md:flex md:items-center md:justify-between">
                                            <div className="flex-1 min-w-0">
                                                <h2 className="text-xl font-bold leading-7 text-gray-900 sm:truncate">{title}</h2>
                                            </div>
                                            <div className="mt-4 flex md:mt-0 md:ml-4">
                                                <button
                                                    type="button"
                                                    onClick={actionOnClick}
                                                    className="ml-4 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-bold rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                                >
                                                    Send Invitations
                                                    <CheckIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                                                </button>
                                                <button
                                                    type="button"
                                                    ref={cancelButtonRef}
                                                    onClick={() => setOpen(false)}
                                                    className="ml-4 inline-flex items-center border-transparent text-sm font-medium rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 "
                                                >
                                                    Cancel
                                                    <XIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex-1">
                                    {children}
                                </div>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>

    )
}

FullScreenModal.defaultProps = {
    warning: false,
    subtitle: null
}

export default FullScreenModal
