import React from 'react'
import { Redirect, Route, Switch } from "react-router-dom"
import { StateContext } from '../../util/state.jsx'

import {
  retrieveOrganization,
  listEmployersForOrganization,
  listInvitationsForOrganization,
  listWorkers,
  listJobs,
  listWorkersForOrganizationAndEmployer
} from '../../models/organization'

import TeamPage from './pages/Team'
import EmployersList from './pages/Employers/EmployerList'
import EmployerDetailPage from './pages/Employers/EmployerDetail'
import BulkSendInvitationsPage from './pages/Employers/EmployerDetail/bulkSendInvitations.jsx'
import WorkerDetailPage from './pages/Employers/WorkerDetail'
import InvitationDetailPage from './pages/Employers/WorkerDetail/invitationDetail'

import {
  CogIcon,
  LogoutIcon,
  UsersIcon,
  OfficeBuildingIcon,
  UserAddIcon,
  NewspaperIcon,
  HomeIcon
} from '@heroicons/react/outline'

import NotificationToast from './components/NotificationToast/index.jsx'
import MyProfile from './pages/MyProfile/index.jsx'
import LeftNavigation from './components/LeftNavigation/index.jsx'
import WorkerDirectory from './pages/WorkerDirectory/index.jsx';
import AddWorkersPage from './pages/AddWorkers/index.jsx'
import JobsPage from './pages/Jobs/index.jsx'
import JobDetailPage from './pages/Jobs/JobDetail/index.jsx'
import AddJobsFlow from './pages/Jobs/AddJobsFlow/index.jsx'
import DashboardPage from './pages/Dashboard/index.jsx'
import { HiCalendarDays, HiChartPie, HiOutlineUsers, HiPresentationChartBar, HiQueueList } from 'react-icons/hi2'
import ActivityFeedPage from './pages/ActivityFeed/index.jsx'


export default class FacilitatorPortal extends React.Component {
  static contextType = StateContext

  state = {
    sidebarOpen: false
  }

  componentDidMount = () => {
    this.retrieveThisOrganization()
    this.listEmployers(null, true)
    this.listWorkers(null)
    // this.listJobs(null)
  }

  retrieveThisOrganization = async () => {
    const [{ user }, dispatch] = this.context;
    dispatch({ type: 'isLoadingOrganization', isLoadingOrganization: true })
    try {
      let organization = await retrieveOrganization(null, user.fields?.organizationId)
      dispatch({
        type: 'setOrganization',
        isLoadingOrganization: false,
        organization: organization
      })
    } catch (error) {
      dispatch({ type: 'setApiErrorMessage', apiErrorMessage: `Something went wrong. ${error.message}` })
      dispatch({
        type: 'setOrganization',
        isLoadingOrganization: false,
        organization: null
      })
    }
  }


  listEmployers = async (filtersAndPagination, mergeResults = false) => {
    // MergeResults is used in case a user navigates directly to an employer. We set this to true on first load of employer details
    const [{ user }, dispatch] = this.context;
    dispatch({ type: 'isLoadingEmployers', isLoadingEmployers: true })
    try {
      let newEmployersAndPagination = await listEmployersForOrganization(null, user.fields?.organizationId, filtersAndPagination)
      const [{ employersAndPagination }] = this.context;
      newEmployersAndPagination.result = mergeResults ? employersAndPagination?.result.concat(newEmployersAndPagination?.result) : newEmployersAndPagination?.result
      dispatch({
        type: 'setEmployersAndPagination',
        isLoadingEmployers: false,
        employersAndPagination: newEmployersAndPagination
      })
    } catch (error) {
      // this.setState({organization: null, isLoadingOrganization: false, apiErrorMessage: `Something went wrong when trying to load organization. ${error.message}`})
    }
  }

  listWorkersForOrganizationAndEmployer = async (employerId, filtersAndPagination) => {
    const [{ user }, dispatch] = this.context;
    dispatch({ type: 'setIsLoadingEmployerWorkersAndMeta', isLoadingEmployerWorkersAndMeta: true })
    try {
      let workersAndMeta = await listWorkersForOrganizationAndEmployer(null, user.fields?.organizationId, employerId, filtersAndPagination)
      dispatch({
        type: 'setEmployerWorkersAndMeta',
        isLoadingEmployerWorkersAndMeta: false,
        workersAndMeta: workersAndMeta,
        employerId: employerId
      })
    } catch (error) {
      dispatch({
        type: 'setEmployerWorkersAndMeta',
        isLoadingEmployerWorkersAndMeta: false,
        workersAndMeta: null,
        employerId: employerId
      })
    }
  }

  listWorkers = async (filtersAndPagination) => {
    const [{ user }, dispatch] = this.context;
    dispatch({ type: 'setIsLoadingWorkers', isLoadingWorkers: true })
    try {
      let workersAndMeta = await listWorkers(null, user.fields?.organizationId, filtersAndPagination)
      dispatch({
        type: 'setWorkersAndMeta',
        isLoadingWorkers: false,
        workersAndMeta: workersAndMeta,
      })
    } catch (error) {
      dispatch({
        type: 'setWorkersAndMeta',
        isLoadingWorkers: false,
        workersAndMeta: null,
      })
    }
  }

  listJobs = async (filtersAndPagination) => {
    const [{ user }, dispatch] = this.context;
    dispatch({ type: 'setIsLoadingJobs', isLoadingJobs: true })
    try {
      let jobsAndMeta = await listJobs(user.fields?.organizationId, filtersAndPagination)
      dispatch({
        type: 'setJobsAndMeta',
        isLoadingJobs: false,
        jobsAndMeta: jobsAndMeta,
      })
    } catch (error) {
      dispatch({
        type: 'setJobsAndMeta',
        isLoadingJobs: false,
        jobsAndMeta: null,
      })
    }
  }

  listInvitationsForOrganizationAndEmployer = async (employerId, filtersAndPagination) => {
    const [{ user }, dispatch] = this.context;
    dispatch({ type: 'isLoadingInvitations', isLoadingInvitations: true })
    try {
      const invitationsAndPagination = await listInvitationsForOrganization(null, user.fields?.organizationId, employerId, filtersAndPagination)
      dispatch({
        type: 'setEmployerInvitationsAndPagination',
        isLoadingInvitations: false,
        invitationsAndPagination: invitationsAndPagination,
        employerId: employerId
      })
    } catch (error) {
      // this.setState({invitations: null, isLoadingInvitations: false, apiErrorMessage: `Something went wrong when trying to load invitations. ${error.message}`})
    }
  }

  handleNavClick = (location) => { this.props.history.push(`/facilitator/${location}`) }

  setSidebarOpen = (newState = false) => {
    this.setState({ sidebarOpen: newState })
  }

  render() {
    const [{ user, showNotification, notificationDetails, firebaseUser }, dispatch] = this.context;

    // I don't think we have to check this anymore bc we have a proper privateRoute function
    // if (!(firebaseUser && user)) {
    //   console.log('sending user to login')
    //   return (
    //     <Redirect to={`/login?redirect=${window.location.pathname}${window.location.search}`} />
    //   )
    // }

    if (user.fields.group === 'EMPLOYER') {
      return (<Redirect to={{ pathname: '/employer', query: this.props.location.query, search: this.props.location.search }} />)
    } else if (user.fields.group === 'EMPLOYEE') {
      return (<Redirect to={{ pathname: '/account', query: this.props.location.query, search: this.props.location.search }} />)
    }

    if (this.props.location.pathname === '/facilitator') {
      console.log('redirecting')
      return (<Redirect to='/facilitator/dashboard' />)
    }

    const activePage = this.props.location.pathname.split("/")[2]
    return (
      <>
        <div className="flex h-full">
          <LeftNavigation
            navigation={[
              { name: 'Dashboard', href: '/facilitator/dashboard', icon: HiPresentationChartBar, current: activePage === 'dashboard' },
              { name: 'Jobs', href: '/facilitator/jobs', icon: HiCalendarDays, current: activePage === 'jobs' },
              { name: 'Activity Feed', href: '/facilitator/feed', icon: HiQueueList, current: activePage === 'feed' },
              { name: 'Workers', href: '/facilitator/workers', icon: HiOutlineUsers, current: activePage === 'workers' },
              { name: 'Employers', href: '/facilitator/employers', icon: OfficeBuildingIcon, current: activePage === 'employers' },
              { name: 'My Team', href: '/facilitator/team', icon: UsersIcon, current: activePage === 'team' },
            ]}
            userNavigation={[
              { name: 'Your Settings', icon: CogIcon, href: '/facilitator/profile' },
              { name: 'Log Out', icon: LogoutIcon, href: '/logout' },
            ]}
            subNavigation = {[
              // { name: 'Add Employers', href: '/facilitator/addemployers', current: activePage === 'addemployers', icon: OfficeBuildingIcon },
              // { name: 'Add Jobs', href: '/facilitator/addjobs', current: activePage === 'addjobs', icon: NewspaperIcon },
              // { name: 'Add Workers', href: '/facilitator/addworkers', current: activePage === 'addworkers', icon: UserAddIcon },
            ]}
          />
          <main className="flex flex-col w-full h-full overflow-scroll md:pl-64 bg-light">
            <Switch>
              <Route path={'/facilitator/dashboard'}>
                <DashboardPage />
              </Route>
              <Route path={'/facilitator/workers'}>
                <WorkerDirectory />
              </Route>
              <Route exact path={'/facilitator/jobs'}>
                <JobsPage listJobs={this.listJobs} listEmployers={this.listEmployers} />
              </Route>
              <Route path={'/facilitator/feed'}>
                <ActivityFeedPage />
              </Route>
              <Route exact path={'/facilitator/add-jobs'}>
                <AddJobsFlow listJobs={this.listJobs} />
              </Route>
              <Route path={'/facilitator/jobs/:jobId'}>
                <JobDetailPage listJobs={this.listJobs} listWorkers={this.listWorkers} />
              </Route>
              <Route path={'/facilitator/addworkers'}>
                <AddWorkersPage />
              </Route>

              <Route
                path="/facilitator/employers"
                render={({ match: { url } }) => (
                  <>
                    <Route path={`${url}/`} exact>
                      <EmployersList
                        listEmployers={this.listEmployers}
                      />
                    </Route>
                    <Route path={`${url}/:employerId/summary`}>
                      <EmployerDetailPage
                        listEmployers={this.listEmployers}
                        listWorkersForOrganizationAndEmployer={this.listWorkersForOrganizationAndEmployer}
                        listInvitationsForOrganizationAndEmployer={this.listInvitationsForOrganizationAndEmployer}
                      />
                    </Route>
                    <Route
                      path={`${url}/:employerId/invite`}
                      render={(props) => (
                        <BulkSendInvitationsPage {...props}
                          listInvitationsForOrganizationAndEmployer={this.listInvitationsForOrganizationAndEmployer}
                        />
                      )}
                    />
                    <Route
                      path={`${url}/:employerId/workers/:workerId`}
                      render={(props) => (
                        <WorkerDetailPage
                          {...props}
                          listEmployers={this.listEmployers}
                          listWorkersForOrganizationAndEmployer={this.listWorkersForOrganizationAndEmployer}
                        />
                      )}
                    />
                    <Route
                      path={`${url}/:employerId/invitations/:invitationId`}
                      render={(props) => (
                        <InvitationDetailPage {...props}
                          listInvitationsForOrganizationAndEmployer={this.listInvitationsForOrganizationAndEmployer}
                        />
                      )}
                    />
                  </>
                )}
              />
              <Route exact path='/facilitator/team'>
                <TeamPage />
              </Route>
              <Route exact path='/facilitator/profile'>
                <MyProfile
                  retrieveThisOrganization={this.retrieveThisOrganization}
                />
              </Route>
              <Route>
                <Redirect to='/facilitator/workers' />
              </Route>
            </Switch>
          </main>
        </div>

        {/* Global Notification */}
        <NotificationToast
          show={showNotification}
          setShow={() => dispatch({ type: 'showNotification', showNotification: false, notificationDetails: {} })}
          title={notificationDetails?.title}
          content={notificationDetails?.content}
          success={notificationDetails?.success}
        />
      </>

    )
  }
}