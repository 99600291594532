import React, { Fragment, Component } from 'react'
import { StateContext } from '../../../../../../util/state.jsx'
import moment from 'moment'
import { Transition, Menu } from '@headlessui/react';
import { classNames } from '../../../../../../util/helpers.js'
import { deleteExpense } from '../../../../../../models/expense.jsx';
import ExpenseModal from '../../../../modals/expenseModal.jsx';
import { withRouter } from 'react-router';
import { Badge, ConfirmModal, Modal } from '../../../../../../components/Tailwind'
import {
  XCircleIcon,
  ChevronDownIcon,
  PencilAltIcon,
  EyeIcon,
} from '@heroicons/react/outline'
import { expenseCategoryOptions } from '../../../../../../util/constants.js';
import { MdPictureAsPdf } from "react-icons/md"
import { CheckIcon } from '@heroicons/react/solid';

class ExpenseSection extends Component {
  static contextType = StateContext
  constructor(props) {
    super(props);
    this.dropzoneRef = React.createRef()
  }
  state = {
    editExpenseModalIsOpen: false,
    deleteExpenseModalIsOpen: false,
    viewExpenseModalIsOpen: false,
    selectedExpense: null,
  }
  toggleExpenseModal = (open, expense) => this.setState({ editExpenseModalIsOpen: open, selectedExpense: expense })
  toggleDeleteExpenseModal = (open, expense) => this.setState({ deleteExpenseModalIsOpen: open, selectedExpense: expense })
  toggleViewExpenseModal = (open, expense) => this.setState({ viewExpenseModalIsOpen: open, selectedExpense: expense })
  handleDeleteExpense = async (tripId, expenseId) => {
    const [{ firebaseUser, employerWorkersAndMeta }, dispatch] = this.context;
    const { employerId } = this.props.match.params
    const filtersAndPagination = employerWorkersAndMeta[employerId]?.meta

    this.setState({ waitingOnAPIResult: true })

    try {
      let expense = await deleteExpense(firebaseUser, tripId, expenseId)
      this.setState({ waitingOnAPIResult: false })
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Success!', content: `Deleted expense.`, success: true },
        showNotification: true
      })
      this.props.pullLatestAfterUpdate()
      this.toggleDeleteExpenseModal(false)
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Failed to remove document.', content: `${error.message}`, success: false },
        showNotification: true
      })
      return
    }
  }

  render() {
    const { expenses, worker, trip } = this.props

    const totalUSD = expenses.reduce((partial_sum, a) => partial_sum + (a?.fields?.currency === 'USD' ? Number(a?.fields?.total) : 0), 0);
    const totalMXN = expenses.reduce((partial_sum, a) => partial_sum + (a?.fields?.currency === 'MXN' ? Number(a?.fields?.total) : 0), 0);
    let expenseCategoryLabels = {}
    expenseCategoryOptions.forEach(ec => expenseCategoryLabels[ec.key] = ec.text)

    expenses.sort((a, b) => a.fields?.date > b.fields?.date)
    expenses.sort((a, b) => a.fields?.payer?.firstName < b.fields?.payer?.firstName)

    return (

      <div className={``}>
        <div className="flex flex-col">
          <div className="px-1 overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-md">
                <table className="min-w-full divide-y divide-gray-300">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="py-2 pl-4 pr-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap"
                      >
                        Date
                      </th>

                      <th
                        scope="col"
                        className="px-2 py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap"
                      >
                        Receipt
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 text-sm font-semibold text-left text-gray-900 whitespace-nowrap"
                      >
                        Paid By
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 text-sm font-semibold text-right text-gray-900 whitespace-nowrap"
                      >
                        USD
                      </th>
                      <th
                        scope="col"
                        className="px-2 py-2 text-sm font-semibold text-right text-gray-900 whitespace-nowrap"
                      >
                        MXN
                      </th>
                      <th scope="col" className="relative py-2 pl-3 pr-4 whitespace-nowrap sm:pr-6">
                        <span className="sr-only">Edit</span>
                      </th>
                      <th scope="col" className="relative py-2 pl-3 pr-4 whitespace-nowrap sm:pr-6">
                        <span className="sr-only">Remove</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {expenses.map((expense) => (
                      <tr key={expense.id}>
                        <td className="py-2 pl-4 pr-2 text-sm text-gray-500 whitespace-nowrap">{moment(expense?.fields?.date).format('MMM DD YYYY')}</td>
                        <td className="px-2 py-2 text-sm text-red-600 whitespace-nowrap">
                          {expense?.fields?.receipt?.url ? (
                            <a onClick={() => this.toggleViewExpenseModal(true, expense)} className="text-blue-600 cursor-pointer hover:text-blue-900">View<span className="sr-only">, {expense.id}</span></a>
                          ) : (
                            <a
                              className="relative inline-flex items-center text-sm rounded-full"
                            >
                              <span className="absolute flex items-center justify-center flex-shrink-0">
                                <span
                                  className={classNames('bg-red-600', 'h-1.5 w-1.5 rounded-full')}
                                  aria-hidden="true"
                                />
                              </span>
                              <span className="ml-3.5 font-medium text-red-500">No Receipt</span>
                            </a>
                          )}

                        </td>
                        <td className="px-2 py-2 text-sm text-gray-900 whitespace-nowrap">{expenseCategoryLabels[expense?.fields?.category]}</td>
                        <td className="px-2 py-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                          {expense?.fields?.payer?.firstName + ' ' + expense?.fields?.payer?.lastName}
                        </td>
                        <td className="px-2 py-2 text-sm text-right text-gray-500 whitespace-nowrap">{expense?.fields?.currency === 'USD' ? (parseFloat(expense?.fields?.total)).toLocaleString('en-US', { style: 'currency', currency: 'USD' }) : ''}</td>
                        <td className="px-2 py-2 text-sm text-right text-gray-500 whitespace-nowrap">{expense?.fields?.currency === 'MXN' ? (parseFloat(expense?.fields?.total)).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' }) : ''}</td>
                        <td className="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                          <a onClick={() => this.toggleExpenseModal(true, expense)} className="text-blue-600 cursor-pointer hover:text-blue-900">
                            Edit<span className="sr-only">, {expense.id}</span>
                          </a>
                        </td>
                        <td className="relative py-2 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6">
                          <a onClick={() => this.toggleDeleteExpenseModal(true, expense)} className="text-red-600 cursor-pointer hover:text-blue-900">
                            Delete<span className="sr-only">, {expense.id}</span>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
            <tr>
            <th
                scope="row"
                colSpan={2}
                className="pl-4"
              >
            <a
                    onClick={() => this.toggleExpenseModal(true, null)}
                    className={`${this.props.readonly ? 'hidden' : ''} flex items-center justify-center w-full px-4 py-2 text-sm font-bold text-white bg-green-600 border border-transparent rounded-md shadow-sm cursor-pointer hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                  >
                    Add New Expense
                  </a>

              </th>


              <th
                scope="row"
                colSpan={2}
                className="hidden px-2 py-6 pl-6 text-sm font-normal text-right text-gray-500 sm:table-cell md:pl-0"
              >
                Subtotal
              </th>
              <th scope="row" className="px-2 py-6 text-sm font-normal text-left text-gray-500 sm:hidden">
                Subtotal
              </th>
              <td className="px-2 py-6 text-sm text-right text-gray-500">
                { (totalUSD).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}
              </td>
              <td className="px-2 py-6 text-sm text-right text-gray-500">
                { (totalMXN).toLocaleString('es-MX', { style: 'currency', currency: 'MXN' })}
              </td>
            </tr>
          </tfoot>
                </table>
              </div>
            </div>
          </div>
        </div>

        
        <ExpenseModal
          open={this.state.editExpenseModalIsOpen}
          setOpen={this.toggleExpenseModal}
          expenseToEdit={this.state.selectedExpense}
          worker={worker}
          trip={trip}
          pullLatestAfterUpdate={this.props.pullLatestAfterUpdate}
        />
        <ConfirmModal
          title='Delete Expense'
          content='Are you sure you delete this document?'
          buttonLabel='Delete'
          open={this.state.deleteExpenseModalIsOpen}
          setOpen={this.toggleDeleteExpenseModal}
          actionOnClick={() => this.handleDeleteExpense(trip.id, this.state.selectedExpense.id)}
        />
        <Modal
          title='View Expense'
          buttonLabel='Done'

          open={this.state.viewExpenseModalIsOpen}
          setOpen={this.toggleViewExpenseModal}
          actionOnClick={() => this.toggleViewExpenseModal(false, null)}
        >
          {this.state.selectedExpense?.fields?.receipt?.url.includes('.pdf') ?
            <object data={this.state.selectedExpense?.fields?.receipt?.url} type="application/pdf" className='h-96' width="100%" height="100%">
              <p><a href={this.state.selectedExpense?.fields?.receipt?.url}>Click here to view</a></p>
            </object>
            :
            <img className="rounded-md h-50 w-50" src={this.state.selectedExpense?.fields?.receipt?.url} alt="" />
          }
        </Modal>


      </div>
    )
  }
}

export default withRouter(ExpenseSection)