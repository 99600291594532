import axios from 'axios'
import moment from 'moment'
import { APIError, handleAxiosAPIError } from '../util/errors.js'

export const createTrip = async (firebaseUser, tripData) => {
  try {
    let result = await axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_HOST}/trips`,
      data: tripData
    })
    let trip = result.data
    // trip.fields.departureDate = trip.fields.departureDate ? moment.utc(trip.fields.departureDate) : null
    // trip.fields.arrivalDate = trip.fields.arrivalDate ? moment.utc(trip.fields.arrivalDate) : null    
    return trip
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const patchTrip = async (firebaseUser, tripData, tripId) => {
  try {
    let result = await axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_HOST}/trips/${tripId}`,
      data: tripData
    })
    let trip = result.data
    // trip.fields.departureDate = trip.fields.departureDate ? moment.utc(trip.fields.departureDate) : null
    // trip.fields.arrivalDate = trip.fields.arrivalDate ? moment.utc(trip.fields.arrivalDate) : null    
    return trip
  } catch(error) {
    handleAxiosAPIError(error)
  }
}

export const listTrips = async (firebaseUser) => {
  try {
    let result = await axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_HOST}/trips`,
    })
    let trips = result.data
    // trips.forEach(trip => {
    //   trip.fields.departureDate = trip.fields.departureDate ? moment.utc(trip.fields.departureDate) : null
    //   trip.fields.arrivalDate = trip.fields.arrivalDate ? moment.utc(trip.fields.arrivalDate) : null
    // })
    return trips
  } catch(error) {
    handleAxiosAPIError(error)
  }
}
