import React, { Fragment, useContext, useEffect, useState } from 'react'
import { HiBriefcase, HiCalendar, HiMapPin, HiOutlineArrowDownTray, HiOutlineSparkles, HiPencil, HiUser, HiUsers } from 'react-icons/hi2'
import { useRouteMatch } from 'react-router-dom'
import { Button } from '../../../components/Tailwind'
import Container from '../../../components/Tailwind/Container'
import { downloadContractForWorkerOnJob, downloadJobReport, retrieveJob } from '../../../models/organization'
import { StateContext } from '../../../util/state'
import { retrieveJobForEmployer } from '../../../models/employer'
import WorkerTable from '../../FacilitatorV2/pages/Jobs/JobDetail/workerTable'
import AvatarImage from '../../../components/AvatarImage'
import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, SearchIcon } from '@heroicons/react/solid'
import { classNames } from '../../../util/helpers'
import moment from 'moment'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min.js'

const EmployerJobDetailPage = props => {
  const [{ user, organization, analytics }, dispatch] = useContext(StateContext)
  const match = useRouteMatch()
  const { employerId, jobId } = match.params
  const [job, setJob] = useState(undefined)
  const [isLoadingJob, setIsLoadingJob] = useState(true)
  const [searchText, setSearchText] = useState('')
  const history = useHistory()
  const [isLoadingJobReport, setisLoadingJobReport] = useState(false)

  const retrieveJobDetails = async () => {
    setIsLoadingJob(true)
    if (true) {
      try {
        const job = await retrieveJobForEmployer(employerId, jobId)
        setJob(job)
        setIsLoadingJob(false)
      } catch (err) {
        console.log(err)
        // setIsLoadingJob(false)
        dispatch({
          type: 'showNotification',
          notificationDetails: { title: `Failed to get job.`, waitingOnAPIResult: false },
          showNotification: true,
        })
      }
    }
  }

  useEffect(() => {
    // retrieveJobDetails()
    if (user) {
      retrieveJobDetails()
    }
  }, [user])

  const handleSearchChange = e => {
    const searchText = e.target.value
    setSearchText(searchText)
  }

  const handleDownloadJobReport = async () => {
    try {
      setisLoadingJobReport(true)
      const response = await downloadJobReport(organization.id, job.id)
      await props.retrieveJobDetails()
      if (!response?.downloadUrl) {
        return
      }
      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = response?.downloadUrl
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Done!', content: `You successfully downloaded the report. `, success: true },
        showNotification: true,
      })
      if (analytics) analytics.track('Downloaded Job Report')
      setisLoadingJobReport(false)
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Failed to download the report.', content: `${error.message}`, success: false },
        showNotification: true,
      })
      setisLoadingJobReport(false)
      return
    }
  }

  const handleDownloadContract = async worker => {
    try {
      // setisLoadingViewContract(true)
      // setviewContractModalIsOpen({ open: true, contractUrl: null })

      const response = await downloadContractForWorkerOnJob(organization?.id, job?.id, worker?.id)
      console.log(response)
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `${job?.fields?.employer?.fields?.name}_${worker?.fields?.displayName}_${moment().format('yyyy-MM-DD')}.zip`) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)

      // setviewContractModalIsOpen({ open: true, contractUrl: result?.data?.url })
      // setisLoadingViewContract(false)
      // window.open(result?.data?.url, '_blank')
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to view contract. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  const filteredWorkers = searchText?.length > 0 && !isLoadingJob ? job?.fields?.workers.filter(worker => worker?.fields?.displayName.toLowerCase().includes(searchText.toLowerCase())) : job?.fields?.workers

  return (
    <div className='h-full bg-light'>
      <Container className='bg-white shadow'>
        <div className='w-full md:flex md:items-center md:justify-between'>
          <div className='flex-1 min-w-0 py-1.5'>
            <h2 className='text-xl font-medium text-gray-900' onClick={() => history.push('/')}>Jobs / {job?.fields?.number}</h2>
          </div>
          <div className='flex mt-4 space-x-4 md:mt-0 md:ml-4'></div>
        </div>
      </Container>
      <Container className=' max-w-7xl'>
        {/* Page heading */}
        <header className='pb-6'>
          <div className='flex items-center justify-between'>
            <div className='flex-1 min-w-0'>
              <h1 className='mt-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight'>{job?.fields?.employer?.fields?.name}</h1>
              <div className='flex flex-col mt-1 space-x-4 sm:mt-0 sm:flex-row sm:flex-wrap'>
                <div className='flex items-center mt-2 text-sm text-gray-500'>
                  <HiBriefcase className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                  Petition # {job?.fields?.referenceId || '--'}
                </div>
                <div className='flex items-center mt-2 text-sm text-gray-500'>
                  <HiMapPin className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                  {job?.fields?.employer?.fields?.address || '' + ', ' + job?.fields?.employer?.fields?.city || '' + ', ' + job?.fields?.employer?.fields?.stateProvinceRegion || '' + ' ' + job?.fields?.employer?.fields?.postalCode || ''}
                </div>
                <div className='flex items-center mt-2 text-sm text-gray-500'>
                  <HiUsers className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                  {job?.fields?.workers.length} Total Workers
                </div>
                <div className='flex items-center mt-2 text-sm text-gray-500'>
                  <HiCalendar className='mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                  {job?.fields?.season} season
                </div>
              </div>
            </div>
            <div className='flex mt-5 xl:mt-0 xl:ml-4'></div>
          </div>
        </header>
        {isLoadingJob && 'loading...'}
        {/* <WorkerTable job={job} isLoadingJob={isLoadingJob} retrieveJobDetails={retrieveJobDetails} listJobs={props.listJobs} /> */}

        <div>
          <div className='py-4 md:flex md:items-center md:justify-between'>
            <div className='flex'>
              <label htmlFor='search' className='sr-only'>
                Search
              </label>
              <div className='relative rounded-md shadow-sm'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                  <SearchIcon className='w-5 h-5 text-gray-400' aria-hidden='true' />
                </div>
                <input
                  type='search'
                  name='search'
                  id='search'
                  onChange={handleSearchChange}
                  value={searchText}
                  className='block w-full pl-10 border-gray-300 rounded-md focus:ring-primary-300 focus:border-primary-300 sm:text-sm'
                  placeholder='Search Workers'
                />
              </div>
            </div>
            <div className='flex items-center space-x-2'>
              <h2 className='text-sm font-bold uppercase'>Job Report: </h2>
              <Button disabled={job?.fields?.reportUrl === null} color='white' onClick={() => window.open(job?.fields?.reportUrl, '_blank', 'noreferrer')}>
                <HiOutlineArrowDownTray className='w-5 h-5 mr-2' />
                Download
              </Button>
            </div>
          </div>

          <div className='flex flex-col'>
            <div>
              <div className='inline-block min-w-full align-middle'>
                <div className='relative rounded-sm shadow ring-1 ring-black ring-opacity-5'>
                  <table className='min-w-full divide-y divide-gray-300 table-fixed'>
                    <thead className='bg-white'>
                      <tr>
                        <th scope='col' className='w-36 pl-6 py-3.5 pr-3 text-left text-sm font-semibold text-gray-900'>
                          <div>Worker</div>
                        </th>
                        <th scope='col' className='w-44 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Contact
                        </th>
                        <th scope='col' className='w-44 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Passport
                        </th>
                        <th scope='col' className='w-44 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Date of Birth
                        </th>
                        <th scope='col' className='w-44 px-3 py-3.5 text-left text-sm font-semibold text-gray-900'>
                          Status
                        </th>
                        <th scope='col' className='w-12 relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6'>
                          <span className='sr-only'>Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className='bg-white divide-y divide-gray-200'>
                      {filteredWorkers &&
                        filteredWorkers.map((worker, index) => (
                          <tr key={worker.id} className={'hover:bg-gray-50'}>
                            <td className={classNames('cursor-pointer whitespace-nowrap py-3  pl-6 pr-3 text-sm font-medium', 'text-gray-900')}>
                              <div className='flex items-center'>
                                <div className='flex-shrink-0 w-10 h-10'>{worker?.fields?.photo ? <img className='object-cover w-10 h-10 rounded-full' src={worker?.fields?.photo.url} alt='' /> : <AvatarImage size={10} />}</div>
                                <div className='ml-4'>
                                  <div className='flex font-medium text-gray-900'>{worker?.fields?.displayName}</div>
                                  <div className='text-gray-500'>{worker?.fields?.displayAddress}</div>
                                </div>
                              </div>
                            </td>

                            <td className='px-3 py-3 text-sm text-gray-500 whitespace-nowrap'>
                              <p>{worker?.fields?.email}</p>
                              <p>{worker?.fields?.displayPhoneNumber}</p>
                            </td>

                            <td className='px-3 py-3 text-sm text-gray-500 whitespace-nowrap'>
                              <p>{worker?.fields?.passportNumber}</p>
                            </td>

                            <td className='px-3 py-3 text-sm text-gray-500 whitespace-nowrap'>{worker?.fields?.dateOfBirth ? moment(worker?.fields?.dateOfBirth).format('LL') : '--'}</td>

                            <td className='flex justify-between px-3 py-4 space-x-8 text-sm whitespace-nowrap items-top'>
                              <div className='break-all'>
                                <p className='justify-center'>
                                  {worker?.fields?.stytchUserId ? (
                                    <svg className='w-3 h-3 mx-auto mb-1 text-green-400' fill='currentColor' viewBox='0 0 8 8'>
                                      <circle cx={4} cy={4} r={3} />
                                    </svg>
                                  ) : (
                                    <svg className='w-3 h-3 mx-auto mb-1 text-red-400' fill='currentColor' viewBox='0 0 8 8'>
                                      <circle cx={4} cy={4} r={3} />
                                    </svg>
                                  )}
                                </p>
                                <p className='w-24 text-xs text-center'>Registered</p>
                              </div>

                              <div className='break-all'>
                                <p className='justify-center'>
                                  {worker?.jobDetails?.externalContractStatus === 'completed' ? (
                                    <svg className='w-3 h-3 mx-auto mb-1 text-green-400' fill='currentColor' viewBox='0 0 8 8'>
                                      <circle cx={4} cy={4} r={3} />
                                    </svg>
                                  ) : (
                                    <svg className='w-3 h-3 mx-auto mb-1 text-red-400' fill='currentColor' viewBox='0 0 8 8'>
                                      <circle cx={4} cy={4} r={3} />
                                    </svg>
                                  )}
                                </p>
                                <p className='w-24 text-xs text-center'>Contract Signed</p>
                              </div>
                            </td>

                            <td className='relative py-3 pl-3 pr-4 text-sm font-medium text-right whitespace-nowrap sm:pr-6' onClick={e => e.stopPropagation()}>
                              <Menu as='div' className='flex-shrink-0 pr-2'>
                                <Menu.Button className='inline-flex items-center justify-center w-8 h-8 text-gray-400 bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-purple-500 focus:ring-offset-2'>
                                  <span className='sr-only'>Open options</span>
                                  <DotsVerticalIcon className='w-5 h-5' aria-hidden='true' />
                                </Menu.Button>
                                <Transition
                                  as={Fragment}
                                  enter='transition ease-out duration-100'
                                  enterFrom='transform opacity-0 scale-95'
                                  enterTo='transform opacity-100 scale-100'
                                  leave='transition ease-in duration-75'
                                  leaveFrom='transform opacity-100 scale-100'
                                  leaveTo='transform opacity-0 scale-95'
                                >
                                  <Menu.Items className='absolute z-10 w-48 mx-3 mt-1 origin-top-right bg-white divide-y divide-gray-200 rounded-md shadow-lg cursor-pointer right-10 top-3 ring-1 ring-black ring-opacity-5 focus:outline-none'>
                                    <div className='py-1'>
                                      {worker?.jobDetails?.externalContractStatus === 'completed' && (
                                        <Menu.Item>
                                          {({ active }) => (
                                            <a onClick={() => handleDownloadContract(worker)} className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm')}>
                                              Download Contract
                                            </a>
                                          )}
                                        </Menu.Item>
                                      )}
                                    </div>
                                  </Menu.Items>
                                </Transition>
                              </Menu>
                            </td>
                          </tr>
                        ))}

                      {isLoadingJob && (
                        <tr>
                          <td colSpan='8'>
                            <div className='flex p-4 space-x-4 animate-pulse'>
                              <div className='w-10 h-10 rounded-full bg-slate-200'></div>
                              <div className='flex-1 py-1 space-y-4'>
                                <div className='h-2 rounded bg-slate-200'></div>
                                <div className='space-y-3'>
                                  <div className='grid grid-cols-3 gap-4'>
                                    <div className='h-2 col-span-2 rounded bg-slate-200'></div>
                                    <div className='h-2 col-span-1 rounded bg-slate-200'></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  )
}

export default EmployerJobDetailPage
