import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import Badge from '../../../../../components/Tailwind/Badge'
import { Modal } from '../../../../../components/Tailwind'
import UserProfileModal from '../../../modals/userProfileModal.jsx'
import moment from 'moment'
import { HiCheckCircle, HiDevicePhoneMobile, HiMapPin, HiOutlineCheckCircle, HiOutlinePhone, HiPhone } from 'react-icons/hi2'
import { HiOutlineMail } from 'react-icons/hi'

export default class WorkerSection extends Component {
  state = {
    editProfileModalIsOpen: false,
    waitingOnAPIResult: false,
  }
  toggleModal = open => this.setState({ editProfileModalIsOpen: open })

  render() {
    const { worker } = this.props
    return (
      <section aria-labelledby='applicant-information-title'>
        <div className=''>
          <div className='flex flex-wrap items-center justify-between px-4 py-4 sm:px-6 sm:flex-nowrap'>
            <div className='mt-2'>
              <h2 className='text-lg font-medium leading-6 text-gray-900'>
                Worker Profile
                <p className='text-gray-500 text-sm'>Key details required for this worker's visa application. </p>
              </h2>
            </div>
            <div className='flex-shrink-0 ml-4'>
              <button
                type='button'
                onClick={() => this.toggleModal(true)}
                className={`${
                  this.props.readonly ? 'hidden' : ''
                } inline-flex items-center justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500`}
              >
                Update Profile
              </button>
              <UserProfileModal open={this.state.editProfileModalIsOpen} setOpen={this.toggleModal} profile={worker} pullLatestAfterUpdate={this.props.pullLatestAfterUpdate} />
            </div>
          </div>

          <div className='overflow-hidden bg-white shadow sm:rounded-lg'>
            <div className='border-t border-gray-200 px-4 py-5 sm:p-0'>
              <dl className='sm:divide-y sm:divide-gray-200'>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>Full name</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{worker?.fields?.displayName}</dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>Contact Info</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center'>
                    <HiDevicePhoneMobile className={`mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400`} aria-hidden='true' />
                    <p className={`${worker?.fields?.preferredContactMethod === 'PHONE' ? ' underline decoration-primary-300 underline-offset-4 decoration-2' : ''}`}>{worker?.fields?.displayPhoneNumber || ''}</p>

                    <HiOutlineMail className='ml-4 mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400' aria-hidden='true' />
                    
                    <p className={`${worker?.fields?.preferredContactMethod === 'EMAIL' ? ' underline decoration-primary-300 underline-offset-4 decoration-2' : ''}`}>{worker?.fields?.email || ''}</p>
                  </dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>Date of Birth</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{worker?.fields?.dateOfBirth ? moment(worker?.fields?.dateOfBirth).format('LL') : ''}</dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>Passport</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0'>{worker?.fields?.passportNumber}</dd>
                </div>
                <div className='py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6'>
                  <dt className='text-sm font-medium text-gray-500'>Address</dt>
                  <dd className='mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0 flex items-center'>
                    {worker?.fields?.address1 || '' + ', ' + worker?.fields?.city || '' + ', ' + worker?.fields?.stateProvinceRegion || '' + ' ' + worker?.fields?.postalCode || ''}
                  </dd>
                </div>
              </dl>
            </div>
          </div>
        </div>
      </section>
    )
  }
}
