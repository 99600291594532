import React, { useState, useContext } from 'react'
import { Modal } from '../../../../../components/Tailwind/index.jsx'
import AssignFacilitatorForm from '../../../forms/AssignFacilitatorForm/index.jsx'
import { StateContext } from '../../../../../util/state.jsx'
import { patchJob } from '../../../../../models/organization.jsx'
import { useEffect } from 'react'

const AssignFacilitatorToJobModal = props => {
  const [{ organization }, dispatch] = useContext(StateContext)
  const { open } = props
  const [waitingOnAPIResult, setWaitingOnAPIResult] = useState(false)
  const [formValues, setFormValues] = useState({
    FORM_assignedFacilitatorUserId: props?.job?.fields?.assignedFacilitatorUserId,
  })

  useEffect(() => {
    setFormValues({ ...formValues, FORM_assignedFacilitatorUserId: props?.job?.fields?.assignedFacilitatorUserId })
  }, [open])

  const handleDropdownChange = async e => setFormValues({ ...formValues, FORM_assignedFacilitatorUserId: e.id })

  const close = async () => {
    setFormValues({
      FORM_assignedFacilitatorUserId: null,
    })
    props.setOpen(false)
  }

  const handlePatchJob = async e => {
    e.preventDefault()
    setWaitingOnAPIResult(true)

    try {
      await patchJob(organization.id, props.job.id, {
        assignedFacilitatorUserId: formValues?.FORM_assignedFacilitatorUserId,
      })
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Success!', content: `Assigned facilitator.`, success: true },
        showNotification: true,
      })
      await props.listJobs(null)
      close()
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to assign facilitator. ${error.message}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      setWaitingOnAPIResult(false)
      return
    }
  }

  return (
    <Modal
      title={'Assign Facilitator for Job'}
      subtitle='Assign a facilitator so that the worker has a point of contact with your company to help them complete their travel tasks.'
      buttonLabel='Assign'
      open={props.open}
      setOpen={close} // this only closes the modal
      actionOnClick={handlePatchJob}
      loading={waitingOnAPIResult}
    >
      <AssignFacilitatorForm FORM_assignedFacilitatorUserId={formValues?.FORM_assignedFacilitatorUserId} people={organization?.fields?.members} handleChange={handleDropdownChange} onSubmit={handlePatchJob} />
    </Modal>
  )
}

export default AssignFacilitatorToJobModal
