import React, { Fragment } from 'react'
import { withRouter, Link } from "react-router-dom"
import { StateContext } from '../../../../../util/state.jsx'
import { ChevronDownIcon, ChevronRightIcon, XIcon } from '@heroicons/react/outline'
import { Button } from '../../../../../components/Tailwind'
import moment from 'moment'
import EmployerModal from '../../../modals/employerModal.jsx'
import { Transition, Popover } from '@headlessui/react'
import { SpinnerCircularFixed } from 'spinners-react'


class EmployersPage extends React.Component {
  static contextType = StateContext

  state = {
    isLoadingSearch: false,
    searchText: null,
    addEmployerModalIsOpen: false,
  }

  handlePaginationChange = (delta) => {
    const [{ employersAndPagination }, dispatch] = this.context;
    const filtersAndPagination = employersAndPagination.meta
    filtersAndPagination['currentPage'] = filtersAndPagination['currentPage'] + delta
    this.props.listEmployers(filtersAndPagination)
  }

  clearFilters = () => {
    this.setState({ searchText: '' })
    this.props.listEmployers(null)
  }

  toggleAddEmployerModal = (open) => this.setState({ addEmployerModalIsOpen: open })

  componentDidMount = () => {
    const [{ employersAndPagination }, dispatch] = this.context;
    const filtersAndPagination = employersAndPagination.meta
    if (filtersAndPagination.searchText) {
      this.setState({ searchText: filtersAndPagination.searchText })
    }
  }

  handleSearchChange = (e) => {
    const searchText = e.target.value
    this.setState({ searchText: searchText })
  }

  handleFilterChange = async (e) => {
    const [{ employersAndPagination }, dispatch] = this.context;
    const filtersAndPagination = employersAndPagination.meta

    filtersAndPagination['updatesInLastXDays'] = e.target.checked ? e.target.value : null

    this.setState({ isLoadingSearch: true })
    await this.props.listEmployers(filtersAndPagination)
    this.setState({ isLoadingSearch: false })
  }

  render() {
    const [{ isLoadingEmployers, employersAndPagination }, dispatch] = this.context;
    let filtersAndPagination = employersAndPagination?.meta
    let firstRecordCount = (filtersAndPagination?.currentPage - 1) * filtersAndPagination?.pageSize + 1
    let lastRecordCount = filtersAndPagination?.count < (filtersAndPagination?.currentPage * filtersAndPagination?.pageSize) ? filtersAndPagination?.count : filtersAndPagination?.currentPage * filtersAndPagination?.pageSize
    let totalCount = filtersAndPagination?.count

    let employers = this.state.searchText ? employersAndPagination.result.filter(e => e.fields?.name.toLowerCase().includes(this.state.searchText.toLowerCase())) : employersAndPagination.result

    return (
      <div className="grid w-full grid-flow-row auto-rows-max">
        <div className="px-4 pt-4 pb-4 shadow bg-slate-50">
          <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
            <div className="flex items-center justify-between">
              <div className="flex-1 min-w-0">
                <nav className="sm:flex" aria-label="Breadcrumb">
                  <ol role="list" className="flex items-center space-x-4">
                    <li>
                      <div className="flex">
                        <Link to='/facilitator/employers' className="text-sm font-medium text-gray-500 hover:text-gray-700">
                          <h2 className="font-bold leading-7 text-gray-900 text-l sm:truncate">Employers</h2>
                        </Link>
                      </div>
                    </li>
                    <li>
                      <div className="flex items-center">
                        <ChevronRightIcon className="flex-shrink-0 w-5 h-5 text-gray-400" aria-hidden="true" />
                        <Link to="/facilitator/employers" className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                          <h2 className="font-bold leading-7 text-l sm:truncate">All</h2>
                        </Link>
                      </div>
                    </li>
                  </ol>
                </nav>

              </div>
              <div className="flex mt-4 md:mt-0 md:ml-4">
                <div className="flex items-center justify-center mr-4">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 mx-auto max-w-7xl sm:px-6 md:px-8">
          <header className="mt-4">
            <section aria-labelledby="filter-heading" className="">

              <div className="flex items-center justify-between">
                <div className="flex items-center justify-center mr-4">
                  <div className="relative z-10 inline-block text-left">
                    <h1 className="text-2xl font-bold text-gray-900">Your Employers</h1>
                  </div>
                </div>
                <div className="flex items-center justify-center mr-4">
                  <Button
                    onClick={() => this.toggleAddEmployerModal(true)}
                  >
                    Add Employer
                  </Button>
                  <EmployerModal
                    open={this.state.addEmployerModalIsOpen}
                    setOpen={this.toggleAddEmployerModal}
                    listEmployers={this.props.listEmployers}
                  />
                </div>
              </div>
            </section>
          </header>

          {/* Replace with your content */}
          <div className="py-4">
            <div className="flex flex-col">
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="relative overflow-hidden border border-gray-100 rounded shadow">
                    <header className="px-4 py-4 border-b border-b-gray-200 bg-gray-50">
                      <section aria-labelledby="filter-heading" className="">
                        <h2 id="filter-heading" className="sr-only">
                          Product filters
                        </h2>
                        <div className="flex items-center justify-between">
                          <div className="flex items-center justify-center mr-4">
                            <div className="relative z-10 inline-block text-left">
                              <input
                                type="text"
                                placeholder="Search for an employer..."
                                onChange={this.handleSearchChange}
                                value={this.state.searchText}
                                id="search"
                                className="block border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                              />
                            </div>
                            <Popover.Group className="flex items-baseline ml-4 space-x-8" >
                              <Popover as="div" id="desktop-menu" className="relative z-10 inline-block text-left">
                                <div>
                                  <Popover.Button className="inline-flex items-center justify-center text-sm font-medium text-gray-700 group hover:text-gray-900">
                                    <span>Filters</span>
                                    {filtersAndPagination?.updatesInLastXDays ? (
                                      <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                                        1
                                      </span>
                                    ) : null}
                                    <ChevronDownIcon
                                      className="flex-shrink-0 w-5 h-5 ml-1 -mr-1 text-gray-400 group-hover:text-gray-500"
                                      aria-hidden="true"
                                    />
                                  </Popover.Button>
                                </div>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-100"
                                  enterFrom="transform opacity-0 scale-95"
                                  enterTo="transform opacity-100 scale-100"
                                  leave="transition ease-in duration-75"
                                  leaveFrom="transform opacity-100 scale-100"
                                  leaveTo="transform opacity-0 scale-95"
                                >
                                  <Popover.Panel className="absolute right-0 p-4 mt-2 origin-top-right bg-white rounded-md shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                    <form className="space-y-4">
                                      <div className="flex items-center">
                                        <input
                                          id={`filter-updatesInLast7Days`}
                                          name='updatesInLast7Days'
                                          value={7}
                                          defaultValue={false}
                                          checked={Number(filtersAndPagination?.updatesInLastXDays) === 7 ? true : false}
                                          type="checkbox"
                                          onChange={this.handleFilterChange}
                                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={`filter-updatesInLast7Days`}
                                          className="pr-6 ml-3 text-sm font-medium text-gray-900 whitespace-nowrap"
                                        >
                                          Updated within 7 days
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id={`filter-updatesInLast2Days`}
                                          name='updatesInLast2Days'
                                          value={2}
                                          defaultValue={false}
                                          checked={Number(filtersAndPagination?.updatesInLastXDays) === 2 ? true : false}
                                          type="checkbox"
                                          onChange={this.handleFilterChange}
                                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={`filter-updatesInLast2Days`}
                                          className="pr-6 ml-3 text-sm font-medium text-gray-900 whitespace-nowrap"
                                        >
                                          Updated within 2 days
                                        </label>
                                      </div>
                                      <div className="flex items-center">
                                        <input
                                          id={`filter-updatesInLast1Days`}
                                          name='updatesInLast1Days'
                                          value={1}
                                          defaultValue={false}
                                          checked={Number(filtersAndPagination?.updatesInLastXDays) === 1 ? true : false}
                                          type="checkbox"
                                          onChange={this.handleFilterChange}
                                          className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                                        />
                                        <label
                                          htmlFor={`filter-updatesInLast3Days`}
                                          className="pr-6 ml-3 text-sm font-medium text-gray-900 whitespace-nowrap"
                                        >
                                          Updated within 1 day
                                        </label>
                                      </div>
                                    </form>
                                  </Popover.Panel>
                                </Transition>
                              </Popover>
                            </Popover.Group>
                            {(filtersAndPagination.searchText || filtersAndPagination.updatesInLastXDays) &&
                              <a
                                onClick={this.clearFilters}
                                className="inline-flex items-center px-4 py-2 ml-4 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md cursor-pointer hover:bg-gray-50"
                              >
                                <XIcon className="-ml-0.5 mr-2 h-4 w-4" aria-hidden="true" />
                                Clear Filters
                              </a>
                            }

                          </div>

                          <div className="flex items-center justify-center mr-4">
                            {this.state.isLoadingSearch || isLoadingEmployers &&
                              <div className="w-4 h-4 border-b-2 border-gray-900 rounded-full animate-spin"></div>
                            }
                          </div>

                        </div>
                      </section>
                    </header>

                    <table className="relative min-w-full divide-y divide-gray-200">
                      {/* Loader */}
                      {(this.state.isLoadingSearch || isLoadingEmployers) &&
                        <div className="w-full h-96">
                          <div className="absolute top-0 left-0 z-50 block w-full h-full bg-white opacity-75">
                            <span className="relative block w-0 h-0 mx-auto opacity-75 top-1/2">
                              <SpinnerCircularFixed className='-mt-6 -ml-6' color='#54537A'/>
                            </span>
                          </div>
                        </div>
                      }
                      <thead className={`${(this.state.isLoadingSearch || isLoadingEmployers) ? 'hidden' :  ''} bg-gray-50`}>
                        <tr>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Name
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Invited Workers
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Active Workers
                          </th>
                          <th
                            scope="col"
                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                          >
                            Added on
                          </th>
                          {/* <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Employee Count
                        </th> */}
                        </tr>
                      </thead>
                      {employers &&
                        <tbody className={`${(this.state.isLoadingSearch || isLoadingEmployers) ? 'hidden' :  ''} bg-white divide-y divide-gray-200`}>
                          {employers.map((employer) => (
                            <tr className="cursor-pointer hover:bg-gray-50" key={employer.fields.name} onClick={() => this.props.history.push(`/facilitator/employers/${employer.fields?.id}/summary`)}>
                              <td className="px-6 py-4 text-sm font-medium text-gray-900 whitespace-nowrap">{employer.fields.name}</td>
                              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{employer.fields?._count?.invitationsAsEmployer}</td>
                              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{employer.fields?._count?.employedBy}</td>
                              <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{moment(employer.createdAt).format('LL')}</td>
                              {/* <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">alex.lorem@gmail.com</td>
                            <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">25</td> */}
                            </tr>
                          ))}
                        </tbody>
                      }
                    </table>
                  </div>
                </div>
              </div>
            </div>
            {employers &&
              <nav
                className={`${(this.state.isLoadingSearch || isLoadingEmployers) ? 'hidden' :  ''} flex items-center justify-between px-4 py-3 bg-white border-gray-200 sm:px-6`}
                aria-label="Pagination"
              >
                <div className="hidden sm:block">
                  <p className="text-sm text-gray-700">
                    Showing <span className="font-medium">{firstRecordCount}</span> to <span className="font-medium">{lastRecordCount}</span> of{' '}
                    <span className="font-medium">{totalCount}</span> employers
                  </p>
                </div>
                <div className="flex justify-between flex-1 sm:justify-end">
                  <a
                    onClick={() => this.handlePaginationChange(-1)}
                    className="relative inline-flex items-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    Previous
                  </a>
                  <a
                    onClick={() => this.handlePaginationChange(1)}
                    className="relative inline-flex items-center px-4 py-2 ml-3 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                  >
                    Next
                  </a>
                </div>
              </nav>
            }
          </div>
          {/* /End replace */}
        </div>
      </div>
    )
  }
}

export default withRouter(EmployersPage)
