import React, { Fragment } from 'react';
import { withTranslation } from "react-i18next";
import { SingleDatePicker } from 'react-dates';
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { classNames } from '../../../../util/helpers.js'
import AvatarImage from '../../../../components/AvatarImage/index.jsx';
import { expenseCategoryOptions } from '../../../../util/constants'
import ImageCropper from '../../../../components/ImageCropper/index.jsx';
import Dropzone from 'react-dropzone'
import { uploadFile } from '../../../../components/Firebase/index.js';

class ExpenseForm extends React.Component {
    constructor(props) {
        super(props);
        this.dropzoneRef = React.createRef()
    }
    state = {
        focused: false,
        dragAndDroppedFileUrl: null,
    }


    onDrop = async (acceptedFiles) => {
        // Assume only 1 for now
        if (this.props.initialReceiptUrl) return
        const file = acceptedFiles[0]
        if (!file) return
        if (this.dropzoneRef.isDragReject) return
        const { trip } = this.props

        let fileURL = undefined
        if (file instanceof File) {
            try {
                fileURL = await uploadFile(trip.fields?.UserId, file)
                console.log(fileURL)
            } catch (error) {
                console.log(error)
            }
        }
        if (!fileURL) return

        console.log(file, fileURL)
        if (file.type === 'application/pdf') {
            const event = { target: { name: 'FORM_receipt', value: file } }
            this.props.handleChange(event)
        }
        this.setState({
            dragAndDroppedFileUrl: fileURL,
        })
    }

    render() {
        const { t } = this.props // translations
        const selectedUser = this.props.possiblePayers.find(i => i.id === this.props.FORM_payerId)
        return (
            <Dropzone
                onDrop={this.onDrop}
                ref={this.dropzoneRef}
                noClick
                noKeyboard
                noDragEventsBubbling
                accept={['image/*', 'application/pdf']}
            >
                {({ getRootProps, getInputProps, isDragAccept }) =>
                (<div className="relative" {...getRootProps()}>
                    <input {...getInputProps()} />
                    <p className={`${isDragAccept && !this.props.initialReceiptUrl ? '' : 'hidden'} z-50 text-lg text-gray-900 absolute -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2`}>Drop your file here</p>
                    <div className={`${isDragAccept && !this.props.initialReceiptUrl ? '' : 'hidden'} absolute left-0 top-0 w-[105%] h-[105%] bg-sky-400 z-50 rounded-lg opacity-30 -m-3 border-dashed border-2 border-slate-900`} />

                    <form className="space-y-8 divide-y divide-gray-200" style={{ minHeight: '200px' }} onSubmit={this.props.onSubmit} >
                        <div className="space-y-8 divide-y divide-gray-200">
                            <div>
                                <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                                    <div className="sm:col-span-6">
                                        <label htmlFor="FORM_departureDate" className="block text-sm font-medium text-gray-700">
                                            Purchase Date
                                        </label>
                                        <div className="mt-1">
                                            <SingleDatePicker
                                                id='FORM_date'
                                                orientation="vertical"
                                                date={this.props.FORM_date}
                                                numberOfMonths={2}
                                                onDateChange={date => this.props.handleDatePickerChange(date)}
                                                isOutsideRange={day => { return false }}
                                                focused={this.state.focused}
                                                onFocusChange={({ focused }) => this.setState({ focused })}
                                            />
                                        </div>
                                    </div>

                            <div className={`${this.props.shouldUpdatePhoto ? 'hidden' : ''} sm:col-span-4`}>
                                <label htmlFor="FORM_email" className="block text-sm font-medium text-gray-700">
                                    Receipt
                                </label>
                                <button
                                    type="button"
                                    onClick={() => this.props.handleChange({ target: { name: 'shouldUpdatePhoto', value: true } })}
                                    className={`w-full flex justify-center mt-1 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500`}
                                >
                                    Change Receipt
                                </button>
                            </div>

                            {this.props.shouldUpdatePhoto &&
                                <ImageCropper
                                    initialDocumentUrl={this.props.initialReceiptUrl ? this.props.initialReceiptUrl : this.state.dragAndDroppedFileUrl}
                                    formInputName='FORM_receipt'
                                    aspectRatio={null}
                                    initialCrop={{
                                        unit: '%', // default, can be 'px' or '%'
                                        x: 0,
                                        y: 0,
                                        width: this.props.FORM_receipt ? 100 : 80,
                                        height: this.props.FORM_receipt ? 100 : 80
                                    }}
                                    handleChange={this.props.handleChange}
                                />
                            }

                                    <div className="sm:col-span-6">
                                        <div>
                                            <label htmlFor="FORM_total" className="block text-sm font-medium text-gray-700">
                                                Total Amount
                                            </label>
                                            <div className="relative mt-1 rounded-md shadow-sm">
                                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                                    <span className="text-gray-500 sm:text-sm">$</span>
                                                </div>
                                                <input
                                                    type="text"
                                                    name="FORM_total"
                                                    id="FORM_total"
                                                    value={this.props.FORM_total}
                                                    onChange={this.props.handleChange}
                                                    className="block w-full pr-12 border-gray-300 rounded-md focus:ring-indigo-500 focus:border-indigo-500 pl-7 sm:text-sm"
                                                    placeholder="0.00"
                                                />
                                                <div className="absolute inset-y-0 right-0 flex items-center">
                                                    <label htmlFor="FORM_currency" className="sr-only">
                                                        Currency
                                                    </label>
                                                    <select
                                                        id="FORM_currency"
                                                        name="FORM_currency"
                                                        value={this.props.FORM_currency}
                                                        onChange={this.props.handleChange}
                                                        className="h-full py-0 pl-2 text-gray-500 bg-transparent border-transparent rounded-md focus:ring-indigo-500 focus:border-indigo-500 pr-7 sm:text-sm"
                                                    >
                                                        <option>USD</option>
                                                        <option>MXN</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="sm:col-span-6">
                                        <label htmlFor="country" className="block text-sm font-medium text-gray-700">
                                            Type
                                        </label>
                                        <div className="mt-1">
                                            <select
                                                id="FORM_category"
                                                name="FORM_category"
                                                value={this.props.FORM_category}
                                                className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                                onChange={this.props.handleChange}
                                            >
                                                {expenseCategoryOptions.sort((a, b) => a.key.localeCompare(b.key)).map(option => (
                                                    <option key={option?.key} value={option?.value}>{option?.text}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="sm:col-span-6">
                                        <Listbox value={selectedUser} onChange={this.props.handleListBoxChange}>
                                            {({ open }) => (
                                                <>
                                                    <Listbox.Label className="block text-sm font-medium text-gray-700">Paid By</Listbox.Label>
                                                    <div className="relative mt-1">
                                                        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                                            {selectedUser ?
                                                                <>
                                                                    <span className="flex items-center">
                                                                        {selectedUser?.fields?.photo ?
                                                                            <img src={selectedUser?.fields?.photo?.url} alt="" className="flex-shrink-0 w-6 h-6 rounded-full" />
                                                                            :
                                                                            <AvatarImage size={6} />
                                                                        }
                                                                        <span className="block ml-3 truncate">{selectedUser?.fields?.displayName}</span>
                                                                    </span>
                                                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                                                        <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                                                    </span>
                                                                </>
                                                                :
                                                                <>
                                                                    <span className="flex items-center">
                                                                        <span className="inline-block w-6 h-6 overflow-hidden bg-gray-100 rounded-full">
                                                                            <svg className="w-full h-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                                                                <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                                                            </svg>
                                                                        </span>

                                                                        <span className="block ml-3 truncate">Not Assigned</span>
                                                                    </span>
                                                                    <span className="absolute inset-y-0 right-0 flex items-center pr-2 ml-3 pointer-events-none">
                                                                        <SelectorIcon className="w-5 h-5 text-gray-400" aria-hidden="true" />
                                                                    </span>
                                                                </>
                                                            }

                                                        </Listbox.Button>

                                                        <Transition
                                                            show={open}
                                                            as={Fragment}
                                                            leave="transition ease-in duration-100"
                                                            leaveFrom="opacity-100"
                                                            leaveTo="opacity-0"
                                                        >
                                                            <Listbox.Options className="absolute z-10 w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-56 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {this.props?.possiblePayers.map((person) => (
                                                                    <Listbox.Option
                                                                        key={person.id}
                                                                        className={({ active }) =>
                                                                            classNames(
                                                                                active ? 'text-white bg-indigo-600' : 'text-gray-900',
                                                                                'cursor-default select-none relative py-2 pl-3 pr-9'
                                                                            )
                                                                        }
                                                                        value={person}
                                                                    >
                                                                        {({ selected, active }) => (
                                                                            <>
                                                                                <div className="flex items-center">
                                                                                    {person?.fields?.photo ?
                                                                                        <img src={person?.fields?.photo?.url} alt="" className="flex-shrink-0 w-6 h-6 rounded-full" />
                                                                                        :
                                                                                        <AvatarImage size={6} />
                                                                                    }
                                                                                    <span
                                                                                        className={classNames(selected ? 'font-semibold' : 'font-normal', 'ml-3 block truncate')}
                                                                                    >
                                                                                        {person?.fields?.displayName}
                                                                                    </span>
                                                                                </div>

                                                                                {selected ? (
                                                                                    <span
                                                                                        className={classNames(
                                                                                            active ? 'text-white' : 'text-indigo-600',
                                                                                            'absolute inset-y-0 right-0 flex items-center pr-4'
                                                                                        )}
                                                                                    >
                                                                                        <CheckIcon className="w-5 h-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </Listbox.Option>
                                                                ))}
                                                            </Listbox.Options>
                                                        </Transition>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>
                                    </div>



                                </div>

                            </div>
                        </div>
                        <button className={'hidden'} type="submit" />
                    </form>
                </div>
                )}
            </Dropzone>

        );
    }
}

export default withTranslation()(ExpenseForm)