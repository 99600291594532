import React, { Component } from 'react';
import { Redirect, Route, Link, withRouter } from "react-router-dom"
import { StateContext } from '../../../../../util/state.jsx'
import {PlusIcon} from '@heroicons/react/outline'
import moment from 'moment';



class InvitationsLedger extends Component {
    static contextType = StateContext

    handlePaginationChange = (delta) => {
        const [{employerInvitationsAndPagination}, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const filtersAndPagination = employerInvitationsAndPagination[employerId]?.meta
        filtersAndPagination['currentPage'] = filtersAndPagination['currentPage'] + delta
        this.props.listInvitationsForOrganizationAndEmployer(employerId, filtersAndPagination)
      }  
    

    render() {
        const [{ employerInvitationsAndPagination }, dispatch] = this.context;
        const { employerId } = this.props.match.params
        const invitedWorkers = employerInvitationsAndPagination[employerId]?.result
        let filtersAndPagination = employerInvitationsAndPagination[employerId]?.meta
        let firstRecordCount = (filtersAndPagination?.currentPage-1) * filtersAndPagination?.pageSize + 1
        let lastRecordCount = filtersAndPagination?.count < (filtersAndPagination?.currentPage * filtersAndPagination?.pageSize) ? filtersAndPagination?.count : filtersAndPagination?.currentPage * filtersAndPagination?.pageSize
        let totalCount = filtersAndPagination?.count        

        return (
            <>
                <div className="md:flex md:items-center md:justify-between mt-4">
                    <div className="flex-1 min-w-0">
                        <h1 className="text-2xl font-bold text-gray-900">All Invited Workers</h1>
                        <p className="text-s text-gray-500">Workers who have been invited to Ruta but have not accepted their invitation.</p>
                    </div>
                    <div className="mt-4 flex md:mt-0 md:ml-4">

                        {/* <div className="relative flex items-center min-w-0 mr-4">
                            <input
                                type="text"
                                placeholder="Search workers..."
                                id="search"
                                className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full pr-12 sm:text-sm border-gray-300 rounded-md"
                            />
                            <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                <kbd className="inline-flex items-center border border-gray-200 rounded px-2 text-sm font-sans font-medium text-gray-400">
                                    ⌘K
                                </kbd>
                            </div>
                        </div>
                        <button
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                        >
                            Edit Employer
                        </button> */}

                    </div>
                </div>
                <div className="flex flex-col py-4">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                        <tr>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Name
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Email
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Phone Number
                                            </th>
                                            <th
                                                scope="col"
                                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                                            >
                                                Invited On
                                            </th>                                            
                                            <th scope="col" className="relative px-6 py-3">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                        {invitedWorkers.map((invitedWorker) => (
                                            <tr key={invitedWorker?.id} className="cursor-pointer hover:bg-gray-50" onClick={() => this.props.history.push(`/facilitator/employers/${employerId}/invitations/${invitedWorker?.id}`) }>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{invitedWorker?.fields?.firstName} {invitedWorker?.fields?.lastName}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invitedWorker?.fields?.email}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{invitedWorker?.fields?.displayPhoneNumber}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{moment(invitedWorker?.createdAt).format('YYYY-MM-DD')}</td>
                                                <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                                    <a href="#" className="text-indigo-600 hover:text-indigo-900">
                                                        Edit
                                                    </a>
                                                </td>
                                            </tr>
                                        ))}                                        
                                    </tbody>
                                </table>
                            </div>
                            <nav
                                className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6"
                                aria-label="Pagination"
                            >
                                <div className="hidden sm:block">
                                    <p className="text-sm text-gray-700">
                                        Showing <span className="font-medium">{firstRecordCount}</span> to <span className="font-medium">{lastRecordCount}</span> of{' '}
                                        <span className="font-medium">{totalCount}</span> results
                                    </p>
                                </div>
                                <div className="flex-1 flex justify-between sm:justify-end">
                                    <a
                                        onClick={ () => this.handlePaginationChange(-1)}
                                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        Previous
                                    </a>
                                    <a
                                        onClick={ () => this.handlePaginationChange(1)}
                                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
                                    >
                                        Next
                                    </a>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

InvitationsLedger.propTypes = {

};

export default withRouter(InvitationsLedger);