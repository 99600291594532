import React from 'react'
import { DayPicker } from 'react-day-picker';
import { format,  } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import { Message } from '../../../components/Tailwind';
import { HiCheckBadge } from 'react-icons/hi2';

const css = `
  .my-selected:not([disabled]) { 
    border: 2px solid currentColor;
    font-weight: bold;
    font-size: 140%;
    background-color: #F2DA92;
  }
  .rdp-button:hover:not([disabled]):not(.rdp-day_selected) { 
    background-color: #FBF7F4;
  }
  .my-today { 
    font-weight: bold;
    font-size: 140%;
  }
`;

const Step2Verify = (props) => {
  const footer = props?.formValues?.FORM_dateOfBirth ? (
    <p>You selected {format(props?.formValues?.FORM_dateOfBirth, 'PPP')}.</p>
  ) : (
    <p>Please pick a day.</p>
  );

  return (
    <div className="mb-6">
      <div className='flex mb-6 justify-center'>
      <style>{css}</style>
        <DayPicker 
          fromYear={1960} 
          toYear={2023} 
          dateFormat="MM-DD-YYYY"
          defaultMonth={new Date(1982, 0)}
          captionLayout="dropdown" 
          mode="single"
          selected={props?.formValues?.FORM_dateOfBirth}
          onSelect={date => {
            const adjustedDate = new Date(`${format(date, 'yyyy-MM-dd')}T12:00:00.0Z`)
            const timeZone = 'UTC'
            const zonedDate = utcToZonedTime(adjustedDate, timeZone)
            props.handleChange(null, {name: 'FORM_dateOfBirth', value: zonedDate})}
          }
          modifiersClassNames={{
            selected: 'my-selected',
            today: 'my-today'
          }}
          modifiersStyles={{
            disabled: { fontSize: '75%' }
          }}
          footer={footer}
        />
      </div>
      {props.isVerified && <Message className="flex items-center" color='green'>You are verified! <HiCheckBadge className="ml-1.5 w-6 h-6" /></Message>}

    </div>
  )
}

export default Step2Verify
