import React from 'react'
import { StateContext } from '../../../../util/state.jsx'
import { patchUser } from '../../../../models/user.jsx'
import { uploadFile } from '../../../../components/Firebase'
import { Button } from '../../../../components/Tailwind'
import { withRouter } from 'react-router'
import UserProfileForm from '../../forms/UserProfileForm/index.jsx'
import Container from '../../../../components/Tailwind/Container/index.jsx'

class MyProfilePage extends React.Component {
  static contextType = StateContext

  state = {
    waitingOnAPIResult: false,
    apiErrorMessage: undefined,

    FORM_firstName: '',
    FORM_lastName: '',
    FORM_email: '',
    FORM_photo: '',
    FORM_phoneNumber: '',
    shouldUpdatePhoto: false,
  }

  handleChange = async event => {
    console.log(event)
    this.setState({ [event.target.name]: event.target.value })
  }
  handleDropdownChange = async (e, target) => this.setState({ [target.name]: target.value })
  handleFileUploadChange = async event => this.setState({ FORM_photo: event.target.files[0] })

  componentDidMount = () => {
    const [{ user }] = this.context
    this.setState({
      FORM_firstName: user?.fields?.firstName,
      FORM_lastName: user?.fields?.lastName,
      FORM_email: user?.fields?.email,
      FORM_photo: user?.fields?.photo,
      FORM_phoneNumber: user?.fields?.phoneNumber,
    })
  }

  handlePatchUser = async e => {
    e.preventDefault()
    const [{ firebaseUser, user, firebaseUserIdToken }, dispatch] = this.context
    this.setState({ waitingOnAPIResult: true })

    let fileURL = undefined
    if (this.state.FORM_photo && (this.state.FORM_photo instanceof File || this.state.FORM_photo instanceof Blob)) {
      try {
        fileURL = await uploadFile(user.id, this.state.FORM_photo)
        console.log(fileURL)
      } catch (error) {
        console.log(error)
      }
    }

    // Only update phone number if it changed to be extra safe
    let newPhoneNumber = `+${this.state?.FORM_phoneNumber.replace(/\D/g, '')}`
    let oldPhoneNumber = `+${user?.fields?.phoneNumber.replace(/\D/g, '')}`
    newPhoneNumber = newPhoneNumber !== oldPhoneNumber ? newPhoneNumber : null

    try {
      let patchedUserResult = await patchUser(firebaseUser, user.id, {
        firstName: this.state.FORM_firstName,
        lastName: this.state.FORM_lastName,
        email: this.state.FORM_email,
        photo: fileURL ? fileURL : null,
        ...(newPhoneNumber && { phoneNumber: newPhoneNumber }),
      })
      this.setState({
        waitingOnAPIResult: false,
      })
      dispatch({ type: 'setUser', user: patchedUserResult })
      this.props.retrieveThisOrganization()
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: 'Success!', content: `Updated your profile.`, success: true },
        showNotification: true,
      })
    } catch (error) {
      console.log(error)
      dispatch({
        type: 'showNotification',
        notificationDetails: { title: `Failed to update profile. ${error.message} ${error.details}`, waitingOnAPIResult: false },
        showNotification: true,
      })
      return
    }
  }

  render() {
    const [{ user }, dispatch] = this.context
    const { waitingOnAPIResult, apiErrorMessage } = this.state

    if (!user) {
      return <p>loading...</p>
    }

    return (
      <>
        <Container className='bg-white shadow'>
          <div className='md:flex md:items-center md:justify-between'>
            <div className='flex-1 min-w-0 py-1.5'>
              <h2 className='text-xl font-medium text-gray-900'>Profile Settings</h2>
            </div>
          </div>
        </Container>
        <div className='px-4 max-w-7xl sm:px-6 md:px-8'>
          {/* Replace with your content */}

          <div className='flex-1 max-w-2xl py-12 pb-10'>
            <div className='space-y-6 sm:px-6 lg:px-0 lg:col-span-9'>
              <section aria-labelledby='payment-details-heading'>
                <div className='shadow sm:rounded-md sm:overflow-hidden'>
                  <div className='px-4 py-6 bg-white sm:p-6'>
                    <div>
                      <h2 id='payment-details-heading' className='text-lg font-medium leading-6 text-gray-900'>
                        My Profile Settings
                      </h2>
                      <p className='mt-1 text-sm text-gray-500'>Updating your profile settings. Please add a picture so that people know you who are!</p>
                    </div>
                    <UserProfileForm
                      FORM_firstName={this.state.FORM_firstName}
                      FORM_lastName={this.state.FORM_lastName}
                      FORM_email={this.state.FORM_email}
                      FORM_phoneNumber={this.state.FORM_phoneNumber}
                      shouldUpdatePhoto={this.state.shouldUpdatePhoto}
                      initialPhotoUrl={user.fields?.photo ? user.fields?.photo?.url : null}
                      isAdminProfile={true}
                      handleChange={this.handleChange}
                      handleFileUploadChange={this.handleFileUploadChange}
                      onSubmit={this.handlePatchUser}
                    />

                    {this.state.canvas && <>{this.state.canvas}</>}
                  </div>
                  <div className='px-4 py-3 text-right bg-gray-50 sm:px-6'>
                    <Button onClick={this.handlePatchUser}>Save</Button>
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* /End replace */}
        </div>
      </>
    )
  }
}

export default withRouter(MyProfilePage)
