import React from 'react';
import { withTranslation } from "react-i18next";
import { visaOptions } from '../../../../util/constants.js'
// import AvatarEditor from 'react-avatar-editor'
import ReactCrop from 'react-image-crop';
import { getCroppedImg, makeid } from '../../../../util/helpers'
import { PhotographIcon, CheckIcon } from '@heroicons/react/outline';

class InviteEmployerForm extends React.Component {
    render() {
        const { t } = this.props // translations
        // const photoUrl = this.props.FORM_photo ? URL.createObjectURL(this.props.FORM_photo) : null

        return (
            <form className="space-y-8 divide-y divide-gray-200" onSubmit={this.props.onSubmit}>
                <div className="space-y-8 divide-y divide-gray-200">
                    <div>
                        <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_firstName" className="block text-sm font-medium text-gray-700">
                                    First name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_firstName"
                                        id="FORM_firstName"
                                        autoComplete="given-name"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                        value={this.props.FORM_firstName || ''}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-3">
                                <label htmlFor="FORM_lastName" className="block text-sm font-medium text-gray-700">
                                    Last name
                                </label>
                                <div className="mt-1">
                                    <input
                                        type="text"
                                        name="FORM_lastName"
                                        id="FORM_lastName"
                                        autoComplete="family-name"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                        value={this.props.FORM_lastName || ''}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>

                            <div className="sm:col-span-4">
                                <label htmlFor="FORM_email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="FORM_email"
                                        name="FORM_email"
                                        type="email"
                                        autoComplete="email"
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                        value={this.props.FORM_email || ''}
                                        onChange={this.props.handleChange}
                                    />
                                </div>
                            </div>


                            {/* <div className="sm:col-span-4">
                                <label htmlFor="FORM_phoneNumber" className="block text-sm font-medium text-gray-700">
                                    Phone Number
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="FORM_phoneNumber"
                                        name="FORM_phoneNumber"
                                        type="tel"
                                        autoComplete="tel"
                                        onChange={this.props.handleChange}
                                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                        value={this.props.FORM_phoneNumber || ''}
                                    />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
                <button className={'hidden'} type="submit" />
            </form>
        );
    }
}

export default withTranslation()(InviteEmployerForm)