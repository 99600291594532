import React from 'react'
import { DayPicker } from 'react-day-picker'
import { format, parseISO} from 'date-fns'
import AvatarImage from '../../../components/AvatarImage'
import { useContext } from 'react'
import { StateContext } from '../../../util/state'
import { useTranslation } from 'react-i18next';

const Step7ReviewAndConfirm = props => {
  const [{user}] = useContext(StateContext)
  const photoUrl = props?.formValues?.FORM_photo ? URL.createObjectURL(props?.formValues?.FORM_photo) : null
  const { t, i18n } = useTranslation();

  return (
    <div className='mb-6'>
      <div className='mb-6 space-y-6'>
        <div className='overflow-hidden bg-white shadow'>
          <div className='px-4 py-5'>
            <h3 className='text-lg font-medium leading-6 text-gray-900'>{t('Personal Information')}</h3>
          </div>
          <div className='border-t border-gray-200 px-4 py-4'>
            <dl className=''>
              <div className='py-2'>
                <dt className='text-sm font-medium text-gray-500'>{t('Full name')}</dt>
                <dd className='mt-1 text-sm text-gray-900 '>{user?.fields?.displayName}</dd>
              </div>
              {user?.fields?.passportNumber && (
                <div className='py-2'>
                  <dt className='text-sm font-medium text-gray-500'>{t('Passport #')}</dt>
                  <dd className='mt-1 text-sm text-gray-900 '>{user?.fields?.passportNumber}</dd>
                </div>
              )}
              {/* <div className='py-2'>
                <dt className='text-sm font-medium text-gray-500'>{t('Date of Birth')}</dt>
                <dd className='mt-1 text-sm text-gray-900 '>{user?.fields?.dateOfBirth ? format(parseISO(user?.fields?.dateOfBirth), 'PP') : ''}</dd>
              </div> */}
              <div className='py-2'>
                <dt className='text-sm font-medium text-gray-500'>{t('Preferred Contact Method')}</dt>
                <dd className='mt-1 text-sm text-gray-900  flex'>
                  {props?.formValues?.FORM_preferredContactMethod?.id === 'PHONE' ? user?.fields?.displayPhoneNumber : props?.formValues?.FORM_preferredContactMethod?.id === 'EMAIL' ? user?.fields?.email : 'whatsapp'}
                </dd>
              </div>
              <div className='py-2'>
                <dt className='text-sm font-medium text-gray-500'>{t('Photo')}</dt>
                <dd className='mt-1 text-sm text-gray-900 '>{photoUrl ? <img className='h-10 w-10 flex-shrink-0 rounded-full bg-gray-300' src={photoUrl} alt='' /> : <AvatarImage size='10' />}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Step7ReviewAndConfirm
