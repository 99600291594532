import { ExternalLinkIcon, LinkIcon } from '@heroicons/react/solid';
import React, { Component } from 'react';
import { withTranslation } from "react-i18next";
import { getCroppedImg, makeid } from '../../util/helpers';
import ReactCrop from 'react-image-crop';

class ImageCropper extends Component {
    constructor(props) {
        super(props);
        this.imageRef = React.createRef()
    }

    state = {
        focused: false,
        photoUrl: undefined,
        crop: this.props.initialCrop ? this.props.initialCrop : {
            unit: '%', // default, can be 'px' or '%'
            x: 0,
            y: 0,
            width: 100,
            aspect: 1
            // height: 200
        }
    }

    componentDidMount() {
        if (this.props.initialDocumentUrl) {
            fetch(this.props.initialDocumentUrl)
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    // Here's where you get access to the blob          
                    this.setState({ photoUrl: URL.createObjectURL(blob), rawPhoto: blob })
                })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.initialDocumentUrl && !prevProps.initialDocumentUrl) {
            fetch(this.props.initialDocumentUrl)
                .then(res => res.blob()) // Gets the response and returns it as a blob
                .then(blob => {
                    // Here's where you get access to the blob          
                    this.setState({ photoUrl: URL.createObjectURL(blob), rawPhoto: blob })
                })
        }
    }

    handleFileChange = (event) => {
        const photo = event.target.files[0]
        const photoUrl = photo ? URL.createObjectURL(photo) : null
        this.setState({ photoUrl: photoUrl, rawPhoto: photo })
        // With PDF, we just dispatch the event now. With images, we let the cropper do that
        if (photo.type === 'application/pdf') {
            this.dispatchEvent(photo)
        }
    }

    saveCrop = async (newCrop) => {
        if (this.state.rawPhoto.type === 'application/pdf') return
        // console.log(this.state.crop)
        // console.log(newCrop)
        // console.log(this.props.initialCrop == this.state.crop)

        this.setState({ crop: newCrop, })

        // TODO: debounce this https://levelup.gitconnected.com/debounce-in-javascript-improve-your-applications-performance-5b01855e086
        let name = `${makeid(10)}.jpeg`
        const document = await getCroppedImg(this.imageRef, newCrop, name)
        if (this.state.photoUrl) {
            this.dispatchEvent(document)
        }
    }

    dispatchEvent = (document) => {
        const event = {
            target: {
                name: this.props.formInputName, // E.g. FORM_photo
                value: document
            }
        }
        this.props.handleChange(event)
    }

    render() {
        const { t } = this.props // translations
        return (
            <>
                <div className="sm:col-span-6">
                    <label htmlFor="photo" className="block text-sm font-medium text-gray-700">
                        <p>{this.props.formLabel || t('Choose a picture or PDF')} <a target='_blank' className={`flex float-right font-medium text-blue-600 hover:text-blue-500 ${this.props.initialDocumentUrl ? '' : 'hidden'}`} href={this.props.initialDocumentUrl}>Open <ExternalLinkIcon className="w-5 h-5 ml-2 text-gray-400 group-hover:text-gray-500" aria-hidden="true" /></a></p>
                    </label>


                    <div className="flex items-center mt-1">
                        <div className="flex items-center space-x-6">
                            <label className="block">
                                <span className="sr-only">Choose document photo</span>
                                <input
                                    type="file"
                                    className="block w-full text-sm text-gray-500 cursor-pointer file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-semibold file:bg-gray-50 file:text-gray-700 hover:file:bg-gray-100"
                                    accept="image/jpeg,image/jpg,image/png,application/pdf"
                                    label='Pick'
                                    onChange={this.handleFileChange}
                                />
                            </label>
                        </div>
                    </div>
                </div>
                <div className={`sm:col-span-6 ${this.state.photoUrl && this.state.rawPhoto?.type !== 'application/pdf' ? '' : 'hidden'}`}>
                    <div className="flex items-center">
                        <ReactCrop
                            src={this.state.photoUrl}
                            crop={this.state.crop}
                            keepSelection={true}
                            onImageLoaded={image => this.imageRef = image}
                            onChange={this.saveCrop}
                            className={`rounded`}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default withTranslation()(ImageCropper)